import React from 'react';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { AlphaPicker, CirclePicker } from 'react-color';
import clsx from 'clsx';
import ReactLoading from 'react-loading';
//Styles
import styles from './header-jss';
import './styles.css';

//Components
import IconButton from '@material-ui/core/IconButton';
import { IconButtonBase } from '../../uielements/iconbutton/IconButton';
import Typography from '@material-ui/core/Typography';
import { FormControl, Tooltip } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { AppBarB } from '../../uielements/appbar/AppBarB';
import { ButtonBase } from '../../uielements/button/ButtonBase';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import Autosuggest from 'react-autosuggest';

//Icons
import { faChartArea, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import MoreIcon from '@material-ui/icons/MoreVert';
import TimeLine from '@material-ui/icons/Timeline';
import { BsBarChart, BsSearch } from "react-icons/bs";
import { AiOutlineLineChart, AiOutlineEdit, AiOutlineDownload } from "react-icons/ai";
import { MdShowChart, MdOutlinePalette, MdTextFields, MdOutlineAccessTime } from "react-icons/md";
import { VscTrash, VscSave } from "react-icons/vsc";
import InsertChartOutlinedOutlined from '@material-ui/icons/InsertChartOutlinedOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { InsertChart } from '@material-ui/icons';

const useStyles = makeStyles(styles);

function Header(props) {
  const [selectedPeriod, setSelectedPeriod] = React.useState(null);
  const [selectedChart, setSelectedChart] = React.useState(null);
  const [secondary, setSecondary] = React.useState(false);
  const [open, setOpen] = React.useState(null);

  const handleClick = (menu) => {
    if (open !== menu && menu !== null) {
      setOpen(menu);
      return;
    }

    setOpen(null);
  };

  const classes = useStyles();
  const [
    anchorEl
    , setAnchorEl
  ] = React.useState(null);

  const [
    anchorTime
    , setAnchorTime
  ] = React.useState(null);

  const [
    anchorChart
    , setAnchorChart
  ] = React.useState(null);

  const [
    anchorDraw
    , setAnchorDraw
  ] = React.useState(null);

  const [
    anchorFunc
    , setAnchorFunc
  ] = React.useState(null);

  const [
    anchorExport
    , setAnchorExport
  ] = React.useState(null);

  const [
    anchorIndicator
    , setAnchorIndicator
  ] = React.useState(null);

  const [
    mobileMoreAnchorEl
    , setMobileMoreAnchorEl
  ] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMenuOpenTime = Boolean(anchorTime);
  const isMenuOpenChart = Boolean(anchorChart);
  const isMenuOpenFunc = Boolean(anchorFunc);
  const isExportMenuOpen = Boolean(anchorExport);
  const isMenuIndicatorOpen = Boolean(anchorIndicator);

  function handleProfileMenuOpen(event, menu) {
    switch (menu) {
      case `color`:
        setAnchorEl(event.currentTarget)
        break;
      case `time`:
        setAnchorTime(event.currentTarget)
        break;
      case `showChart`:
        setAnchorChart(event.currentTarget);
        break;
      case `draw`:
        setAnchorDraw(event.currentTarget)
        break;
      case `showFunction`:
        setAnchorFunc(event.currentTarget);
        break;
      case `export`:
        setAnchorExport(event.currentTarget);
        break;
      case `showIndicator`:
        setAnchorIndicator(event.currentTarget);
        break;
      default:
        break;
    }
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {

    setAnchorEl(null);
    setAnchorTime(null);
    setAnchorChart(null);
    setAnchorDraw(null);
    setAnchorFunc(null)
    setAnchorExport(null);
    setAnchorIndicator(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  const menuId = 'primary-search-account-menu';
  const menuIdTimer = 'primary-search-timer-menu';
  const menuIdShow = 'primary-search-show-menu';
  const renderMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {
        props.chartType === `line` || props.chartType === `area` ?
          <MenuItem>
            <List className={classes.w100}>
              <ListItem>
                <Typography variant={`inherit`}>{props.lang.line} </Typography>
              </ListItem>
              <ListItem>
                <CirclePicker
                  className={clsx(classes.mTop, classes.w100)}
                  circleSize={15}
                  circleSpacing={5}
                  color={props.colorLine}
                  onChangeComplete={(color) => props.handleChangeComplete(`stroke`, color)}
                />
              </ListItem>
            </List>
          </MenuItem> : ``
      }
      {
        props.chartType !== `line` && props.chartType !== `vela` && props.chartType !== `velahueca` ?
          <div>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.highestPoint}</Typography>
                </ListItem>
                <ListItem>
                  <CirclePicker
                    className={clsx(classes.mTop, classes.w100)}
                    circleSize={15}
                    circleSpacing={5}
                    color={props.highestPoint}
                    onChangeComplete={(color) => props.handleChangeComplete(`highestPoint`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.fill}</Typography>
                </ListItem>
                <ListItem>
                  <CirclePicker
                    className={clsx(classes.mTop, classes.w100)}
                    circleSize={15}
                    circleSpacing={5}
                    color={props.background}
                    onChangeComplete={(color) => props.handleChangeComplete(`fill`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.opacity}</Typography>
                </ListItem>
                <ListItem>
                  <AlphaPicker
                    className={classes.w100}
                    color={props.opacityFill}
                    onChangeComplete={(color) => props.handleChangeComplete(`opacityFill`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
          </div>
          : ``
      }

      {
        props.chartType === `vela` || props.chartType === `velahueca` ?
          <div>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.vela.upCandle}</Typography>
                </ListItem>
                <ListItem>
                  <CirclePicker
                    className={clsx(classes.mTop, classes.w100)}
                    circleSize={15}
                    circleSpacing={5}
                    color={props.upCandle}
                    onChangeComplete={(color) => props.handleChangeComplete(`upCandle`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.vela.downCandle}</Typography>
                </ListItem>
                <ListItem>
                  <CirclePicker
                    className={clsx(classes.mTop, classes.w100)}
                    circleSize={15}
                    circleSpacing={5}
                    color={props.downCandle}
                    onChangeComplete={(color) => props.handleChangeComplete(`downCandle`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.vela.opacity}</Typography>
                </ListItem>
                <ListItem>
                  <AlphaPicker
                    className={classes.w100}
                    color={props.opacityCandle}
                    onChangeComplete={(color) => props.handleChangeComplete(`opacityCandle`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.vela.upWick}</Typography>
                </ListItem>
                <ListItem>
                  <CirclePicker
                    className={clsx(classes.mTop, classes.w100)}
                    circleSize={15}
                    circleSpacing={5}
                    color={props.upWick}
                    onChangeComplete={(color) => props.handleChangeComplete(`upWick`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
          </div>
          : ``
      }

      {
        props.chartType === `vela` ?
          <div>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.vela.downWick}</Typography>
                </ListItem>
                <ListItem>
                  <CirclePicker
                    className={clsx(classes.mTop, classes.w100)}
                    circleSize={15}
                    circleSpacing={5}
                    color={props.downWick}
                    onChangeComplete={(color) => props.handleChangeComplete(`downWick`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.vela.upBorder}</Typography>
                </ListItem>
                <ListItem>
                  <CirclePicker
                    className={clsx(classes.mTop, classes.w100)}
                    circleSize={15}
                    circleSpacing={5}
                    color={props.upBorder}
                    onChangeComplete={(color) => props.handleChangeComplete(`upBorder`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
            <MenuItem>
              <List className={classes.w100}>
                <ListItem>
                  <Typography variant={`inherit`}>{props.lang.vela.downBorder}</Typography>
                </ListItem>
                <ListItem>
                  <CirclePicker
                    className={clsx(classes.mTop, classes.w100)}
                    circleSize={15}
                    circleSpacing={5}
                    color={props.downBorder}
                    onChangeComplete={(color) => props.handleChangeComplete(`downBorder`, color)}
                  />
                </ListItem>
              </List>
            </MenuItem>
          </div> : ``
      }
    </Menu>
  );

  //Menu periodo

  const renderHistory = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={anchorTime}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuIdTimer}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuOpenTime}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => {props.time(`10m`); setSelectedPeriod("10m");}}>
        10m
      </MenuItem>
      <MenuItem onClick={() => {props.time(`30m`);setSelectedPeriod("30m");}}>
        30m
      </MenuItem>
      <MenuItem onClick={() => {props.time(`2h`);setSelectedPeriod("2H");}}>
        2H
      </MenuItem>
      <MenuItem onClick={() => {props.time(`1D`);setSelectedPeriod("1D");}}>
        1D
      </MenuItem>
      <MenuItem onClick={() => {props.time(`30D`);setSelectedPeriod("30D");}}>
        30D
      </MenuItem>
      <MenuItem onClick={() => {props.time(`6M`);setSelectedPeriod("6M");}}>
        6M
      </MenuItem>
      <MenuItem onClick={() => {props.time(`1Y`);setSelectedPeriod(props.lang.period.one);}}>
        {props.lang.period.one}
      </MenuItem>
      <MenuItem onClick={() => {props.time(`2Y`);setSelectedPeriod(props.lang.period.two);}}>
        {props.lang.period.two}
      </MenuItem>
      <MenuItem onClick={() => {props.time(`3Y`);setSelectedPeriod(props.lang.period.three);}}>
        {props.lang.period.three}
      </MenuItem>
      <MenuItem onClick={() => {props.time(`Max`);setSelectedPeriod("Max.");}}>
        Max.
      </MenuItem>
    </Menu>
  );
  // Menú tipo de gráfico
  const renderChartType = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={anchorChart}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuIdShow}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuOpenChart}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => {props.changeChart(`line`); setSelectedChart(props.lang.typeChart.line)}}>
        <ListItemIcon>
          <TimeLine />
        </ListItemIcon>
        <Typography variant="inherit">{props.lang.typeChart.line}</Typography>
      </MenuItem>
      <MenuItem onClick={() => {props.changeChart(`area`); setSelectedChart(props.lang.typeChart.area)}}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faChartArea} />
        </ListItemIcon>
        <Typography variant="inherit">{props.lang.typeChart.area}</Typography>
      </MenuItem>
      {
        props.stockType !== 1 &&
        <MenuItem onClick={() => {props.changeChart(`vela`); setSelectedChart(props.lang.typeChart.vela)}} >
          <ListItemIcon>
            <InsertChart />
          </ListItemIcon>
          <Typography variant="inherit">{props.lang.typeChart.vela}</Typography>
        </MenuItem>
      }
      {
        props.stockType !== 1 &&
        <MenuItem onClick={() => {props.changeChart(`velahueca`); setSelectedChart(props.lang.typeChart.velahueca)}} >
          <ListItemIcon>
            <InsertChartOutlinedOutlined />
          </ListItemIcon>
          <Typography variant="inherit">{props.lang.typeChart.velahueca}</Typography>
        </MenuItem>
      }
    </Menu>
  )
  const renderFunction = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={anchorFunc}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuIdShow}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuOpenFunc}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => {
        props.createIndicator(`GANNFAN`)
        handleMenuClose()
      }}>
        <Typography variant="inherit">{props.lang.functions.gannFann}</Typography>
      </MenuItem>
      <MenuItem onClick={() => {
        props.createIndicator(`FB`)
        handleMenuClose()
      }} >
        <Typography variant="inherit">{props.lang.functions.fibonacci}</Typography>
      </MenuItem>
      <MenuItem onClick={() => {
        props.createIndicator(`TR`)
        handleMenuClose()
      }} >
        <Typography variant="inherit">{props.lang.functions.tendencia}</Typography>
      </MenuItem>
    </Menu>
  )

  const renderExportMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={anchorExport}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuIdShow}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isExportMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { props.exportToImage() }}><Typography variant="inherit">{props.lang.options.Image}</Typography></MenuItem>
      <MenuItem onClick={() => { props.exportToCSV() }}><Typography variant="inherit">{props.lang.options.CSV}</Typography></MenuItem>
      <MenuItem onClick={() => { props.exportToXLSX() }}><Typography variant="inherit">{props.lang.options.XLSX}</Typography></MenuItem>
      <MenuItem onClick={() => { props.editTechnical() }}><Typography variant="inherit">{props.lang.options.Edit}</Typography></MenuItem>
      {/* <MenuItem onClick={() => { props.deleteTechnical() }}><Typography variant="inherit">{props.lang.options.Delete}</Typography></MenuItem> */}
    </Menu>
  )

  const renderIndicatorMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={anchorIndicator}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuIdShow}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuIndicatorOpen}
      keepMounted={false}
      onClose={handleMenuClose}
    >
      <MenuItem value="" disabled>{props.lang.header.indicator}</MenuItem>
      <MenuItem onClick={() => {
        props.createIndicator(`SMA`);
        handleMenuClose()
      }} value={'SMA'}>{props.lang.Indicators.sma}</MenuItem>
      <MenuItem onClick={() => {
        props.createIndicator(`EMA`)
        handleMenuClose()
      }} value={'EMA'}>{props.lang.Indicators.ema}</MenuItem>
      <MenuItem onClick={() => {
        props.createIndicator(`MACD`)
        handleMenuClose()
      }} value={'MACD'}>{props.lang.Indicators.macd}</MenuItem>
      <MenuItem onClick={() => {
        props.createIndicator(`RSI`)
        handleMenuClose()
      }} value={`RSI`}>{props.lang.Indicators.rsi}</MenuItem>
      <MenuItem onClick={() => {
        props.createIndicator(`BBands`)
        handleMenuClose()
      }} value={`BBands`}>{props.lang.Indicators.bbands}</MenuItem>
      {
        props.currentStockType !== 6 && props.currentStockType !== 3 && props.currentStockType !== 4 ?
          <MenuItem onClick={() => {
            props.createIndicator(`Volume`)
            handleMenuClose()
          }} value={`Volume`}>{props.lang.Indicators.volume}</MenuItem> : ``
      }
      <MenuItem onClick={() => {
        props.createIndicator(`Stochastic`)
        handleMenuClose()
      }} value={`Stochastic`}>{props.lang.Indicators.stochastic}</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.menu}
    > 
      {/* Funciones */}
      <List>
        <ListItem onClick={() => handleClick("tecnicos")} className={classes.sizeText}>
          <ListItemIcon>
            <BsBarChart />
          </ListItemIcon>
          <Typography variant={`inherit`}>{props.lang.header.functions}</Typography>
          {open === "tecnicos" ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open === "tecnicos"} timeout="auto" unmountOnExit>
          <MenuItem onClick={() => {
            props.createIndicator(`GANNFAN`)
            handleMenuClose()
          }}>
            <Typography variant="inherit">{props.lang.functions.gannFann}</Typography>
          </MenuItem>
          <MenuItem onClick={() => {
            props.createIndicator(`FB`)
            handleMenuClose()
          }} >
            <Typography variant="inherit">{props.lang.functions.fibonacci}</Typography>
          </MenuItem>
          <MenuItem onClick={() => {
            props.createIndicator(`TR`)
            handleMenuClose()
          }} >
            <Typography variant="inherit">{props.lang.functions.tendencia}</Typography>
          </MenuItem>
        </Collapse>
      </List>
      {/* Tipo de gráfica */}
      <List>
        <ListItem onClick={() => handleClick("grafica")} className={classes.sizeText}>
          <ListItemIcon>
            <AiOutlineLineChart />
          </ListItemIcon>
          <Typography variant={`inherit`}>{props.lang.header.typeChart}</Typography>
          {open === "grafica" ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open === "grafica"} timeout="auto" unmountOnExit>
          <MenuItem onClick={() => props.changeChart(`line`)}>
            <ListItemIcon>
              <TimeLine  className={classes.iconSmall} />
            </ListItemIcon>
            <Typography variant="inherit">{props.lang.typeChart.line}</Typography>
          </MenuItem>
          <MenuItem onClick={() => props.changeChart(`area`)}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faChartArea} className={classes.iconSmall} />
            </ListItemIcon>
            <Typography variant="inherit">{props.lang.typeChart.area}</Typography>
          </MenuItem>
          <MenuItem onClick={() => props.changeChart(`vela`)} >
            <ListItemIcon>
              <InsertChart  className={classes.iconSmall} />
            </ListItemIcon>
            <Typography variant="inherit">{props.lang.typeChart.vela}</Typography>
          </MenuItem>
          <MenuItem onClick={() => props.changeChart(`velahueca`)} >
            <ListItemIcon>
              <InsertChartOutlinedOutlined className={classes.iconSmall} />
            </ListItemIcon>
            <Typography variant="inherit">{props.lang.typeChart.velahueca}</Typography>
          </MenuItem>
        </Collapse>
      </List>
      {/* Periodo */}
      <List>
        <ListItem onClick={() => handleClick("periodo")} className={classes.sizeText}>
          <ListItemIcon>
            <MdOutlineAccessTime />
          </ListItemIcon>
          <Typography variant={`inherit`}>
            {!selectedPeriod ? 'Max.' : selectedPeriod}
          </Typography>
          {open === "periodo" ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open === "periodo"} timeout="auto" unmountOnExit>
          <MenuItem onClick={() => {props.time(`10m`); setSelectedPeriod("10m");}}>
            10m
          </MenuItem>
          <MenuItem onClick={() => {props.time(`30m`); setSelectedPeriod("30m");}}>
            30m
          </MenuItem>
          <MenuItem onClick={() => {props.time(`2h`); setSelectedPeriod("2H");}}>
            2H
          </MenuItem>
          <MenuItem onClick={() => {props.time(`1D`); setSelectedPeriod("1D");}}>
            1D
          </MenuItem>
          <MenuItem onClick={() => {props.time(`30D`); setSelectedPeriod("30D");}}>
            30D
          </MenuItem>
          <MenuItem onClick={() => {props.time(`6M`); setSelectedPeriod("6M");}}>
            6M
          </MenuItem>
          <MenuItem onClick={() => {props.time(`1Y`); setSelectedPeriod(props.lang.period.one);}}>
            {props.lang.period.one}
          </MenuItem>
          <MenuItem onClick={() => {props.time(`2Y`); setSelectedPeriod(props.lang.period.two);}}>
            {props.lang.period.two}
          </MenuItem>
          <MenuItem onClick={() => {props.time(`3Y`); setSelectedPeriod(props.lang.period.three);}}>
            {props.lang.period.three}
          </MenuItem>
          <MenuItem onClick={() => {props.time(`Max`); setSelectedPeriod("Max.");}}>
            Max.
          </MenuItem>
        </Collapse>
      </List>
      {/* Color */}
      <List>
        <ListItem onClick={() => handleClick("colores")} className={classes.sizeText}>
          <ListItemIcon>
            <MdOutlinePalette />
          </ListItemIcon>
          <Typography variant={`inherit`}>{props.lang.header.color}</Typography>
          {open === "colores" ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open === "colores"} timeout="auto" unmountOnExit>
          {
            props.chartType !== `vela` || props.chartType !== `velahueca` ?
              <MenuItem>
                <List className={clsx(classes.p0, classes.w100)}>
                  <ListItem className={classes.item}>
                    <Typography variant={`inherit`}>{props.lang.line}</Typography>
                  
                    <CirclePicker
                      className={clsx(classes.w100)}
                      circleSize={15}
                      circleSpacing={5}
                      color={props.colorLine}
                      onChangeComplete={(color) => props.handleChangeComplete(`stroke`, color)}
                    />
                  </ListItem>
                </List>
              </MenuItem> : ``
          }
          {
            props.chartType !== `line` && props.chartType !== `vela` && props.chartType !== `velahueca` ?
              <div>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.highestPoint}</Typography>
                    
                      <CirclePicker
                        className={classes.w100}
                        circleSize={15}
                        circleSpacing={5}
                        color={props.highestPoint}
                        onChangeComplete={(color) => props.handleChangeComplete(`highestPoint`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.fill}</Typography>
                    
                      <CirclePicker
                        className={classes.w100}
                        circleSize={15}
                        circleSpacing={5}
                        color={props.background}
                        onChangeComplete={(color) => props.handleChangeComplete(`fill`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.opacity}</Typography>
                    
                      <AlphaPicker
                        className={classes.w100}
                        color={props.opacityFill}
                        onChangeComplete={(color) => props.handleChangeComplete(`opacityFill`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
              </div>
              : ``
          }
          {
            props.chartType === `vela` || props.chartType === `velahueca` ?
              <div>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.vela.upCandle}</Typography>
                    
                      <CirclePicker
                        className={clsx(classes.w100)}
                        circleSize={15}
                        circleSpacing={5}
                        color={props.upCandle}
                        onChangeComplete={(color) => props.handleChangeComplete(`upCandle`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.vela.downCandle}</Typography>
                    
                      <CirclePicker
                        className={clsx(classes.w100)}
                        circleSize={15}
                        circleSpacing={5}
                        color={props.downCandle}
                        onChangeComplete={(color) => props.handleChangeComplete(`downCandle`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.vela.opacity}</Typography>
                    
                      <AlphaPicker
                        className={classes.w100}
                        color={props.opacityCandle}
                        onChangeComplete={(color) => props.handleChangeComplete(`opacityCandle`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.vela.upWick}</Typography>
                    
                      <CirclePicker
                        className={clsx(classes.w100)}
                        circleSize={15}
                        circleSpacing={5}
                        color={props.upWick}
                        onChangeComplete={(color) => props.handleChangeComplete(`upWick`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.vela.downWick}</Typography>
                    
                      <CirclePicker
                        className={clsx(classes.w100)}
                        circleSize={15}
                        circleSpacing={5}
                        color={props.downWick}
                        onChangeComplete={(color) => props.handleChangeComplete(`downWick`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.vela.upBorder}</Typography>
                    
                      <CirclePicker
                        className={clsx(classes.w100)}
                        circleSize={15}
                        circleSpacing={5}
                        color={props.upBorder}
                        onChangeComplete={(color) => props.handleChangeComplete(`upBorder`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
                <MenuItem>
                  <List className={clsx(classes.p0, classes.w100)}>
                    <ListItem className={classes.item}>
                      <Typography variant={`inherit`}>{props.lang.vela.downBorder}</Typography>
                    
                      <CirclePicker
                        className={clsx(classes.w100)}
                        circleSize={15}
                        circleSpacing={5}
                        color={props.downBorder}
                        onChangeComplete={(color) => props.handleChangeComplete(`downBorder`, color)}
                      />
                    </ListItem>
                  </List>
                </MenuItem>
              </div>
              : ``
          }
        </Collapse>
      </List>
      {/* Dibujar */}
      <List>
        <ListItem className={classes.sizeText}
          onClick={(event) => {
            handleProfileMenuOpen(event, 'draw')
            props.handleEnableDrawnLine()
          }}>
          <ListItemIcon>
            <AiOutlineEdit />
          </ListItemIcon>
          <Typography variant={`inherit`}>{props.lang.header.draw}</Typography>
        </ListItem>
      </List>
      {/* Escribir */}
      <List>
        <ListItem onClick={() => props.enableText()} className={classes.sizeText}>
          <ListItemIcon>
            <MdTextFields />
          </ListItemIcon>
          <Typography variant={`inherit`}>{props.lang.header.write}</Typography>
        </ListItem>
      </List>
      {/* Descargar / Opciones */}
      <List>
        <ListItem className={classes.sizeText} onClick={() => handleClick("opciones")}>
          <ListItemIcon>
            <AiOutlineDownload />
          </ListItemIcon>
          <Typography variant={`inherit`}>{props.lang.options.Options}</Typography>
          {open === "opciones" ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open === "opciones"} timeout="auto" unmountOnExit>
          <MenuItem onClick={() => { props.exportToImage() }}><Typography variant="inherit">{props.lang.options.Image}</Typography></MenuItem>
          <MenuItem onClick={() => { props.exportToCSV() }}><Typography variant="inherit">{props.lang.options.CSV}</Typography></MenuItem>
          <MenuItem onClick={() => { props.exportToXLSX() }}><Typography variant="inherit">{props.lang.options.XLSX}</Typography></MenuItem>
          <MenuItem onClick={() => { props.exportToEdit() }}><Typography variant="inherit">{props.lang.options.Edit}</Typography></MenuItem>
          {/* <MenuItem onClick={() => { props.deleteTechnical() }}><Typography variant="inherit">{props.lang.options.Delete}</Typography></MenuItem> */}
        </Collapse>
      </List>
      {/* Eliminar filtros */}
      {/* <List>
          <ListItem className={classes.sizeText}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faStar} size="sm" />
            </ListItemIcon>
            <Typography variant={`inherit`}>{props.lang.Default}</Typography>
          </ListItem>
        </List> */}
      {/* Eliminar filtros */}
      <List>
        <ListItem className={classes.sizeText}>
          <ListItemIcon onClick={() => props.deleteTechnical()}>
            <VscTrash />
          </ListItemIcon>
          <Typography variant={`inherit`}>{props.lang.Remove}</Typography>
        </ListItem>
      </List>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBarB position="static">
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item
              xs={12}
              className={classes.dflex}>
              {/* Botón Autocomplete*/}
              <FormControl className={classes.formControl}>
                <Autosuggest
                  suggestions={props.suggestions}
                  onSuggestionsFetchRequested={props.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={props.onSuggestionsClearRequested}
                  getSuggestionValue={props.getSuggestionValue}
                  renderSuggestion={props.renderSuggestion}
                  inputProps={props.inputProps}
                  theme={{
                    input: classes.autocompleteInput,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                  }}
                />
               <span className={classes.inputIcon}>
                <BsSearch className={classes.colorIcon}/> 
               </span> 
              </FormControl>
              {/* Botón Indicadores*/}
              {/* <FormControl className={classes.formControl}>
                <SelectBase
                  disableUnderline={true}
                  value={props.currentIndicator}
                  onChange={props.createIndicator}
                  name="indicatorId"
                  id="indicatorId"
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value="" disabled>{props.lang.header.indicator}</MenuItem>
                  <MenuItem value={'SMA'}>{props.lang.Indicators.sma}</MenuItem>
                  <MenuItem value={'EMA'}>{props.lang.Indicators.ema}</MenuItem>
                  <MenuItem value={'MACD'}>{props.lang.Indicators.macd}</MenuItem>
                  <MenuItem value={`RSI`}>{props.lang.Indicators.rsi}</MenuItem>
                  <MenuItem value={`BBands`}>{props.lang.Indicators.bbands}</MenuItem>
                  {
                    props.currentStockType !== 6 && props.currentStockType !== 3 && props.currentStockType !== 4 ?
                      <MenuItem value={`Volume`}>{props.lang.Indicators.volume}</MenuItem> : ``
                  }
                  <MenuItem value={`Stochastic`}>{props.lang.Indicators.stochastic}</MenuItem>
                </SelectBase>
              </FormControl> */}
              <div className={classes.sectionDesktop}>
                <div className={classes.dflex}>
                  <Tooltip title={props.lang.header.indicator} arrow>
                    <ButtonBase
                      disableRipple
                      className="secondary"
                      onClick={(event) => handleProfileMenuOpen(event, 'showIndicator')}
                      edge="end"
                      aria-label="showchart"
                      aria-controls={menuIdTimer}
                      aria-haspopup="true">
                      <MdShowChart className={classes.iconBtn} />
                      <span className={classes.textBtn}>{props.lang.header.indicator}</span>
                    </ButtonBase>
                  </Tooltip>
                  {/* Botón Funciones*/}
                  <Tooltip title={props.lang.header.technicals} arrow>
                    <ButtonBase
                      disableRipple
                      className="secondary"
                      onClick={(event) => handleProfileMenuOpen(event, 'showFunction')}
                      edge="end"
                      aria-label="showchart"
                      aria-controls={menuIdTimer}
                      aria-haspopup="true">
                      <BsBarChart className={classes.small} />
                      <span className={classes.textBtn}>{props.lang.header.functions}</span>
                    </ButtonBase>
                  </Tooltip>
                  {/* Menú tipo de gráfica */}
                  <Tooltip title={props.lang.header.types} arrow>
                    <ButtonBase
                      disableRipple
                      className="secondary"
                      onClick={(event) => handleProfileMenuOpen(event, 'showChart')}
                      edge="end"
                      aria-label="showchart"
                      aria-controls={menuIdTimer}
                      aria-haspopup="true">
                      <AiOutlineLineChart className={classes.iconBtn} />
                      <span className={classes.textBtn}>
                        {/* {props.lang.header.typeChart} */}
                        {!selectedChart ? props.lang.header.typeChart : selectedChart}
                      </span>
                    </ButtonBase>
                  </Tooltip>
                  {/* Menú periodo */}
                  <Tooltip title={props.lang.header.period} arrow>
                    <ButtonBase
                      disableRipple
                      className="secondary"
                      onClick={(event) => handleProfileMenuOpen(event, 'time')}
                      edge="end"
                      aria-label="timeseries"
                      aria-controls={menuIdTimer}
                      aria-haspopup="true">
                      <MdOutlineAccessTime className={classes.iconBtn} />
                      <span className={classes.textBtn}>
                        {/* {props.lang.header.period} */}
                        {!selectedPeriod ? 'Max.' : selectedPeriod} 
                      </span>
                    </ButtonBase>
                  </Tooltip>
                  {/* Menú color */}
                  <Tooltip title={props.lang.header.colors} arrow>
                    <ButtonBase
                      disableRipple
                      className="secondary"
                      onClick={(event) => handleProfileMenuOpen(event, 'color')}
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true">
                      <MdOutlinePalette className={classes.iconBtn} />
                      <span className={classes.textBtn}>{props.lang.header.color}</span> 
                    </ButtonBase>
                  </Tooltip>
                  {/* Botón dibujar*/}
                  <Tooltip title={props.lang.header.draw} arrow>
                    <ButtonBase
                      disableRipple
                      className="secondary"
                      onClick={(event) => {
                        handleProfileMenuOpen(event, 'draw')
                        props.handleEnableDrawnLine()
                      }}
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true">
                      <AiOutlineEdit className={classes.iconBtn} />
                      <span className={classes.textBtn}>{props.lang.header.draw}</span>
                    </ButtonBase>
                  </Tooltip>
                  {/* Botón escribir */}
                  <Tooltip title={props.lang.header.write} arrow>
                    <ButtonBase
                      disableRipple
                      onClick={() => props.enableText()}
                      className="secondary">
                      <MdTextFields className={classes.iconBtn} />
                      <span className={classes.textBtn}>{props.lang.header.write}</span>
                    </ButtonBase>
                  </Tooltip>
                </div>
                <div className={classes.dflex}>
                  {/* Limpiar Descargar */}
                  <Tooltip className={classes.margin8} title={props.lang.header.options} arrow>
                    <IconButtonBase className="secondary" onClick={(event) => handleProfileMenuOpen(event, 'export')}>
                      <FontAwesomeIcon icon={faEllipsisV} className={classes.m0} />
                    </IconButtonBase>
                  </Tooltip>
                  {/* Favorito */}
                  {/* <div className={classes.margin8}>
                    <IconButtonBase className="secondary">
                      <FontAwesomeIcon icon={faStar} className={classes.m0} />
                    </IconButtonBase>
                  </div> */}
                  {/* Limpiar filtros */}
                  <Tooltip className={classes.margin8} title={props.lang.header.save} arrow>
                    <IconButtonBase className="secondary" onClick={() => props.saveIndicators()}>
                      <VscSave />
                    </IconButtonBase>
                  </Tooltip>
                  <Tooltip className={classes.margin8} title={props.lang.header.delete} arrow>
                    <IconButtonBase className="secondary" onClick={() => props.deleteTechnical()}>
                      <VscTrash />
                    </IconButtonBase>
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className={classes.sectionMobile}>
            <Tooltip title={props.lang.header.more}>
              <IconButton
                disableRipple
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon className={classes.colorIcon} />
            </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBarB>
      {renderMobileMenu}
      {renderMenu}
      {renderHistory}
      {renderChartType}
      {renderFunction}
      {renderExportMenu}
      {renderIndicatorMenu}
    </div>
  );
}

export default withStyles(styles)(Header)