//File with environment purpouses 

//Load Settings - DEV

// export const urlSettings = "https://bvl-qa.financialsolutions.app/services/apps/loadsettings";
// export const auxUrl = "https://bvl-qa.financialsolutions.app/services/";


// //Load Settings - QA
export const urlSettings = "https://ppromotorqa.financialsolutions.mx/ppromotor/apps/loadsettings";
export const auxUrl = "https://ppromotorqa.financialsolutions.mx/ppromotor/";


//Load Settings - PROD
// export const urlSettings = "https://pantallapromotor.financialsolutions.mx/ppromotor/apps/loadsettings";
// export const auxUrl = "https://pantallapromotor.financialsolutions.mx/ppromotor/";

//Download
export const download = "";

//External APIS 
//DEV
//export const fileManagerAPI = "https://gchbasa1q9.execute-api.us-east-1.amazonaws.com/dev"; //Temporal
//QA
// export const fileManagerAPI = "https://dh1ib546gf.execute-api.us-east-1.amazonaws.com/qa"; //Temporal
export const fileManagerAPI = "https://routeToFileManagerApi.com" //Temporal, change for the right one if needed
// PROD FileManager
//export const fileManagerAPI = "https://d2z7r5r464.execute-api.us-east-1.amazonaws.com/prod"; //Temporal

//Params 
export const paramsSession = "params"; 
export const params = {
    "params": [
      "AWS_FILEMANAGER_FOLDER",
      "AWS_BUCKETNAME",
      "DECIMAL_SEPARATOR",
      "GMT",
      "MARKET_CLOSE",
      "MARKET_DAYS",
      "MARKET_OPEN",
      "PRODUCTION",
      "THOUSAND_SEPARATOR",
      "TIMEOUT_SERVICES",
      "URL_SERVICES",
      "URL_SERVICES_INTERNAL",
      "VERSION",
      "LANG_BR",
      "MODULES",
      "SESSION_NAME"
    ]
}
