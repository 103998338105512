import React from 'react';
import styled from 'styled-components';
import { Select } from '@material-ui/core';

const SelectBase = styled(({ className, ...props }) => (
    <Select {...props} MenuProps={{ classes: { paper: className } }} />
  ))`
    && {
    //   background-color: red;
      .primary {
          background: blue;
      }
    }
  `;

export { SelectBase };