module.exports = {
    main: {
        typography: {
            fontFamily: 'Nunito, Sans serif',
            fontSize: 10,
        },
        overrides: {
            MuiToolbar: {
                gutters: {
                    padding: '0 16px !important',
                }
            },
            MuiFormControl: {
                root: {
                    width: 'auto',
                }
            },
            MuiSelect: {
                root: {
                    color: '#666',
                    fontSize: '0.825rem',
                    lineHeight: '1.475',
                    padding: '6px 12px !important',

                    '&:focus': {
                        backgroundColor: `#fff`,
                    }
                },
                icon: {
                    color: '#b6b6b7',
                    top: 'calc(50% - 9px)'
                },
                select: {
                    borderRadius: '3px !important',
                    border: '1px solid rgba(0,0,0,0.07)',
                    '&:focus': {
                        borderColor: '#183c96',
                        backgroundColor: 'transparent'
                    }
                }
            },
            MuiMenuItem: {
                root: {
                    fontSize: '0.75rem',
                    minHeight: 0,
                }
            },
            MuiInputBase: {
                root: {
                    background: '#fff',
                    borderRadius: '3px',
                    color: '#b6b6b7',
                    fontSize: '0.875rem',
                    marginTop: '0 !important'
                },
                input: {
                    border: '1px solid rgba(0,0,0,0.07)',
                    borderRadius: '3px',
                    color: '#b6b6b7',
                    padding: '6px 12px',
                },
                inputMultiline: {
                    border: '1px solid rgba(0,0,0,0.07)',
                    borderRadius: '3px',
                    color: '#666',
                    padding: '6px',
                }
            },
            MuiInput: {
                underline: {
                    "&::before": {
                        borderBottom: '0 !important',
                    },
                    "&::after": {
                        borderBottom: '0 !important',
                    },
                }
            },
            MuiDialogTitle: {
                root: {
                    padding: '10px 24px',
                }
            },
            MuiDialog: {
                paperWidthSm: {
                    '@media screen and (max-width: 668px)': {
                        minWidth: '95%',
                    }
                },
            },
            MuiList: {
                padding: {
                    padding: `8px`,
                }
            },
            MuiListItem: {
                root: {
                    color: `#4d627b`,
                    marginBottom: `2px`,
                    '&:hover':{
                        backgroundColor: `#f4f4f4 !important`,
                        color: `#183c96 !important`,
                    },
                    '&:focus': {
                        backgroundColor: `#f4f4f4 !important`,
                        backgroundImage: `linear-gradient(0deg,#1e45a8 2px,rgba(30,69,168,0) 0),linear-gradient(0deg,#232d31 1px,rgba(35,45,49,0) 0)`,
                        backgroundSize: `100% 100%, 100% 100%`,
                        color: `#183c96 !important`,
                    },
                }
            },
            MuiListItemIcon: {
                root:{
                    color: `#4d627b`,
                    minWidth: `24px`,
                    '&:hover': {
                        color: `#183c96 !important`,
                    },
                    '&:focus': {
                        color: `#183c96 !important`,
                    } 
                }
            },
            MuiPickersDay: {
                day: {
                    color: '#b6b6b7',
                },
                dayDisabled: {
                    color: '#aeaeae',
                },
                current: {
                    color: '#356cff !important',
                    backgroundColor: ' transparent !important',
                    fontWeight: '600 !important',
                },
                daySelected: {
                    color: '#fff !important',
                    backgroundColor: '#ff6767 !important',
                }
            },
            MuiCheckbox: {
                colorSecondary: {
                    color: '#356cff !important',
                }
            },
            MuiTreeItem: {
                root: {
                    '&:focus > $content': {
                        backgroundColor: '#edecf1',
                    },
                }
            },
            MuiSvgIcon: {
                root: {
                    fontSize: '1.25rem !important',  
                }
            },
            MuiTypography: {
                root: {
                    color: '#666',
                }
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor:'#3b444e',
                    fontSize: '0.75rem',
                },
            }
        },
        palette: {
            primary: {
                light: '#356cff',
                main: '#ffffff',
                dark: '#b6b6b7',
                contrastText: '#4a4a4a',
                borderBorder: 'rgba(0,0,0,0.07)',
                textColor: '#b6b6b7',
                //Btn Autocomplete
                bgAutocomplete: '#fff',
                borderAutocomplete: 'rgba(0,0,0,.07)',
                colorAutocomplete: '#666',
                //Btn suggestionsContainerOpen
                bgSuggestions: '#fff',
                //
                colorIcon: '#666',
                bgColor: '#fff',
                bgColorSecond: '#fff',
                borderInput: 'rgba(0,0,0,0.07)',
                borderFocus: '#183c96',
                ligthColor: '#aeaeae',
            },
            secondary: {
                light: '#9ec4db',
                main: '#00497f',
                dark: '#00385b',
                contrastText: '#000',
                textColor: '#4a4a4a',
            },

        },
        Red: '#356cff',
        Redlighten20: '#ff6767',
    },
    maindark: {
        typography: {
            fontFamily: 'Nunito, Sans serif',
            fontSize: 10,
        },
        overrides: {
            MuiToolbar: {
                gutters: {
                    padding: '0 16px !important',
                }
            },
            MuiFormControl: {
                root: {
                    width: 'auto',
                }
            },
            MuiSelect: {
                root: {
                    fontSize: '0.825rem',
                    lineHeight: '1.475',
                    padding: '6px 12px !important',
                },
                icon: {
                    color: '#d4d4d4',
                    top: 'calc(50% - 9px)',
                }
            },
            MuiMenuItem: {
                root: {
                    fontSize: '0.7rem',
                    minHeight: 0,
                }
            },
            MuiInputBase: {
                root: {
                    background: '#0e131a',
                    borderRadius: '3px',
                    color: '#d4d4d4',
                    fontSize: '0.875rem',
                },
                input: {
                    padding: '6px 12px',
                    width : '100% !important'
                },
                inputMultiline: {
                    borderRadius: '3px',
                    padding: '6px',
                }
            },
            MuiInput: {
                underline: {
                    "&::before": {
                        borderBottom: '0 !important',
                    },
                }
            },
            MuiInputLabel: {
                root: {
                    color: '#d4d4d4',
                }
            },
            MuiDialogTitle: {
                root: {
                    padding: '10px 24px',
                }
            },
            MuiDialog: {
                paperWidthSm: {
                    background: '#191f28',
                    '@media screen and (max-width: 668px)': {
                        minWidth: '95%',
                    }
                },
            },
            MuiSvgIcon: {
                root: {
                    fontSize: '1.25rem !important',
                }
            },
            MuiTypography: {
                root: {
                    color: '#d4d4d4',
                },
                h6: {
                    color: '#d4d4d4',
                }
            },
            MuiMenu: {
                paper: {
                    background: '#191f28',
                    color: '#d4d4d4',
                }
            },
            MuiList: {
                padding: {
                    padding: `8px`,
                }
            },
            MuiListItem: {
                root: {
                    color: `#d4d4d4`,
                    marginBottom: `2px`,
                    '&:hover':{
                        backgroundColor: `#0e131a !important`,
                        color: `#ababab !important`,
                    },
                    '&:focus': {
                        backgroundColor: `#0e131a !important`,
                        backgroundImage: `linear-gradient(0deg, #356cff 2px, rgba(14, 186, 175, 0) 0), linear-gradient(0deg, rgb(35, 45, 49) 1px, rgba(35, 45, 49, 0) 0) !important`,
                        backgroundSize: `100% 100%, 100% 100%`,
                        color: `#ababab !important`,
                    },
                }
            },
            MuiListItemIcon: {
                root:{
                    color: `#ababab`,
                    minWidth: `24px`,
                }
            },
            MuiPickersDay: {
                day: {
                    color: '#ababab',
                },
                dayDisabled: {
                    color: '#5e5e5e',
                },
                current: {
                    color: '#356cff !important',
                    backgroundColor: ' transparent !important',
                    fontWeight: '600 !important',
                },
                daySelected: {
                    color: '#fff !important',
                    backgroundColor: '#ff6767 !important',
                }
            },
            MuiPickersBasePicker: {
                container: {
                    background: '#191f28',
                }
            },
            MuiPickersCalendarHeader: {
                dayLabel: {
                    color: '#4a4a4a',
                },
                iconButton: {
                    backgroundColor: '#191f28',
                }
            },
            MuiCheckbox: {
                colorSecondary: {
                    color: '#356cff !important',
                }
            },
            MuiTreeItem: {
                root: {
                    '&:focus > $content': {
                        backgroundColor: '#191f28',
                    },
                }
            },
            MuiIconButton: {
                root: {
                    color: `#d4d4d4`,
                }
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor:'#0e131a',
                    fontSize: '0.75rem',
                },
                arrow: {
                    color: '#0e131a',
                }
            }
        },
        palette: {
            primary: {
                light: '#d4d4d4',
                main: '#191f28',
                dark: '#0e131a',
                contrastText: '#000',
                borderBorder: 'rgba(255,255,255,0.15)',
                textColor: '#fff',
                //Btn Autocomplete
                bgAutocomplete: 'transparent',
                borderAutocomplete: 'rgba(255,255,255,0.15)',
                colorAutocomplete: '#fff',
                //Btn suggestionsContainerOpen
                bgSuggestions: '#191f28',
                //
                colorIcon: '#d4d4d4',
                bgColor: '#0e131a',
                bgColorSecond: '#191f28',
                borderInput: 'rgba(255,255,255,.03)',
                borderFocus: '#356cff',
                ligthColor: '#5e5e5e',
            },
            secondary: {
                light: '#9ec4db',
                main: '#00497f',
                dark: '#00385b',
                contrastText: '#000',
            },
        },
    }
};