import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Folder from '@material-ui/icons/Folder';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Typography from '@material-ui/core/Typography';

const useTreeItemStyles = makeStyles((theme) =>
    createStyles({
        group: {
            marginLeft: '0.5rem',
        },
        expanded: {},
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: '0.25rem',
        },
        labelIcon: {
            fontSize: '13px',
            marginRight: '0.5rem',
        },
        labelText: {
            fontSize: '0.825rem',
            flexGrow: 1,
        },
    }),
);

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { clickItem, labelText, labelInfo, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot} onClick={() => clickItem()}>
          <Folder className={classes.labelIcon} />
          <Typography className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography>
            {labelInfo}
          </Typography>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

class FileTree extends React.Component {
  
  render(){
    const {tree, clickItem, currentFolder} = this.props;
    
    const renderItems = (childs, level) => {
      return (
        <React.Fragment>
          {
            childs.map((f, index) => {
              if(f.childs.length > 0){
                return (
                  <StyledTreeItem className={`${currentFolder === f.key ? 'Mui-selected' : ''}`} nodeId={`${level}_${index}_${f.childs.length + 1}_${f.name}_${f.key}`}  labelText={f.name} key={`item_${level}_${index}_${f.childs.length + 1}_${f.name}_${f.key}`} clickItem={() => clickItem(f)}>
                    { renderItems(f.childs, level + 1)}
                  </StyledTreeItem>
                )
              }else{
                return (
                  <StyledTreeItem className={`${currentFolder === f.key ? 'Mui-selected' : ''}`} nodeId={`${level}_${index}_${f.childs.length + 1}_${f.name}_${f.key}`} labelText={f.name} key={`item_${level}_${index}_${f.childs.length + 1}_${f.name}_${f.key}`} clickItem={() => clickItem(f)} />
                )
              }
              
            })
          }
        </React.Fragment>
      )
    }

    return (
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <StyledTreeItem nodeId="0" labelText={tree.name} clickItem={() => clickItem(tree)}>
          {
            renderItems(tree.childs, 1)
          }
        </StyledTreeItem>
      </TreeView>
    )
  }
}

FileTree.propTypes = {
  tree: PropTypes.object,
  clickItem: PropTypes.func
}

FileTree.defaultProps = {
  clickItem: () => {}
}

export default FileTree;