import React from 'react';
import { Grid, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import { SelectBase } from '../../../uielements/select/SelectBase';
import { CirclePicker, AlphaPicker } from 'react-color';
import { StyledTextField } from '../../../uielements/textfield/StyledTextField';
class FormDraw extends React.Component {
    render() {
        const {
            classes,
            handleChange,
            handleChangeComplete,
            strokeOpacityLine,
            strokeTrendingLine,
            strokeWidthTrending,
            typeTrendingLine,
            styleTrendingLine,
            lang,
            optionsLang
        } = this.props;
        console.log(`ddadads`, strokeOpacityLine);
        
        return <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}>
                <Grid item xs={12}>
                    <InputLabel htmlFor='component-helper'>{lang.type}</InputLabel>
                    <FormControl className={classes.formControl}>
                        <SelectBase
                            disableUnderline={true}
                            value={typeTrendingLine}
                            onChange={(e) => handleChange(e, 'typeTrendingLine')}
                            name="typeTrendingLine"
                            id="typeTrendingLine"
                            displayEmpty
                            className={classes.selectEmpty}
                        >
                            <MenuItem value="" disabled>{lang.selectOption}</MenuItem>
                            <MenuItem value={'XLINE'}>XLine</MenuItem>
                            <MenuItem value={'Ray'}>Ray</MenuItem>
                            <MenuItem value={'Line'}>Line</MenuItem>
                        </SelectBase>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.stroke}</InputLabel>
                    <CirclePicker
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        color={strokeTrendingLine}
                        onChangeComplete={(color) => handleChangeComplete(`strokeTrendingLine`, color)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.strokeOpacity}</InputLabel>
                    <AlphaPicker
                        color={strokeOpacityLine}
                        className={classes.colorCustom}
                        onChangeComplete={(color) => handleChangeComplete(`strokeOpacityLine`, color)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.strokeWidthTrendingLine}</InputLabel>
                    <FormControl className={classes.formControl}>
                        <SelectBase
                            value={strokeWidthTrending}
                            onChange={(e) => handleChange(e, 'strokeWidthTrending')}
                            id="strokeWidthTrending"
                            name="strokeWidthTrending"
                            displayEmpty
                            className={classes.selectEmpty}
                        >
                            <MenuItem value={1}>{optionsLang.thin}</MenuItem>
                            <MenuItem value={2}>{optionsLang.full}</MenuItem>
                        </SelectBase>
                    </FormControl>
                    {/* <StyledTextField
                        autoFocus
                        id="lowerRSI"
                        type="number"
                        fullWidth
                        value={strokeWidthTrending}
                        onChange={(e) => handleChange(e, 'strokeWidthTrending')}
                    /> */}
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='component-helper'>{lang.style}</InputLabel>
                    <FormControl className={classes.formControl}>
                        <SelectBase
                            value={styleTrendingLine}
                            onChange={(e) => handleChange(e, 'styleTrendingLine')}
                            name="styleTrendingLine"
                            id="styleTrendingLine"
                            displayEmpty
                            className={classes.selectEmpty}
                        >
                            <MenuItem value="" disabled>{lang.selectOption}</MenuItem>
                            <MenuItem value={'Solid'}>{optionsLang.solid}</MenuItem>
                            <MenuItem value={'ShortDash'}>{optionsLang.shortDash}</MenuItem>
                            <MenuItem value={'ShortDash2'}>{optionsLang.shortDash2}</MenuItem>
                            <MenuItem value={'ShorDot'}>{optionsLang.shortDot}</MenuItem>
                            <MenuItem value={'ShortDashDot'}>{optionsLang.shortDashDot}</MenuItem>
                            <MenuItem value={'ShortDashDotDot'}>{optionsLang.shortDashDotDot}</MenuItem>
                            <MenuItem value={'Dot'}>{optionsLang.dot}</MenuItem>
                            <MenuItem value={'Dash'}>{optionsLang.dash}</MenuItem>
                            <MenuItem value={'LongDash'}>{optionsLang.longDash}</MenuItem>
                            <MenuItem value={'DashDot'}>{optionsLang.dashDot}</MenuItem>
                            <MenuItem value={'LongDashDot'}>{optionsLang.longDashDot}</MenuItem>
                            <MenuItem value={'LongDashDotDot'}>{optionsLang.longDashDotDot}</MenuItem>
                        </SelectBase>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    }
}
export default FormDraw;