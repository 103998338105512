const styles = theme => (
    {
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(1, 2),
        },
        drawerBody: {
            padding: theme.spacing(1, 2),
        },
        mt1:{
            marginTop: theme.spacing(2),
        },
        span: {
            color:theme.palette.primary.ligthColor,
            display: 'block',
            fontWeight: 300,
        }
    }
)

export default styles;