import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import { FormControl, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DialogActions from '@material-ui/core/DialogActions';
import { ButtonBase } from '../../../uielements/button/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';

//Autocomplete
import Autosuggest from 'react-autosuggest';
import RicSuggestionCard from '../../Cards/RicSuggestionCard/RicSuggestionCard';

//Styles 
import { withStyles } from '@material-ui/core/styles';
import styles from '../forms-jss';
import { InputLabel, DateDIV } from '../Forms'
import { StyledFormTextField, StyledFormTextArea } from '../../../uielements/textfield/StyledFormTextField'

//Language
import { LanguageConsumer, LanguageCxt } from '../../../language/LanguageContext';

//Services 
import { validateRic, searchRicSymbol } from '../../../services/index';

//Autocomplete
const getSuggestions = (array, value) => {
    if(!array || !value){
        return [];
    }
    
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : array.filter(ric =>
        ric.mnemonic.toLowerCase().slice(0, inputLength) === inputValue || ric.cfname.toLowerCase().slice(0, inputLength) === inputValue  || ric.mainIndexCommonNam.toLowerCase().slice(0, inputLength) === inputValue
    );
};

const getSuggestionValue = suggestion => {
    return suggestion.assetgroupid === 2 ? suggestion.mnemonic : suggestion.assetgroupid === 6 ? suggestion.mainIndexCommonNam : suggestion.cfname;
};

const renderSuggestion = suggestion => (
    <RicSuggestionCard ric={suggestion}/>
);

//End Autocomplete

class UpdateFileForm extends React.Component {

    //---------------------------- Lifecycle

    constructor(props){
        super(props); 

        this.state = {
            //Form
            titleFile: '',
            date: null,
            description: '',
            //Date
            openDatePicker: false,
            dateError: false,
            //RICS autocomplete
            rics: [], 
            value: '',
            suggestions: [],
            ricError: false
        }

        this.form = React.createRef();
    }

    componentDidMount(){
        ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
            if (/^\s+$/i.test(value)) {
                return false;
            }
            return true;
          });

        this.setState({rics: this.props.rics});
        this.initializeForm(this.props.file);
    }
    //---------------------------- End Lifecycle

    //---------------------------- Submit
    handleFormSubmit = () => {
        if(!this.props.handleSubmit || !this.props.file){
            return; 
        }

        this.form.walk(this.form.childs).then(
            isValid => {
                let err = false, errRic = false;
                if(this.props.file.typeFile === "FOLDER"){
                    if(isValid){
                        let auxFile = Object.assign({}, this.props.file);
                        auxFile.newName = this.state.titleFile;
                        auxFile.isRecommendation = 0;
                        this.props.handleSubmit(auxFile);
                    }
                }else{
                    //Validate date
                    if(!this.state.date || this.state.date === ""){
                        err = true;
                    }

                    if(this.state.value !== ''){
                        errRic = validateRic(this.state.value, this.state.rics) === undefined ? true : false;
                    }

                    this.setState({ dateError: err, ricError: errRic });

                    if(isValid && !err && !errRic){
                        let auxFile = Object.assign({}, this.props.file);
                        auxFile.title = this.state.titleFile;
                        auxFile.description = this.state.description; 
                        auxFile.instrument = this.state.value !== '' ? searchRicSymbol(this.state.value, this.state.rics) : ''; 
                        auxFile.ric = this.state.value; 
                        auxFile.datePublication = new Date(this.state.date).setHours(0,0,0,0); 
                        auxFile.isRecommendation = 0;
                        this.props.handleSubmit(auxFile);
                    }
                }
            }
        ).catch(
            err => {
                console.log(err);
            }
        );

    }
    
    emptySubmit = () => {

    }
    //---------------------------- End Submit

    //---------------------------- Inputs
    handleChange = (event) => {
        if(!/^[a-z0-9 ÁÉÍÚÓáéíóú.,-_!#$%&/()=?¡¿]+$/gi.test(event.target.value.toLowerCase()) && event.target.value !== ''){
            return;
        }
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChangeDate = (date) => {
        this.setState({date: date, openDatePicker: false, dateError: false});
    }

    handleClickDate = (open) => {
        this.setState({ openDatePicker: open })
    }

    onChange = (event, { newValue }) => {
        this.setState({ value: newValue });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({ suggestions: getSuggestions(this.state.rics, value) });
    };
    
    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };
    //---------------------------- End Inputs

    //---------------------------- Helpers
    initializeForm = (file) => {
        if(!file){
            return;
        }

        this.setState({
            titleFile: file.title, 
            description: file.description ? file.description : '', 
            value: file.instrument ? file.instrument : '', 
            date: file.datePublication ? new Date(file.datePublication) : null,
        });
    }
    //---------------------------- End Helpers 

    render(){
        const { classes, file, handleCancel } = this.props; 
        const { value, suggestions } = this.state; 

        const inputProps = {
            value,
            onChange: this.onChange,
            maxLength: "15"
        };

        return(
            <LanguageConsumer>
                {
                    ({lang}) => 
                    <ValidatorForm onSubmit={() => this.emptySubmit()} ref={form => (this.form = form)}>
                        <div style={{ flexGrow: 1 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl className={classes.w100}>
                                        <InputLabel shrink htmlFor="title">
                                            {lang.fileManager.upateFilesForm.titleName}*
                                        </InputLabel>
                                        <StyledFormTextField
                                            id="titleFile"
                                            name="titleFile"
                                            value={this.state.titleFile}
                                            onChange={this.handleChange}
                                            validators={['required', 'isSpaceEmpty', 'maxStringLength: 50']}
                                            errorMessages={[lang.fileManager.upateFilesForm.titleRequired, lang.fileManager.upateFilesForm.titleRequired, lang.fileManager.upateFilesForm.maxLengthTitle]}    
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                </Grid>
                                {
                                    file.typeFile !== 'FOLDER' && 
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl className={classes.suggestionWrap} error={this.state.ricError}>
                                                <InputLabel shrink htmlFor="title">
                                                    {lang.fileManager.upateFilesForm.issuer}
                                                </InputLabel>
                                                <Autosuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    inputProps={inputProps}
                                                    theme={{
                                                        input: classes.autocompleteInput,
                                                        suggestionsContainerOpen: classes.suggestionsContainerOpenUpdate,
                                                        suggestionsList: classes.suggestionsList,
                                                        suggestion: classes.suggestion,
                                                    }}
                                                />
                                                {
                                                    this.state.ricError &&
                                                    <FormHelperText>{lang.fileManager.upateFilesForm.invalidRic}</FormHelperText>
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl className={classes.w100} error={this.state.dateError}>
                                                <InputLabel shrink htmlFor="date">
                                                    {lang.fileManager.upateFilesForm.date}*
                                                </InputLabel>
                                                <DateDIV>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <DatePicker
                                                            allowKeyboardControl={false}
                                                            disableToolbar
                                                            variant="inline"
                                                            format="DD/MM/YYYY"
                                                            id="dp-editFile"
                                                            value={this.state.date}
                                                            onChange={this.handleChangeDate}
                                                            disableFuture={true}
                                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                            placeholder=""
                                                            open={this.state.openDatePicker}
                                                            onOpen={() => this.handleClickDate(true)}
                                                            onClose={() => this.handleClickDate(false)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </DateDIV>
                                                {
                                                    this.state.dateError && <FormHelperText>{lang.fileManager.uploadFilesForm.dateRequired}</FormHelperText>
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl className={classes.w100}>
                                                <InputLabel shrink htmlFor="bootstrap-input">
                                                    {lang.fileManager.upateFilesForm.description}   
                                                </InputLabel>
                                                <StyledFormTextArea
                                                    InputLabelProps={{ shrink: true }}
                                                    multiline
                                                    id="description"
                                                    name="description"
                                                    value={this.state.description}
                                                    onChange={this.handleChange}
                                                    validators={['isSpaceEmpty', 'maxStringLength: 250']}
                                                    errorMessages={[lang.fileManager.upateFilesForm.emptyField, lang.fileManager.upateFilesForm.maxLengthDescription]}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                }
                                
                            </Grid>
                        </div>

                        <DialogActions>
                            {/**Action buttons */} 
                            <ButtonBase className="outline" onClick={() => handleCancel()} type="button">
                                <FontAwesomeIcon icon={faTimesCircle} />  
                                {lang.fileManager.upateFilesForm.cancel}
                            </ButtonBase> 
                            <ButtonBase className='primary ml-1' type="button" onClick={() => this.handleFormSubmit()}>
                                <FontAwesomeIcon icon={faCheckCircle} /> 
                                {lang.fileManager.upateFilesForm.accept}
                            </ButtonBase>   
                        </DialogActions>
                    </ValidatorForm>
                    
                }
            </LanguageConsumer>
        )
    }
}

UpdateFileForm.contextType = LanguageCxt;

UpdateFileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired, 
    file: PropTypes.object,
    handleCancel: PropTypes.func,
    rics: PropTypes.array
}

UpdateFileForm.defaultProps = {
    handleCancel: () => {},
    rics: []
}

export default withStyles(styles)(UpdateFileForm);