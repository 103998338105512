const styles = theme => (
    {
        dialogHeadign: {
            position:'relative',
            '&::after': {
                content: "''",
                background: '#ff0101',
                height: 2,
                width: 25,
                position: 'absolute',
                bottom: -4,
                left: 0,
            }
        }
        
    }
) 

export default styles;