import styled from 'styled-components';
import { Card } from "@material-ui/core";

const StyledCard = styled(Card)`
    background-color: ${(props) => props.theme.card.bgColor} !important;
    cursor: pointer;
    margin-bottom: 1rem;
    text-align: center;
    width: 132px;
    @media (min-width: 767px){
        margin-right: 0.5rem;
    }
    .media{
        margin-bottom: 0.5rem;
        span{
            color: #ff8080;
            font-size: 2.5rem;
        }
    }
    h6 {
        color: ${(props) => props.theme.card.titleColor};
        overflow: hidden!important;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    p{
        color: ${(props) => props.theme.card.subColor};
    }
    .actions {
        display:flex;
        opacity: 1;

        @media (min-width: 768px){
            opacity: 0;
        }

        input[type=checkbox] {
            color: ${(props) => props.theme.borderCheckbox};
            background: transparent !important;
            appearance: none;
            border: 1px solid ${(props) => props.theme.borderCheckbox};
            height: 13px;
            margin: 0 5px 0 0;
            transition: 0.5s;
            width: 13px;
            &:checked {
                background:${(props) => props.theme.checkboxChecked} !important;
            }
            &:focus {
                outline: 0;
                outline-offset: 0;
            }
        }

        >div {
            margin-left: auto;
            button{
                background: none !important;
                border: 1px solid ${(props) => props.theme.borderColor} !important;
                min-width: auto;
                padding: 1px 6px !important;
                svg{
                    margin: 0;
                    fill: ${(props) => props.theme.ligthColor} !important;
                }
                &:hover{
                    background: none !important;
                    border: 1px solid ${(props) => props.theme.borderColor} !important;
                }
            }
            
        }  
    }

    .content {
        padding: 8px 8px 16px;
    }

    &.active{
        background: ${(props) => props.theme.card.bgColorActive} !important;
        .actions { 
            opacity: 1;
        }
        h6 {
            color: #ff6767 !important;
        }
    }

    &:hover {
        h6 {
            color: #ff6767;
        }
        .actions { 
            opacity: 1;
        }
    }
`;

export { StyledCard };


const DropdownPanel = styled.div`
    margin: 0.5rem 0 1rem;
    .card {
        .card-title {
            color: ${(props) => props.theme.textColor};
            display: block;
            font-size: 0.9rem;
            font-weight: 600;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .card-subtitle {
            color: ${(props) => props.theme.ligthColor} !important;
            display: block;
            font-size: 0.7rem;
        }
    }
`;
export { DropdownPanel };