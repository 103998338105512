import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const StyledTextField = styled(TextField)`
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    & > div {
        &:hover:before {
            border: none !important;
        }
        &:before {
            border: none !important;
        }
        &:after {
            border: none !important;
        }
        &:focus {
            border-color: ${(props) => props.theme.borderFocus};
            outline: none;
        }
    }
    & > label {
        z-index:1;
        left: 1rem;
    }

`;

export { StyledTextField };