import { paramsSession } from './env';
import { API_GET, API_POST, getCompleteUser } from './index'; 

var jwt = require('jsonwebtoken');

export const getUserRics = () => {
    let user = getCompleteUser();
    const params = JSON.parse(sessionStorage.getItem(paramsSession));

    return new Promise((resolve, reject) => {
        API_GET('/rics', { username: user ? user.userData.email : '', "_": new Date().getTime()}).then(
            response => {
                user.userData.userRics = response;
                sessionStorage.setItem(params.SESSION_NAME, JSON.stringify(user));
                resolve(response);
            }
        ).catch(
            err => {
                console.log("Err at: ", err);
                reject(err);
            }
        )
    });
}

export const setToken = (token, refreshToken) => {
    const params = JSON.parse(sessionStorage.getItem(paramsSession));
    if(params){
        var decoded = jwt.decode(token, {complete: true});
        if(!decoded){
            return false;
        }

        decoded = decoded.payload
        let auxUser = {
            accessToken: token,
            refreshToken : refreshToken ? refreshToken : ``,
            userData: {
                email: decoded.email
            }
        }

        sessionStorage.setItem(params.SESSION_NAME, JSON.stringify(auxUser))
        return true;
    }else{
        return false;
    }
}

export const getPermissions = () => {
    let user = getCompleteUser();
    const params = JSON.parse(sessionStorage.getItem(paramsSession));
    //users/getuserconfig
    return new Promise((resolve, reject) => {
        API_POST('POST', '/users/getuserconfig', {user: user ? user.userData.email : ''}).then(
            response => {
                if(response.status === "OK"){
                    let permissions = response.data.permissionList;
                    permissions = permissions.filter(p => p.indexOf("FILEMANAGER") > -1);
                    user.permissions = permissions;
                    sessionStorage.setItem(params.SESSION_NAME, JSON.stringify(user));
                    resolve(permissions);
                }else{
                    reject([]);
                }
            }
        ).catch(
            err => {
                console.log("Err: ", err);
                reject([]);
            }
        )
    })
}