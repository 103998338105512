const styles = theme => (
    {
        w100: {
            width: '100% !important',
        },
        hiddenInput: {
            display: 'none'
        },
        boxLoading: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
        },
        autocompleteInput: {
            backgroundColor: theme.palette.primary.bgColor,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.borderInput,
            borderRadius: '3px',
            boxSizing: 'border-box',
            color: theme.palette.primary.textColor,
            fontSize: '13px',
            fontFamily: 'inherit',
            height: '33px',
            lineHeight: 1.42857143,
            padding: '6px 12px',
            transition: 'border-color .15s ease-in-out',
            width: '100%',
            '&:focus': {
                borderColor: theme.palette.primary.borderFocus,
                outline: 'none'
            }
        },
        suggestionsContainerOpen: {
            background: theme.palette.primary.bgColorSecond,
            boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            borderRadius: '3px',
            left: 0,
            maxHeight: '240px',
            overflow: 'hidden',
            overflowY: 'auto',
            padding: theme.spacing(1),
            position: 'absolute',
            right: 0,
            width: '230px',
            zIndex: 1,
        },
        suggestionsContainerOpenInModal: {
            background: theme.palette.primary.bgColorSecond,
            boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            borderRadius: '3px',
            left: 0,
            maxHeight: '85px',
            overflow: 'hidden',
            overflowY: 'auto',
            padding: theme.spacing(1),
            position: 'absolute',
            right: 0,
            width: '230px',
            zIndex: 1,
        },
        suggestionsContainerOpenUpdate: {
            background: theme.palette.primary.bgColorSecond,
            boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            borderRadius: '3px',
            left: 0,
            maxHeight: '125px',
            overflow: 'hidden',
            overflowY: 'auto',
            padding: theme.spacing(1),
            position: 'absolute',
            right: 0,
            width: '230px',
            zIndex: 1,
        },
        suggestionsContainerOpenRight: {
            background: theme.palette.primary.bgColorSecond,
            boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            borderRadius: '3px',
            left: 'auto',
            maxHeight: '240px',
            overflow: 'hidden',
            overflowY: 'auto',
            padding: theme.spacing(1),
            position: 'absolute',
            right: 0,
            width: '230px',
            zIndex: 1,
        },
        suggestion: {
            display: 'block',
        },
        suggestionInModal: {
            display: 'block',
            '& > div': {
                margin: '0 0 0 .3rem',
                '& .card-title':{
                    fontZize: '0.75rem !important',
                },
                '& .card-subtitle':{
                    fontZize: '0.6rem !important',
                },
            }
        },
        suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
        },
        divider: {
            height: theme.spacing(2),
        },
        select: {
            width: '100%',
            maxWidth: '100%'
        },
        errorStyle: { 
            color: `#f44336`, 
            fontSize: `0.5357142857142857rem`, 
            marginTop: `8px`, 
            textAlign: `left`, 
            fontFamily: `Nunito, sans-serif` 
        },
        suggestionWrap: {
            position: 'relative',
            width: '100%',
            zIndex: 999,
        }
    }
)

export default styles;