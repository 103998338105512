const styles = theme => (
    {
        root: {
            flexGrow: 1,
          },
          padding: {
            padding: theme.spacing(3),
          },
          demo1: {
            backgroundColor: theme.palette.background.paper,
          },
          demo2: {
            backgroundColor: '#2e1534',
          },
    }
) 

export default styles;

