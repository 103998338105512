import styled from 'styled-components';
import { Button } from "@material-ui/core";

const ButtonBase = styled(Button)`
    border-radius: 3px !important;
    font-size: 0.815rem !important;
    font-weight: 400 !important;
    line-height: 1.475 !important;
    padding: 6px 12px !important;
    text-transform: capitalize !important;
        @media screen and (max-width: 1024px) {
            min-width: auto !important;
        }
        &.primary {
            background: ${(props) => props.theme.btnPrimary} !important;
            border: 1px solid ${(props) => props.theme.btnPrimary} !important;
            color: ${(props) => props.theme.whiteColor} !important;
            &:hover {
                background: ${(props) => props.theme.btnHoverPrimary}!important;
                border-color: ${(props) => props.theme.btnHoverPrimary} !important;
            }
            svg {
                color: ${(props) => props.theme.whiteColor};
            }
        }
        &.secondary {
            background: ${(props) => props.theme.btnSecondary} !important;
            border: 0 !important;
            color: ${(props) => props.theme.textBtnSecondary} !important;
            &:hover {
                background: ${(props) => props.theme.btnSecondary} !important;
                border-color: 0 !important;
                color: ${(props) => props.theme.textHoverSecondary} !important;

                svg {
                    color: ${(props) => props.theme.textHoverSecondary};
                }
            }
            svg {
                color: ${(props) => props.theme.textBtnSecondary};
            }
        }
        &.outline {
            background: ${(props) => props.theme.btnOutline};
            border: 1px solid ${(props) => props.theme.borderOutline};
            color: ${(props) => props.theme.textBtnOutline};
            &:hover {
                background-color: ${(props) => props.theme.btnHoverOutline} !important;
                border: 1px solid ${(props) => props.theme.borderHoverOutline} !important;
                color: ${(props) => props.theme.colorHoverOutline} !important;
            }
        }
        &.noDisplay {
            visibility: hidden
        }
        &.ml-1{
            margin-left: 0.5rem !important;
        }
        svg{
            font-size: 13px;
            margin-right: 5px;
            @media screen and (max-width: 991px) {
                margin: 0;
            }
        }
        span {
            span {
                @media screen and (max-width: 375px) {
                    display: none
                }
            }
        }
`;

export { ButtonBase };