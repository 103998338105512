import { isAbsolute } from "path";

const styles = theme => (
    {
        btnContent: {
            bottom: 300,
            position: 'absolute',
            padding: theme.spacing(0, 2),
            zIndex : 99
        },
        btnMinus: {
            background: '#ff3434',
            color: theme.palette.common.white,
            height: 28,
            fontSize: '0.85rem',
            padding: 0,
            width: 28,
            '&:hover': {
                background: '#ff0101'
            }
        }
    }
) 

export default styles;