import React from 'react';
import { LanguageConsumer, LanguageCxt } from '../../../app/language/LanguageContext';
import Chart from '../../components/MainChart/Chart';
import ReactLoading from 'react-loading';
import { API_POST } from '../../services';
import Header from '../../components/Header/index';
import AntTabs from '../../components/Tabs/index';
import Alert from '../../components/Message/Alert';
import l from './../../language/lang';
import FormDialog from '../../components/Dialog/FormDialog';
import { SaveChartAsImage } from 'react-stockcharts/lib/helper';
import { withStyles } from '@material-ui/styles';
import styles from './technical-jss';
import ReactDOM from 'react-dom';
import RicSuggestionCard from '../../components/Cards/RicSuggestionCard/RicSuggestionCard';
import { setToken, getUserRics } from '../../services/user';
import {
    ExcelExport,
    ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import products from './products.json';
import AddForm from '../../components/Forms/addAnalysis/new';
import EditTechnical from '../../components/Dialog/EditTechnical';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Typography } from '@material-ui/core';
import DialogLoading from '../../components/Dialog/DialogLoading';
import { paramsSession } from '../../services/env';


const removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
    })
}

//Autocomplete
const getSuggestions = (array, value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let uniqueArr = []
    if (inputLength === 0) return [];
    uniqueArr = array.filter(ric =>
        ric.name.toLowerCase().slice(0, inputLength) === inputValue
        || ric.shortName.toLowerCase().slice(0, inputLength) === inputValue
        // || ric.cfname.toLowerCase().slice(0, inputLength) === inputValue
    );
    uniqueArr = removeDuplicates(uniqueArr, "ric")
    return uniqueArr
};

// const getSuggestionValue = suggestion => suggestion && suggestion.mnemonic !== `-` ? `${suggestion.mnemonic}` : `${suggestion.ric}`;
const getSuggestionValue = suggestion => suggestion && suggestion.name !== `-` ? `${suggestion.ric}` : `${suggestion.ric}`;



const renderSuggestion = suggestion => (
    <RicSuggestionCard ric={suggestion} />
);

const aggregates = [{ field: 'UnitPrice', aggregate: 'sum' }];
const group = [{ field: 'Discontinued', aggregates: aggregates }];

class TechnicalAnalyst extends React.Component {

    state = {
        originalData: [],
        data: [],
        dataChart: [],
        currentLang: 'es',
        currentLanguage: [],
        currentTheme: 'dark',
        currentStockType: 0,
        stockType: '',
        chartType: `vela`,
        fill: '#356cff',
        stroke: `#356cff`,
        currentRic: ``,
        currentName: ``,
        currentShortName: ``,
        selectedOption: {},
        optionsRic: [],

        highestPoint: `#000000`,
        showStroke: false,
        showFill: false,
        showHighestPoint: false,
        dataHistory: [],
        openAlert: false,
        typeAlert: `error`,
        messageAlert: ``,
        opacityFill: 1,
        opacityHighestPoint: 0.5,

        upCandle: `#00c277`,
        downCandle: `#f3383f`,
        opacityCandle: 1,
        colorOpacityCandle: { r: 0, g: 0, b: 0, a: 1 },
        upWick: `#417505`,
        downWick: `#D0021B`,
        upBorder: `#00c277`,
        downBorder: `#f3383f`,

        currentIndicator: '',
        arraySMA: [],
        openDialog: false,
        periodIndicator: 20,
        colorStrokeSMA: ``,
        //macd
        indicatorMACD: [],
        fastMACD: 12,
        slowMACD: 26,
        signalMACD: 9,
        colorStrokeMACD: ``,

        //gannfan
        enableGannFan: false,
        strokeColorGannFan: ``,
        colorFontFill: ``,
        alphaGannFan: ``,
        strokeGannFanWidth: 1,

        indicatorFannGan: [],

        //Fibonacci 
        enableFibonacci: false,
        indicatorFibonacci: [],
        typeLineFB: `Extend`,
        colorStrokeFB: ``,
        strokeOpacityFB: 1,
        strokeWidthFB: 1,
        fontFillFB: ``,

        //tendencia de regresión
        enableTR: false,
        indicatorTR: [],
        colorStrokeTR: ``,
        strokeOpacityTR: 1,
        strokeWidthTR: 1,
        fillTR: ``,
        fillOpacity: 1,

        //RSI 
        indicatorRSI: null,
        periodRSI: 14,
        upperBandRSI: 70,
        middleRSI: 14,
        lowerRSI: 30,
        colorRSI: `#bd4148`,
        colorOverBoughtRSI: ``,
        colorMiddleRSI: ``,
        colorOverSoldRSI: ``,
        colorOutsideRSI: ``,
        colorInsideRSI: ``,

        //drawn line
        enableDrawLine: false,
        typeTrendingLine: ``,
        strokeTrendingLine: ``,
        strokeOpacityLine: 1,
        strokeWidthTrending: 1,
        styleTrendingLine: 1,
        settingTrendingLine: {},

        //BBAND
        indicatorBB: undefined,
        windowSizeBBand: 20,
        multiplierBBand: 2,
        maTypeBBand: `sma`,

        //volumen
        indicatorVolume: {
            active: false,
            colorUp: {},
            colorDown: {},
            colorOpacity: {}
        },
        //Stochastic Oscillator
        indicatorStochastic: {
            activeSlow: false,
            activeFast: false,
            activeFull: false,
            period: 3,
            kWindowSize: 14,
            dWindowSize: 3,
            strokeKLine: ``,
            strokeDLine: ``
        },
        periodStochastic: 3,
        kWindowSizeStochastic: 14,
        dWindowSizeStochastic: 3,
        activeSlowStochastic: false,
        activeFastStochastic: false,
        activeFullStochastic: false,

        //enableText
        enableText: false,

        //suggestions
        suggestions: [],
        value: '',
        rics: [],
        userInformation: {},
        start: 0,
        end: 0,

        dataExcel: process(products, { group }).data,
        isIntraday: false,

        //confirm delete
        deleteIndicator: ``,
        eventDelete: null,

        //CONSOLE
        addTechnicalAnalyst: false,
        nameTechnical: ``,
        descriptionTechnical: ``,
        symbolTechnical: ``,
        currentTechnical: undefined,
        allTechnical: [],
        updateTechnical: false,
        errorSymbol: false,

        //LOADER
        isLoading: false,
        pivTechnical: {
            name: ``,
            symbol: ``
        },
        disableAdd: true,
        showName: true,
        showZoom: true,
        selectedStartPeriod: '',
        selectedEndPeriod: '',
        saveWork: false,
        periodsIntervals: '2Y',
        typeInterval: "",
        defaultInterval: "DAILY",
        selectedDate: new Date()
        , openCalendar: false
        , selectedInterval : {
            intraday : '1M'
            , interday : 'DAILY'
        }
        , mnemonic : ''
    }

    _exporter;

    _isValidNewTA = () => {
        const { nameTechnical, descriptionTechnical } = this.state;
        let valid = true;
        [nameTechnical, descriptionTechnical].forEach(value => {
            if (/^\s+$/i.test(value) || /[!123456790#“º$%&*¡¿!;\\\\'"|?]/.test(value)) {
                valid = false;
            }
        });
        return valid;
    }

    _isLoading = () => {
        return !!this.state.isLoading;
    }

    _resetValues = (reload) => {
        this.setState(
            {
                originalData: [],
                data: [],
                dataChart: [],
                dataHistory: [],
                arraySMA: [],
                periodIndicator: 20,
                indicatorVolume: {
                    active: false,
                    colorUp: {},
                    colorDown: {},
                    colorOpacity: {}
                },
                //macd default values
                indicatorMACD: [],
                fastMACD: 12,
                slowMACD: 26,
                signalMACD: 9,
                //rsi
                indicatorRSI: null,
                periodRSI: 14,
                upperBandRSI: 70,
                middleRSI: 14,
                lowerRSI: 30,

                indicatorStochastic: {},
                periodStochastic: 3,
                kWindowSizeStochastic: 14,
                dWindowSizeStochastic: 3,
                activeSlowStochastic: false,
                activeFastStochastic: false,
                activeFullStochastic: false,
                //bbands
                indicatorBB: undefined,
                windowSizeBBand: 20,
                multiplierBBand: 2,
                maTypeBBand: `sma`,
                disableAdd: false
            }
            , () => {
                if (reload) {
                    let params = this.props.match.params;
                    let userRic = JSON.parse(sessionStorage.getItem(JSON.parse(sessionStorage.getItem(paramsSession)).SESSION_NAME));
                    this._loadData(params, userRic.userData.email, this.state.currentRic);
                }
            }
        )
    }

    componentDidMount() {
        
        let params = this.props.match.params;
        l.setLanguage(`${params.lang}`);
        this.context.changeLang(l);
        this.context.changeTheme(params.theme);
        if (params.token) {
            let hasToken = setToken(params.token, params.refreshToken);
            
            let userRic = JSON.parse(sessionStorage.getItem(JSON.parse(sessionStorage.getItem(paramsSession)).SESSION_NAME));
            if (hasToken) {
                this.setState(
                    {
                        userInformation: userRic,
                        allTechnical: [],
                        updateTechnical: false,
                        addTechnicalAnalyst: false,
                        nameTechnical: ``,
                        descriptionTechnical: ``,
                        symbolTechnical: ``,
                        errorSymbol: false
                    }, () => {
                        this._getTechnicalAnalyst(userRic.userData.email);
                    }
                )
            }
        }
    }

    _loadData = (params, user, rics) => {

        this.setState({ isLoading: true });

        let ricsIntraday = [
            {
                shortName: this.state.currentShortName,
                symbol: this.state.currentRic,
                stockType: this.state.stockType
            }
        ];

        API_POST('POST', `historico/getintraday`, {
            "rics": JSON.stringify(ricsIntraday)
            , "user": user
            , "fields": []
            , "checkPermissions": false

        }).then(
            response => {
                if (response && response.data) {
                    const completeRic = this.state.rics.find(ric => ric.symbol === this.state.currentRic);
                    params.chartType = completeRic ? completeRic.assetgroupid === 1 ? 'line' : params.chartType : params.chartType;
                    let parse = this.parseIntraday(response.data[`${this.state.currentShortName}`]);
                    if (parse.length === 0) {
                        this._getHistory(params, user, rics, true);
                    } else if (parse.length <= 1) {
                        this._getHistory(params, user, rics, true);
                    } else {
                        let timestamp = new Date().getTime();
                        timestamp = new Date().setHours(-24);
                        let aux = [];
                        let timestampLastTick = new Date(parse[0].date).getTime();;
                        parse.reverse().map(
                            item => {
                                let parse = new Date(item.date).getTime();
                                if (parseInt(parse) >= timestamp && parseInt(parse) <= timestampLastTick) {
                                    aux.push(item)
                                }
                            }
                        );

                        let startTimeStamp = Number(new Date(aux[0].date).getTime());
                        let findStartIndex = parse.findIndex(x => Number(new Date(x.date).getTime() === startTimeStamp));
                        let endTimeStamp = Number(new Date(aux[aux.length - 1].date).getTime());
                        let findEndIndex = parse.findIndex(x => Number(new Date(x.date).getTime() === endTimeStamp));

                        if (findStartIndex === findEndIndex) {
                            findEndIndex += 1;
                        }

                        this.setState(
                            {
                                data: [],
                                dataChart: [],
                                start: findStartIndex,
                                end: findEndIndex,
                                isLoading: false,
                                // disableAdd : false
                            }, () => {
                                this.setState(
                                    {
                                        data: parse,
                                        // dataChart: parse,
                                        chartType: params.chartType
                                    }, () => {
                                        this._getHistory(params, user, rics, true);
                                    }
                                );
                            }
                        )
                    }
                }
            }
        ).catch(
            error => {
                this.setState(
                    {
                        openAlert: true,
                        messageAlert: this.context.lang.technicalAnalyst.Messages.noData,
                        typeAlert: 'warning',
                        isLoading: false
                    }
                )
            }
        )
    }

    _getHistory = (params, user, rics, intraday) => {
        /*
            if the user has delay rics, for historical data need to replace first character /
        */
        let isDelay = String(rics).split("")[0] === `/` ? true : false;
        let parseRic = isDelay === true ? String(rics).substring(1, String(rics).length) : rics;
        this.setState(
            {
                isLoading: true
            }, () => {
                API_POST('POST', 'historico/gethistory', {
                    "rics": [
                        parseRic
                    ],
                    "user": user
                    , "checkPermissions" : isDelay ? false : true
                }).then(
                    response => {
                        if (response.message !== `Unauthorized` || response.message !== `The incoming token has expired`) {
                            const { currentStockType } = this.state;
                            const completeRic = this.state.rics.find(r => r.symbol === isDelay ? parseRic : this.state.currentRic);
                            let aux = [];
                            response.data[ isDelay ? parseRic : this.state.currentRic].map((item, index) => {

                                if (Number(item.open) > 0) {
                                    const close = !completeRic ? item.close : completeRic.assetgroupid === 1 ? Number.parseFloat(item.closeYield) : item.close;
                                    item.date = new Date(item.date);
                                    item.change = isNaN(Number(item.change)) ? 0 : Number(item.change)
                                    item.close = isNaN(Number(close)) ? 0 : Number(close)
                                    item.high = isNaN(Number(item.high)) ? 0 : currentStockType === 4 ? Number(item.open) : Number(item.high)
                                    item.low = isNaN(Number(item.low)) ? 0 : (currentStockType === 4 && Number(item.low) === 0) ? Number(item.open) : Number(item.low)
                                    item.open = isNaN(Number(item.open)) ? 0 : Number(item.open)
                                    item.pctChange = isNaN(Number(item.pctChange)) ? 0 : Number(item.pctChange)
                                    item.timestamp = isNaN(Number(item.timestamp)) ? 0 : Number(item.timestamp)
                                    item.volume = isNaN(Number(item.volume)) ? 0 : Number(item.volume)
                                    aux.push(item)
                                }

                            });


                            const { currentTechnical } = this.state;
                            const config = JSON.parse(currentTechnical.configuration);

                            this.setState(
                                {
                                    dataHistory: [],
                                }, () => {
                                    this.setState(
                                        {

                                            dataHistory: aux.reverse(),
                                            currentLang: params.lang,
                                            currentTheme: params.theme,
                                            chartType: params.chartType,
                                            isLoading: false,
                                            disableAdd: false,
                                            arraySMA: config && config.sma ? config.sma : [],
                                            indicatorMACD: config && config.macd ? config.macd.value : [],
                                            colorStrokeMACD: config && config.macd ? config.macd.stroke : { hex: "#f3383f" },
                                            indicatorRSI: config && config.rsi ? config.rsi : null,
                                            indicatorBB: config && config.bb ? config.bb : null,
                                            indicatorStochastic: config && config.stochastic ? config.stochastic : {
                                                activeSlow: false,
                                                activeFast: false,
                                                activeFull: false,
                                                period: 3,
                                                kWindowSize: 14,
                                                dWindowSize: 3,
                                                strokeKLine: ``,
                                                strokeDLine: ``
                                            },
                                            selectedPeriod: config && config.period ? config.period : '3Y',
                                            indicatorVolume: config && config.volume ? config.volume : {
                                                active: false,
                                                colorUp: {},
                                                colorDown: {},
                                                colorOpacity: {}
                                            }
                                            // addTechnicalAnalyst : false
                                        }, () => {
                                            if(this.state.dataHistory.length === 0 && this.state.data.length > 0){
                                                this.setState(
                                                    {
                                                        selectedPeriod : '1D'
                                                    }, () => this.handleTime(this.state.selectedPeriod)
                                                )
                                            }else{
                                                if (intraday) {
                                                    // let timestampNow = new Date().getTime();
                                                    // timestamp = new Date().setMonth(new Date().getMonth() - 1);
                                                    // console.log('period', this.state.selectedPeriod)
                                                    this.handleTime(this.state.selectedPeriod);
                                                    //this._refreshDataIntraday(null, timestampNow, true);
                                                }
                                            }
                                        }
                                    )
                                }
                            )
                        }
                    }
                ).catch(
                    error => {
                        this.setState(
                            {
                                openAlert: true,
                                messageAlert: this.context.lang.technicalAnalyst.Messages.noData,
                                typeAlert: 'warning',
                                isLoading: false,
                                disableAdd: false
                            }
                        )
                    }
                )
            }
        );
    }

    handleChangeRic = selectedOption => {
        this.setState(
            { selectedOption },
            () => {
                this.setState(
                    {
                        currentRic: this.state.selectedOption.value,
                        currentName: this.state.selectedOption.name,
                        currentShortName: this.state.selectedOption.shortName,
                        currentStockType: this.state.selectedOption.assetgroupid,
                        stockType: this.state.selectedOption.stockType
                    }, () => {

                        let params = this.props.match.params;
                        this._loadData(params, this.state.userInformation.userData.email, this.state.currentRic);

                        // this._getHistory(params, this.state.userInformation.userData.email, this.state.currentRic);
                        // this._getIntraday(params, this.state.userInformation.userData.email);
                    }
                )
            }
        );
    };

    parseIntraday = (arrIntraday, ric) => {

        if (arrIntraday === undefined) {
            return [];
        }

        let arrayResult = [];

        for (let k = 0; k < arrIntraday.length; k++) {

            let objIntraday = arrIntraday[k].split(";");
            arrayResult.push({
                date: new Date(parseInt(objIntraday[1])),
                close: isNaN(Number(objIntraday[0])) ? 0 : Number(objIntraday[0]),
                pctChange: isNaN(Number(objIntraday[2])) ? 0 : Number(objIntraday[2]),
                volume: isNaN(Number(objIntraday[6])) ? 0 : Number(objIntraday[6]),
                open: isNaN(Number(objIntraday[5])) ? 0 : Number(objIntraday[5]),
                high: isNaN(Number(objIntraday[3])) ? 0 : Number(objIntraday[3]),
                low: isNaN(Number(objIntraday[4])) ? 0 : Number(objIntraday[4]),
                bid: isNaN(Number(objIntraday[8])) ? 0 : Number(objIntraday[8]),
                ask: isNaN(Number(objIntraday[9])) ? 0 : Number(objIntraday[9]),
                closeYield: isNaN(Number(objIntraday[7])) ? 0 : Number(objIntraday[7])
            });
        }

        if (arrayResult.length === 0) {
            return [];
        } else {
            return arrayResult.reverse();
        }
    }

    timestampToString = (timestamp) => {
        let aDate = new Date(Number(timestamp));
        let year = aDate.getFullYear();
        let month = aDate.getMonth() + 1;
        let day = aDate.getDate();
        let hours = aDate.getHours();
        let minutes = aDate.getMinutes();
        let seconds = aDate.getSeconds();

        return year + "-" + this.twoDigits(month) + "-" + this.twoDigits(day) + "T" + this.twoDigits(hours) + ":" + this.twoDigits(minutes) + ":" + this.twoDigits(seconds);
    }

    twoDigits = (value) => {
        return value < 10 ? "0" + value : value;
    }

    showChart = (chart, lang) => {
        const {
            currentLang,
            mnemonic,
            currentName

        } = this.state;
        
        if (chart && chart !== ``) {
            return <Chart
                id="chart"
                symbol={currentName}
                showName={this.state.showName}
                showZoom={this.state.showZoom}
                type={`hybrid`}
                ref={this.saveNode}
                data={this.state.dataChart}
                upCandle={this.state.upCandle}
                downCandle={this.state.downCandle}
                opacityCandle={this.state.opacityCandle}
                upWick={this.state.upWick}
                downWick={this.state.downWick}
                upBorder={this.state.upBorder}
                downBorder={this.state.downBorder}
                indicatorSMA={this.state.arraySMA}
                indicatorMACD={this.state.indicatorMACD}
                colorStrokeMACD={this.state.colorStrokeMACD}
                indicatorFannGan={this.state.indicatorFannGan}
                indicatorFibonacci={this.state.indicatorFibonacci}
                indicatorTR={this.state.indicatorTR}
                indicatorRSI={this.state.indicatorRSI}
                enableDrawLine={this.state.enableDrawLine}
                disableDrawLine={this.disableDrawLine}
                settingTrendingLine={this.state.settingTrendingLine}
                indicatorBB={this.state.indicatorBB}
                indicatorVolume={this.state.indicatorVolume}
                indicatorStochastic={this.state.indicatorStochastic}
                enableText={this.state.enableText}
                disableText={this._disableText}
                enableGannFan={this.state.enableGannFan}
                enableFibonacci={this.state.enableFibonacci}
                enableTR={this.state.enableTR}
                cleanIndicator={this._cleanIndicatorEnabled}
                start={this.state.start}
                end={this.state.end}

                //BBand
                windowSizeBBand={this.state.windowSizeBBand}
                multiplierBBand={this.state.multiplierBBand}
                maTypeBBand={this.state.maTypeBBand}

                //area
                stroke={this.state.stroke}
                fill={this.state.fill}
                highestPoint={this.state.highestPoint}
                opacityFill={this.state.opacityFill}
                opacityHighestPoint={this.state.opacityHighestPoint}
                typeChart={chart}
                isIntraday={this.state.isIntraday}
                resetValues={() => this._resetValues(true)}
                removeIndicator={this._confirmDelete}
                limitDrawFunc={() => this._limitDrawFunction(lang.technicalAnalyst.limitDrawFunc)}
                theme={this.state.currentTheme}
                ps={this.parseToCurrency}
                lang={lang}
                currentLang={currentLang}
            />
        }
    }

    onChangeSuggest = (event, { newValue }) => {
        if(event && event.type !== `click`){
            this.setState({ value: newValue, errorSymbol: false });
            return false
        }
        
        let findSymbol = this.state.rics.filter(y => y.ric === newValue)[0];
        if (findSymbol) {
            let find = this.state.rics.filter(x => x.symbol === findSymbol.symbol)[0];
            if (find) {
                this.setState(
                    {
                        value: newValue,
                        mnemonic : newValue,
                        currentRic: findSymbol.symbol,
                        currentName: find.name,
                        currentShortName: find.shortName,
                        currentStockType: find.assetgroupid,
                        stockType: find.stockType,
                        dataChart: [],
                        data: [],
                        dataHistory: []
                    }, () => {
                        let params = this.props.match.params;
                        this._resetValues(false);
                        this._loadData(params, this.state.userInformation.userData.email, this.state.currentRic)
                    }
                )
            } else {
                this.setState({ value: newValue, mnemonic: newValue, errorSymbol: false });
            }
        } else {
            this.setState({ value: newValue, mnemonic: newValue, errorSymbol: false });
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({ suggestions: getSuggestions(this.state.rics, value), errorSymbol: false });
    };

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [], errorSymbol: false });
    };

    handleChangeComplete = (name, color) => {
        let c = color.rgb;
        switch (name) {
            case `fill`:
                this.setState(
                    {
                        colorFill: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        fill: color.hex
                    }
                )
                break;

            case `opacityFill`:
                this.setState(
                    {
                        opacityFill: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        }
                    }
                )
                break;

            case `stroke`:
                this.setState(
                    {
                        colorStroke: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        stroke: color.hex
                    }
                )
                break;

            case `colorStrokeMACD`:
                this.setState(
                    {
                        colorStrokeMACD: color
                    }
                )
                break;

            case `highestPoint`:
                this.setState(
                    {
                        colorHigestPoint: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        highestPoint: color.hex,
                        opacityHighestPoint: c.a
                    }
                )
                break;
            case `upCandle`:

                this.setState(
                    {
                        colorUpCandle: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        upCandle: color.hex
                    }
                )

                break;
            case `downCandle`:

                this.setState(
                    {
                        colorDownCandle: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        downCandle: color.hex
                    }
                )

                break;
            case `opacityCandle`:

                this.setState(
                    {
                        colorOpacityCandle: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        opacityCandle: c.a
                    }
                )

                break;
            case `upWick`:

                this.setState(
                    {
                        colorUpWick: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        upWick: color.hex
                    }
                )

                break;
            case `downWick`:

                this.setState(
                    {
                        colorDownWick: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        downWick: color.hex
                    }
                )

                break;
            case `upBorder`:

                this.setState(
                    {
                        colorUpBorder: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        upBorder: color.hex
                    }
                )

                break;
            case `downBorder`:

                this.setState(
                    {
                        colorDownBorder: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        },
                        downBorder: color.hex
                    }
                )

                break;
            case `colorStokeSMA`:
                this.setState(
                    {
                        colorStrokeSMA: color.hex
                    }
                )
                break;
            case `colorStrokeGannFan`:
                this.setState(
                    {
                        strokeColorGannFan: color.hex
                    }
                )
                break;
            case `colorFontFill`:
                this.setState(
                    {
                        colorFontFill: color.hex
                    }
                )
                break;
            case `alphaGannFan`:
                this.setState(
                    {
                        alphaGannFan: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        }
                    }
                )
                break;
            case `colorStrokeFB`:
                this.setState(
                    {
                        colorStrokeFB: color.hex
                    }
                )
                break;
            case `alphaStokeFB`:
                this.setState(
                    {
                        strokeOpacityFB: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        }
                    }
                )
                break;
            case `strokeOpacityTR`:
                this.setState(
                    {
                        strokeOpacityTR: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        }
                    }
                )
                break;
            case `strokeWidthTR`:
                this.setState(
                    {
                        strokeWidthTR: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        }
                    }
                )
                break;
            // case `fillTR`:
            //     this.setState(
            //         {
            //             fillTR: {
            //                 a: c.a,
            //                 b: c.b,
            //                 g: c.g,
            //                 r: c.r
            //             }
            //         }
            //     )
            //     break;
            case `fillOpacity`:
                this.setState(
                    {
                        fillOpacity: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        }
                    }
                )
                break;

            case `strokeTrendingLine`:
                this.setState(
                    {
                        strokeTrendingLine: color.hex
                    }
                )
                break;
            case `strokeOpacityLine`:
                this.setState(
                    {
                        strokeOpacityLine: {
                            a: c.a,
                            b: c.b,
                            g: c.g,
                            r: c.r
                        }
                    }
                )
                break;
            // case `strokeWidthTrending`:
            //         this.setState(
            //             {
            //                 strokeWidthTrending : c.a
            //             }
            //         )
            // break;

            case `colorUpVolume`:
                this.setState(
                    {
                        indicatorVolume: {
                            active: this.state.indicatorVolume.active,
                            [`colorUp`]: color,
                            [`colorDown`]: this.state.indicatorVolume.colorDown,
                            [`colorOpacity`]: this.state.indicatorVolume.colorOpacity,
                        }
                    }
                )
                break;
            case `colorDownVolume`:
                this.setState(
                    {
                        indicatorVolume: {
                            active: this.state.indicatorVolume.active,
                            [`colorUp`]: this.state.indicatorVolume.colorUp,
                            [`colorDown`]: color,
                            [`colorOpacity`]: this.state.indicatorVolume.colorOpacity,
                        }
                    }
                )
                break;
            case `OpacityVolume`:
                this.setState(
                    {
                        indicatorVolume: {
                            active: this.state.indicatorVolume.active,
                            [`colorUp`]: this.state.indicatorVolume.colorUp,
                            [`colorDown`]: this.state.indicatorVolume.colorDown,
                            [`colorOpacity`]: color
                        }
                    }
                )
                break;
            case `strokeKLine`:
                this.setState(
                    {
                        strokeKLine: color
                    }
                );
                break;
            case `strokeDLine`:
                this.setState(
                    {
                        strokeDLine: color
                    }
                );
                break;
            case `colorStrokeTR`:
            case `strokeOpacityTR`:
            case `fillTR`:
            case `fillOpacity`:
            //RSI
            case `colorRSI`:
            case `colorOverBoughtRSI`:
            case `colorMiddleRSI`:
            case `colorOverSoldRSI`:
            case `colorOutsideRSI`:
            case `colorInsideRSI`:
                this.setState(
                    {
                        [name]: color
                    }
                )
                break;
            default:
                break;
        }
    };

    handleTime = (value) => {
        let timestampNow = new Date().getTime();
        let timestamp = new Date().getTime();
        let timestampLastTick = 0;

        this.setState(
            {
                selectedPeriod: value
            }, () => {
                switch (value) {
                    case `10m`:
                        if (this.state.data.length > 0) {
                            timestampLastTick = new Date(this.state.data[this.state.data.length - 1].date).getTime();
                            timestamp = new Date(timestampLastTick).setMinutes(new Date(timestampLastTick).getMinutes() - 10);
                            this._refreshDataIntraday(timestamp, timestampLastTick, false);
                        } else {
                            this.dataNotFound()
                        }
                        break;
                    case `30m`:
                        if (this.state.data.length > 0) {
                            timestampLastTick = new Date(this.state.data[this.state.data.length - 1].date).getTime();
                            timestamp = new Date(timestampLastTick).setMinutes(new Date(timestampLastTick).getMinutes() - 30);
                            this._refreshDataIntraday(timestamp, timestampLastTick, false);
                        } else {
                            this.dataNotFound()
                        }
                        break;
                    case `2h`:
                        if (this.state.data.length > 0) {
                            timestampLastTick = new Date(this.state.data[this.state.data.length - 1].date).getTime();
                            timestamp = new Date(timestampLastTick).setMinutes(new Date(timestampLastTick).getMinutes() - 120);
                            this._refreshDataIntraday(timestamp, timestampLastTick, false);
                        } else {
                            this.dataNotFound()
                        }
                        break;
                    case `1D`:
                        if (this.state.data.length > 0) {
                            timestampLastTick = new Date(this.state.data[this.state.data.length - 1].date).getTime();
                            timestamp = new Date(timestampLastTick).setHours(0);
                            this._refreshDataIntraday(timestamp, timestampLastTick, false);
                        } else {
                            this.dataNotFound()
                        }
                        break;
                    case `30D`:
                        if (this.state.dataHistory.length > 0) {
                            timestampLastTick = new Date(this.state.dataHistory[this.state.dataHistory.length - 1].date).getTime();
                            timestamp = new Date(timestampLastTick).setMonth(new Date(timestampLastTick).getMonth() - 1);
                            this._refreshDataIntraday(timestamp, timestampNow, true);
                        } else {
                            this.dataNotFound()
                        }
                        break;
                    case `6M`:
                        if (this.state.dataHistory.length > 0) {
                            timestampLastTick = new Date(this.state.dataHistory[this.state.dataHistory.length - 1].date).getTime();
                            timestamp = new Date(timestampLastTick).setMonth(new Date(timestampLastTick).getMonth() - 6);
                            this._refreshDataIntraday(timestamp, timestampNow, true);
                        } else {
                            this.dataNotFound()
                        }
                        break;
                    case `1Y`:
                        if (this.state.dataHistory.length > 0) {
                            timestampLastTick = new Date(this.state.dataHistory[this.state.dataHistory.length - 1].date).getTime();
                            timestamp = new Date(timestampLastTick).setMonth(new Date(timestampLastTick).getMonth() - 12);
                            this._refreshDataIntraday(timestamp, timestampNow, true);
                        } else {
                            this.dataNotFound()
                        }
                        break;
                    case `2Y`:
                        if (this.state.dataHistory.length > 0) {
                            timestampLastTick = new Date(this.state.dataHistory[this.state.dataHistory.length - 1].date).getTime();
                            timestamp = new Date(timestampLastTick).setMonth(new Date(timestampLastTick).getMonth() - 24);
                            this._refreshDataIntraday(timestamp, timestampNow, true);
                        } else {
                            this.dataNotFound()
                        }
                        break;
                    case `3Y`:
                        if (this.state.dataHistory.length > 0) {
                            timestampLastTick = new Date(this.state.dataHistory[this.state.dataHistory.length - 1].date).getTime();
                            timestamp = new Date(timestampLastTick).setMonth(new Date(timestampLastTick).getMonth() - 36);
                            this._refreshDataIntraday(timestamp, timestampNow, true);
                        } else {
                            this.dataNotFound()
                        }
                        break;
                    case `Max`:
                        this._refreshDataIntraday(null, timestampNow, true);
                        break;
                    default:
                        this.setState(
                            {
                                dataChart: this.state.dataHistory
                            }
                        );
                        break;
                }
            }
        )
    }

    dataNotFound = () => {
        this.setState(
            {
                isLoading: false,
                addTechnicalAnalyst: false,
                dataChart: []
            }
        )
    }

    _refreshDataIntraday = (timestamp, timestampNow, history) => {
        const completeRic = this.state.rics.find(r => r.symbol === this.state.currentRic);
        let aux = [];
        if (history) {

            this.state.dataHistory.map(
                item => {
                    if (timestamp) {
                        let parse = new Date(item.date).getTime();
                        if (Number(parse) >= timestamp && Number(parse) <= timestampNow) {
                            aux.push(item);
                        }
                    } else {
                        aux.push(item);
                    }
                }
            );
            let onlyOneData = false;
            if (aux.length <= 1) {
                if (aux[aux.length - 1]) {
                    const close = !completeRic ? aux[aux.length - 1].close : completeRic.assetgroupid === 1 ? Number.parseFloat(aux[aux.length - 1].closeYield) : aux[aux.length - 1].close;
                    aux.push(
                        {
                            date: aux[aux.length - 1].date,
                            close: close,
                            pctChange: aux[aux.length - 1].pctChange,
                            volume: aux[aux.length - 1].volume,
                            open: aux[aux.length - 1].open,
                            high: aux[aux.length - 1].high,
                            low: aux[aux.length - 1].low,
                            bid: aux[aux.length - 1].bid,
                            ask: aux[aux.length - 1].ask,
                            closeYield: aux[aux.length - 1].closeYield,
                            timestamp: aux[aux.length - 1].timestamp
                        }
                    );
                    onlyOneData = true;
                }
            }
            if (aux && aux.length > 0) {
                let startTimeStamp = Number(aux[0].timestamp);
                let findStartIndex = this.state.dataHistory.findIndex(x => Number(x.timestamp === startTimeStamp));
                let endTimeStamp = Number(aux[aux.length - 1].timestamp);
                let findEndIndex = this.state.dataHistory.findIndex(x => Number(x.timestamp === endTimeStamp));
                if (findStartIndex === findEndIndex) {
                    findEndIndex += 1;
                }
                this.setState(
                    {
                        dataChart: this.state.dataHistory,
                        isIntraday: history,
                        start: onlyOneData ? findEndIndex - 2 : findStartIndex,
                        end: findEndIndex
                    }
                )
            } else {
                this.setState(
                    {
                        openAlert: true,
                        typeAlert: `warning`,
                        messageAlert: this.context.lang.technicalAnalyst.Messages.noData
                    }
                )
            }
        } else {
            this.state.data.map(
                item => {
                    let parse = new Date(item.date).getTime();
                    if (parseInt(parse) >= timestamp && parseInt(parse) <= timestampNow) {
                        aux.push(item)
                    }
                }
            );
            let onlyOneData = false;
            if (aux.length <= 1) {
                if (aux[aux.length - 1]) {
                    const close = !completeRic ? aux[aux.length - 1].close : completeRic.assetgroupid === 1 ? Number.parseFloat(aux[aux.length - 1].closeYield) : aux[aux.length - 1].close
                    aux.push(
                        {
                            date: aux[aux.length - 1].date,
                            close: close,
                            pctChange: aux[aux.length - 1].pctChange,
                            volume: aux[aux.length - 1].volume,
                            open: aux[aux.length - 1].open,
                            high: aux[aux.length - 1].high,
                            low: aux[aux.length - 1].low,
                            bid: aux[aux.length - 1].bid,
                            ask: aux[aux.length - 1].ask,
                            closeYield: aux[aux.length - 1].closeYield
                        }
                    );
                    onlyOneData = true;
                }
            }

            if (aux && aux.length > 0) {
                let startTimeStamp = Number(new Date(aux[0].date).getTime());
                let findStartIndex = this.state.data.findIndex(x => Number(new Date(x.date).getTime() === startTimeStamp));
                let endTimeStamp = Number(new Date(aux[aux.length - 1].date).getTime());
                let findEndIndex = this.state.data.findIndex(x => Number(new Date(x.date).getTime() === endTimeStamp));
                if (findStartIndex === findEndIndex) {
                    findEndIndex += 1;
                }
                this.setState(
                    {
                        dataChart: this.state.data,
                        // openAlert: false,
                        isIntraday: history,
                        start: onlyOneData ? findEndIndex - 2 : findStartIndex,
                        end: findEndIndex
                    }
                )
            } else {
                this.setState(
                    {
                        openAlert: true,
                        typeAlert: `warning`,
                        messageAlert: this.context.lang.technicalAnalyst.Messages.noData
                    }
                )
            }
        }
    }

    _showAlert = (open, message, type) => {
        this.setState(
            {
                openAlert: open,
                messageAlert: message,
                typeAlert: type
            }
        )
    }

    _closeAlert = () => {
        this.setState(
            {
                openAlert: false
            }
        )
    }

    _handleChangeChart = (type) => {
        this.setState(
            {
                upCandle: type === `velahueca` ? `#FFFFFF` : type === `vela` ? `#00c277` : this.state.upCandle,
                downCandle: type === `velahueca` ? `#000000` : type === `vela` ? `#f3383f` : this.state.downCandle,
                colorOpacityCandle: type === `velahueca` ? { r: 0, g: 0, b: 0, a: 0 } : { r: 0, g: 0, b: 0, a: 1 },
                opacityCandle: type === 'velahueca' ? 0 : 1,
                chartType: type
            }
        )
    }

    handleChange = (event, name) => {
        switch (name) {
            case `activeSlowStochastic`:
            case `activeFastStochastic`:
            case `activeFullStochastic`:
                this.setState(
                    {
                        [name]: event.target.checked
                    }
                )
                break;
            default:
                this.setState(
                    {
                        [name]: event.target.value
                    }
                )
                break;
        }
    }


    selectIndicator = (value) => {
        this.setState(
            {
                openDialog: true,
                currentIndicator: value.target ? `${value.target.value}` : value,
                windowSizeBBand: 20,
                multiplierBBand: 2,
                maTypeBBand: `sma`
            }
        )
    }

    _cleanIndicatorEnabled = () => {
        this.setState(
            {
                enableGannFan: false,
                enableTR: false,
                enableFibonacci: false,
            }
        )
    }

    createIndicator = (lang) => {
        this._cleanIndicatorEnabled();
        let aux = []
        let currentData = []
        switch (this.state.currentIndicator) {
            case 'SMA':
            case `EMA`:

                if (this.state.periodIndicator === '' || Number(this.state.periodIndicator) <= 0 || Number(this.state.periodIndicator) > 1000) {
                    this._showAlert(true, lang.smaEma.messages.invalid, 'warning');
                    return false;
                }

                let band = this.state.currentIndicator === `SMA` ? true : false
                let findSMA = this.state.arraySMA.filter(x => x.isSMA === band).length;

                if (findSMA >= 5) {
                    this._showAlert(true, lang.smaEma.messages.limit, 'warning');
                    return false;
                }

                aux = this.state.arraySMA;
                aux.push({ period: this.state.periodIndicator, color: this.state.colorStrokeSMA, isSMA: this.state.currentIndicator === `SMA` ? true : false });
                this.setState(
                    {
                        openDialog: false,
                        currentIndicator: ``,
                        arraySMA: aux,
                        periodIndicator: 20,
                        currentIndicator: ``
                    }
                )
                break;
            case `MACD`:

                if (this.state.fastMACD === '' || Number(this.state.fastMACD) <= 0) {
                    this._showAlert(true, lang.macd.messages.invalidFast, 'warning');
                    return false;
                }

                if (this.state.slowMACD === '' || Number(this.state.slowMACD) <= 0) {
                    this._showAlert(true, lang.macd.messages.invalidSlow, 'warning');
                    return false;
                }

                if (this.state.signalMACD === '' || Number(this.state.signalMACD) <= 0) {
                    this._showAlert(true, lang.macd.messages.invalidSignal, 'warning');
                    return false;
                }
                // aux = this.state.indicatorMACD;
                aux.push({ fast: this.state.fastMACD, slow: this.state.slowMACD, signal: this.state.signalMACD, color: this.state.colorStrokeMACD })
                currentData = this.state.dataChart;
                this.setState(
                    {
                        dataChart: [],
                        indicatorMACD: []
                    }, () => {
                        this.setState(
                            {
                                openDialog: false,
                                currentIndicator: ``,
                                indicatorMACD: aux,
                                dataChart: currentData,
                                fastMACD: 12,
                                slowMACD: 26,
                                signalMACD: 9,
                                currentIndicator: ``
                            }
                        )
                    }
                )
                break;

            case `GANNFAN`:

                aux = this.state.indicatorFannGan;
                aux.push({ activate: true, color: this.state.strokeColorGannFan, alpha: this.state.alphaGannFan, width: this.state.strokeGannFanWidth })
                currentData = this.state.dataChart;
                this.setState(
                    {
                        // dataChart: [],
                        indicatorFannGan: []
                    }, () => {
                        this.setState(
                            {
                                openDialog: false,
                                enableGannFan: true,
                                // dataChart: currentData,
                                indicatorFannGan: aux,
                                strokeColorGannFan: ``,
                                alphaGannFan: ``,
                                strokeGannFanWidth: 1,
                                currentIndicator: ``
                            }
                        )
                    }
                )
                break;

            case `FB`:

                aux = this.state.indicatorFibonacci;
                aux.push({ activate: true, type: this.state.typeLineFB, color: this.state.colorStrokeFB, alpha: this.state.strokeOpacityFB, width: this.state.strokeWidthFB, fill: this.state.colorFontFill });
                this.setState(
                    {
                        openDialog: false,
                        enableFibonacci: true,
                        indicatorFibonacci: aux,
                        // typeLineFB: ``,
                        colorFontFill: ``,
                        colorStrokeFB: ``,
                        strokeOpacityFB: 1,
                        strokeWidthFB: 1,
                        currentIndicator: ``
                    }
                )
                break;

            case `TR`:
                aux = this.state.indicatorTR;
                let cst = this.state.colorStrokeTR.hex ? this.state.colorStrokeTR : {
                    hex: "#FFFFFF",
                    rgb:
                    {
                        a: 1,
                        b: 0,
                        g: 0,
                        r: 0
                    }
                };

                let ftr = this.state.fillTR.hex ? this.state.fillTR : {
                    hex: "#8AAFE2",
                    rgb:
                    {
                        a: 1,
                        b: 0,
                        g: 0,
                        r: 0
                    }
                };

                aux.push(
                    {
                        colorStrokeTR: cst,
                        strokeOpacityTR: this.state.strokeOpacityTR,
                        strokeWidthTR: this.state.strokeWidthTR,
                        fillTR: ftr,
                        fillOpacity: this.state.fillOpacity
                    }
                );
                this.setState(
                    {
                        enableTR: true,
                        openDialog: false,
                        indicatorTR: aux,
                        colorStrokeTR: ``,
                        strokeOpacityTR: 1,
                        strokeWidthTR: 1,
                        fillTR: ``,
                        fillOpacity: 1,
                        currentIndicator: ``
                    }
                )
                break;

            case `RSI`:

                let period = Number(this.state.periodRSI);
                if (period <= 0 || period > 200) {
                    this._showAlert(true, lang.rsi.messages.invalidPeriod, 'warning');
                    return false
                }

                let upper = Number(this.state.upperBandRSI);
                if (upper <= 0) {
                    this._showAlert(true, lang.rsi.messages.invalidUpper, 'warning');
                    return false
                }

                let middle = Number(this.state.middleRSI);
                if (middle <= 0) {
                    this._showAlert(true, lang.rsi.messages.invalidMiddle, 'warning');
                    return false
                }

                let lower = Number(this.state.lowerRSI);
                if (lower <= 0) {
                    this._showAlert(true, lang.rsi.messages.invalidLower, 'warning');
                    return false
                }

                let rsi = {
                    periodRSI: this.state.periodRSI,
                    upperBandRSI: this.state.upperBandRSI,
                    middleRSI: this.state.middleRSI,
                    lowerRSI: this.state.lowerRSI,
                    colorRSI: this.state.colorRSI,
                    colorOverBoughtRSI: this.state.colorOverBoughtRSI,
                    colorMiddleRSI: this.state.colorMiddleRSI,
                    colorOverSoldRSI: this.state.colorOverSoldRSI,
                    colorOutsideRSI: this.state.colorOutsideRSI,
                    colorInsideRSI: this.state.colorInsideRSI
                }
                this.setState(
                    {
                        openDialog: false,
                        indicatorRSI: rsi,
                        currentIndicator: ``,
                        periodRSI: 14,
                        upperBandRSI: 70,
                        middleRSI: 14,
                        lowerRSI: 30,
                    }
                )
                break;

            case `drawLine`:

                if (this.state.typeTrendingLine === ``) {
                    this._showAlert(true, lang.drawnLine.messages.typeLine, 'warning');
                    return false;
                }

                if (this.state.strokeTrendingLine === ``) {
                    this._showAlert(true, lang.drawnLine.messages.color, 'warning');
                    return false;
                }

                if (this.state.styleTrendingLine === ``) {
                    this._showAlert(true, lang.drawnLine.messages.styleLine, 'warning');
                    return false;
                }

                this.setState(
                    {
                        openDialog: false,
                        enableDrawLine: true,
                        settingTrendingLine: {
                            typeTrendingLine: this.state.typeTrendingLine,
                            strokeTrendingLine: this.state.strokeTrendingLine,
                            strokeOpacityLine: this.state.strokeOpacityLine,
                            strokeWidthTrending: this.state.strokeWidthTrending,
                            styleTrendingLine: this.state.styleTrendingLine
                        },
                        currentIndicator: ``
                    }
                )
                break;

            case `BBands`:

                if (Number(this.state.windowSizeBBand) <= 0 || Number(this.state.windowSizeBBand) > 200) {
                    this._showAlert(true, lang.bbands.messages.invalidWindowSize, 'warning');
                    return false;
                }

                if (Number(this.state.multiplierBBand) <= 0 || Number(this.state.multiplierBBand) > 200) {
                    this._showAlert(true, lang.bbands.messages.invalidMultiplier, 'warning');
                    return false;
                }

                if (this.state.maTypeBBand === ``) {
                    this._showAlert(true, lang.bbands.messages.invalidMaType, 'warning');
                    return false;
                }

                this.setState(
                    {
                        openDialog: false,
                        indicatorBB: true,
                        currentIndicator: ``,
                    }
                )
                break;

            case `Volume`:
                this.setState(
                    {
                        openDialog: false,
                        indicatorVolume: {
                            active: true,
                            [`colorUp`]: this.state.indicatorVolume.colorUp,
                            [`colorDown`]: this.state.indicatorVolume.colorDown,
                            [`colorOpacity`]: this.state.indicatorVolume.colorOpacity
                        },
                        currentIndicator: ``
                    }
                )
                break;

            case `Stochastic`:
                const { activeSlowStochastic, activeFastStochastic, activeFullStochastic, periodStochastic, kWindowSizeStochastic, dWindowSizeStochastic,
                    strokeDLine, strokeKLine } = this.state;

                if (periodStochastic === `` || Number(periodStochastic) <= 0 || Number(periodStochastic) > 300) {
                    this._showAlert(true, lang.stochastic.messages.invalidPeriod, 'warning');
                    return false;
                }

                if (kWindowSizeStochastic === `` || Number(kWindowSizeStochastic) <= 0 || Number(kWindowSizeStochastic) > 300) {
                    this._showAlert(true, lang.stochastic.messages.invalidKWindowSize, 'warning');
                    return false;
                }

                if (dWindowSizeStochastic === `` || Number(dWindowSizeStochastic) <= 0 || Number(dWindowSizeStochastic) > 300) {
                    this._showAlert(true, lang.stochastic.messages.invalidDWindowSize, 'warning');
                    return false;
                }

                if (Boolean(activeSlowStochastic) === false && Boolean(activeFastStochastic) == false && Boolean(activeFullStochastic) === false) {
                    this._showAlert(true, lang.stochastic.messages.selectedChart, 'warning');
                    return false;
                }

                this.setState(
                    {
                        openDialog: false,
                        indicatorStochastic: {
                            [`activeSlow`]: activeSlowStochastic,
                            [`activeFast`]: activeFastStochastic,
                            [`activeFull`]: activeFullStochastic,
                            [`periodStochastic`]: periodStochastic,
                            [`kWindowSizeStochastic`]: kWindowSizeStochastic,
                            [`dWindowSizeStochastic`]: dWindowSizeStochastic,
                            [`strokeDLine`]: strokeDLine,
                            [`strokeKLine`]: strokeKLine
                        },
                        currentIndicator: ``,
                        // activeSlowStochastic: false,
                        // activeFastStochastic: false,
                        // activeFullStochastic: false,
                        periodStochastic: 3,
                        kWindowSizeStochastic: 14,
                        dWindowSizeStochastic: 3
                    }
                )
                break;

            default:
                break;
        }
    }

    enableDrawLine = () => {

        this.setState(
            {
                openDialog: true,
                currentIndicator: `drawLine`
            }
        )
    }

    disableDrawLine = () => {

        this.setState(
            {
                enableDrawLine: false
            }
        )
    }

    saveChartAsImage = () => {
        var container = ReactDOM.findDOMNode(this.chart); // eslint-disable-line react/no-find-dom-node
        SaveChartAsImage.saveChartAsImage(container);
    }


    _enableText = () => {
        this.setState(
            {
                enableText: true
            }
        )
    }

    _disableText = () => {
        this.setState(
            {
                enableText: false
            }
        )
    }

    exportToCSV = (data, lang) => {
        let content = `${lang.date}, ${lang.close}, ${lang.open}, ${lang.high}, ${lang.low}\n`;
        this.state.dataChart.map(
            item => {
                let dec = this.state.currentStockType === 3 ? 4 : 2;
                let d = new Date(item.date).toISOString().split(`T`)[0].split(`-`);
                content += `${d[2]}/${d[1]}/${d[0]},${Number(item.close).toFixed(dec)},${Number(item.open).toFixed(dec)},${Number(item.high).toFixed(dec)},${Number(item.low).toFixed(dec)}\n`;
            }
        )
        var csv_content = data,
            download = document.createElement("a"),
            blob = new Blob(["\ufeff", content], { type: 'application/vnd.ms-excel' });
        document.body.appendChild(download);
        download.href = window.URL.createObjectURL(blob);
        download.download = `${lang.name} - ${this.state.currentName}.csv`;
        download.click();
    }

    exportToXLSX = (lang) => {
        let aux = [];
        this.state.dataChart.map(
            item => {
                let dec = this.state.currentStockType === 3 ? 4 : 2;
                aux.push(
                    {
                        close: this.parseToCurrency(Number(item.close).toFixed(dec)),
                        date: item.date,
                        high: this.parseToCurrency(Number(item.high).toFixed(dec)),
                        low: this.parseToCurrency(Number(item.low).toFixed(dec)),
                        open: this.parseToCurrency(Number(item.open).toFixed(dec))
                    }
                )
            }
        )

        this.setState(
            {
                dataExcel: aux
            }, () => {
                const options = this._exporter.workbookOptions();
                options.sheets[0].name = `${lang} - ${this.state.currentName}`;
                this._exporter.save(options);
            }
        )
    }

    exportToImage = (lang, instrument, theme) => {
        this.setState(
            {
                // showName : true,
                showZoom: false
            }, () => {
                let container = ReactDOM.findDOMNode(this.chart); // eslint-disable-line react/no-find-dom-node
                // SaveChartAsImage.saveChartAsImage(container);
                const stage = this;
                if (theme === 'dark') {
                    SaveChartAsImage.saveWithDarkBG(document, container, function (src) {
                        const a = document.createElement("a");
                        a.setAttribute("href", src);
                        a.setAttribute("download", `${lang} - ${instrument}`);
                        document.body.appendChild(a);
                        a.addEventListener("click", function (/* e */) {
                            a.parentNode.removeChild(a);
                        });
                        a.click();
                        stage.setState(
                            {
                                // showName : false,
                                showZoom: true
                            }
                        )
                    });
                } else {
                    SaveChartAsImage.saveWithWhiteBG(document, container, function (src) {
                        const a = document.createElement("a");
                        a.setAttribute("href", src);
                        a.setAttribute("download", `${lang} - ${instrument}`);
                        document.body.appendChild(a);
                        a.addEventListener("click", function (/* e */) {
                            a.parentNode.removeChild(a);
                        });
                        a.click();
                        stage.setState(
                            {
                                // showName : false,
                                showZoom: true
                            }
                        )
                    });
                }
            }
        )
    }

    saveNode = (node) => {
        this.chart = node;
    }

    _confirmDelete = (e, currentIndicator) => {
        this.setState(
            {
                openDialog: true,
                currentIndicator: `delete`,
                deleteIndicator: currentIndicator,
                eventDelete: e
            }
        )
    }

    _deleteTechnical = (e) => {
        this.setState(
            {
                openDialog : true
                , currentIndicator : 'deleteTechnical'
                , deleteIndicator : 'deleteTechnical'
                , eventDelete : e
            }
        )
    }

    

    _removeIndicator = () => {

        switch (this.state.deleteIndicator) {
            case 'smaema':
                let i = this.state.arraySMA.findIndex(x => Number(x.period) === Number(this.state.eventDelete.windowSize) && x.isSMA === (this.state.eventDelete.type === `SMA`) ? true : false);
                if (i !== -1) {
                    let d = this.state.arraySMA;
                    d.splice(i, 1);
                    this.setState(
                        {
                            arraySMA: d,
                            openDialog: false
                        }
                    );
                }
                break;
            case `MACD`:
                this.setState(
                    {
                        indicatorMACD: [],
                        openDialog: false
                    }
                )
                break;
            case `RSI`:
                this.setState(
                    {
                        indicatorRSI: null,
                        openDialog: false
                    }
                )
                break;
            case `stochasticSlow`:
                this.setState(
                    {
                        indicatorStochastic: {
                            [`activeSlow`]: false,
                            [`activeFast`]: this.state.activeFastStochastic,
                            [`activeFull`]: this.state.activeFullStochastic,
                            [`periodStochastic`]: this.state.periodStochastic,
                            [`kWindowSizeStochastic`]: this.state.kWindowSizeStochastic,
                            [`dWindowSizeStochastic`]: this.state.dWindowSizeStochastic
                        },
                        openDialog: false,
                        activeSlowStochastic: false
                    }
                )
                break;

            case `stochasticFast`:
                this.setState(
                    {
                        indicatorStochastic: {
                            [`activeSlow`]: this.state.activeSlowStochastic,
                            [`activeFast`]: false,
                            [`activeFull`]: this.state.activeFullStochastic,
                            [`periodStochastic`]: this.state.periodStochastic,
                            [`kWindowSizeStochastic`]: this.state.kWindowSizeStochastic,
                            [`dWindowSizeStochastic`]: this.state.dWindowSizeStochastic
                        },
                        activeFastStochastic: false,
                        openDialog: false
                    }
                )
                break;

            case `stochasticFull`:
                this.setState(
                    {
                        indicatorStochastic: {
                            [`activeSlow`]: this.state.activeSlowStochastic,
                            [`activeFast`]: this.state.activeFastStochastic,
                            [`activeFull`]: false,
                            [`periodStochastic`]: this.state.periodStochastic,
                            [`kWindowSizeStochastic`]: this.state.kWindowSizeStochastic,
                            [`dWindowSizeStochastic`]: this.state.dWindowSizeStochastic
                        },
                        activeFullStochastic: false,
                        openDialog: false
                    }
                )
                break;
            case `bbands`:
                this.setState(
                    {
                        openDialog: false,
                        indicatorBB: undefined
                    }
                )
                break;
            case 'volumen':
                this.setState(
                    {
                        openDialog: false,
                        indicatorVolume: {
                            active: false,
                            colorUp: {},
                            colorDown: {},
                            colorOpacity: {}
                        }
                    }
                )
                break;
            default:
                break;
        }
    }

    _getTechnicalAnalyst = (email) => {
        this.setState({ isLoading: true });
        API_POST(`POST`, `technicalanalysis/gettechnicalanalysis`, {
            "user": email
        }).then(
            response => {
                if (response && response.status === `OK` && response.data.length > 0) {
                    //get favorite 
                    let userRic = JSON.parse(sessionStorage.getItem(JSON.parse(sessionStorage.getItem(paramsSession)).SESSION_NAME));
                    
                    getUserRics().then(r => {
                        let s = response.data[0].symbol;
                        let c = response.data[0];

                        if (this.state.pivTechnical.name !== `` && this.state.pivTechnical.symbol !== ``) {
                            s = response.data.find(x => x.name === this.state.pivTechnical.name && x.symbol === this.state.pivTechnical.symbol).symbol;
                            c = response.data.find(x => x.name === this.state.pivTechnical.name && x.symbol === this.state.pivTechnical.symbol);
                        }
                         
                        let isRicRT = String(s).split('')[0] === '/' ? true : false;
                        let delay = isRicRT === true ? response.data.findIndex(x => String(x.symbol).split('')[0] !== '/' ? true : false ) : response.data.findIndex(x => String(x.symbol).split('')[0] === '/' ? true : false );
                        // En caso de que el usario tenga permisos RT y lo cambios a delay
                         
                        if(delay > -1 && isRicRT === true){
                            getUserRics().then(
                                r => {
                                    this.setState(
                                        {
                                            addTechnicalAnalyst: true,
                                            rics: r,
                                            value: ``
                                            , isLoading : false
                                        }
                                    );
                                }
                            );
                        }else{
                            let ric = r.find(x =>  x.symbol === s);
                            // console.log('Ric', ric && ric.name !== `-` ? ric.name : ric.symbol ? ric.symbol : '')
                            this.setState({
                                allTechnical: response.data,
                                currentTechnical: c,
                                rics: r,
                                userInformation: userRic,
                                currentRic: ric.symbol,
                                currentName: ric ? ric.name ? ric.name : `` : '',
                                currentShortName: ric ? ric.shortName ? ric.shortName : `` : '',
                                currentStockType: ric ? ric.assetgroupid ? ric.assetgroupid : 0 : 0,
                                stockType: ric ? ric.stockType : '',
                                //value: ric && ric.mnemonic !== `-` ? ric.mnemonic : ric.symbol ? ric.symbol : '',
                                value: ric && ric.symbol !== `-` ? ric.symbol : ric.name,
                                mnemonic: ric && ric.name !== `-` ? ric.name : ric.symbol ? ric.symbol : '',
                                dataChart: [],
                                data: [],
                                dataHistory: [],
                                // addTechnicalAnalyst: false
                            }, () => {
                                this._resetValues(false);
                                let params = this.props.match.params;
                                this._loadData(params, userRic.userData.email, this.state.currentRic);
                            });
                        }


                    }).catch(e => {

                    });

                } else {
                    getUserRics().then(
                        r => {
                            this.setState(
                                {
                                    addTechnicalAnalyst: true,
                                    rics: r,
                                    value: ``
                                    , isLoading : false
                                }
                            );
                        }
                    );
                }
            }
        ).catch(
            e => {
                this.setState({ isLoading: false });
                console.log(`error get technical`, e);
            }
        )
    }

    _deleteTechnicalAnalyst = () => {
        API_POST(`POST`, 'technicalanalysis/delete', {
            "idTechnicalAnalysis": this.state.currentTechnical.idTechnicalAnalysis,
            "user": this.state.userInformation.userData.email
        }).then(
            response => {
                if (response && response.status === `OK`) {
                    
                    this.setState(
                        {
                            openDialog : false
                        }, () => {
                            this._showAlert(true, this.context.lang.technicalAnalyst.Messages.deleted, `success`);
                            this._resetValues();
                            this.componentDidMount();
                        }
                    )
                }
            }
        ).catch(
            e => {
                console.log(`error delete`, e);
            }
        )
    }

    validateRicSuggestion = () => {
        const { rics, symbolTechnical } = this.state;

        // console.log(symbolTechnical)
        // if (symbolTechnical === '') {
        //     return false
        // }

        if (rics.findIndex(x => x.symbol === symbolTechnical) === -1) {
            this.setState(
                {
                    errorSymbol: true
                }
            )
            return false;
        } else {
            this.setState(
                {
                    errorSymbol: false
                }
            )
        }
        return true;
    }

    _createTechnicalAnalyst = () => {
        const { nameTechnical, descriptionTechnical, symbolTechnical, userInformation } = this.state;
        this.setState({ isLoading: true });
        API_POST(`POST`, `/technicalanalysis/add`, {
            "user": userInformation.userData.email,
            "name": nameTechnical,
            "description": descriptionTechnical,
            "symbol": symbolTechnical,
            "configuration": "{}"
        }).then(
            response => {
                console.log(`response add`, response);
                if (response && response.status === `OK`) {
                    this._showAlert(true, this.context.lang.technicalAnalyst.Messages.created, `success`);
                    this.setState(
                        {
                            addTechnicalAnalyst: false,
                            errorSymbol: false,
                            nameTechnical: ``,
                            descriptionTechnical: ``,
                            symbolTechnical: ``,
                            pivTechnical: {
                                name: nameTechnical,
                                symbol: symbolTechnical
                            },
                            isLoading: false
                        }, () => {
                            this.componentDidMount();
                        }
                    );
                }
            }
        ).catch(
            e => {
                console.log(`error add`, e);
                this.setState({ isLoading: false });
            }
        )
    }

    _updateTechnicalAnalyst = () => {
        const { nameTechnical, descriptionTechnical, symbolTechnical, userInformation, currentTechnical, rics } = this.state;
        if (!this._isValidNewTA()) {
            return false;
        }
        if (rics.findIndex(x => x.symbol === symbolTechnical) === -1) {
            this.setState(
                {
                    errorSymbol: true
                }
            )
            return false;
        }
        this.setState({ isLoading: true });
        API_POST(`POST`, `/technicalanalysis/update`, {
            "idTechnicalAnalysis": currentTechnical.idTechnicalAnalysis,
            "user": userInformation.userData.email,
            "name": nameTechnical,
            "description": descriptionTechnical,
            "symbol": symbolTechnical,
            "configuration": "{}"
        }).then(
            response => {
                if (response && response.status === `OK`) {
                    this._showAlert(true, this.context.lang.technicalAnalyst.Messages.updated, `success`);
                    this.setState(
                        {
                            addTechnicalAnalyst: false,
                            errorSymbol: false,
                            nameTechnical: ``,
                            descriptionTechnical: ``,
                            symbolTechnical: ``,
                            pivTechnical: {
                                name: nameTechnical,
                                symbol: symbolTechnical
                            },
                            isLoading: false
                        }, () => {
                            this.componentDidMount();
                        }
                    );
                }
            }
        ).catch((e) => {
            this.setState({ isLoading: false });
        })
    }

    _changeSymbolTechnical = (event, { newValue }) => {
        // let findSymbol = this.state.rics.filter(x => x.mnemonic === newValue)[0];
        
        let findSymbol = this.state.rics.filter(x => x.name === newValue)[0];
        if (findSymbol) {
            let find = this.state.rics.filter(x => x.symbol === findSymbol.symbol)[0];
            if (find) {
                this.setState(
                    {
                        value: newValue,
                        currentRic: findSymbol.symbol,
                        currentName: find.name,
                        symbolTechnical: find.symbol
                    }
                );
            } else {
                this.setState({ value: newValue, symbolTechnical: newValue });
            }
        } else {
            this.setState({ value: newValue, symbolTechnical: newValue });
        }
    }

    _selectTechnical = (technical) => {
        if (this.state.currentTechnical.idTechnicalAnalysis !== technical.idTechnicalAnalysis) {
            let ric = this.state.rics.find(x => x.symbol === technical.symbol);
            if (ric) {
                this.setState({
                    currentTechnical: technical,
                    currentRic: technical.symbol,
                    currentName: ric.name ? ric.name : ``,
                    currentShortName: ric.shortName ? ric.shortName : ``,
                    currentStockType: ric.assetgroupid ? ric.assetgroupid : 0,
                    stockType: ric ? ric.stockType : '',
                    // value: ric.mnemonic !== `-` ? ric.mnemonic : ric.symbol ? ric.symbol : '',
                    value: ric.name !== `-` ? ric.name : ric.symbol ? ric.symbol : '',
                    dataChart: [],
                    data: [],
                    dataHistory: [],
                    addTechnicalAnalyst: false
                }, () => {
                    this._resetValues(false);
                    let params = this.props.match.params;
                    this._loadData(params, this.state.userInformation.userData.email, this.state.currentRic);
                });
            } else {
                this.setState({
                    currentTechnical: technical,
                    currentRic: '',
                    currentName: '',
                    currentShortName: '',
                    currentStockType: 0,
                    stockType: '',
                    value: '',
                    dataChart: [],
                    data: [],
                    dataHistory: []
                }, () => {
                    this._resetValues(false);
                    let params = this.props.match.params;
                    this._loadData(params, this.state.userInformation.userData.email, '');
                });

            }
        }
    }

    _newTechnicalAnalyst = () => {

        this.setState({ addTechnicalAnalyst: true, dataChart: [], allTechnical: [], symbolTechnical: ``, value: `` });

    }

    _cancelAdd = () => {
        this.setState(
            {
                disableAdd: true
            }
        )
        this.componentDidMount();
    }

    _updateTechnical = () => {
        this.setState(
            {
                updateTechnical: true,
                nameTechnical: this.state.currentTechnical.name,
                descriptionTechnical: this.state.currentTechnical.description,
                symbolTechnical: this.state.currentTechnical.symbol
            }
        )
    }

    _limitDrawFunction = (lang) => {
        this._showAlert(true, `${lang}`, `warning`)
    }

    parseToCurrency = (value) => {
        value = value.toString().replace(".", ",");
        value = value.replace(/\d(?=(\d{3})+\,)/g, '$&.');
        return value;
    }

    saveIndicators = () => {
        this.setState(
            {
                saveWork: true
            }, () => {
                const { userInformation
                    , currentTechnical
                    , rics
                    , arraySMA
                    , indicatorMACD
                    , colorStrokeMACD
                    , indicatorRSI
                    , indicatorBB
                    , indicatorStochastic
                    , selectedPeriod
                    , indicatorVolume
                } = this.state;
                if (rics.findIndex(x => x.symbol === currentTechnical.symbol) === -1) {
                    this.setState(
                        {
                            errorSymbol: true,
                            saveWork: false
                        }
                    )
                    return false;
                }
                const c = JSON.stringify(
                    {
                        period: selectedPeriod,
                        sma: arraySMA,
                        macd: {
                            value: indicatorMACD,
                            stroke: colorStrokeMACD
                        },
                        rsi: indicatorRSI,
                        bb: indicatorBB,
                        volume: indicatorVolume,
                        stochastic: indicatorStochastic
                    }
                );

                API_POST(`POST`, `/technicalanalysis/update`, {
                    "idTechnicalAnalysis": currentTechnical.idTechnicalAnalysis,
                    "user": userInformation.userData.email,
                    "name": currentTechnical.name,
                    "description": currentTechnical.description,
                    "symbol": currentTechnical.symbol,
                    "configuration": c
                }).then(
                    response => {
                        if (response && response.status === `OK`) {
                            currentTechnical.configuration = c;
                            this.setState({
                                currentTechnical: currentTechnical,
                                saveWork: false
                            });
                            this._showAlert(true, this.context.lang.technicalAnalyst.Messages.updated, `success`);
                        }
                    }
                )
            }
        )
    }

    render() {
        const { classes } = this.props;
        const { value, suggestions } = this.state;
        const inputProps = {
            value,
            onChange: this.onChangeSuggest
        };

        const inputPropsAdd = {
            value,
            onChange: this._changeSymbolTechnical
        }

        const styleHeader = {
            background: `#FFFFFF`,
            color: "#000000",

        }
        return (
            <LanguageConsumer>
                {
                    ({ lang }) =>
                        <React.Fragment>
                            <AntTabs
                                allTechnical={this.state.allTechnical}
                                select={this._selectTechnical}
                                selected={this.state.currentTechnical}
                                add={this._newTechnicalAnalyst}
                                disableAdd={this.state.disableAdd}
                            />
                            {
                                this.state.addTechnicalAnalyst ? `` :
                                    <Header
                                        title={this.state.currentRic}
                                        saveIndicators={this.saveIndicators}
                                        colorLine={this.state.colorStroke}
                                        background={this.state.colorFill}
                                        highestPoint={this.state.colorHigestPoint}
                                        opacityFill={this.state.opacityFill}
                                        handleChangeComplete={this.handleChangeComplete}
                                        time={this.handleTime}
                                        changeChart={this._handleChangeChart}
                                        lang={lang.technicalAnalyst}
                                        chartType={this.state.chartType}
                                        upCandle={this.state.colorUpCandle}
                                        downCandle={this.state.colorDownCandle}
                                        opacityCandle={this.state.colorOpacityCandle}
                                        upWick={this.state.colorUpWick}
                                        downWick={this.state.colorDownWick}
                                        upBorder={this.state.colorUpBorder}
                                        downBorder={this.state.colorDownBorder}
                                        handleChange={this.handleChange}
                                        EMA={this.state.EMA}
                                        SMA={this.state.SMA}
                                        printGraphic={this.saveChartAsImage}
                                        currentIndicator={this.state.currentIndicator}
                                        createIndicator={this.selectIndicator}
                                        classes={classes}
                                        handleEnableDrawnLine={this.enableDrawLine}
                                        enableText={this._enableText}
                                        handleChangeRic={this.handleChangeRic}
                                        selectRic={this.state.selectedOption}
                                        optionsRic={this.state.optionsRic}
                                        stockType={this.state.currentStockType}

                                        //autosugest
                                        inputProps={inputProps}
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}

                                        //reset values
                                        resetValues={() => this._resetValues(true)}

                                        //save data
                                        exportToCSV={() => this.exportToCSV([], lang.technicalAnalyst.export)}
                                        exportToXLSX={() => this.exportToXLSX(lang.technicalAnalyst.export.name)}
                                        exportToImage={() => this.exportToImage(lang.technicalAnalyst.export.name, this.state.currentName, this.state.currentTheme)}
                                        currentStockType={this.state.currentStockType}

                                        //deleteTechnical={this._deleteTechnicalAnalyst}
                                        deleteTechnical={(e) => this._deleteTechnical(e)}
                                        editTechnical={this._updateTechnical}
                                        isLoading={this._isLoading}
                                    />
                            }

                            <FormDialog
                                lang={lang.technicalAnalyst.formIndicator}
                                optionsDraw={lang.technicalAnalyst.formIndicator.drawnLine.options}
                                titleDelete={lang.technicalAnalyst.confirmDelete}
                                titleDeleteTech={lang.technicalAnalyst.confirmDeleteTechnical}
                                titleForm={lang.technicalAnalyst.config}
                                open={this.state.openDialog}
                                handleClose={() => this.setState({ openDialog: false })}
                                indicator={this.state.currentIndicator}
                                period={this.state.periodIndicator}
                                createIndicator={() => this.createIndicator(lang.technicalAnalyst.formIndicator)}
                                handleChange={this.handleChange}
                                strokeColorSMA={this.state.colorStrokeSMA}
                                handleChangeComplete={this.handleChangeComplete}
                                fastMACD={this.state.fastMACD}
                                slowMACD={this.state.slowMACD}
                                signalMACD={this.state.signalMACD}
                                colorStrokeMACD={this.state.colorStrokeMACD}
                                strokeColorGannFan={this.state.strokeColorGannFan}
                                alphaGannFan={this.state.alphaGannFan}
                                strokeGannFanWidth={this.state.strokeGannFanWidth}
                                accept={lang.technicalAnalyst.accept}
                                cancel={lang.technicalAnalyst.cancel}

                                //fibonacci
                                colorStrokeFB={this.state.colorStrokeFB}
                                colorFontFill={this.state.colorFontFill}
                                alphaStokeFB={this.state.strokeOpacityFB}
                                strokeWidthFB={this.state.strokeWidthFB}
                                typeLineFB={this.state.typeLineFB}

                                //TR
                                colorStrokeTR={this.state.colorStrokeTR}
                                strokeOpacityTR={this.state.strokeOpacityTR}
                                strokeWidthTR={this.state.strokeWidthTR}
                                fillTR={this.state.fillTR}
                                fillOpacity={this.state.fillOpacity}

                                //RSI 
                                periodRSI={this.state.periodRSI}
                                upperBandRSI={this.state.upperBandRSI}
                                middleRSI={this.state.middleRSI}
                                lowerRSI={this.state.lowerRSI}
                                colorRSI={this.state.colorRSI}
                                colorOverBoughtRSI={this.state.colorOverBoughtRSI}
                                colorMiddleRSI={this.state.colorMiddleRSI}
                                colorOverSoldRSI={this.state.colorOverSoldRSI}
                                colorOutsideRSI={this.state.colorOutsideRSI}
                                colorInsideRSI={this.state.colorInsideRSI}

                                //trending line
                                typeTrendingLine={this.state.typeTrendingLine}
                                strokeTrendingLine={this.state.strokeTrendingLine}
                                strokeOpacityLine={this.state.strokeOpacityLine}
                                strokeWidthTrending={this.state.strokeWidthTrending}
                                styleTrendingLine={this.state.styleTrendingLine}

                                //BBand
                                windowSizeBBand={this.state.windowSizeBBand}
                                multiplierBBand={this.state.multiplierBBand}
                                maTypeBBand={this.state.maTypeBBand}
                                //volume
                                indicatorVolume={this.state.indicatorVolume}
                                colorUpVolume={this.state.indicatorVolume.colorUp}
                                colorDownVolume={this.state.indicatorVolume.colorDown}
                                OpacityVolume={this.state.indicatorVolume.colorOpacity}

                                periodStochastic={this.state.periodStochastic}
                                kWindowSizeStochastic={this.state.kWindowSizeStochastic}
                                dWindowSizeStochastic={this.state.dWindowSizeStochastic}
                                activeSlowStochastic={this.state.activeSlowStochastic}
                                activeFastStochastic={this.state.activeFastStochastic}
                                activeFullStochastic={this.state.activeFullStochastic}
                                strokeDLine={this.state.strokeDLine}
                                strokeKLine={this.state.strokeKLine}
                                // remove
                                remove={this._removeIndicator}
                                deleteTech={() => this._deleteTechnicalAnalyst()}
                            />

                            <div key={0} className={classes.contentChart}>
                                {
                                    this.state.dataChart.length > 0 && this.state.addTechnicalAnalyst === false ?
                                        this.showChart(this.state.chartType, lang) :
                                        this.state.addTechnicalAnalyst === false ?
                                            <div className={classes.boxLoading}>
                                                {
                                                    this.state.isLoading ?
                                                        <ReactLoading
                                                            type={"bars"}
                                                            delay={10}
                                                            color={"#5c5c5c"}
                                                            height={'6%'}
                                                            width={'6%'} /> :
                                                        <div className={classes.noData}>
                                                            <InfoOutlinedIcon className={classes.color} size="md" />
                                                            <Typography variant="h6">
                                                                {lang.technicalAnalyst.NoResults}
                                                            </Typography>
                                                        </div>
                                                }
                                            </div>
                                            : ``
                                }
                            </div>
                            <Alert
                                open={this.state.openAlert}
                                variant={this.state.typeAlert}
                                message={this.state.messageAlert}
                                onClose={() => this._closeAlert()}
                            />

                            {
                                this.state.addTechnicalAnalyst ?
                                    <div className={classes.contentForm}>
                                        <AddForm
                                            lang={lang.technicalAnalyst.Messages}
                                            nameTechnical={this.state.nameTechnical}
                                            descriptionTechnical={this.state.descriptionTechnical}
                                            symbolTechnical={this.state.symbolTechnical}
                                            onChange={this.handleChange}
                                            inputProps={inputPropsAdd}
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            save={this._createTechnicalAnalyst}
                                            cancel={this._cancelAdd}

                                            validateRic={this.validateRicSuggestion}
                                            errorSymbol={this.state.errorSymbol}
                                            isValidNewTA={this._isValidNewTA}
                                            isLoading={this._isLoading}
                                        />
                                    </div>
                                    : ``
                            }

                            <DialogLoading 
                                open={this.state.saveWork} 
                                handleClose={() => this.setState({ saveWork: false })} 
                                boxLoading={classes.boxLoadingModal}
                            />

                            <EditTechnical
                                lang={lang.technicalAnalyst.Messages}
                                langButton={lang.technicalAnalyst.formAnalysis}
                                isEdit={true}
                                handleClose={() => this.setState({ updateTechnical: false })}
                                open={this.state.updateTechnical}
                                nameTechnical={this.state.nameTechnical}
                                descriptionTechnical={this.state.descriptionTechnical}
                                symbolTechnical={this.state.symbolTechnical}
                                onChange={this.handleChange}
                                inputProps={inputPropsAdd}
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                save={this._updateTechnicalAnalyst}
                                cancel={this._cancelAdd}
                                _changeSymbolTechnical={this._changeSymbolTechnical}
                                value={this.state.value}
                                errorSymbol={this.state.errorSymbol}
                                validateRic={this.validateRicSuggestion}
                                isValidNewTA={this._isValidNewTA}
                                isLoading={this._isLoading}
                            />

                            <ExcelExport
                                data={this.state.dataExcel}
                                group={[]}
                                paddingCellOptions={{
                                    background: `#FFFFFF`
                                }}
                                fileName={`${lang.technicalAnalyst.export.name} - ${this.state.currentName}.xlsx`}
                                ref={(exporter) => { this._exporter = exporter; }}
                            >

                                <ExcelExportColumn headerCellOptions={styleHeader} field="date" title={lang.technicalAnalyst.export.date} locked={true} width={150} />
                                <ExcelExportColumn headerCellOptions={styleHeader} field="close" title={lang.technicalAnalyst.export.close} width={100} />
                                <ExcelExportColumn headerCellOptions={styleHeader} field="open" title={lang.technicalAnalyst.export.open} width={100} />
                                <ExcelExportColumn headerCellOptions={styleHeader} field="high" title={lang.technicalAnalyst.export.high} width={100} />
                                <ExcelExportColumn headerCellOptions={styleHeader} field="low" title={lang.technicalAnalyst.export.low} width={100} />
                            </ExcelExport>
                        </React.Fragment>
                }
            </LanguageConsumer>
        )
    }
}

TechnicalAnalyst.contextType = LanguageCxt;

export default withStyles(styles)(TechnicalAnalyst);