import React from 'react';
import { Grid, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import { CirclePicker, AlphaPicker } from 'react-color';
import { StyledTextField } from '../../../uielements/textfield/StyledTextField';
import { SelectBase } from '../../../uielements/select/SelectBase';

class FormTR extends React.Component {
    render() {

        const {
            classes,
            handleChange,
            handleChangeComplete,
            colorStrokeTR,
            strokeOpacityTR,
            strokeWidthTR,
            fillOpacity,
            fillTR,
            lang
        } = this.props;

        return <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.stroke}</InputLabel>
                    <CirclePicker
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        color={colorStrokeTR}
                        onChangeComplete={(color) => handleChangeComplete(`colorStrokeTR`, color)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.opacity}</InputLabel>
                    <AlphaPicker
                        color={strokeOpacityTR}
                        className={classes.colorCustom}
                        onChangeComplete={(color) => handleChangeComplete(`strokeOpacityTR`, color)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.width}</InputLabel>
                    {/* <StyledTextField
                        autoFocus
                        id="strokeWidthTR"
                        type="number"
                        fullWidth
                        value={strokeWidthTR}
                        onChange={(e) => handleChange(e, 'strokeWidthTR')}
                    /> */}
                    <FormControl className={classes.formControl}>
                        <SelectBase
                            disableUnderline={true}
                            value={strokeWidthTR}
                            onChange={(e) => handleChange(e, 'strokeWidthTR')}
                            name="strokeWidthTR"
                            id="strokeWidthTR"
                            displayEmpty
                            className={classes.selectEmpty}
                        >
                            <MenuItem value={1}>{lang.thin}</MenuItem>
                            <MenuItem value={2}>{lang.full}</MenuItem>
                        </SelectBase>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.fill}</InputLabel>
                    <CirclePicker
                        color={fillTR}
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        onChangeComplete={(color) => handleChangeComplete(`fillTR`, color)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.fillOpacity}</InputLabel>
                    <AlphaPicker
                        color={fillOpacity}
                        className={classes.colorCustom}
                        onChangeComplete={(color) => handleChangeComplete(`fillOpacity`, color)}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

export default FormTR;