import styled from 'styled-components';

const InputLabel = styled.label `
    color: ${(props) => props.theme.textColor};
    font-size: 0.775rem;
    font-weight: 300;
    position: relative;
    top: -0.25rem;
`;
export { InputLabel };

const Snackbars = styled.div`
    align-items: center;
    background-color: ${(props) => props.theme.snackbars.bgColor};
    border-radius: 3px;
    display:flex;
    margin: 0.5rem 0;
    padding: 6px 12px;
    >div {
        &.img {
            margin-right: 0.5rem;
            span {
                color: ${(props) => props.theme.textColor};
                font-size: 1.5rem;
            }
        }
        &.data{
            flex: 1;
            .name {
                font-size: 0.875rem;
            }
            .freight{
                color: #8f8f8f
            }
        }
    }
    
`;
export { Snackbars };

const IconButton = styled.button`
    background: none;
    border: 0;
    border-radius: 50%;
    height: 35px;
    transition: background .5s ease-in-out;
    width: 35px;
    &:hover{
        background: ${(props) => props.theme.snackbars.hover};
        svg {
            color: ${(props) => props.theme.snackbars.svgHover};
        }
    }
    &:focus{
        outline: none;
    }
    svg{
        color: ${(props) => props.theme.snackbars.svg};
        font-size: 16px;
    }
`;
export { IconButton };


const Upload = styled.div`
    text-align: center;
    img{
        width: 20px;
    }
    .text{
        color: ${(props) => props.theme.ligthColor};
    }
`;
export { Upload };

const DateDIV = styled.div`
    >div{
        width: 100%;
        >div{
            background: transparent !important;
            >input {
                background: ${(props) => props.theme.input.bgColor};
                border: 1px solid ${(props) => props.theme.input.borderColor} !important;
                border-radius: 3px;
                box-sizing: border-box;
                font-size: 13px;
                height: 33px;
                line-height: 1.42857143;
                padding: 6px 12px !important;
                transition: border-color .15s ease-in-out;
                &:focus{
                    border-color: ${(props) => props.theme.input.borderFocus} !important;
                    outline: none;
                }
            }
            &::after {
                content: initial !important;
            }
        }
    }
`;
export { DateDIV };
