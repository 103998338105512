import React from "react";
import PropTypes from "prop-types";

import { format, formatSpecifier, precisionFixed, formatLocale, formatDefaultLocale } from "d3-format";
import { timeFormat, timeFormatDefaultLocale } from "d3-time-format";
import * as localeEs from 'd3-time-format/locale/es-MX.json';
import * as localeEn from 'd3-time-format/locale/en-US.json';

import { ChartCanvas, Chart, ZoomButtons } from "react-stockcharts";
import { EdgeIndicator} from "react-stockcharts/lib/coordinates";
import {
	BarSeries,
	CandlestickSeries,
	LineSeries,
	MACDSeries,
	BollingerSeries,
} from "react-stockcharts/lib/series";
import { Label } from 'react-stockcharts/lib/annotation'
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
	CrossHairCursor,
	CurrentCoordinate,
	MouseCoordinateX,
	MouseCoordinateY,
} from "react-stockcharts/lib/coordinates";

import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { GannFan, DrawingObjectSelector, FibonacciRetracement, TrendLine, EquidistantChannel } from "react-stockcharts/lib/interactive";
import {
	OHLCTooltip,
	MovingAverageTooltip,
	StochasticTooltip,
	HoverTooltip
} from "react-stockcharts/lib/tooltip";
import { ema, sma, macd, rsi, bollingerBand } from "react-stockcharts/lib/indicator";
import { fitWidth, fitDimensions } from "react-stockcharts/lib/helper";
import { last, toObject, head, createVerticalLinearGradient, hexToRGBA } from "react-stockcharts/lib/utils";
import MACDTooltip from "react-stockcharts/lib/tooltip/MACDTooltip";
import {
	saveInteractiveNodes,
	getInteractiveNodes,
} from "./InteractiveUtils";
import StandardDeviationChannel from "react-stockcharts/lib/interactive/StandardDeviationChannel";
import RSISeries from "react-stockcharts/lib/series/RSISeries";
import RSITooltip from "react-stockcharts/lib/tooltip/RSITooltip";
import BollingerBandTooltip from "react-stockcharts/lib/tooltip/BollingerBandTooltip";
import StochasticSeries from "react-stockcharts/lib/series/StochasticSeries";
import stochasticOscillator from "react-stockcharts/lib/indicator/stochasticOscillator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//Modal Fullscreen

import InteractiveText from "react-stockcharts/lib/interactive/InteractiveText";
import { getMorePropsForChart } from "react-stockcharts/lib/interactive/utils";
import FormText from "../Forms/Indicator";
import { Grid, Button, IconButton } from "@material-ui/core";

//Styles
import { withStyles } from '@material-ui/styles';
import styles from './chart-jss';

//ICONS
import { faMinus, faSleigh, faBan } from '@fortawesome/free-solid-svg-icons';
import AreaSeries from "react-stockcharts/lib/series/AreaSeries";
import { curveMonotoneX } from 'd3-shape';
import ToolTipText from "react-stockcharts/lib/tooltip/ToolTipText";
import SingleValueTooltip from "react-stockcharts/lib/tooltip/SingleValueTooltip";
import defaultLocale from "d3-time-format/src/defaultLocale";

const mouseEdgeAppearance = {
	textFill: "#542605",
	stroke: "#05233B",
	strokeOpacity: 1,
	strokeWidth: 2,
	arrowWidth: 5,
	fill: "#BCDEFA",
};

const bbStroke = {
	top: "#964B00",
	middle: "#000000",
	bottom: "#964B00",
};

const bbFill = "#4682B4";

const es_ES = {
	"decimal": ",",
	"thousands": ".",
	"grouping": [3],
	"currency": ["€", ""],
	"dateTime": "%a %b %e %X %Y",
	"date": "%d/%m/%Y",
	"time": "%H:%M:%S",
	"periods": ["AM", "PM"],
	"days": ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
	"shortDays": ["Dom", "Lun", "Mar", "Mi", "Jue", "Vie", "Sab"],
	"months": ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
	"shortMonths": ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
};
const numberFormat = format(",.2f");
const timeDisplayFormat = timeFormat("%Y-%m-%d");


class CandleStickChartWithMA extends React.Component {


	size = () => {
		this.updateWindowDimensions();
	}

	constructor(props) {
		super(props);
		window.addEventListener('resize', this.size, false)
		this.saveInteractiveNodes = saveInteractiveNodes.bind(this);
		this.getInteractiveNodes = getInteractiveNodes.bind(this);
		const { symbol } = this.props;
		this.state = {
			enableInteractiveObject: false,
			fans: [],
			enableFib: false,
			retracements_1: [],
			enableStandardDesv: false,
			channels_1: [],

			enableTrendLine: false,
			appearanceTrendingLine: {
				edgeFill: "#FFFFFF",
				edgeStroke: "#000000",
				edgeStrokeWidth: 1,
				r: 6,
				stroke: '',
				strokeDasharray: "Solid",
				strokeOpacity: 1,
				strokeWidth: 1
			},
			trends_1: [],

			//equidistant channel
			enableEquidistant: false,
			channelEquidistant: [],

			//text
			enableInteractiveText: false,
			textList_1: [],
			showModal: false,
			showButton: false,
			currentPosition: [],
			panEvent: false,
			Dwidth: 0,
			Dheight: 0,
			symbol: symbol,
			heigthIndicators: {
				macd: 0
				, rsi: 0
				, slow: 0
				, fast: 0
				, full: 0
			}
		};
	}

	saveInteractiveNode = (node) => {
		this.node = node;
	}

	saveCanvasNode = (node) => {
		this.canvasNode = node;
	}



	componentDidMount() {
		const { currentLang } = this.props;
		let es = localeEs.default;
		let en = localeEn.default;
		// console.log('es', es)
		// es[`dateTime`] = "%B"
		// es.date = "%B"
		defaultLocale(currentLang === "es" ? es : en)
		this.updateWindowDimensions();

		document.addEventListener("keyup", this.onKeyPress);
	}

	handleDialogClose = () => {
		let t = this.state.textList_1;
		let newArray = [];
		t.map(
			(item, index) => {
				if (t.length - 1 !== index) {
					newArray.push(item)
				}
			}
		)

		this.setState({ showModal: false, enableText: false, textList_1: newArray });
		this.componentDidMount();
		this.props.disableText();
	}

	componentWillReceiveProps(props) {

		this.setState(
			{
				enableInteractiveObject: props.enableGannFan,
				enableFib: props.enableFibonacci,
				enableStandardDesv: props.enableTR,
				enableText: props.enableText,
				enableInteractiveText: props.enableText,
				enableTrendLine: props.enableDrawLine,
				appearanceTrendingLine: {
					edgeFill: "#FFFFFF",
					edgeStroke: "#000000",
					edgeStrokeWidth: 1,
					r: 6,
					stroke: props.settingTrendingLine.strokeTrendingLine,
					strokeDasharray: props.settingTrendingLine.styleTrendingLine,
					strokeOpacity: props.settingTrendingLine.strokeOpacityLine ? props.settingTrendingLine.strokeOpacityLine.a : 1,
					strokeWidth: props.settingTrendingLine.strokeWidthTrending
				}
			}
		)
	}

	calculateHeigthIndicator = (h, ind) => {
		const {
			indicatorMACD
			, indicatorRSI
			, indicatorStochastic
		} = this.props;
		const {
			Dheight
		} = this.state;
		let calculated;
		let indicators = this.numberOfIndicators().total;

		if (ind === 1) {
			calculated = (h + 60) - (h - this.getMaxHeigthChart().main);
		} else if (ind === 2 && indicators >= ind) {
			calculated = ((h - this.getMaxHeigthChart().main) / indicators) * ind
		}
		return calculated < 0 ? (calculated * -1) : calculated;
	}

	numberOfIndicators = () => {
		const {
			indicatorMACD
			, indicatorRSI
			, indicatorStochastic
		} = this.props;
		let num = 0;
		let _macd = false;
		let _rsi = false;
		let _slow = false;
		let _fast = false;
		let _full = false;
		if (indicatorMACD && indicatorMACD.length > 0) {
			num += indicatorMACD.length
			_macd = true;
		}
		if (indicatorRSI) {
			num++;
			_rsi = true
		}
		if (indicatorStochastic) {
			if (indicatorStochastic.activeFast) { num++; _fast = true }
			if (indicatorStochastic.activeFull) { num++; _full = true }
			if (indicatorStochastic.activeSlow) { num++; _slow = true }
		}
		return { total: num, macd: _macd, rsi: _rsi, slow: _slow, fast: _fast, full: _full };
	}

	getMaxHeigthChart = () => {
		const {
			Dheight
		} = this.state;
		const { total, macd, rsi, slow, fast, full } = this.numberOfIndicators();
		const indicators = total;
		let h = Dheight;
		if (indicators > 0) {

			/*
				Se calcula que porcentaje del alto de la pagina corresponde de acuerdo al número de indicadores
				la gráfica principal tendrá 
			*/
			let per = 0;
			if (indicators === 1) {
				per = 90;
			} else if (indicators >= 1 && indicators <= 2) {
				per = 85;
			} else if (indicators > 2 && indicators <= 3) {
				per = 75;
			} else if (indicators > 3) {
				per = 55
			} else {
				per = 95
			}
			h = ((per * Dheight) / 100) - 200;


		} else {
			h = (Dheight - ((30 * Dheight) / 100));
		}

		let calc = h + ((80 * (Dheight - h) / indicators) / 100);
		let _heightByIndicator = ((( (indicators === 1 ? 30 : indicators === 2 ? 50 : indicators > 3 ? 50 : 45) * (Dheight - h) / indicators) / 100) );

		return {
			main: h
			, heightMacd: macd ? h + 20 : 0
			, heightRsi: rsi && macd ? h + _heightByIndicator + 20 : rsi ? h + 10 : 0
			, heightSlow:   slow && macd && rsi && fast && full ? h + (_heightByIndicator * 3):
							slow && macd && rsi && fast ? h +( _heightByIndicator * 3) :
							slow && macd && rsi && full ?  h +( _heightByIndicator * 3) :
							slow && macd && rsi ? h +( _heightByIndicator * 3) :
							slow && macd && fast ? h + (_heightByIndicator * 2) :
							slow && macd && fast && full ? h + (_heightByIndicator * 2) :
							slow && macd ? calc :
							slow && rsi ? calc :
							slow && fast && full ? calc - 120 :
							slow && fast ? calc - 140 :
							slow && full ? calc - 140 :
							slow ? h + 10 : 0
			, heightFast: fast && macd && rsi && slow && full ? h+ (_heightByIndicator * 4)  :
				fast && macd && rsi && slow ?  h +( _heightByIndicator * 4) :
					fast && macd && rsi && full ?  h +( _heightByIndicator * 3) :
						fast && macd && rsi ? h + (_heightByIndicator * 3)  :
						fast && macd && slow && full ? h + (_heightByIndicator * 3) :
							fast && macd && slow ? h + (_heightByIndicator * 3) :
								fast && macd ? calc :
									fast && rsi ? calc :
										fast && slow && full ? calc + 20 :
											fast && slow ? calc :
												fast && full ? calc - 150 :
													fast ? h + 10 : 0
			, heightfull: full && macd && rsi && fast && slow ? h + ( _heightByIndicator * 5)  :
				full && macd && rsi && fast ?  h +( _heightByIndicator * 4) :
					full && macd && rsi && slow ?  h +( _heightByIndicator * 4) :
						full && macd && rsi ? h + (_heightByIndicator * 3):
						full && macd && slow & fast ? h + (_heightByIndicator * 4) :
						full && macd && fast ? h + (_heightByIndicator * 3) :
							full && macd ? calc :
							full && macd && slow ? h + (_heightByIndicator * 3) :
								full && rsi ? calc :
									full && slow && fast ? calc + 160 :
										full && slow ? calc :
											full && fast ? calc :
												full ? h + 10 : 0
			, heightByIndicator: _heightByIndicator
		};
	}

	componentWillUnmount() {
		document.removeEventListener("keyup", this.onKeyPress);
	}

	updateWindowDimensions = () => {
		this.setState({ Dwidth: window.innerWidth, Dheight: window.innerHeight });
	}

	handleSelection = (interactives, moreProps, e) => {

		const fans = toObject([interactives[0]], each => {
			return [
				"fans",
				each.objects,
			];
		});
		this.setState(fans);
		const retracements_1 = toObject([interactives[1]], each => {
			return [
				`retracements_${each.chartId}`,
				each.objects
			];
		});
		this.setState(retracements_1);
		const channels_1 = toObject([interactives[2]], each => {
			return [
				`channels_${each.chartId}`,
				each.objects,
			];
		});
		this.setState(channels_1);
		const trends_1 = toObject([interactives[3]], each => {
			return [
				`trends_${each.chartId}`,
				each.objects
			];
		});
		this.setState(trends_1);
		const channelEquidistant = toObject([interactives[4]], each => {
			return [
				`channels1_${each.chartId}`,
				each.objects,
			];
		});
		this.setState(channelEquidistant);
		if (interactives[5].type == "InteractiveText" && this.state.enableInteractiveText) {
			const independentCharts = moreProps.currentCharts.filter(d => d !== 2)
			if (independentCharts.length > 0) {
				const first = head(independentCharts);
				const morePropsForChart = getMorePropsForChart(moreProps, first)
				const {
					mouseXY: [, mouseY],
					chartConfig: { yScale },
					xAccessor,
					currentItem,
				} = morePropsForChart;

				const position = [xAccessor(currentItem), yScale.invert(mouseY)];
				const newText = {
					...InteractiveText.defaultProps.defaultText,
					position,
				};
				this.handleChoosePosition(newText, morePropsForChart, e);
			}
		}

		this.setState(
			{
				s: ``,
				currentPosition: moreProps.xScale.domain(),
			}, () => {

				let f = this.state.fans.findIndex(x => x.selected === true);
				let r = this.state.retracements_1.findIndex(x => x.selected === true);
				let c = this.state.channels_1.findIndex(x => x.selected === true);
				let t = this.state.trends_1.findIndex(x => x.selected === true);

				// let active = this.state.currentPosition.length > 0 && this.state.currentPosition[0] < 0 ? true : false;

				if (f !== -1 || r !== -1 || c !== -1 || t !== -1) {
					this.setState({
						showButton: true,
						//  panEvent : active
					});
				} else {
					this.setState({
						showButton: false,
						// panEvent : active 
					});
				}
			}
		)
	}

	onDrawComplete = (fans) => {
		this.props.cleanIndicator()
		if (this.state.fans.length >= 3) {
			this.props.limitDrawFunc();
			this.setState(
				{
					enableInteractiveObject: false
				}
			)
			return false;
		}
		this.setState({
			enableInteractiveObject: false,
			fans
		});
	}

	onDrawCompleteStandardDesv = (channels_1) => {
		this.props.cleanIndicator();
		if (this.state.channels_1.length === 3) {
			this.props.limitDrawFunc();
			this.setState(
				{
					enableStandardDesv: false
				}
			)
			return false;
		}
		this.setState({
			enableStandardDesv: false,
			channels_1
		});
	}

	onDrawCompleteEquidistant = (channelEquidistant) => {
		this.setState({
			enableEquidistant: false,
			channelEquidistant
		});
	}

	onKeyPress = (e) => {
		const keyCode = e.which;
		// console.log(keyCode);

		switch (keyCode) {
			case 46: { // DEL

				const fans = this.state.fans.filter(each => !each.selected);
				const retracements_1 = this.state.retracements_1.filter(each => !each.selected);
				const channels_1 = this.state.channels_1.filter(each => !each.selected);
				const trends_1 = this.state.trends_1.filter(each => !each.selected);
				const channelEquidistant = this.state.channelEquidistant.filter(each => !each.selected);
				const textList_1 = this.state.textList_1.filter(each => !each.selected);

				this.canvasNode.cancelDrag();
				this.setState({
					fans,
					retracements_1,
					channels_1,
					trends_1,
					channelEquidistant,
					textList_1
				});
				break;
			}
			case 27: { // ESC

				//this.node.terminate();
				// this.canvasNode.cancelDrag();
				// this.setState({
				// 	enableInteractiveObject: false
				// });
				break;
			}
			case 68:   // D - Draw drawing object
			case 69: { // E - Enable drawing object
				this.setState({
					enableInteractiveObject: true
				});
				break;
			}
			default: break;
		}
	}

	onFibComplete1 = (retracements_1) => {
		this.props.cleanIndicator();
		if (this.state.retracements_1.length >= 3) {
			this.setState(
				{
					enableFib: false
				}, () => {
					this.props.limitDrawFunc();
				}
			)

		} else {
			this.setState({
				retracements_1,
				enableFib: false
			});
		}
	}

	onDrawCompleteChart1 = (trends_1) => {
		this.setState({
			enableTrendLine: false,
			trends_1
		});
		this.props.disableDrawLine();
	}

	onDrawCompleteText = (textList, moreProps) => {
		const { id: chartId } = moreProps.chartConfig;

		this.setState({
			enableInteractiveObject: false,
			[`textList_${chartId}`]: textList,
		});
	}

	handleChoosePosition = (text, moreProps) => {
		this.componentWillUnmount();
		const { id: chartId } = moreProps.chartConfig;

		this.setState({
			[`textList_${chartId}`]: [
				...this.state[`textList_${chartId}`] ? this.state[`textList_${chartId}`] : [],
				text
			],
			showModal: true,
			text: text.text,
			chartId
		});
	}

	handleTextChange = (text, chartId) => {
		const textList = this.state[`textList_${chartId}`];
		const allButLast = textList
			.slice(0, textList.length - 1);

		const lastText = {
			...last(textList),
			text,
		};

		this.setState({
			[`textList_${chartId}`]: [
				...allButLast,
				lastText
			],
			showModal: false,
			enableInteractiveObject: false,
		});
		this.componentDidMount();
		this.props.disableText();
	}

	_deleteFunc = () => {
		const fans = this.state.fans.filter(each => !each.selected);
		const retracements_1 = this.state.retracements_1.filter(each => !each.selected);
		const channels_1 = this.state.channels_1.filter(each => !each.selected);
		const trends_1 = this.state.trends_1.filter(each => !each.selected);
		const channelEquidistant = this.state.channelEquidistant.filter(each => !each.selected);
		const textList_1 = this.state.textList_1.filter(each => !each.selected);

		this.canvasNode.cancelDrag();
		this.setState({
			fans,
			retracements_1,
			channels_1,
			trends_1,
			channelEquidistant,
			textList_1,
			showButton: false
		});
	}

	_enableChart = () => {
		this.setState(
			{
				currentPosition: [],
				panEvent: false
			}, () => {
				// this.render();
			}
		)
	}

	render() {
		const { type, data: initialData, width, ratio, upCandle,
			downCandle,
			opacityCandle,
			upWick,
			downWick,
			upBorder,
			downBorder,
			indicatorSMA,
			indicatorMACD,
			colorStrokeMACD,
			indicatorRSI,
			indicatorBB,
			indicatorVolume,
			indicatorStochastic,
			classes,
			start,
			end,
			stroke,
			fill,
			highestPoint,
			opacityFill,
			opacityHighestPoint,
			typeChart,
			windowSizeBBand,
			multiplierBBand,
			maTypeBBand,
			isIntraday,
			removeIndicator,
			indicatorFibonacci,
			settingTrendingLine,
			indicatorTR,
			symbol,
			showName,
			showZoom,
			lang 
		} = this.props;
		
		const {
			Dheight
		} = this.state;
		const { main, heightMacd, heightRsi, heightSlow, heightFast, heightfull, heightByIndicator } = this.getMaxHeigthChart();
		let heightMainChart = main;
		let aux = [];
		let options = [];

		if (indicatorSMA.length > 0) {
			indicatorSMA.map(
				(item, index) => {
					if (item.isSMA) {
						aux[index] = sma()
							.options({ windowSize: parseInt(item.period), sourcePath: `close` })
							.merge((d, c) => { d[`sma${item.period}`] = c; })
							.accessor(d => d[`sma${item.period}`])
							.stroke(`${item.color}`);

						options.push(
							{
								yAccessor: aux[index].accessor(),
								type: "SMA",
								stroke: aux[index].stroke(),
								windowSize: parseInt(aux[index].options().windowSize),
								echo: "some echo here",
							}
						);
					} else {
						aux[index] = ema()
							.options({ windowSize: parseInt(item.period), sourcePath: `close` })
							.merge((d, c) => { d[`ema${item.period}`] = c; })
							.accessor(d => d[`ema${item.period}`])
							.stroke(`${item.color}`);

						options.push(
							{
								yAccessor: aux[index].accessor(),
								type: "EMA",
								stroke: aux[index].stroke(),
								windowSize: parseInt(aux[index].options().windowSize),
								echo: "some echo here",
							}
						);
					}
				}
			)
		}

		let cal = [];
		aux.map(
			item => {
				if (cal.length === 0) {
					cal = item(initialData);
				} else {
					cal = item(cal)
				}
			}
		)
		let calculatedData = cal.length === 0 ? initialData : cal;
		//calculatedData = macdCalculator(calculatedData);
		let auxMACD = []
		indicatorMACD.map(
			(item, index) => {
				auxMACD[index] = macd()
					.options({
						fast: parseInt(item.fast),
						slow: parseInt(item.slow),
						signal: parseInt(item.signal),
					})
					.merge((d, c) => { d[`macd${index}`] = c; })
					.accessor(d => d[`macd${index}`])
			}
		)
		let macdAppearance = {
			stroke: {
				macd: "#f3383f", // rojo
				signal: "#00c277", //verde
			},
			fill: {
				divergence: "#4682B4"
			},
		};

		if (auxMACD.length > 0) {
			// if (height < 520) {
			// 	height = height + 220;
			// }
			macdAppearance.stroke.macd = colorStrokeMACD.hex;
			auxMACD.map(
				item => {
					// height = height + 150;
					calculatedData = item(calculatedData);
				}
			)
		}

		//RSI indicator 
		let rsiCalculator = [];
		// let rsiHeigth = 130;
		if (indicatorRSI) {
			// if (height < 520) {
			// 	height = height + 220;
			// }
			// height = height + 150;
			if (auxMACD.length > 0) {
				// discountHeightMACD += 120;
				// rsiHeigth = 100;
			}
			rsiCalculator = rsi()
				.options({ windowSize: parseInt(indicatorRSI.periodRSI) })
				.merge((d, c) => { d.rsi = c; })
				.accessor(d => d.rsi);
			calculatedData = rsiCalculator(calculatedData);
		}

		//BB 
		let bb = []
		if (indicatorBB) {
			bb = bollingerBand()
				.options({ windowSize: Number(windowSizeBBand), multiplier: Number(multiplierBBand), movingAverageType: maTypeBBand })
				.merge((d, c) => { d.bb = c; })
				.accessor(d => d.bb);

			calculatedData = bb(calculatedData);

		}

		let slowSTO = [];
		let fastSTO = [];
		let fullSTO = [];
		
		if (indicatorStochastic) {
			if (indicatorStochastic.activeSlow) {
				 
				slowSTO = stochasticOscillator()
					.options({ windowSize: parseInt(indicatorStochastic.periodStochastic), kWindowSize: (parseInt(indicatorStochastic.kWindowSizeStochastic)) })
					.merge((d, c) => { d.slowSTO = c; })
					.accessor(d => d.slowSTO);
				calculatedData = slowSTO(calculatedData);
			}

			if (indicatorStochastic.activeFast) {
				fastSTO = stochasticOscillator()
					.options({ windowSize: parseInt(indicatorStochastic.periodStochastic), kWindowSize: (parseInt(indicatorStochastic.kWindowSizeStochastic)) })
					.merge((d, c) => { d.fastSTO = c; })
					.accessor(d => d.fastSTO);
				calculatedData = fastSTO(calculatedData);
			}

			if (indicatorStochastic.activeFull) {
				fullSTO = stochasticOscillator()
					.options({ windowSize: parseInt(indicatorStochastic.periodStochastic), kWindowSize: (parseInt(indicatorStochastic.kWindowSizeStochastic)), dWindowSize: parseInt(indicatorStochastic.dWindowSizeStochastic) })
					.merge((d, c) => { d.fullSTO = c; })
					.accessor(d => d.fullSTO);
				calculatedData = fullSTO(calculatedData);

			}
		}

		let strokeStochastic = StochasticSeries.defaultProps.stroke;
		strokeStochastic.dLine = indicatorStochastic.strokeDLine ? indicatorStochastic.strokeDLine.hex : StochasticSeries.defaultProps.dLine;
		strokeStochastic.kLine = indicatorStochastic.strokeKLine ? indicatorStochastic.strokeKLine.hex : StochasticSeries.defaultProps.kLine;
		const stoAppearance = {
			stroke: Object.assign({}, strokeStochastic)
		};

		let xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(d => d.date);

		let {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(calculatedData);

		let xExtents = [start, end];

		let candlesAppearance = {
			wickStroke: function wickStroke(d) {
				return d.close > d.open ? upWick : downWick;
			},
			fill: function fill(d) {
				return d.close > d.open ? upCandle : downCandle;
			},
			stroke: function stroke(d) {
				return d.close > d.open ? upBorder : downBorder;
			},
			candleStrokeWidth: 1,
			widthRatio: 0.8,
			opacity: opacityCandle
		}

		let candlesAppearanceHueca = {
			wickStroke: function wickStroke(d) {
				return d.close > d.open ? upWick : downWick;
			},
			fill: function fill(d) {
				return d.close > d.open ? upCandle : downCandle;
			},
			stroke: function stroke(d) {
				return d.close > d.open ? upBorder : downBorder;
			},
			candleStrokeWidth: 1,
			widthRatio: 0.8,
			opacity: opacityCandle,
		}

		//color volume 
		let colorUpVolume = ``;
		let colorDownVolume = ``;
		let ColorRba = ``;
		if (indicatorVolume.colorUp.hex) {
			colorUpVolume = indicatorVolume.colorUp.hex;
		}

		if (indicatorVolume.colorDown.hex) {
			colorDownVolume = indicatorVolume.colorDown.hex;
		}
		if (indicatorVolume.colorOpacity.a) {
			ColorRba = indicatorVolume.colorOpacity.a;
		} else {
			ColorRba = 0.95
		}
		/* 		console.log("Color up: ",colorUpVolume," Color down: ",colorDownVolume," ColorRBA: ",ColorRba)
		 */
		let d = 0;
		const margin = { left: 70, right: 70, top: 20, bottom: 30 };
		const gridHeight = Dheight - margin.top - margin.bottom;
		const gridWidth = width - margin.left - margin.right;

		const yGrid = { innerTickSize: -1 * gridWidth, tickStrokeOpacity: 0.1 };
		const xGrid = { innerTickSize: -1 * gridHeight, tickStrokeOpacity: 0.1 };

		let displayTextsDefault = {
			d: "Date: ",
			o: " O: ",
			h: " H: ",
			l: " L: ",
			c: " C: ",
			v: " Vol: ",
			na: " "
		};
		 
		const Value = (currentItem) => {
			let y = []
			if (typeChart === `vela` || typeChart === `velahueca`) {
				y = [
					{
						label: "Apertura",
						value: currentItem.open && numberFormat(currentItem.open),
					},
					{
						label: "Máximo",
						value: currentItem.high && numberFormat(currentItem.high),
					},
					{
						label: "Mínimo",
						value: currentItem.low && numberFormat(currentItem.low),
					},
					{
						label: "Cierre",
						value: currentItem.close && numberFormat(currentItem.close),
					},
				];
				if (indicatorVolume && indicatorVolume.active) {
					y.push({
						label: "Volumen",
						value: currentItem.volume && numberFormat(currentItem.volume),
					});
				}
			} else if ((typeChart === `area` || typeChart === `line`) && (indicatorVolume && indicatorVolume.active)) {
				y = [{
					label: "Volumen",
					value: currentItem.volume && numberFormat(currentItem.volume),
				}
				];
			}


			return y;

		}
		const HoverTooltipCustom = () => {
			return (
				<HoverTooltip
					yAccessor={(d) => d.close}
					tooltipContent={({ currentItem, xAccessor }) => ({
						x: timeDisplayFormat(xAccessor(currentItem)),
						y: Value(currentItem),
					})
					}
				/>
			);
		};

		/* 	const tooltipContent = () => {
				return ({ currentItem, xAccessor }) => {
					
				  return {
				  x:timeDisplayFormat(xAccessor(currentItem)),
				  y:[{
					label: "Apertura",
					value: currentItem.open && numberFormat(currentItem.open),
				  },
				  {
					label: "Máximo",
					value: currentItem.high && numberFormat(currentItem.high),
				  },
				  {
					label: "Mínimo",
					value: currentItem.low && numberFormat(currentItem.low),
				  },
				  {
					label: "Cierre",
					value: currentItem.close && numberFormat(currentItem.close),
				  },]
				  };
				};
			  }
	
			  
			  const tooltipContentVolumen = () => {
				return ({ currentItem, xAccessor }) => {
				  return {
				  x:timeDisplayFormat(xAccessor(currentItem)),
				  y:[{
					label: "Apertura",
					value: currentItem.open && numberFormat(currentItem.open),
				  },
				  {
					label: "Máximo",
					value: currentItem.high && numberFormat(currentItem.high),
				  },
				  {
					label: "Mínimo",
					value: currentItem.low && numberFormat(currentItem.low),
				  },
				  {
					label: "Cierre",
					value: currentItem.close && numberFormat(currentItem.close),
				  },
				   {
					label: "Volumen",
					value: currentItem.close && numberFormat(currentItem.volume),
				  },]
				  };
				};
			  }
			
			const HoverTooltipCustom =()=>{
			
				return( <>
				{(indicatorVolume && indicatorVolume.active) ?
	
				<HoverTooltip
				yAccessor={d => d.volume}
				tooltipContent={ tooltipContentVolumen([])}
				fontSize={15}
			  />: 
			  <HoverTooltip
			  yAccessor={d => d.volume}
			  tooltipContent={ tooltipContent([])}
			  fontSize={15}
			/>}
				
				  </>
				)
			
			} */
		return (
			<React.Fragment>
				<ChartCanvas
					height={Dheight}
					width={width}
					ratio={ratio}
					margin={margin}
					type={type}
					seriesName="MSFT"
					data={data}
					xScale={xScale}
					xAccessor={xAccessor}
					displayXAccessor={displayXAccessor}
					xExtents={xExtents}
					ref={this.saveCanvasNode}
				>
					<Chart id={1}
						yExtents={[d => typeChart === 'vela' || typeChart === 'velahueca' ? [d.high, d.low] : d.close,	aux[0] ? aux[0].accessor() : null]}
						height={heightMainChart}
						padding={{ top: 10, bottom: 20 }}
					>
						<XAxis 
							
							tickFormat={d=> timeFormat(`${isIntraday ? '%b %d': '%H:%M'}`)(data[d].date)}
							ticks={window.innerWidth > 400 ? 17 : 5} 
							tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} 
							stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} 
							fontSize={11} 
							axisAt="bottom" 
							orient="bottom" 
							{...xGrid} 
						/>
						{/* <AxisTicks
							tickFormat={timeFormat("%B")}
						/> */}

						<YAxis tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontSize={11} axisAt="right" orient="right" ticks={13} {...yGrid} />

						<MouseCoordinateY
							at="right"
							orient="right"
							displayFormat={format(",.3f")}
							fontFamily="Nunito, sans-serif"
							fontSize={11}
						/>

						<MouseCoordinateX
							at="bottom"
							orient="bottom"
							displayFormat={timeFormat(`%Y-%m-%d ${!isIntraday ? '%H:%M' : ``}`)} 
							fontFamily="Nunito, sans-serif"
							fontSize={11}
						/>

						{
							typeChart === `vela` ?
								<>
									<CandlestickSeries
										{...candlesAppearance} />
									<HoverTooltipCustom />

								</>
								: typeChart === `area` ?
									<><HoverTooltipCustom />

									<AreaSeries
										stroke={stroke}
										opacity={1}
										strokeWidth={1}
										interpolation={curveMonotoneX}
										canvasGradient={createVerticalLinearGradient([
											{ stop: 0.7, color: hexToRGBA("#356cff", 0.7) },
											{ stop: 1, color: hexToRGBA("#356cff", 0.7) },
										])}
										yAccessor={d => d.close}
									/></>
									: typeChart === `velahueca` ?
										<>
											<CandlestickSeries
												{...candlesAppearanceHueca}
											/>
											<HoverTooltipCustom />
										</>
										: typeChart === `line` ?
											<>								<HoverTooltipCustom />

												<AreaSeries
													fill={"#ffffff"}
													stroke={stroke}
													opacity={0}
													strokeWidth={2}
													interpolation={curveMonotoneX}
													yAccessor={d => d.close}
												/></>
											: ``
						}

						{
							(aux && aux.length > 0) ?
								aux.map(
									item => {
										return <React.Fragment>
											<LineSeries yAccessor={item.accessor()} stroke={item.stroke()} />
											<CurrentCoordinate yAccessor={item.accessor()} fill={item.stroke()} />
											
											<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={item.accessor()} fill={item.stroke()} />
										</React.Fragment>

									}
								)
								: ``
						}

						<GannFan
							ref={this.saveInteractiveNodes("GannFan", 1)}
							enabled={this.state.enableInteractiveObject}
							onStart={() => console.log("START")}
							onComplete={(f) => this.onDrawComplete(f)}
							fans={this.state.fans}
							appearance={
								{
									"stroke": this.props.indicatorFannGan.length > 0 ? this.props.indicatorFannGan[this.props.indicatorFannGan.length - 1].color : `#FFFFFF`,
									"fillOpacity": this.props.indicatorFannGan.length > 0 && this.props.indicatorFannGan[this.props.indicatorFannGan.length - 1].alpha.a != undefined ? this.props.indicatorFannGan[this.props.indicatorFannGan.length - 1].alpha.a : 0.4,
									"strokeOpacity": 1,
									"strokeWidth": this.props.indicatorFannGan.length > 0 && this.props.indicatorFannGan[this.props.indicatorFannGan.length - 1].width > 0 ? Number(this.props.indicatorFannGan[this.props.indicatorFannGan.length - 1].width) : 1,
									"edgeStroke": "#000000",
									"edgeFill": "#FFFFFF",
									"edgeStrokeWidth": 1,
									"r": 5,
									"fill": [
										"#e41a1c",
										"#377eb8",
										"#4daf4a",
										"#984ea3",
										"#ff7f00",
										"#ffff33",
										"#a65628",
										"#f781bf"
									],
									"fontFamily": "Nunito, sans-serif",
									"fontSize": 12,
									"fontFill": "#000000"
								}
							}
						/>

						<FibonacciRetracement
							ref={this.saveInteractiveNodes("FibonacciRetracement", 1)}
							enabled={this.state.enableFib}
							retracements={this.state.retracements_1}
							onComplete={this.onFibComplete1}
							type={indicatorFibonacci.length > 0 ? indicatorFibonacci[indicatorFibonacci.length - 1].type.toString().toUpperCase() : `RAY`}
							appearance={
								{
									stroke: indicatorFibonacci.length > 0 ? indicatorFibonacci[indicatorFibonacci.length - 1].color : `#000000`,
									strokeWidth: indicatorFibonacci.length > 0 ? indicatorFibonacci[indicatorFibonacci.length - 1].width : 1,
									strokeOpacity: indicatorFibonacci.length > 0 && indicatorFibonacci[indicatorFibonacci.length - 1].alpha.a ? indicatorFibonacci[indicatorFibonacci.length - 1].alpha.a : 1,
									fontFamily: "Nunito, sans-serif",
									fontSize: 11,
									fontFill: indicatorFibonacci.length > 0 ? indicatorFibonacci[indicatorFibonacci.length - 1].fill : `#000000`,
									edgeStroke: "#000000",
									edgeFill: "#FFFFFF",
									nsEdgeFill: "#000000",
									edgeStrokeWidth: 1,
									r: 5
								}
							}
						/>

						<StandardDeviationChannel
							ref={this.saveInteractiveNodes("StandardDeviationChannel", 1)}
							enabled={this.state.enableStandardDesv}
							onStart={() => console.log("START")}
							onComplete={this.onDrawCompleteStandardDesv}
							channels={this.state.channels_1}
							appearance={
								{
									stroke: this.props.indicatorTR.length > 0 && this.props.indicatorTR[this.props.indicatorTR.length - 1].colorStrokeTR.hex !== `` ?
										this.props.indicatorTR[this.props.indicatorTR.length - 1].colorStrokeTR.hex : `#FFFFFF`,

									fillOpacity: 0.2,

									strokeOpacity: this.props.indicatorTR.length > 0 && this.props.indicatorTR[this.props.indicatorTR.length - 1].strokeOpacityTR.a !== undefined ?
										this.props.indicatorTR[this.props.indicatorTR.length - 1].strokeOpacityTR.a : 1,

									strokeWidth: this.props.indicatorTR.length > 0 ? Number(this.props.indicatorTR[this.props.indicatorTR.length - 1].strokeWidthTR) : 1,

									fill: this.props.indicatorTR.length > 0 && this.props.indicatorTR[this.props.indicatorTR.length - 1].fillTR.hex !== `` ?
										this.props.indicatorTR[this.props.indicatorTR.length - 1].fillTR.hex : `#8AAFE2`,

									edgeStrokeWidth: 2,
									edgeStroke: "#000000",
									edgeFill: "#FFFFFF",
									r: 5
								}
							}
						/>
						<TrendLine
							ref={this.saveInteractiveNodes("Trendline", 1)}
							enabled={this.state.enableTrendLine}
							type={settingTrendingLine.typeTrendingLine ? settingTrendingLine.typeTrendingLine.toString().toUpperCase() : `RAY`}
							snap={false}
							snapTo={d => [d.high, d.low]}
							onStart={() => console.log("START")}
							onComplete={(e)=> this.onDrawCompleteChart1(e)}
							trends={this.state.trends_1}
							appearance={this.state.appearanceTrendingLine}
						/>

						<EquidistantChannel
							ref={this.saveInteractiveNodes("EquidistantChannel", 1)}
							enabled={this.state.enableEquidistant}
							onStart={() => console.log("START")}
							onComplete={(e) => this.onDrawCompleteEquidistant(e)}
							channels={this.state.channelEquidistant}
						/>

						<InteractiveText
							ref={this.saveInteractiveNodes("InteractiveText", 1)}
							enabled={this.state.enableInteractiveText}
							text=" "
							onDragComplete={(a,e) => this.onDrawCompleteText(a,e)}
							textList={this.state.textList_1}
						/>

						{
							indicatorBB ?
								<React.Fragment>
									<BollingerSeries yAccessor={d => d.bb}
										stroke={bbStroke}
										fill={bbFill} 
										fontFamily="Nunito, sans-serif"
									/>

									{/* Indicador BBANDS */}
									<BollingerBandTooltip
										origin={[-52, options.length > 0 ? 100 : 70]}
										yAccessor={d => d.bb}
										options={bb.options()}
										textFill={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`}
										fillBackground={`${this.props.theme === `dark` ? `#444444` : `#FFFFFF`}`}
										onClick={e => removeIndicator(e, `bbands`)}
										displayFormat={format(",.3f")}
										fontFamily="Nunito, sans-serif"
									/>
									
									<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.bb.top} fill= {bbStroke.top} />
									<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.bb.middle} fill= {bbStroke.middle} />
									<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.bb.bottom} fill= {bbStroke.bottom} />
								</React.Fragment> : ``
						}

						{
							showName ? <Label textAnchor="left" fill="#356cff" fontWeight="700" fontFamily="Nunito, sans-serif" x={-52} y={-8} fontSize="16" text={symbol}  /> : ''
						}	

						{
							window.innerWidth > 400 ?
							// Aqui se cambia la posición "Date: 2019-11-25" ... //
							<OHLCTooltip onClick={(e) => removeIndicator(e, `volumen`)}
								fontSize={12}
								textFill={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`}
								origin={[-52, 10]}
								ohlcFormat={format(",.3f")}
								fillBackground={`${this.props.theme === `dark` ? `#444444` : `#FFFFFF`}`}
								displayTexts={displayTextsDefault}
							/>
							:''
						}
						{/* Indicador SMA y EMA */}
						<MovingAverageTooltip
							onClick={e => removeIndicator(e, `smaema`)}
							origin={[-52, 45]}
							options={options}
							textFill={`${this.props.theme === `dark` ? `#edecf1` : `#666`}`}
							displayFormat={format(",.3f")}
							fontSize={11}
							fontFamily="Nunito, sans-serif"
							_fillBackground={`${this.props.theme === `dark` ? `#444444` : `red`}`}
							labelFill={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`}
						/>

						{
							showZoom ? <ZoomButtons onReset={() => {
								this.props.resetValues();
							}} /> : ''
						}

					</Chart>
					{
						(indicatorVolume && indicatorVolume.active) ?
							<Chart id={2}
								yExtents={[d => d.volume]}
								height={150}

								origin={(w, h) => [0, heightMainChart - 150]}
							// origin={(w, h) => [0, heightMainChart > 500 ? 350 : heightMainChart < 300 ? 150 :350]}
							>

								{/* <YAxis onClick={() => console.log("hola")} axisAt="left" orient="left" ticks={5} tickFormat={format(".2s")} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontSize={11} fontFamily="Nunito, sans-serif" fontSize={11} /> */}
								<MouseCoordinateX
									at="bottom"
									orient="bottom"
									displayFormat={timeFormat("%Y-%m-%d")} 
									fontFamily="Nunito, sans-serif"
									fontSize={11}
								/>
								{/* <MouseCoordinateY
									at="left"
									orient="left"
									displayFormat={format(",.3f")} /> */}
								<BarSeries yAccessor={d => d.volume}
									fill={d => d.close > d.open ? hexToRGBA(colorUpVolume, ColorRba) : hexToRGBA(colorDownVolume, ColorRba)
									} />
								{/*<YAxis onClick={() => console.log("hola")} axisAt="left" orient="left" ticks={5} tickFormat={format(".2s")} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} />*/}
								 
								<CurrentCoordinate yAccessor={d => d.volume} fill="#9B0A47" />
							</Chart> : ``
					}

					{
						(auxMACD && auxMACD) ?
							auxMACD.map(
								(item, index) => {
									return <Chart id={(3 + index)} height={heightByIndicator}
										yExtents={item.accessor()}
										origin={(w, h) => {
											return [0, heightMacd]
										}}
										padding={{ top: 10, bottom: 10 }}
									>
										<XAxis tickFormat={d=> timeFormat(`${isIntraday ? '%b %d': '%H:%M'}`)(data[d].date)} axisAt="bottom" orient="bottom" tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontSize={11} fontFamily="Nunito, sans-serif" fontSize={11} />
										<YAxis axisAt="right" orient="right" ticks={window.innerWidth > 400 ? 17 : 5} ticks={2} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontSize={11} fontFamily="Nunito, sans-serif" fontSize={11} />
										<MouseCoordinateX
											at="bottom"
											orient="bottom"
											displayFormat={timeFormat("%Y-%m-%d")}
											rectRadius={5}
											{...mouseEdgeAppearance}
											fontFamily="Nunito, sans-serif"
											fontSize={11}
										/>
										<MouseCoordinateY
											at="right"
											orient="right"
											displayFormat={format(",.3f")}
											{...mouseEdgeAppearance}
											fontFamily="Nunito, sans-serif"
											fontSize={11}
										/>

										<MACDSeries yAccessor={d => d[`macd${index}`]}
											{...macdAppearance} 
											fontFamily="Nunito, sans-serif"
											fontSize={11}
										/>
										
										<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={ d => d[`macd${index}`] ? d[`macd${index}`].macd : ''} fill= {macdAppearance.stroke.macd} />
										<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={ d => d[`macd${index}`] ? d[`macd${index}`].signal : ''} fill= {macdAppearance.stroke.signal} />

										{/* Indicador MACD */}
										<MACDTooltip
											origin={[-52, 15]}
											yAccessor={d => d[`macd${index}`]}
											options={item.options()}
											appearance={macdAppearance}
											textFill={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`}
											fillBackground={`${this.props.theme === `dark` ? `#444444` : `#FFFFFF`}`}
											onClick={(e) => removeIndicator(e, `MACD`)}
											displayFormat={format(",.3f")}
											fontFamily="Nunito, sans-serif"
											fontSize={11}
										/>

									</Chart>

								}
							)
							: ``
					}
					{
						indicatorRSI ?
							<Chart id={20}
								yExtents={[0, 100]}
								height={heightByIndicator} origin={(w, h) => [0, heightRsi]}
							>
								<XAxis tickFormat={d=> timeFormat(`${isIntraday ? '%b %d': '%H:%M'}`)(data[d].date)} axisAt="bottom" orient="bottom"  ticks={window.innerWidth > 400 ? 17 : 5} showTicks={true} outerTickSize={0} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontFamily="Nunito, sans-serif" fontSize={11} />
								<YAxis axisAt="right"
									orient="right"
									tickValues={[30, 50, 70]}
									tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`}
									fontFamily="Nunito, sans-serif" fontSize={11}
								 />
								<MouseCoordinateY
									at="right"
									orient="right"
									displayFormat={format(",.3f")} 
									fontFamily="Nunito, sans-serif"
								/>

								<RSISeries
									yAccessor={d => d.rsi}
									stroke={{
										line: indicatorRSI.colorRSI ? indicatorRSI.colorRSI.hex : "#000000",
										top: indicatorRSI.colorOverBoughtRSI ? indicatorRSI.colorOverBoughtRSI.hex : "#B8C2CC",
										middle: indicatorRSI.colorMiddleRSI ? indicatorRSI.colorMiddleRSI.hex : "#8795A1",
										bottom: indicatorRSI.colorOverSoldRSI ? indicatorRSI.colorOverSoldRSI.hex : "#B8C2CC",
										outsideThreshold: indicatorRSI.colorOutsideRSI ? indicatorRSI.colorOutsideRSI.hex : "#b300b3",
										insideThreshold: indicatorRSI.colorInsideRSI ? indicatorRSI.colorInsideRSI.hex : "#ffccff"
									}}
									fontFamily="Nunito, sans-serif"
								/>

								{/* Indicador RSI */}
								<RSITooltip origin={[-52, 20]}
									yAccessor={d => d.rsi}
									options={rsiCalculator.options()}
									textFill={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`}
									fillBackground={`${this.props.theme === `dark` ? `#444444` : `#FFFFFF`}`}
									onClick={(e) => removeIndicator(e, `RSI`)}
									displayFormat={format(",.3f")}
									fontFamily="Nunito, sans-serif"
								/>
								<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.rsi} fill= {indicatorRSI.colorRSI ? indicatorRSI.colorRSI.hex : "#000000"} />
							</Chart> : ``
					}

					{
						(indicatorStochastic && Boolean(indicatorStochastic.activeSlow)) ?
							<Chart id={23}
								yExtents={[0, 100]}
								height={heightByIndicator} origin={(w, h) => [0, heightSlow]} padding={{ top: 10, bottom: 10 }}
							>
								<XAxis tickFormat={d=> timeFormat(`${isIntraday ? '%b %d': '%H:%M'}`)(data[d].date)} axisAt="bottom" orient="bottom" ticks={window.innerWidth > 400 ? 17 : 5} showTicks={true} outerTickSize={0} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontFamily="Nunito, sans-serif" fontSize={11} />
								<YAxis axisAt="right" orient="right"
									tickValues={[20, 50, 80]} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontFamily="Nunito, sans-serif" fontSize={11} />
								<MouseCoordinateY
									at="right"
									orient="right"
									displayFormat={format(",.3f")}
									fontFamily="Nunito, sans-serif"
								/>

								<StochasticSeries
									yAccessor={d => d.slowSTO}
									{...stoAppearance} 
									fontFamily="Nunito, sans-serif"
								/>
								{/* Indicador Stochastic */}
								<StochasticTooltip
									origin={[-52, 20]}
									yAccessor={d => d.slowSTO}
									options={slowSTO.options()}
									appearance={stoAppearance}
                  					fillBackground={`${this.props.theme === `dark` ? `#444444` : `#FFFFFF`}`}
									textFill={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`}
									label="Slow STO"
									onClick={(e) => removeIndicator(e, `stochasticSlow`)}
									displayFormat={format(",.3f")}
									fontFamily="Nunito, sans-serif"
								/>

								<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.slowSTO ? d.slowSTO.D : ''} fill= {stoAppearance.stroke.dLine} />
								<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.slowSTO ? d.slowSTO.K : ''} fill= {stoAppearance.stroke.kLine} />
							</Chart>
							: ``
					}

					{
						(indicatorStochastic && Boolean(indicatorStochastic.activeFast)) ?
							<Chart id={24}
								yExtents={[0, 100]}
								height={heightByIndicator} origin={(w, h) => [0, heightFast]} padding={{ top: 10, bottom: 10 }}
							>
								<XAxis tickFormat={d=> timeFormat(`${isIntraday ? '%b %d': '%H:%M'}`)(data[d].date)} axisAt="bottom" orient="bottom" ticks={window.innerWidth > 400 ? 17 : 5} showTicks={true} outerTickSize={0} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontFamily="Nunito, sans-serif"fontSize={11} />
								<YAxis axisAt="right" orient="right"
									tickValues={[20, 50, 80]} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontFamily="Nunito, sans-serif" fontSize={11} />

								<MouseCoordinateY
									at="right"
									orient="right"
									displayFormat={format(",.3f")} 
									fontFamily="Nunito, sans-serif"
								/>

								<StochasticSeries
									yAccessor={d => d.fastSTO}
									{...stoAppearance} 
									fontFamily="Nunito, sans-serif"
								/>

								<StochasticTooltip
									origin={[-52, 25]}
									yAccessor={d => d.fastSTO}
									options={fastSTO.options()}
									appearance={stoAppearance}
									textFill={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`}
									displayFormat={format(",.3f")}
									onClick={(e) => removeIndicator(e, `stochasticFast`)}
									label="Fast STO" 
									fontFamily="Nunito, sans-serif"
                  fillBackground={`${this.props.theme === `dark` ? `#444444` : `#FFFFFF`}`}
								/>
								
								<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.fastSTO ? d.fastSTO.D : '' } fill= {stoAppearance.stroke.dLine} />
								<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.fastSTO ? d.fastSTO.K : '' } fill= {stoAppearance.stroke.kLine} />

							</Chart>
							: ``
					}

					{
						(indicatorStochastic && Boolean(indicatorStochastic.activeFull)) ?
							<Chart id={25}
								yExtents={[0, 100]}
								height={heightByIndicator} origin={(w, h) => [0, heightfull]} padding={{ top: 10, bottom: 10 }}
							>
								
								<XAxis tickFormat={d=> timeFormat(`${isIntraday ? '%b %d': '%H:%M'}`)(data[d].date)} axisAt="bottom" orient="bottom" ticks={window.innerWidth > 400 ? 17 : 5} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontFamily="Nunito, sans-serif" fontSize={11} />
								<YAxis axisAt="right" orient="right"
									tickValues={[20, 50, 80]} tickStroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} fontFamily="Nunito, sans-serif" fontSize={11} />

								<MouseCoordinateX
									at="bottom"
									orient="bottom"
									displayFormat={timeFormat("%Y-%m-%d")} 
									fontFamily="Nunito, sans-serif"
									fontSize={11}
								/>
								<MouseCoordinateY
									at="right"
									orient="right"
									displayFormat={format(",.3f")} 
									fontFamily="Nunito, sans-serif"
									fontSize={11}
								/>

								<StochasticSeries
									yAccessor={d => d.fullSTO}
									{...stoAppearance} 
									fontFamily="Nunito, sans-serif"
									fontSize={11}
								/>

								<StochasticTooltip
									origin={[-52, 25]}
									yAccessor={d => d.fullSTO}
									options={fullSTO.options()}
									appearance={stoAppearance}
									textFill={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`}
									displayFormat={format(",.3f")}
									onClick={(e) => removeIndicator(e, `stochasticFull`)}
									label="Full STO" 
									fontFamily="Nunito, sans-serif"
									fontSize={11}
                  					fillBackground={`${this.props.theme === `dark` ? `#444444` : `#FFFFFF`}`}
								/>

								<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.fullSTO ? d.fullSTO.D : ''} fill= {stoAppearance.stroke.dLine} />
								<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.fullSTO ? d.fullSTO.K : ''} fill= {stoAppearance.stroke.kLine} />

							</Chart>
							: ``
					}

					<CrossHairCursor stroke={`${this.props.theme === `dark` ? `#edecf1` : `#000000`}`} />
					<DrawingObjectSelector
						enabled={!this.state.enableInteractiveObject}
						getInteractiveNodes={()=>this.getInteractiveNodes()}
						drawingObjectMap={{
							GannFan: "fans",
							StandardDeviationChannel: "channels",
							FibonacciRetracement: "retracements",
							InteractiveText: "textList",
							Trendline: "trends"
						}}
						onSelect={(interactives, moreProps, e) => this.handleSelection(interactives, moreProps, e)}			
								/>

				</ChartCanvas>
				{
					this.state.showButton ?
						<div className={classes.btnContent}>
							<IconButton onClick={() => this._deleteFunc()} className={classes.btnMinus}>
								<FontAwesomeIcon icon={faMinus} />
							</IconButton>
						</div>
						: ``
				}

				{/* {
					this.state.panEvent ?
						<div className={classes.btnContent}>
							<IconButton onClick={() => this._enableChart()} className={classes.btnMinus}>
								<FontAwesomeIcon icon={faBan} />
							</IconButton>
						</div>
						: false
				} */}

				<FormText
					showModal={this.state.showModal}
					text={this.state.text}
					chartId={this.state.chartId}
					onClose={this.handleDialogClose}
					onSave={this.handleTextChange}
					lang={lang}
				/>
			</React.Fragment>
		);
	}
}

CandleStickChartWithMA.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

CandleStickChartWithMA.defaultProps = {
	type: "svg",
	panEvent: true
};
CandleStickChartWithMA = fitDimensions(fitWidth(CandleStickChartWithMA))



export default withStyles(styles)(CandleStickChartWithMA);