import React from 'react';
import { renderRoutes } from 'react-router-config';
import Loading from 'react-loading-bar';
import { LanguageConsumer } from '../../../app/language/LanguageContext';

//Theme
import ThemePallete from '../../utils/themePallete';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import styles from './appStyles-jss';
import { withTheme } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import { ligth, dark } from '../../utils/themePaletteStyled';

class App extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: true,
            theme: createMuiTheme(ThemePallete['main'])
        };        
    }

    componentWillMount = () => {
        this.onProgressShow();
    }

    componentDidMount = () => {
        this.playProgress();
        this.unlisten = this.props.history.listen(() => {
            this.playProgress();
        });
    }

    componentWillUnmount() {
        this.unlisten();
        this.onProgressShow();
    }

    onProgressShow = () => {
        this.setState({ pageLoaded: true });
    }

    onProgressHide = () => {
        this.setState({ pageLoaded: false });
    }

    playProgress = () => {
        this.onProgressShow();
        setTimeout(() => {
            this.onProgressHide();
        }, 500);
    }

    render() {
        const {
            classes,
            route,
        } = this.props;
        const {
            pageLoaded,
        } = this.state;

        const createTheme = (theme) => {
            theme = theme ? theme : "main";
            return createMuiTheme(ThemePallete[theme]);
        }

        const createThemeStyled = (theme) => {
            return !theme ? ligth : theme === "main" ? ligth : dark;
        }

        return (
            <LanguageConsumer>
                {
                    ({theme}) => 
                    <MuiThemeProvider theme={createTheme(theme)}>
                        <ThemeProvider theme={createThemeStyled(theme)}>
                            <div className={classes.root}>
                                <Loading
                                    show={pageLoaded}
                                    color="rgba(255,255,255,.9)"
                                    showSpinner={false}
                                />
                                {renderRoutes(route.routes)}
                            </div>
                        </ThemeProvider>
                    </MuiThemeProvider>
                }
            </LanguageConsumer>

        )
    }
}

export default withTheme(withStyles(styles)(App));
