import styled from 'styled-components';
import { AppBar } from '@material-ui/core';

const AppBarB = styled(AppBar)`
    background: ${(props) => props.theme.appBg} !important;
    box-shadow: unset !important;
    justify-content: center !important;
    width: 100% !important;
    &.themeDark {
        background: #333 !important;
    }
    &.positionFixed {
        position: fixed !important;
    }
`;

export { AppBarB };