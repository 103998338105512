import React from 'react';
import PropTypes from 'prop-types';

//Styles 
import { withStyles } from '@material-ui/core/styles';
import styles from '../cards-jss';
import { DropdownPanel } from "../card"

class RicSuggestionCard extends React.Component {
    
    render(){
        const {classes, ric} = this.props;

        const truncateName = (value, delimiter) => {
            const indexValue = value.indexOf(delimiter);
            return indexValue <= -1 ? value : value.substring(0, indexValue);
        }

        return(
            <React.Fragment>
                <DropdownPanel>
                    <div className="card">
                        {(() => {
                            switch(ric.assetgroupid) {
                            case 6:
                                return (
                                    <React.Fragment>
                                        <span className="card-title">{ric.name !== "-" ? ric.name : ric.symbol}</span>
                                        <span className="card-subtitle">{ric.shortName !== "-" ? ric.shortName : ric.symbol}</span>
                                    </React.Fragment>
                                );
                            case 2:
                                return (
                                    <React.Fragment>
                                        <span className="card-title">{ric.name !== "-" ? ric.name : ric.symbol}</span>
                                        <span className="card-subtitle">{ric.shortName !== "-" ? ric.shortName : ric.symbol} | {ric.market.split(" ")[1]}</span>
                                    </React.Fragment>
                                );
                            default:
                                return (
                                    <React.Fragment>
                                        <span className="card-title">{ric.name !== "-" ? ric.name : ric.symbol}</span>
                                        <span className="card-subtitle">{ric.shortName !== "-" ? ric.shortName : ric.symbol}</span>
                                    </React.Fragment>
                                );
                            }
                        })()}
                    </div>
                </DropdownPanel>
            </React.Fragment>
        )
    }
}

RicSuggestionCard.propTypes = {
    classes : PropTypes.object.isRequired, 
    ric: PropTypes.object
}

export default withStyles(styles)(RicSuggestionCard);