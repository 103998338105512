import React from "react";
import langstr from './lang'; 

export const themes = {
    ligth: "main", 
    dark: "maindark"
}; 

const LanguageContext = React.createContext({
    lang: langstr,
    changeLang : () => {},
    theme: themes.ligth,
    changeTheme: () => {}
}); 

export const LanguageCxt = LanguageContext; 
export const LanguageProvider = LanguageContext.Provider;
export const LanguageConsumer = LanguageContext.Consumer;  
