import LocalizedStrings from 'react-localization';

let l = new LocalizedStrings({
    en: {
        technicalAnalyst: {
            add: "Technical analysis",
            config: `Configuration`,
            NoResults: 'No data available',
            header: {
                indicator: `Indicator`,
                typeChart: `Chart`,
                functions: `Technicals`,
                period: `Period`,
                color: `Color`,
                draw: `Draw`,
                write: `Write`,
                types: `Type gráficas`,
                technicals: `Technical indicators`,
                colors: `Chart colors`,
                options: `Options`,
                save: `Save`,
                delete: `Delete`,
                more: `More options`,
            },
            title: 'Graphics',
            stroke: `Stroke`,
            fill: `Fill`,
            opacity: `Opacity`,
            line: `Stroke`,
            highestPoint: `Highest point`,
            Indicators: {
                sma: `SMA`,
                ema: `EMA`,
                macd: `MACD`,
                rsi: `RSI`,
                bbands: `BOLLINGER`,
                volume: `VOLUME`,
                stochastic: `STOCHASTIC`
            },
            typeChart: {
                line: `Line`,
                area: `Area`,
                vela: `Candle`,
                velahueca: `Hollow Candle`
            },
            functions: {
                gannFann: `Gann Fan`,
                fibonacci: `Fibonacci Retracements`,
                tendencia: `Linear regression channel`
            },
            vela: {
                upCandle: `Up Candle`,
                downCandle: `Down Candle`,
                opacity: `Opacity`,
                upWick: `Up Wick`,
                downWick: `Down Wick`,
                upBorder: `Up Border`,
                downBorder: `Down Border`,
            },
            formIndicator: {
                smaEma: {
                    period: `Period`,
                    stroke: `Stroke`,
                    messages: {
                        invalid: `Invalid period`,
                        limit: `Limit exceded`
                    }
                },
                macd: {
                    fast: `Fast`,
                    slow: `Full`,
                    signal: `Signal`,
                    stroke: `Stroke`,
                    messages: {
                        invalidFast: `Invalid fast`,
                        invalidSlow: `Invalid Full`,
                        invalidSignal: `Invalid Signal`
                    }
                },
                rsi: {
                    period: `Period`,
                    upperBand: `Upper Band`,
                    middle: `Middle`,
                    lowerBand: `Lower Band`,
                    rsiColor: `RSI`,
                    overBoughtColor: `Over Bought`,
                    middleColor: `Middle`,
                    overSoldColor: `Over Sold`,
                    outsideColor: `Outside`,
                    insideColor: `Inside`,
                    messages: {
                        invalidPeriod: `Invalid Period`,
                        invalidUpper: `Invalid Upper`,
                        invalidMiddle: `Invalid Middle`,
                        invalidLower: `Invalid Lower`
                    }
                },
                bbands: {
                    windowSize: `Window Size`,
                    multiplier: `Multiplier`,
                    maType: `Ma Type`,
                    option: `Selected`,
                    messages: {
                        invalidWindowSize: `Invalid Window Size`,
                        invalidMultiplier: `Invalid multiplier`,
                        invalidMaType: `Invalid Ma Type`
                    }
                },
                volume: {
                    upColor: `Up`,
                    downColor: `Down`,
                    opacity: `Opacity`
                },
                stochastic: {
                    period: `Period`,
                    kWindowSize: `K Window Size`,
                    dWindowSize: `D Window Size`,
                    slowActive: `Slow`,
                    fastActive: `Fast`,
                    fullActive: `Full`,
                    stroke: `Stroke`,
                    messages: {
                        invalidPeriod: `Invalid Period`,
                        invalidKWindowSize: `Invalid K Window Size`,
                        invalidDWindowSize: `Invalid D Window Size`,
                        selectedChart: `Selected at least one type of chart`
                    }
                },
                gannFan: {
                    stroke: `Stroke`,
                    opacity: `Opacity`,
                    thin : `Thin`,
                    full : `Thick`,
                    width: `Width`,
                },
                fibonacci: {
                    fontFill: `Font Fill`,
                    type: `Type`,
                    stroke: `Stroke`,
                    opacity: `Opacity`,
                    width: `Width`,
                    option: `Select`
                },
                tr: {
                    stroke: `Stroke`,
                    opacity: `Opacity`,
                    width: `Width`,
                    thin : `Thin`,
                    full : `Thick`,
                    fill: `Fill`,
                    fillOpacity: `Fill Opacity`
                },
                drawnLine: {
                    type: `Type`,
                    stroke: `Stroke`,
                    strokeOpacity: `Stroke Opacity`,
                    strokeWidthTrendingLine: `Stroke Width`,
                    style: `Style`,
                    selectOption: `Select`,
                    messages: {
                        typeLine: `Invalid type line`,
                        color: `Invalid color`,
                        styleLine: `Invalid style line`
                    },
                    options: {
                        solid: `Solid`,
                        shortDash: `Short Dash`,
                        shortDash2: `Short Dash 2`,
                        shortDot: `Short Dot`,
                        shortDashDot: `Short Dash Dot`,
                        shortDashDotDot: `Short Dash Dot Dot`,
                        dot: `Dot`,
                        dash: `Dash`,
                        longDash: `Long Dash`,
                        dashDot: `Dash Dot`,
                        longDashDot: `Long Dash Dot`,
                        longDashDotDot: `Long Dash Dot Dot`,
                        thin: `Thin`,
                        full: `Thick`
                    }
                }
            },
            export: {
                date: `Date`,
                close: `Close`,
                open: `Open`,
                high: `High`,
                low: `Low`,
                name: `Technical Analyst`
            },
            confirmDelete: `You want to delete the indicator?`,
            confirmDeleteTechnical: `You want to delete the technical analyst?`,
            formAnalysis: {
                title: 'Technical analysis title',
                Description: 'Description',
                symbol: 'Symbol',
                Group: "Group",
                Save: "Save",
                cancel: "Cancel",
                Select: "Select a group",
                Market: "Money Market/Fix Income",
                Index: "Index",
                FX: "FX",
                Commodities: "Commodities",
                Equities: "Equities"
            },
            options: {
                Options: 'Options',
                Image: 'Export to image',
                CSV: 'Export to CSV',
                XLSX: 'Export to XLSX',
                Edit: 'Edit',
                Delete: 'Delete'
            },
            Default: 'Default',
            Remove: 'Remove filters',
            Messages: {
                min: `Enter at leat 5 characters.`,
                max: `Maximum of characters 50.`,
                empty: `Enter a name for the technical analysis.`,
                emptyD: `Enter a description for the technical analysis.`,
                specialCharacter: `Special characters are not allowed.`,
                errorSymbol: `Invalid Symbol`,
                emptyField: `Field Required`,
                noData : "No data available",
                deleted : "Technical Analyst deleted",
                created : "Technical Analyst created",
                updated : "Technical Analyst updated"
            },
            limitDrawFunc: `Can only add 3 elements.`,
            titleForm: "Enter text",
            multiline:"Multiline",
            cancel: "Cancel",
            accept: "Accept",
            period: {
                one: `1Y`,
                two: `2Y`,
                three: `3Y`,
            }
        },
        fileManager: {
            filters: {
                title: "Filters",
                byRic: "By instrument",
                search: "Search",
                ric: "Instrument",
                byDate: "By date",
                date: "Date",
                byType: "By type",
                document: "Documents",
                image: "Images",
                fileType: "File type",
                folder: "Folder",
                contentSearch: "Search in your content",
                results: "results"
            },
            createFolderForm: {
                title: "Create new folder",
                folderName: "Folder name",
                isRequired: "Folder name is required",
                accept: "Accept",
                cancel: "Cancel",
                maxLength: "Enter a name maximum of 50 characters",
                messages: {
                    SuccessTitle: "Create Folder",
                    SuccessMsg: "The folder was created successfully.",
                    ErrorTitle: "Error creating folder",
                    ErrorExists: "The folder name already exists in the current directory.",
                    ErrorMsg: "Try again, if the problem persists contact the administrator."
                }
            },
            uploadFilesForm: {
                title: "Upload file",
                titleRecommendation: "Analyst recommendation",
                titleName: "Title",
                Name: "File name",
                issuer: "Instrument",
                date: "Date",
                description: "Description",
                Source: "Source",
                Rating: "Rating",
                Analyst: "Lead Analyst",
                selectFile: "Select a file to upload",
                selectFileButton: "Select file",
                files: "Files",
                titleRequired: "File title required",
                dateRequired: "Publication date is required field",
                fileLimit: "The maximum limit for uploading files is 9.99MB.",
                minFiles: "Select at least one file to continue",
                accept: "Upload",
                cancel: "Cancel",
                emptyField: "Empty fields not allowed",
                invalidRic: "Invalid instrument",
                maxLengthTitle: "Enter a title maximum of 50 characters",
                maxLengthDescription: "Enter a description maximum of 250 characters",
                maxLengthNameFile: "Enter a name minimum of 50 characters",
                nameFileRequired: "File name required",
                sourceRequired: "Source required",
                analystRequired: "Analyst required",
                recommendationRequired: "Recommendation required",
                instrumentRequired: "Instrument required",
                messages: {
                    SuccessTitle: "Upload Files",
                    SuccessMsg: "Selected files uploaded successfully.",
                    ErrorTitle: "Error uploading files",
                    ErrorMsg: "One or more files were not uploaded correctly, if the problem persists contact the administrator."
                }
            },
            upateFilesForm: {
                title: "Edit file",
                titleFolder: "Edit folder",
                titleRecommendation: "Edit analyst recommendation",
                accept: "Accept",
                cancel: "Cancel",
                titleName: "Title",
                issuer: "Instrument",
                date: "Date",
                description: "Description",
                Name: "File name",
                Source: "Source",
                Rating: "Rating",
                Analyst: "Lead Analyst",
                titleRequired: "Title is required field",
                dateRequired: "Publish date required",
                emptyField: "Empty fields not allowed",
                invalidRic: "Invalid instrument",
                maxLengthTitle: "Enter a title maximum of 50 characters",
                maxLengthDescription: "Enter a description maximum of 250 characters",
                maxLengthNameFile: "Enter a name minimum of 50 characters",
                nameFileRequired: "File name required",
                sourceRequired: "Source required",
                analystRequired: "Analyst required",
                recommendationRequired: "Recommendation required",
                instrumentRequired: "Instrument required",
                messages: {
                    SuccessTitle: "Edit selected item",
                    SuccessMsg: "The selected item was updated successfully.",
                    ErrorMsg: "Error editing selected item",
                    ErrorTitle: "Try again, if the problem persists contanct the administrator."
                }
            },
            deleteFilesForm: {
                title: "Do you confirm deleting the file?",
                titleMultiple: "Do you confirm deleting the selected items?",
                message: "The file will be permanently deleted",
                messageMultiple: "The files will be permanently deleted.",
                accept: "Accept",
                cancel: "Cancel",
                messages: {
                    SuccessSingleMsg: "The file was delected successfully.",
                    SuccessSingleTitle: "Delete selected file",
                    SuccessMultipleMsg: "The selected files were delected successfully.",
                    SuccessMultipleTitle: "Delete selected files",
                    SuccessFolderMsg: "The folder was deleted successfully.",
                    SuccessFolderTitle: "Delete folder.",
                    ErrorFolderTitle: "Error at deleteing selected folder",
                    ErrorMultipleTitle: "Error at deleting selected files.",
                    ErrorSingleTitle: "Error at deleting selected file.",
                    ErrorMsg: "Try again, if the problem persists contanct the administrator."
                }
            },
            new: "New",
            uploadFile: "Upload file",
            createFolder: "Create folder",
            uploadRecommendation: "Analyst recommendation",
            typeFiles: {
                IMAGE: "Image",
                PDF: "PDF",
                ZIP: "ZIP",
                OFFICE: "Document",
                WEB: "Web file",
                VIDEO: "Video",
                AUDIO: "Audio"
            },
            detailContent: {
                created: "Created",
                type: "Type",
                issuer: "Issuer",
                description: "Description",
                recommendation: "Recommendation",
                source: "Source",
                analyst: "Analyst",
                fileName: "File Name"
            },
            NoResults: "Documents no found",
            cardOptions: {
                edit: "Edit",
                delete: "Delete",
                download: "Download",
                detail: "Detail"
            },
            name: "Name"
        }
    },
    es: {
        technicalAnalyst: {
            add: "Análisis técnico",
            config: `Configuración`,
            NoResults: 'No existe información disponible',
            header: {
                indicator: `Indicadores`,
                typeChart: `Gráfica`,
                functions: `Técnicos`,
                period: `Periodo`,
                color: `Color`,
                draw: `Dibujar`,
                write: `Escribir`,
                types: `Tipos de gráficas`,
                technicals: `Indicadores técnicos`,
                colors: `Colores del gráfico`,
                options: `Opciones`,
                save: `Guardar`,
                delete: `Eliminar`,
                more: `Más opciones`
            },
            title: 'Gráficas',
            stroke: `Color de fondo punto más alto`,
            fill: `Color de fondo`,
            opacity: `Opacidad`,
            line: `Color de Línea`,
            highestPoint: `Color del punto más alto`,
            Indicators: {
                sma: `SMA`,
                ema: `EMA`,
                macd: `MACD`,
                rsi: `RSI`,
                bbands: `BBANDS`,
                volume: `VOLUME`,
                stochastic: `STOCHASTIC`
            },
            typeChart: {
                line: `Línea`,
                area: `Área`,
                vela: `Vela`,
                velahueca: `Vela Hueca`
            },
            functions: {
                gannFann: `Abanico de Gann`,
                fibonacci: `Retroceso de Fibonacci`,
                tendencia: `Tendencia de Regresión`
            },
            vela: {
                upCandle: `Color de relleno superior`,
                downCandle: `Color de relleno inferior`,
                opacity: `Opacidad`,
                upWick: `Color de línea superior`,
                downWick: `Color de línea inferior`,
                upBorder: `Color de línea máximo`,
                downBorder: `Color de línea mínimo`,

            },
            formIndicator: {
                smaEma: {
                    period: `Periodo`,
                    stroke: `Color de Línea`,
                    messages: {
                        invalid: `Periodo inválido`,
                        limit: `Límite excedido`
                    }
                },
                macd: {
                    fast: `Rápido`,
                    slow: `Lento`,
                    signal: `Señal`,
                    stroke: `Línea`,
                    messages: {
                        invalidFast: `Rápido inválido`,
                        invalidSlow: `Lento inválido`,
                        invalidSignal: `Señal inválido`
                    }
                },
                rsi: {
                    period: `Periodo`,
                    upperBand: `Banda Superior`,
                    middle: `Media`,
                    lowerBand: `Banda Inferior`,
                    rsiColor: `RSI`,
                    overBoughtColor: `Sobre Compra`,
                    middleColor: `Media`,
                    overSoldColor: `Sobre Vendido`,
                    outsideColor: `Fuera de`,
                    insideColor: `Dentro de`,
                    messages: {
                        invalidPeriod: `Periodo inválido`,
                        invalidUpper: `Banda superior inválido`,
                        invalidMiddle: `Media inválida`,
                        invalidLower: `Banda Inferior inválida`
                    }
                },
                bbands: {
                    windowSize: `Longitud`,
                    multiplier: `Multiplicador`,
                    maType: `Tipo MA`,
                    option: `Selecciona`,
                    messages: {
                        invalidWindowSize: `Longitud inválida`,
                        invalidMultiplier: `Multiplicador inválido`,
                        invalidMaType: `Tipo inválido`
                    }
                },
                volume: {
                    upColor: `Alto`,
                    downColor: `Bajo`,
                    opacity: `Opacidad`
                },
                stochastic: {
                    period: `Periodo`,
                    kWindowSize: `Linea %K`,
                    dWindowSize: `Línea %D`,
                    slowActive: `Lento`,
                    fastActive: `Rápido`,
                    fullActive: `Completo`,
                    stroke: `Color de línea`,
                    messages: {
                        invalidPeriod: `Periodo inválido`,
                        invalidKWindowSize: `K inválido`,
                        invalidDWindowSize: `D inválido`,
                        selectedChart: `Selecciona por lo menos un tipo de gráfica`
                    }
                },
                gannFan: {
                    stroke: `Color de línea`,
                    opacity: `Opacidad`,
                    width: `Ancho de línea`,
                    thin : `Delgado`,
                    full : `Grueso`,
                },
                fibonacci: {
                    fontFill: `Color de fuente`,
                    type: `Tipo`,
                    stroke: `Línea`,
                    opacity: `Opacidad`,
                    width: `Ancho de línea`,
                    option: `Selecciona`
                },
                tr: {
                    stroke: `Color de Línea`,
                    opacity: `Opacidad de color de línea`,
                    width: `Grosor de línea`,
                    thin : `Delgado`,
                    full : `Grueso`,
                    fill: `Color de fondo`,
                    fillOpacity: `Opacidad del color de fondo`
                },
                drawnLine: {
                    type: `Tipo de línea`,
                    stroke: `Color`,
                    strokeOpacity: `Opacidad`,
                    strokeWidthTrendingLine: `Grosor de línea`,
                    style: `Estilo de línea`,
                    selectOption: `Selecciona`,
                    messages: {
                        typeLine: `Selecciona un tipo de línea`,
                        color: `Selecciona un color de línea`,
                        styleLine: `Selecciona un estilo de línea`
                    },
                    options: {
                        solid: `Solido`,
                        shortDash: `Guión, Punto`,
                        shortDash2: `Guión`,
                        shortDot: `Punto, corto`,
                        shortDashDot: `Punto, guión corto`,
                        shortDashDotDot: `Guión corto, punto punto`,
                        dot: `Punto`,
                        dash: `Guión`,
                        longDash: `Guión, Largo`,
                        dashDot: `Guión, punto`,
                        longDashDot: `Guión largo, punto`,
                        longDashDotDot: `Guión largo, punto punto`,
                        thin: `Delgado`,
                        full: `Grueso`,
                        stroke: `Color de línea`
                    }
                }
            },
            export: {
                date: `Fecha`,
                close: `Cierre`,
                open: `Apertura`,
                high: `Máximo`,
                low: `Mínimo`,
                name: `Análisis Técnico`
            },
            confirmDelete: `¿Está seguro que desea borrar el indicador?`,
            confirmDeleteTechnical: `¿Está seguro que desea borrar el análisis técnico?`,
            formAnalysis: {
                title: 'Título del Análisis técnico',
                symbol: 'Instrumento',
                Description: 'Descripción',
                Group: "Grupo",
                Save: "Guardar",
                cancel: "Cancelar",
                Select: "Selecciona un grupo",
                Market: "Mercado Dinero/Deuda",
                Index: "Índices",
                FX: "Divisas",
                Commodities: "Commodities",
                Equities: "Cotizaciones"
            },
            options: {
                Options: 'Opciones',
                Image: 'Exportar a imagen',
                CSV: 'Exportar a CSV',
                XLSX: 'Exportar a XLSX',
                Edit: 'Editar',
                Delete: 'Eliminar'
            },
            Default: 'Principal',
            Remove: 'Eliminar filtros',
            Messages: {
                min: `Mínimo de caractéres 5`,
                max: `Máximo de caractéres 50`,
                empty: `Ingresar un nombre para el análisis técnico`,
                emptyD: `Ingresar una descripción para el análisis técnico`,
                specialCharacter: `No se permiten caractéres especiales`,
                errorSymbol: `Instrumento inválido`,
                emptyField : `Campo obligatorio`,
                noData : "No hay información disponible",
                deleted : "Análisis técnico eliminado",
                created : "Análisis técnico creado",
                updated : "Análisis técnico actualizado"
            },
            limitDrawFunc: `Solo puedes agregar 3 elementos.`,
            titleForm: "Ingrese el texto",
            multiline:"Multilínea",
            cancel: "Cancelar",
            accept: "Aceptar",
            period: {
                one: `1A`,
                two: `2A`,
                three: `3A`,
            }
        },
        fileManager: {
            filters: {
                title: "Filtros",
                byRic: "Por instrumento",
                search: "Buscar",
                ric: "Instrumento",
                byDate: "Por fecha",
                date: "Fecha",
                byType: "Por tipo",
                document: "Documentos",
                image: "Imágenes",
                fileType: "Tipo de archivo",
                folder: "Carpeta",
                contentSearch: "Buscar en tu contenido",
                results: "resultados"
            },
            createFolderForm: {
                title: "Crear nueva carpeta",
                folderName: "Nombre de la carpeta",
                isRequired: "Nombre de la carpeta obligatorio",
                accept: "Aceptar",
                cancel: "Cancelar",
                maxLength: "Ingrese un nombre máximo de 50 caracteres",
                messages: {
                    SuccessTitle: "Crear carpeta",
                    SuccessMsg: "La carpeta se creó correctamente.",
                    ErrorTitle: "Error al crear la carpeta",
                    ErrorExists: "El nombre de la carpeta ya existe en el directorio actual.",
                    ErrorMsg: "Inténtelo nuevamente, si el problema persiste pongase en contacto con el administrador."
                }
            },
            uploadFilesForm: {
                title: "Subir archivo",
                titleRecommendation: "Recomendación de analista",
                titleName: "Título",
                Name: "Nombre de archivo",
                issuer: "Instrumento",
                date: "Fecha",
                description: "Descripción",
                Source: "Fuente",
                Rating: "Recomendación",
                Analyst: "Analista",
                selectFile: "Elige un archivo para subir",
                selectFileButton: "Elegir archivo",
                files: "Archivos",
                titleRequired: "Título del archivo obligatorio",
                dateRequired: "Fecha de publicación obligatoria",
                fileLimit: "El límite máximo para subir archivos es de 9.99MB",
                minFiles: "Seleccionar por lo menos un archivo para continuar",
                accept: "Subir",
                cancel: "Cancelar",
                emptyField: "No se permiten campos vacíos",
                invalidRic: "Instrumento no válido",
                maxLengthTitle: "Ingrese un título máximo de 50 caracteres",
                maxLengthDescription: "Ingrese una descripción máxima de 250 caracteres",
                maxLengthNameFile: "Ingrese un nombre máximo de 50 caracteres",
                nameFileRequired: "Nombre del archivo obligatorio",
                sourceRequired: "Fuente obligatoria",
                analystRequired: "Analista obligatorio",
                recommendationRequired: "Recomendación obligatoria",
                instrumentRequired: "Instrumento obligatorio",
                messages: {
                    SuccessTitle: "Subir archivos",
                    SuccessMsg: "Los archivos seleccionados se subieron correctamente.",
                    ErrorTitle: "Error al subir los archivos",
                    ErrorMsg: "Uno o más archivos no se subieron de manera correcta, si el problema persiste pongase en contacto con el administrador."
                }
            },
            upateFilesForm: {
                title: "Editar archivo",
                titleFolder: "Editar carpeta",
                titleRecommendation: "Editar recomendación de analista",
                accept: "Aceptar",
                cancel: "Cancelar",
                titleName: "Título",
                issuer: "Instrumento",
                date: "Fecha",
                description: "Descripción",
                Name: "Nombre de archivo",
                Source: "Fuente",
                Rating: "Recomendación",
                Analyst: "Analista",
                titleRequired: "Título del archivo obligatorio",
                dateRequired: "Fecha de publicación obligatoria",
                emptyField: "No se permiten campos vacíos",
                invalidRic: "Instrumento no válido",
                maxLengthTitle: "Ingrese un título máximo de 50 caracteres",
                maxLengthDescription: "Ingrese una descripción máxima de 250 caracteres",
                maxLengthNameFile: "Ingrese un nombre máximo de 50 caracteres",
                nameFileRequired: "Nombre del archivo obligatorio",
                sourceRequired: "Fuente obligatoria",
                analystRequired: "Analista obligatorio",
                recommendationRequired: "Recomendación obligatoria",
                instrumentRequired: "Instrumento obligatorio",
                messages: {
                    SuccessMsg: "Editar elemento seleccionado",
                    SuccessTitle: "El elemento se actualizó correctamente.",
                    ErrorMsg: "Error al editar elemento seleccionado",
                    ErrorTitle: "Inténtelo nuevamente, si el problema persiste pongase en contacto con el administrador."
                }
            },
            deleteFilesForm: {
                title: "¿Confirma eliminar el archivo seleccionado?",
                titleMultiple: "¿Confirma eliminar los elementos selecionados?",
                message: "El archivo será eliminado permanentemente",
                messageMultiple: "Los elementos seleccionados serán eliminados permanentemente.",
                accept: "Aceptar",
                cancel: "Cancelar",
                messages: {
                    SuccessSingleMsg: "El elemento se eliminó correctamente.",
                    SuccessSingleTitle: "Eliminar elemento seleccionado",
                    SuccessMultipleMsg: "Los elementos seleccionados se eliminaron correctamente.",
                    SuccessMultipleTitle: "Eliminar elementos seleccionados",
                    SuccessFolderMsg: "La carpeta se eliminó con éxito.",
                    SuccessFolderTitle: "Eliminar carpeta.",
                    ErrorFolderTitle: "Error al eliminar carpeta seleccionada",
                    ErrorMultipleTitle: "Error al eliminar los elementos seleccionados",
                    ErrorSingleTitle: "Error al eliminar el elemento seleccionado.",
                    ErrorMsg: "Inténtelo nuevamente, si el problema persiste pongase en contacto con el administrador."
                }
            },
            new: "Nuevo",
            uploadFile: "Subir archivo",
            uploadRecommendation: "Recomendación de analista",
            createFolder: "Crear carpeta",
            typeFiles: {
                IMAGE: "Imagen",
                PDF: "PDF",
                ZIP: "ZIP",
                OFFICE: "Documento",
                WEB: "Archivo web",
                VIDEO: "Vídeo",
                AUDIO: "Audio"
            },
            detailContent: {
                created: "Creado el",
                type: "Tipo",
                issuer: "Emisora",
                description: "Descripción",
                recommendation: "Recomendación",
                source: "Fuente",
                analyst: "Analista",
                fileName: "Nombre del archivo"
            },
            NoResults: "Documentos no encontrados",
            cardOptions: {
                edit: "Editar",
                delete: "Eliminar",
                download: "Descargar",
                detail: "Información",
                editRecommendation: "Editar recomendación"
            },
            name: "Nombre"
        }
    }
})

l.setLanguage('es');

export default l;