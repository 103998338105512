import React from 'react';
//Components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ButtonBase } from '../../uielements/button/ButtonBase';
import { IconButton } from '@material-ui/core';
//Modal Fullscreen
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
//Color
import Typography from '@material-ui/core/Typography';

//Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './FormDialog-jss';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import CloseIcon from '@material-ui/icons/Close';
//Graph
import FormEMASMA from '../Forms/ema_sma/index'
import FormMACD from '../Forms/macd/index';
import FormBBands from '../Forms/BBands/index';
import FormVolume from '../Forms/Volume';
import FormStochastic from '../Forms/Stochastic';
import FormGannFan from '../Forms/gannfan';
import FormFibonacci from '../Forms/fibonacci';
import FormTR from '../Forms/tr';
import FormRSI from '../Forms/rsi';
import FormDraw from '../Forms/draw';
import FormDelete from '../Forms/Delete';

function FormDialog(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { open, handleClose, createIndicator, indicator, handleChange, period, strokeColorSMA, handleChangeComplete,
        fastMACD, slowMACD, signalMACD, colorStrokeMACD, strokeColorGannFan, alphaGannFan, strokeGannFanWidth, classes,
        //Fibonacci
        colorStrokeFB,
        alphaStokeFB,
        strokeWidthFB,
        typeLineFB,
        //TR
        colorStrokeTR,
        strokeOpacityTR,
        strokeWidthTR,
        fillTR,
        fillOpacity,
        //RSI
        periodRSI,
        upperBandRSI,
        middleRSI,
        lowerRSI,
        colorRSI,
        colorOverBoughtRSI,
        colorMiddleRSI,
        colorOverSoldRSI,
        colorOutsideRSI,
        colorInsideRSI,

        //trendingLine
        typeTrendingLine,
        strokeTrendingLine,
        strokeOpacityLine,
        strokeWidthTrending,
        styleTrendingLine,

        //bband
        windowSizeBBand,
        multiplierBBand,
        maTypeBBand,

        //Volume
        indicatorVolume,
        colorUpVolume,
        colorDownVolume,
        OpacityVolume,

        //stochastic 
        periodStochastic, kWindowSizeStochastic, dWindowSizeStochastic, activeSlowStochastic, activeFastStochastic, activeFullStochastic,
        lang,
        titleForm,
        accept,
        cancel,

        //delete
        remove,
        titleDelete,
        colorFontFill,
        optionsDraw,
        strokeKLine,
        strokeDLine,
        titleDeleteTech,
        deleteTech
    } = props;

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title">
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <div className={classes.titleModal}>
                        <Typography variant="h6">{titleForm}</Typography>
                        <IconButton className={classes.closeButton} aria-label="close" onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    {
                        indicator === "SMA" || indicator === `EMA` ?
                            <FormEMASMA
                                lang={lang.smaEma}
                                classes={classes}
                                handleChange={handleChange}
                                handleChangeComplete={handleChangeComplete}
                                period={period}
                                strokeColorSMA={strokeColorSMA}
                            />
                            : indicator === `MACD` ?
                                <FormMACD
                                    lang={lang.macd}
                                    classes={classes}
                                    handleChange={handleChange}
                                    handleChangeComplete={handleChangeComplete}
                                    fastMACD={fastMACD}
                                    slowMACD={slowMACD}
                                    signalMACD={signalMACD}
                                    colorStrokeMACD={colorStrokeMACD}
                                />
                                : indicator === `GANNFAN` ?
                                    <FormGannFan
                                        lang={lang.gannFan}
                                        classes={classes}
                                        strokeColorGannFan={strokeColorGannFan}
                                        alphaGannFan={alphaGannFan}
                                        strokeGannFanWidth={strokeGannFanWidth}
                                        handleChange={handleChange}
                                        handleChangeComplete={handleChangeComplete}
                                    />
                                    : indicator === `FB` ?
                                        <FormFibonacci
                                            lang={lang.fibonacci}
                                            classes={classes}
                                            handleChange={handleChange}
                                            handleChangeComplete={handleChangeComplete}
                                            typeLineFB={typeLineFB}
                                            colorStrokeFB={colorStrokeFB}
                                            alphaStokeFB={alphaStokeFB}
                                            strokeWidthFB={strokeWidthFB}
                                            colorFontFill={colorFontFill}
                                        />
                                        : indicator === `TR` ?
                                            <FormTR
                                                lang={lang.tr}
                                                classes={classes}
                                                handleChange={handleChange}
                                                handleChangeComplete={handleChangeComplete}
                                                colorStrokeTR={colorStrokeTR}
                                                strokeOpacityTR={strokeOpacityTR}
                                                strokeWidthTR={strokeWidthTR}
                                                fillOpacity={fillOpacity}
                                                fillTR={fillTR}
                                            />
                                            :
                                            indicator === `RSI` ?
                                                <FormRSI
                                                    lang={lang.rsi}
                                                    classes={classes}
                                                    periodRSI={periodRSI}
                                                    handleChange={handleChange}
                                                    handleChangeComplete={handleChangeComplete}
                                                    upperBandRSI={upperBandRSI}
                                                    middleRSI={middleRSI}
                                                    lowerRSI={lowerRSI}
                                                    colorRSI={colorRSI}
                                                    colorInsideRSI={colorInsideRSI}
                                                    colorMiddleRSI={colorMiddleRSI}
                                                    colorOverBoughtRSI={colorOverBoughtRSI}
                                                    colorOverSoldRSI={colorOverSoldRSI}
                                                    colorOutsideRSI={colorOutsideRSI}
                                                />
                                                : indicator === `drawLine` ?
                                                    <FormDraw
                                                        lang={lang.drawnLine}
                                                        optionsLang={optionsDraw}
                                                        classes={classes}
                                                        handleChange={handleChange}
                                                        handleChangeComplete={handleChangeComplete}
                                                        strokeOpacityLine={strokeOpacityLine}
                                                        strokeTrendingLine={strokeTrendingLine}
                                                        strokeWidthTrending={strokeWidthTrending}
                                                        typeTrendingLine={typeTrendingLine}
                                                        styleTrendingLine={styleTrendingLine}
                                                    />
                                                    : indicator === `BBands` ?
                                                        <FormBBands
                                                            lang={lang.bbands}
                                                            classes={classes}
                                                            windowSizeBBand={windowSizeBBand}
                                                            multiplierBBand={multiplierBBand}
                                                            handleChange={handleChange}
                                                            maTypeBBand={maTypeBBand}
                                                        />
                                                        : indicator === `Volume` ?
                                                            <FormVolume
                                                                lang={lang.volume}
                                                                classes={classes}
                                                                handleChangeComplete={handleChangeComplete}
                                                                colorUpVolume={colorUpVolume}
                                                                colorDownVolume={colorDownVolume}
                                                                OpacityVolume={OpacityVolume}
                                                            />
                                                            : indicator === `Stochastic` ?
                                                                <FormStochastic
                                                                    lang={lang.stochastic}
                                                                    classes={classes}
                                                                    periodStochastic={periodStochastic}
                                                                    kWindowSizeStochastic={kWindowSizeStochastic}
                                                                    dWindowSizeStochastic={dWindowSizeStochastic}
                                                                    handleChange={handleChange}
                                                                    activeSlowStochastic={activeSlowStochastic}
                                                                    activeFastStochastic={activeFastStochastic}
                                                                    activeFullStochastic={activeFullStochastic}
                                                                    handleChangeComplete={handleChangeComplete}
                                                                    strokeKLine={strokeKLine}
                                                                    strokeDLine={strokeDLine}
                                                                />
                                                                : indicator === `delete` ?
                                                                    <FormDelete lang={titleDelete} />
                                                                    : indicator === 'deleteTechnical' ?
                                                                    <FormDelete lang={titleDeleteTech} />
                                                                    :''
                    }
                </DialogContent>
                <DialogActions>
                    <ButtonBase
                        className="outline"
                        onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimesCircle} className={classes.iconBtn} />
                        {cancel}
                    </ButtonBase>
                    <ButtonBase
                        onClick={() => indicator === `delete` ? remove() : indicator === 'deleteTechnical' ? deleteTech(): createIndicator()}
                        className="primary ml-1">
                        <FontAwesomeIcon icon={faCheckCircle} className={classes.iconBtn} />
                        {accept}
                    </ButtonBase>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withStyles(styles)(FormDialog);