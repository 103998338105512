import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { FormControl, Typography, Box, FormHelperText} from '@material-ui/core';
import { ButtonBase } from '../../../uielements/button/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DialogActions from '@material-ui/core/DialogActions';

//Autocomplete
import Autosuggest from 'react-autosuggest';
import RicSuggestionCard from '../../Cards/RicSuggestionCard/RicSuggestionCard';

//Styles 
import { withStyles } from '@material-ui/core/styles';
import { InputLabel , Snackbars, IconButton, Upload, DateDIV} from '../Forms'
import { StyledFormTextField } from '../../../uielements/textfield/StyledFormTextField'
import styles from '../forms-jss';

//Icons
import { faTimesCircle, faSave } from '@fortawesome/free-regular-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import iconoFile from '../../../../assets/img/icons/file.svg';
import Icon from '@material-ui/core/Icon';


//Language
import { LanguageConsumer, LanguageCxt } from '../../../language/LanguageContext';

//Services 
import { getBookmark } from '../../../services/file-s3'; 
import { searchRic, validateRic, searchRicSymbol } from '../../../services/index';
import { getTotalFileSize, getIconClass } from '../../../utils/helpers';

//Autocomplete
const getSuggestions = (array, value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : array.filter(ric =>
        ric.mnemonic.toLowerCase().slice(0, inputLength) === inputValue || ric.cfname.toLowerCase().slice(0, inputLength) === inputValue  || ric.mainIndexCommonNam.toLowerCase().slice(0, inputLength) === inputValue
    );
};

const getSuggestionValue = suggestion => {
    return suggestion.assetgroupid === 2 ? suggestion.mnemonic : suggestion.assetgroupid === 6 ? suggestion.mainIndexCommonNam : suggestion.cfname;
};

const renderSuggestion = suggestion => (
    <RicSuggestionCard ric={suggestion}/>
);

//End Autocomplete

const maxSize = 12288; 

class UploadRecommendationForm extends React.Component {
    
    constructor(props){
        super(props); 

        this.state = {
            //FORM
            nameFile: '',
            source: '',
            recommendation: '',
            analyst: '',
            date: null,
            ric: '',
            //Date picker
            openDatePicker: false,
            dateError: false,
            //FILES
            filesToUpload: [], 
            files: [],
            filesRaw: [],
            errFiles: "",
            //RICS autocomplete
            rics: [], 
            value: '',
            suggestions: [],
            ricError: false,
            ricErrorMsg: ''
        }

        this.fileInput = React.createRef();
        this.form = React.createRef();
    }

    //----------------------------- Lifecycle
    componentDidMount(){
        ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
            if (/^\s+$/i.test(value)) {
                return false;
            }
            return true;
          });

        this.setState({rics: this.props.rics});
    }
    //----------------------------- End Lifecycle

    //----------------------------- Inputs
    //General
    handleChange = (event) => {
        if(!/^[a-z0-9 ]+$/gi.test(event.target.value.toLowerCase()) && event.target.value !== ''){
            return;
        }

        this.setState({ [event.target.name]: event.target.value });
    }

    //Datepicker
    handleChangeDate = (date) => {
        this.setState({date: date, openDatePicker: false, dateError: false});
    }

    handleClickDate = (open) => {
        this.setState({ openDatePicker: open })
    }

    //Autosuggest
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({ suggestions: getSuggestions(this.state.rics, value) });
    };
    
    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onChange = (event, { newValue }) => {
        this.setState({ value: newValue, ricError: false, ricErrorMsg: '' });
    };

    //File
    handleFile = (event) => {
        let file = event.target.files[0]; //New file
        if(!file){
            return;
        }

        let files = [...this.state.files]; //Copy files
        
        let extension = file.name.split("."); 
        extension = extension[extension.length - 1];

        let _newDate = new Date(this.state.date).setHours(0,0,0,0);
        
        let completeFile = {
            idFile: files.length + 1,
            name: file.name,
            title: this.state.nameFile, 
            datePublication: _newDate,
            typeFile: getBookmark(extension),
            description: '',
            ric: this.state.value,
            content: '',
            dateUpload: new Date().setHours(0,0,0,0), 
            file: file, 
            size: file.size / 1000,
            instrument: searchRicSymbol(this.state.value, this.state.rics),
            isRecommendation: 1,
            recommendation: this.state.recommendation,
            analyst: this.state.analyst,
            source: this.state.source
        }

        files.push(completeFile);
        this.setState({ files: files });
        this.clearForm();
    }
    //----------------------------- End Inputs

    //----------------------------- Form
    handleFormSubmit = () => {
        let err = false, errRic = false, errRicMsg = '', errForm = false, errors = [];

        for(let i in this.form.childs){
            let child = this.form.childs[i];
            if(child.state.isValid){
                this.form.childs[i].validate(this.state[child.props.name], true)
            }
            else{
                errors.push(child);
            }

            if(this.state[child.props.name] === ""){
                errors.push(child);
            }
        }

        errForm = errors.length > 0 ? true : false;

        setTimeout(() => {
            for(let i in this.form.childs){
                if(!this.form.childs[i].state.isValid){
                    errors.push(this.form.childs[i]);
                }
            }

            errForm = errors.length > 0 ? true : false;
        }, 5);

        
        if(!this.state.date || this.state.date === ""){
            err = true;
        }

        if(!this.state.value || this.state.value === ""){
            errRic = true;
            errRicMsg = 'instrumentRequired';
        }

        if(this.state.value !== ''){
            errRic = validateRic(this.state.value, this.state.rics) === undefined ? true : false;
            errRicMsg = 'invalidRic';
        }

        this.setState({ dateError: err, ricError: errRic, ricErrorMsg: errRicMsg });

        errForm = errors.length > 0 ? true : false; 

        if(!err && !errRic && !errForm){
            //this.fileInput.current.click();
            document.getElementById("filesPickerRec").click();
        }
    }

    deleteFiles = (file) => {
        let auxFiles = [...this.state.files];
        let auxIndex = auxFiles.findIndex(f => f.idFile === file.idFile);

        if(auxIndex > -1){
            auxFiles.splice(auxIndex, 1);
        }

        this.setState({files: auxFiles});
    }

    uploadFiles = () => {

        //Return if there is no submit
        if(!this.props.handleSubmit){
            return false;
        }

        if(this.state.files.length <= 0){
            this.setState({errFiles: "noFiles"});
            return;
        }

        if(getTotalFileSize(this.state.files, maxSize)){
            this.setState({errFiles: "maxLimit"});
            return;
        }

        this.props.handleSubmit(this.state.files);
    }
    //----------------------------- End Form

    //----------------------------- Helpers
    clearForm = () => {
        this.setState({
            nameFile: '',
            date: null,
            ric: '',
            analyst: '',
            recommendation: '',
            source: '',
            value: '',
            dateError: false, 
            ricError: false,
            ricErrorMsg: ''
        }, () => { })
    }

    emptySubmit = () => {

    }
    //----------------------------- End Helpers

    render(){
        const { classes, handleCancel } = this.props; 
        const { nameFile, source, recommendation, analyst, date, suggestions, value, files } = this.state;

        const inputProps = {
            value,
            onChange: this.onChange,
            maxLength: "15"
        };

        return (
            <LanguageConsumer>
                {
                    ({lang}) => 
                    <React.Fragment>
                        <div style={{ flexGrow: 1 }}>
                            <ValidatorForm onSubmit={() => this.emptySubmit()} ref={form => (this.form = form)}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink htmlFor="name">
                                                {lang.fileManager.uploadFilesForm.Name}*
                                            </InputLabel>
                                            <StyledFormTextField 
                                                id="nameFile"
                                                name="nameFile"
                                                value={nameFile}
                                                onChange={this.handleChange}
                                                InputLabelProps={{ shrink: true }}
                                                validators={['required', 'isSpaceEmpty', 'maxStringLength: 50']}
                                                errorMessages={[lang.fileManager.uploadFilesForm.nameFileRequired, lang.fileManager.uploadFilesForm.nameFileRequired, lang.fileManager.uploadFilesForm.maxLengthNameFile,]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink htmlFor="source">
                                                {lang.fileManager.uploadFilesForm.Source}*
                                            </InputLabel>
                                            <StyledFormTextField 
                                                id="source"
                                                name="source"
                                                value={source}
                                                onChange={this.handleChange}
                                                InputLabelProps={{ shrink: true }}
                                                validators={['required', 'isSpaceEmpty']}
                                                errorMessages={[lang.fileManager.uploadFilesForm.sourceRequired, lang.fileManager.uploadFilesForm.sourceRequired]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl className={classes.suggestionWrap} error={this.state.ricError}>
                                            <InputLabel shrink htmlFor="symbol">
                                                {lang.fileManager.uploadFilesForm.issuer}*
                                            </InputLabel>
                                            <Autosuggest
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                getSuggestionValue={getSuggestionValue}
                                                renderSuggestion={renderSuggestion}
                                                inputProps={inputProps}
                                                theme={{
                                                    input: classes.autocompleteInput,
                                                    suggestionsContainerOpen: classes.suggestionsContainerOpenRight,
                                                    suggestionsList: classes.suggestionsList,
                                                    suggestion: classes.suggestion,
                                                }}
                                            />
                                            {
                                                this.state.ricError &&
                                                <FormHelperText>{lang.fileManager.uploadFilesForm[this.state.ricErrorMsg]}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink htmlFor="recommendation">
                                                {lang.fileManager.uploadFilesForm.Rating}*
                                            </InputLabel>
                                            <StyledFormTextField 
                                                id="recommendation"
                                                name="recommendation"
                                                value={recommendation}
                                                onChange={this.handleChange}
                                                validators={['required', 'isSpaceEmpty']}
                                                errorMessages={[lang.fileManager.uploadFilesForm.recommendationRequired, lang.fileManager.uploadFilesForm.recommendationRequired]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink htmlFor="analyst">
                                                {lang.fileManager.uploadFilesForm.Analyst}*
                                            </InputLabel>
                                            <StyledFormTextField 
                                                id="analyst"
                                                name="analyst"
                                                value={analyst}
                                                onChange={this.handleChange}
                                                validators={['required', 'isSpaceEmpty']}
                                                errorMessages={[lang.fileManager.uploadFilesForm.analystRequired, lang.fileManager.uploadFilesForm.analystRequired]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl className={classes.w100} error={this.state.dateError}>
                                            <InputLabel shrink htmlFor="date">
                                                {lang.fileManager.uploadFilesForm.date}*
                                            </InputLabel>
                                            <DateDIV>
                                                <MuiPickersUtilsProvider utils={MomentUtils}> 
                                                    <DatePicker 
                                                        allowKeyboardControl={false}
                                                        disableToolbar
                                                        variant="inline"
                                                        format="DD/MM/YYYY"
                                                        id="date-picker-inline"
                                                        value={date}
                                                        onChange={this.handleChangeDate}
                                                        disableFuture={true}
                                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                        placeholder=""
                                                        open={this.state.openDatePicker}
                                                        onOpen={() => this.handleClickDate(true)}
                                                        onClose={() => this.handleClickDate(false)}
                                                    /> 
                                                </MuiPickersUtilsProvider> 
                                            </DateDIV>
                                            {
                                                this.state.dateError && <FormHelperText>{lang.fileManager.uploadFilesForm.dateRequired}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <Upload>
                                            <img  src={iconoFile} alt="file"/>
                                            <Typography className='text' component="p" >
                                                {lang.fileManager.uploadFilesForm.selectFile}
                                            </Typography>
                                            
                                            <ButtonBase  className='secondary' onClick={() => this.handleFormSubmit()}>
                                                {lang.fileManager.uploadFilesForm.selectFileButton}
                                            </ButtonBase>

                                            {/** File input */}
                                            <input
                                                type="file"
                                                name="filesRaw"
                                                value={this.state.filesRaw}
                                                onChange={this.handleFile}
                                                hidden
                                                id="filesPickerRec"
                                            />
                                        </Upload>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                            <Grid container spacing={3}>
                                <Grid item xs={12}> 
                                    {
                                        files.length > 0 &&
                                        <Typography variant="h6" component="h6" >
                                            {lang.fileManager.uploadFilesForm.files}
                                        </Typography>
                                    }
                                    {
                                        this.state.files.map((file, index) => 
                                        <Snackbars key={`fileUR_${index}`}>
                                            <Box className='img'>
                                                <Icon className={getIconClass(file.typeFile)}></Icon>
                                            </Box>
                                            <Box className='data'>
                                                <Typography className='name' component="p" >
                                                    {file.title}
                                                </Typography>
                                                <Typography className='freight' component="p">
                                                    {file.size} KB
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IconButton onClick={() => this.deleteFiles(file)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />        
                                                </IconButton>
                                            </Box>
                                        </Snackbars>
                                        )
                                    }
                                    
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        this.state.errFiles !== '' &&
                                        <Typography variant="caption" display="block" gutterBottom color="error">
                                            { this.state.errFiles === 'noFiles' ? lang.fileManager.uploadFilesForm.minFiles : lang.fileManager.uploadFilesForm.fileLimit}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        <DialogActions>
                            {/**Action buttons */} 
                            <ButtonBase className="outline" onClick={() => handleCancel()} type="button">
                                <FontAwesomeIcon icon={faTimesCircle} />  
                                {lang.fileManager.uploadFilesForm.cancel}
                            </ButtonBase> 
                            <ButtonBase className='primary ml-1' type="button" onClick={() => this.uploadFiles()}>
                                <FontAwesomeIcon icon={faSave} /> 
                                {lang.fileManager.uploadFilesForm.accept}
                            </ButtonBase>   
                        </DialogActions>
                    </React.Fragment>
                }
            </LanguageConsumer>
        )
    }
}

UploadRecommendationForm.contextType = LanguageCxt;

UploadRecommendationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func
}

UploadRecommendationForm.defaultProps = {
    handleCancel: () => {}
}

export default withStyles(styles)(UploadRecommendationForm);