import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Box, Typography, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

//Language
import { LanguageConsumer } from '../../language/LanguageContext';

//Styles
import styles from './filemanager-jss';

const useStyles = makeStyles(styles);

function DrawerContent(props) {
    const classes = useStyles();
    const {file, handleClick} = props;

    const getRecommendation = (file) => {
        console.log(file);
    }

    return(
        <LanguageConsumer>
            {
                ({lang}) => 
                <React.Fragment>
                    {/*Drawer Header*/}
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleClick}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </div>

                    {/*Drawer body*/}
                    <Box className={classes.drawerBody}>
                        <Typography variant="h6" component="h6">
                            {file ? !file.title ? '-' : file.title : '-'}
                        </Typography>
                        <Box className={classes.mt1}>
                            <Typography component="span" className={classes.span}>
                                {lang.fileManager.detailContent.created}
                            </Typography>
                            <Typography component="p">
                                {file ? !file.date ? '-' : file.date : '-'}
                            </Typography>
                        </Box>
                        <Box className={classes.mt1}>
                            <Typography component="span" className={classes.span}>
                                {lang.fileManager.detailContent.type}
                            </Typography>
                            <Typography component="p">
                                {file ? !file.type ? '-' : file.type : '-'}
                            </Typography>
                        </Box>
                        {/* <Box className={classes.mt1}>
                            <Typography component="span" className={classes.span}>
                               Tamaño
                            </Typography>
                            <Typography component="p">
                                18mb
                            </Typography>
                        </Box> */}
                        <Box className={classes.mt1}>
                            <Typography component="span" className={classes.span}>
                                {lang.fileManager.detailContent.issuer}
                            </Typography>
                            <Typography component="p">
                                {file ? !file.ric ? '-' : file.ric : ''}
                            </Typography>
                        </Box>
                        {
                            !file ? null : file.isRecommendation !== 1 ? 
                            <Box className={classes.mt1}>
                                <Typography component="span" className={classes.span}>
                                    {lang.fileManager.detailContent.description}
                                </Typography>
                                <Typography component="p">
                                    {file ? !file.description ? '-' : file.description : '-'}
                                </Typography>
                            </Box> : null
                        }
                        
                        {
                            !file ? null : file.isRecommendation === 1 ?
                            <React.Fragment>
                                {/** Recommendation */}
                                <Box className={classes.mt1}>
                                    <Typography component="span" className={classes.span}>
                                        {lang.fileManager.detailContent.recommendation}
                                    </Typography>
                                    <Typography component="p">
                                        {!file.recommendation ? '-' : file.recommendation}
                                    </Typography>
                                </Box>
                                {/** Source */}
                                <Box className={classes.mt1}>
                                    <Typography component="span" className={classes.span}>
                                        {lang.fileManager.detailContent.source}
                                    </Typography>
                                    <Typography component="p">
                                        {file ? !file.source ? '-' : file.source : '-'}
                                    </Typography>
                                </Box>
                                {/** Analyst */}
                                <Box className={classes.mt1}>
                                    <Typography component="span" className={classes.span}>
                                        {lang.fileManager.detailContent.analyst}
                                    </Typography>
                                    <Typography component="p">
                                        {file ? !file.analyst ? '-' : file.analyst : '-'}
                                    </Typography>
                                </Box>
                            </React.Fragment> : null
                        }
                    </Box>
                </React.Fragment>
            }
        </LanguageConsumer>
    )
}

DrawerContent.propTypes = {
    file: PropTypes.object,
    handleClick: PropTypes.func
}

DrawerContent.defaultProps = {
    handleClick: () => {}
}

export default DrawerContent
