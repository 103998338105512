import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { Box, FormControl, Typography, IconButton } from '@material-ui/core';
import DropDown from "../../uielements/dropdown/dropdown";
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import TreeView from "../../uielements/TreeView/TreeView";
import Drawer from '@material-ui/core/Drawer';
import DrawerContent from '../../components/FileManager/DrawerContent';
import FileCard from '../../components/Cards/FileCard/FileCard';
import Alert from '../../components/Message/Alert';
import ReactLoading from 'react-loading';
import { ButtonBase } from '../../uielements/button/ButtonBase';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
//ReactiveSearch
import { ReactiveBase, DataSearch, SelectedFilters, MultiList, MultiDataList, DateRange, ReactiveList, SingleList } from '@appbaseio/reactivesearch';

//FORMS
import CustomDialog from '../../components/Dialog/CustomDialog';
import CreateFolderForm from '../../components/Forms/FilesManager/CreateFolderForm';
import UploadFileForm from '../../components/Forms/FilesManager/UploadFileForm';
import UpdateFileForm from '../../components/Forms/FilesManager/UpdateFileForm';
import UploadRecommendationForm from '../../components/Forms/FilesManager/UploadRecommendationForm';
import UpdateRecommendationForm from '../../components/Forms/FilesManager/UpdateRecommendationForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Language
import { LanguageConsumer, LanguageCxt } from '../../../app/language/LanguageContext';
import l from './../../language/lang';

//Styles
import { withStyles } from '@material-ui/styles';
import styles from './files-jss';
import { MultiSelect, Wrapper, Paginator, BarTop, WrappDate } from './style';

//Icons
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';


//Services
import { createFolder, deleteFolder, getFoldersTree, deleteFile, updateFile, getRootFolder, uploadMultipleFiles, deleteMultipleFiles, changeNameFolder } from '../../services/file-s3';
import { getURLPATH, searchRicSymbol } from '../../services/index';
import { FormatDate } from '../../utils/helpers';
import { setToken, getUserRics, getPermissions } from '../../services/user';
import { download } from '../../services/env';

class FilesManager extends React.Component {

    //------------------- Lifecycle hooks
    state = {
        //Reactivesearch
        urlES: `${getURLPATH()}elastic/`,
        //Dialogs
        openCF: false,
        openUF: false,
        openEF: false,
        openDF: false,
        openMDF: false,
        openUR: false,
        openER: false,
        //Drawer
        openDrawer: false,
        showFilters: false,
        //Rics:
        //SelectedFiles
        selectedFiles: [],
        slctdFileEF: undefined,
        slctdFileDF: undefined,
        slctdFileDetail: undefined,
        //Folder navigation
        currentFolder: `${getRootFolder()}/files`,
        folderTree: { key: `${getRootFolder()}/files`, name: "Inicio", childs: [] },
        //Alert
        openAlert: false,
        typeAlert: `error`,
        messageAlert: ``,
        titleAlert: '',
        titleEdit: '',
        //Loading
        isLoading: false,
        //Rics
        rics: [],
        //Searchs
        searchSensor: '',
        dataS: false,
        stream: false,
        typeFilesOptions: [],
        startDate: '',
        endDate: '',
        //Permissions
        permissions: [],
        canDeleteFile: false,
        canDeleteFolder: false,
        canDownloadFile: false,
        canEditFile: false,
        canEditFolder: false,
        canSearch: false,
        canUploadFile: false,
        canCreateFolder: false
    };

    componentDidMount() {
        this.initFileManager()
    }

    componentDidUpdate() {
        if (this.state.stream) {
            this.setState({ stream: false })
        }
    }
    
    initFileManager = () => {
        let params = this.props.match.params;
        l.setLanguage(`${params.lang}`);
        this.context.changeLang(l);
        this.context.changeTheme(params.theme);

        let { lang } = this.context;

        if (params.token) {
            let hasToken = setToken(params.token, params.refreshToken);
            if (hasToken) {

                //RICS
                getUserRics().then(r => {
                    this.setState({ rics: r, searchSensor: `` });
                }).catch(e => {
                    this.setState({ rics: [] });
                });

                //TREEFOLDER
                this.getFolderTreeMap();

                //PERMISSIONS
                getPermissions().then(r => {
                    let byTypeOptions = [];
                    r.map(p => {
                        switch (p) {
                            case "FILEMANAGER_MARKER_OFFICE":
                                byTypeOptions.push({
                                    label: lang.fileManager.filters.document,
                                    value: "OFFICE"
                                });
                                break;
                            case "FILEMANAGER_MARKER_IMAGES":
                                byTypeOptions.push({
                                    label: lang.fileManager.filters.image,
                                    value: "IMAGE"
                                });
                                break;
                            case "FILEMANAGER_MARKER_PDF":
                                byTypeOptions.push({
                                    label: "PDF",
                                    value: "PDF"
                                });
                                break;
                            case "FILEMANAGER_MARKER_ZIP":
                                byTypeOptions.push({
                                    label: "ZIP",
                                    value: "ZIP"
                                });
                                break;
                            default:
                                break;
                        }
                    });

                    this.setState({
                        permissions: r,
                        canDeleteFile: r.findIndex(r => r === "FILEMANAGER_FILEOPTION_DELETE") > -1 ? true : false,
                        canDeleteFolder: r.findIndex(r => r === "FILEMANAGER_FOLDEROPTION_DELETE") > -1 ? true : false,
                        canDownloadFile: r.findIndex(r => r === "FILEMANAGER_FILEOPTION_DOWNLOAD") > -1 ? true : false,
                        canEditFile: r.findIndex(r => r === "FILEMANAGER_FILEOPTION_RENAME") > -1 ? true : false,
                        canEditFolder: r.findIndex(r => r === "FILEMANAGER_FOLDEROPTION_RENAME") > -1 ? true : false,
                        canSearch: r.findIndex(r => r === "FILEMANAGER_SEARCH") > -1 ? true : false,
                        canUploadFile: r.findIndex(r => r === "FILEMANAGER_ACCESS_UPLOAD_FILES") > -1 ? true : false,
                        canCreateFolder: r.findIndex(r => r === "FILEMANAGER_ACCESS_CREATE_FOLDERS") > -1 ? true : false,
                        typeFilesOptions: byTypeOptions,
                        isLoading: false
                    });
                }).catch(e => {
                    this.setState({
                        permissions: []
                    })
                });
            } else {
                //TODO: Mandar error
            }
        } else {
            //TODO: Mandar error
        }
    }
    //------------------- End Lifecycle hooks

    //------------------- Folder
    createFolder = (file) => {
        this.setState({ openCF: false, isLoading: true });

        const { lang } = this.context;
        // TODO: refresh reactive, volverr a listar carpetas
        createFolder(file, this.state.currentFolder)
        .then(_ => {
            this.getFolderTreeMap();
            this.setState({
                isLoading: false,
                openAlert: true,
                typeAlert: 'success',
                messageAlert: lang.fileManager.createFolderForm.messages['SuccessMsg'],
                titleAlert: lang.fileManager.createFolderForm.messages['SuccessTitle'],
                searchSensor: '',
                currentFolder: this.state.currentFolder,
            })
        })
        .catch(
            err => {
                this.setState({
                    isLoading: false,
                    openAlert: true,
                    typeAlert: 'error',
                    messageAlert: !err ? lang.fileManager.createFolderForm.messages['ErrorMsg'] : err === "-2" ? lang.fileManager.createFolderForm.messages['ErrorMsg'] : lang.fileManager.createFolderForm.messages['ErrorExists'],
                    titleAlert: lang.fileManager.createFolderForm.messages['ErrorTitle'],
                    searchSensor: ''
                })
            }
        )
    }

    getFolderTreeMap = () => {
        let folders = { key: `${getRootFolder()}/files`, name: "Inicio", childs: [] }
        getFoldersTree().then(
            response => {
                if (response.status === "OK") {
                    folders.childs = response.data;
                    this.setState({ folderTree: folders });
                } else {
                    folders.childs = [];
                    this.setState({ folderTree: folders });
                }
            }
        ).catch(
            err => {
                folders.childs = [];
                this.setState({ folderTree: folders });
            }
        )
    }

    changeFolderName = (file) => {
        const oldKey = `${file.folder}/${file.name}/`;
        const newKey = `${file.folder}/${file.newName}/`;

        this.setState({ openEF: false, isLoading: true });
        const { lang } = this.context;

        const data = {
            location: oldKey, 
            newlocation: newKey, 
            file: file, 
            rics: this.state.rics
        }

        changeNameFolder(data).then(
            r => {
                this.getFolderTreeMap();
                this.setState({
                    isLoading: false,
                    openAlert: true,
                    typeAlert: 'success',
                    messageAlert: lang.fileManager.upateFilesForm.messages['SuccessMsg'],
                    titleAlert: lang.fileManager.upateFilesForm.messages['SuccessTitle'],
                    searchSensor: ''
                })
            }
        ).catch(
            err => {
                console.error("Error in:", err);
                this.setState({
                    isLoading: false,
                    openAlert: true,
                    typeAlert: 'error',
                    messageAlert: lang.fileManager.upateFilesForm.messages['ErrorMsg'],
                    titleAlert: lang.fileManager.upateFilesForm.messages['ErrorTitle'],
                    searchSensor: ''
                })
            }
        )
    }

    deleteFolder = (folder) => {
        if (!folder) {
            return;
        }

        const { lang } = this.context;
        deleteFolder(`${folder.folder}/${folder.name}/`, folder).then(
            r => {
                this.getFolderTreeMap();
                this.setState({
                    isLoading: false,
                    openAlert: true,
                    typeAlert: 'success',
                    messageAlert: lang.fileManager.deleteFilesForm.messages['SuccessFolderMsg'],
                    titleAlert: lang.fileManager.deleteFilesForm.messages['SuccessFolderTitle'],
                    searchSensor: ''
                })
            }
        ).catch(
            err => {
                this.setState({
                    isLoading: false,
                    openAlert: true,
                    typeAlert: 'error',
                    messageAlert: lang.fileManager.uploadFilesForm.messages['ErrorMsg'],
                    titleAlert: lang.fileManager.uploadFilesForm.messages['ErrorFolderTitle'],
                    searchSensor: ''
                })
            }
        )
    }
    //------------------- End folder

    //------------------- File
    uploadFiles = (files) => {
        if (!files) {
            return;
        }

        if (files.length <= 0) {
            return;
        }

        //TODO: refrescar reactivesearch
        this.setState({ openUF: false, isLoading: true, openUR: false });
        const { lang } = this.context;
        uploadMultipleFiles(files, this.state.currentFolder).then(
            response => {
                let error = response.errors.length > 0 ? true : false;

                this.setState({
                    // isLoading: false,
                    openAlert: true,
                    typeAlert: error ? 'error' : 'success',
                    messageAlert: error ? lang.fileManager.uploadFilesForm.messages['ErrorMsg'] : lang.fileManager.uploadFilesForm.messages['SuccessMsg'],
                    titleAlert: error ? lang.fileManager.uploadFilesForm.messages['ErrorTitle'] : lang.fileManager.uploadFilesForm.messages['SuccessTitle'],
                    searchSensor: ' '
                }, () => {
                    this.componentDidMount();
                });

                // this.setState({searchSensor: ' '}, () => this.componentDidMount());
            }
        )
    }

    editFile = (file) => {
        if (!file) {
            return;
        }

        if (file.typeFile !== "FOLDER") {
            let key = `${file.folder}/${file.name}`;
            //TODO: refrescar reactivesearch
            this.setState({ openEF: false, isLoading: true, openER: false });
            const { lang } = this.context;

            updateFile(key, file).then(r => {
                this.setState({
                    isLoading: false,
                    openAlert: true,
                    typeAlert: 'success',
                    messageAlert: lang.fileManager.upateFilesForm.messages['SuccessMsg'],
                    titleAlert: lang.fileManager.upateFilesForm.messages['SuccessTitle'],
                    slctdFileEF: undefined,
                    searchSensor: ' '
                });
                this.setState({ searchSensor: '' });
            }).catch(e => {
                console.log("Err: ", e);
                this.setState({
                    isLoading: false,
                    openAlert: true,
                    typeAlert: 'error',
                    messageAlert: lang.fileManager.upateFilesForm.messages['ErrorMsg'],
                    titleAlert: lang.fileManager.upateFilesForm.messages['ErrorTitle'],
                    slctdFileEF: undefined,
                    searchSensor: ' '
                });
                this.setState({ searchSensor: '' });
            });
        } else {
            this.changeFolderName(file);
        }
    }

    openDetail = (file) => {

        if (!file) {
            return;
        }

        const { lang } = this.context;
        if (file.typeFile === 'FOLDER') {
            this.setState({ 
                currentFolder: `${file.folder}/${file.name}`,
                searchSensor: '',
            });
        } else {
            let fileToShow = {
                name: file.name,
                title: file.title,
                ric: file.ric,
                description: file.description,
                date: FormatDate(file.datePublication),
                type: file.typeFile ? lang.fileManager.typeFiles[file.typeFile] : '',
                isRecommendation: file.isRecommendation ? file.isRecommendation : 0,
                recommendation: file.recommendation ? file.recommendation.description : undefined,
                source: file.recommendation ? file.recommendation.source : undefined,
                analyst: file.recommendation ? file.recommendation.analyst : undefined
            }

            this.setState({ 
                openDrawer: true, 
                slctdFileDetail: fileToShow,
                searchSensor: '',
             });
        }
    }

    deleteFile = () => {
        this.setState({ isLoading: true, openDF: false });
        const { lang } = this.context;
        if (this.state.slctdFileDF.typeFile === 'FOLDER') {
            this.deleteFolder(this.state.slctdFileDF);
        } else {
            let key = `${this.state.slctdFileDF.folder}/${this.state.slctdFileDF.name}`;
            deleteFile(key, this.state.slctdFileDF).then(
                r => {
                    this.setState({
                        isLoading: false,
                        openAlert: true,
                        typeAlert: 'success',
                        messageAlert: lang.fileManager.deleteFilesForm.messages['SuccessSingleMsg'],
                        titleAlert: lang.fileManager.deleteFilesForm.messages['SuccessSingleTitle'],
                        slctdFileDF: undefined,
                        searchSensor: '',
                        
                    });
                }
            ).catch(
                e => {
                    this.setState({
                        isLoading: false,
                        openAlert: true,
                        typeAlert: 'error',
                        messageAlert: lang.fileManager.deleteFilesForm.messages['ErrorMsg'],
                        titleAlert: lang.fileManager.deleteFilesForm.messages['ErrorSingleTitle'],
                        slctdFileDF: undefined,
                        searchSensor: ''
                    });
                }
            )
        }
    }

    deleteMultipleFiles = () => {
        if (this.state.selectedFiles.length <= 0) {
            return;
        }

        //TODO: refrescar reactivesearch
        this.setState({ openMDF: false, isLoading: true });
        const { lang } = this.context;
        let auxFiles = [...this.state.selectedFiles];
        deleteMultipleFiles(auxFiles).then(r => {
            let error = r.errors.length > 0 ? true : false;

            this.setState({
                selectedFiles: [],
                isLoading: false,
                openAlert: true,
                typeAlert: error ? 'error' : 'success',
                messageAlert: error ? lang.fileManager.deleteFilesForm.messages['ErrorMsg'] : lang.fileManager.deleteFilesForm.messages['SuccessMultipleMsg'],
                titleAlert: error ? lang.fileManager.deleteFilesForm.messages['ErrorMultipleTitle'] : lang.fileManager.deleteFilesForm.messages['SuccessMultipleTitle'],
                searchSensor: ''
            })
        });
    }

    downloadFile = (file) => {
        //Validations
        if (!file) {
            return;
        }

        if (!file.folder || file.folder === '') {
            return;
        }

        const link = document.createElement('a');
        const folder = file.folder.charAt(0) === "/" ? file.folder : `/${file.folder}`;
        link.href = `${download}${folder}/${encodeURIComponent(file.name)}`;
        link.target = '_blank';
        link.download = file.name;
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }
    //------------------- End File

    //------------------- Helpers
    handleCloseDialog = (dialog) => {
        this.setState({ [dialog]: false });
    }

    handleOpenDialogCF = () => {
        this.setState({ openCF: true });
    }

    handleOpenDialogUF = () => {
        this.setState({ openUF: true });
    }

    handleOpenDialogUR = () => {
        this.setState({ openUR: true });
    }

    handleOpenDialogEF = (file) => {

        let fileToShow = Object.assign({}, file);
        fileToShow.instrument = file.ric;

        this.setState({ openEF: true, slctdFileEF: fileToShow, titleEdit: file.typeFile === 'FOLDER' ? 'titleFolder' : 'title' });
    }

    handleOpenDialogER = (file) => {
        let fileToShow = Object.assign({}, file);
        fileToShow.instrument = file.ric;

        this.setState({ openER: true, slctdFileEF: fileToShow });
    }

    handleCloseDrawer = (open) => {
        this.setState({ openDrawer: open, slctdFileDetail: undefined })
    }

    handleOpenDialogDF = (file) => {
        this.setState({ openDF: true, slctdFileDF: file });
    }

    handleOpenDialogMDF = () => {
        this.setState({ openMDF: true });
    }

    handleCheckItem = (file) => (selected) => {

        let auxSelectedFiles = [...this.state.selectedFiles];
        let auxIndex = auxSelectedFiles.findIndex(f => f.idFile === file.idFile);

        if (selected) {
            if (auxIndex <= -1) {
                auxSelectedFiles.push(file);
            }
        } else {
            if (auxIndex > -1) {
                auxSelectedFiles.splice(auxIndex, 1);
            }
        }

        this.setState({ selectedFiles: auxSelectedFiles });
    }

    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    }

    handleShowFilters = (show) => {
        this.setState({ showFilters: show });
    }

    //TODO: Ponerlos en el mismo método
    onChange = (value, triggerQuery, event) => {
        const val = value;
        // .replace(/[^\w\s]/gi, '');
        this.setState({ searchSensor: val }, () => triggerQuery());
    }

    onChangeFolder = (value, triggerQuery, event) => {
        this.setState({ currentFolder: value }, () => triggerQuery());
    }

    handleClickTreeItem = (item) => {
        if (!item) {
            return;
        }

        item.key = item.key.charAt(item.key.length - 1) === "/" ? item.key.substring(0, item.key.length - 1) : item.key;
        this.setState({ currentFolder: item.key });
    }

    handleChangeDate = (value, triggerQuery, event) => {
        if (value.start !== "" && value.end !== "") {
            let auxStart = new Date(value.start);
            let auxEnd = new Date(value.end);

            value.end = auxStart.getTime() > auxEnd.getTime() ? "" : value.end;
        }

        this.setState({
            startDate: value.start,
            endDate: value.end
        });
    }
    //------------------- End Helpers

    render() {
        const { classes } = this.props;
        const { openCF, openUF, openEF, openDF, openMDF, openUR, openER, urlES, isLoading, selectedFiles } = this.state;

        const getPage = (total, current, direction) => {
            let page = 0;
            if (direction === -1) {
                page = current === 0 ? 0 : current - 1;
            } else {
                page = current === total - 1 ? current : current + 1;
            }

            return page;
        }

        const getOptions = (lang) => {
            let options = [];
            if (this.state.canUploadFile) {
                options.push(
                    { label: lang.fileManager.uploadFile, handler: this.handleOpenDialogUF, key: "openUF" },
                    { label: lang.fileManager.uploadRecommendation, handler: this.handleOpenDialogUR, key: "openUR" },
                );
            }
            if (this.state.canCreateFolder) {
                options.push({ label: lang.fileManager.createFolder, handler: this.handleOpenDialogCF, key: "openCF" });
            }

            return options.length > 0 ?
                (<DropDown
                    id="add-new"
                    name={lang.fileManager.new}
                    options={options}
                    icon={<AddCircleOutline />}
                    disabled={isLoading}
                />) : ''
        }

        return (
            <LanguageConsumer>
                {
                    ({ lang }) =>
                        <div className={classes.root}>
                            <ReactiveBase app="file" url={urlES} className={classes.flexContainer} >
                                <Grid className={clsx(classes.contentWidth, {
                                    [classes.contentWidthShift]: this.state.openDrawer,
                                })}
                                    container>
                                    <Grid item className={clsx(classes.asideLeft, {
                                        [classes.positionLeft]: this.state.showFilters
                                    })}>

                                        <Box className={classes.mb1} display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="h5" component="h5" >
                                                {lang.fileManager.filtersitle}
                                            </Typography>
                                            <Box display={{ xs: 'block', md: 'none' }}>
                                                <IconButton onClick={() => this.handleShowFilters(false)}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Box className={classes.mb1}>
                                            <Box className={classes.heading}>
                                                <Typography variant="h6" component="h6" >
                                                    {lang.fileManager.filters.byRic}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <MultiSelect>
                                                    <MultiList
                                                        componentId="IssuerSensor"
                                                        dataField="ric.keyword"
                                                        title=""
                                                        size={1000}
                                                        sortBy="asc"
                                                        showCheckbox={true}
                                                        showCount={false}
                                                        showSearch={true}
                                                        placeholder={lang.fileManager.filters.search}
                                                        showFilter={true}
                                                        filterLabel={lang.fileManager.filters.ric}
                                                        react={{
                                                            and: ["GeneralSearchSensor", "FolderSensor"]
                                                        }}
                                                        queryFormat="or"
                                                    />
                                                </MultiSelect>
                                            </Box>
                                        </Box>
                                        <Box className={classes.mb1}>
                                            <Box className={classes.heading}>
                                                <Typography variant="h6" component="h6" >
                                                    {lang.fileManager.filters.byDate}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <WrappDate>
                                                    <DateRange
                                                        componentId="DateRangeSensor"
                                                        dataField="datePublication"
                                                        title=""
                                                        placeholder={{
                                                            start: '',
                                                            end: ''
                                                        }}
                                                        focused={false}
                                                        numberOfMonths={2}
                                                        queryFormat="date"
                                                        autoFocusEnd={true}
                                                        showClear={true}
                                                        showFilter={true}
                                                        filterLabel={lang.fileManager.filters.date}
                                                        dayPickerInputProps={{
                                                            dayPickerProps: {
                                                                disabledDays: { after: this.state.endDate === '' ? new Date() : this.state.endDate, before: this.state.startDate }
                                                            },
                                                            inputProps: {
                                                                readOnly: true
                                                            }
                                                        }}
                                                        innerClass={{
                                                            'daypicker-container': 'container',
                                                            'daypicker-overlay': 'overlay'
                                                        }}
                                                        className='customDate'
                                                        value={{
                                                            start: this.state.startDate,
                                                            end: this.state.endDate
                                                        }}
                                                        onChange={this.handleChangeDate}
                                                    />
                                                </WrappDate>
                                            </Box>
                                        </Box>
                                        <Box className={classes.mb1}>

                                            {
                                                this.state.typeFilesOptions.length > 0 &&
                                                <React.Fragment>
                                                    <Box className={classes.heading} display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography variant="h6" component="h6" >
                                                            {lang.fileManager.filters.byType}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <MultiSelect>
                                                            <MultiDataList
                                                                componentId="TypeFileSensor"
                                                                dataField="typeFile.keyword"
                                                                showSearch={false}
                                                                data={
                                                                    this.state.typeFilesOptions
                                                                }
                                                                showCount={false}
                                                                filterLabel={lang.fileManager.filters.fileType}
                                                                showFilter={true}
                                                                queryFormat="or"
                                                            />
                                                        </MultiSelect>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                        </Box>
                                        <Box className={classes.mb1}>
                                            <Box className={classes.heading}>
                                                <Typography variant="h6" component="h6" >
                                                    {lang.fileManager.filters.folder}
                                                </Typography>
                                            </Box>
                                            <TreeView currentFolder={this.state.currentFolder} tree={this.state.folderTree} clickItem={this.handleClickTreeItem}></TreeView>
                                            <MultiSelect>
                                                <SingleList
                                                    componentId="FolderSensor"
                                                    dataField="folder.keyword"
                                                    title=""
                                                    size={1000}
                                                    sortBy="asc"
                                                    showRadio={false}
                                                    showCount={false}
                                                    showSearch={false}
                                                    showFilter={false}
                                                    react={{
                                                        and: ["GeneralSearchSensor"]
                                                    }}
                                                    className='search-hidden'
                                                    value={this.state.currentFolder}
                                                    onChange={this.onChangeFolder}
                                                    name="fileSensor"
                                                    defaultValue={`${getRootFolder()}/files`}
                                                    queryFormat="or"
                                                    // customQuery={
                                                    //     () => {
                                                    //         return "query": {
                                                    //             "term" : {
                                                    //                 "datePublication" : "desc"
                                                    //             }
                                                    //         }
                                                    //     }
                                                    // }
                                                />
                                            </MultiSelect>

                                        </Box>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12} className={classes.GridContent}>
                                        <Box display="flex" flexDirection="column" flexWrap="nowrap" height="100%">
                                            <Box className={classes.mb1} display="flex" justifyContent="flex-end">
                                                <Box display={{ xs: 'block', md: 'none' }} className={classes.mr1} flex="1" >
                                                    <ButtonBase className='secondary' onClick={() => this.handleShowFilters(true)}>
                                                        <FontAwesomeIcon icon={faSlidersH} />
                                                        <span>{lang.fileManager.filters.title}</span>
                                                    </ButtonBase>
                                                </Box>
                                                <Box>
                                                    <form autoComplete="off" className={classes.dFlex} width="100%">
                                                        {
                                                            this.state.canSearch &&
                                                            <FormControl className={classes.mr1}>
                                                                <MultiSelect>
                                                                    <DataSearch
                                                                        componentId="GeneralSearchSensor"
                                                                        dataField={["title", "description", "content"]}
                                                                        title=""
                                                                        placeholder={lang.fileManager.filters.contentSearch}
                                                                        autosuggest={false}
                                                                        highlight={false}
                                                                        highlightField="group_city"
                                                                        queryFormat="or"
                                                                        showFilter={true}
                                                                        filterLabel="General"
                                                                        className='search-input'
                                                                        value={this.state.searchSensor}
                                                                        onChange={this.onChange}
                                                                        name="searchSensor"
                                                                    />
                                                                </MultiSelect>
                                                            </FormControl>
                                                        }
                                                        <FormControl>
                                                            {
                                                                getOptions(lang)
                                                            }
                                                        </FormControl>
                                                    </form>
                                                </Box>
                                            </Box>
                                            <BarTop className={classes.barTop}>
                                                <Box>
                                                    <Typography variant="h6" component="h6" >
                                                        {lang.fileManager.name}
                                                    </Typography>
                                                    <SelectedFilters
                                                        showClearAll={false}
                                                        clearAllLabel="Limpiar"
                                                        onClear={(component, value) => {
                                                            switch (component) {
                                                                case "DateRangeSensor":
                                                                    this.setState({ startDate: "", endDate: "" });
                                                                    break;
                                                                default:
                                                                    break;
                                                            }
                                                        }}
                                                        className='selectFilter'
                                                    />
                                                </Box>
                                                {
                                                    this.state.canDeleteFile && selectedFiles.length > 0 &&
                                                    <IconButton onClick={() => this.handleOpenDialogMDF()}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </IconButton>
                                                }
                                            </BarTop>
                                            <Box height='calc(100% - 120px)'>
                                                {
                                                    isLoading ?
                                                        <div className={classes.load} >
                                                            <ReactLoading type={"bars"} color={"gray"} height={'5%'} width={'5%'} />
                                                        </div> :

                                                        <ReactiveList
                                                            componentId="SearchResult"
                                                            className={classes.wrappList}
                                                            dataField="title"
                                                            size={40}
                                                            pagination
                                                            renderResultStats={
                                                                function (stats) {
                                                                    return (
                                                                        ``
                                                                    )
                                                                }
                                                            }
                                                            paginationAt="bottom"
                                                            react={{
                                                                and: ['IssuerSensor', 'DateRangeSensor', 'TypeFileSensor', 'GeneralSearchSensor', 'FolderSensor'],
                                                            }}
                                                            render={
                                                                ({ loading, data }) => {
                                                                    // let sort = data.sort((a, b) => {
                                                                    //     return b.datePublication - a.datePublication;
                                                                    // })

                                                                    return (
                                                                        <div className={loading || data.length <= 0 ? classes.hiddenList : classes.list}>
                                                                            <Wrapper>
                                                                                {
                                                                                    !this.state.stream ?
                                                                                        data.map((item, index) =>
                                                                                            <FileCard
                                                                                                file={item}
                                                                                                handleDownload={() => this.downloadFile(item)}
                                                                                                handleOpenDetail={() => this.openDetail(item)}
                                                                                                handleEdit={() => this.handleOpenDialogEF(item)}
                                                                                                handleEditRecommendation={() => this.handleOpenDialogER(item)}
                                                                                                handleDelete={() => this.handleOpenDialogDF(item)}
                                                                                                handleCheck={() => this.handleCheckItem(item)}
                                                                                                key={`fileES_${item.idFile}_${index}`}

                                                                                                canDeleteFile={this.state.canDeleteFile}
                                                                                                canDeleteFolder={this.state.canDeleteFolder}
                                                                                                canEditFile={this.state.canEditFile}
                                                                                                canEditFolder={this.state.canEditFolder}
                                                                                                canDownloadFile={this.state.canDownloadFile}
                                                                                            />
                                                                                        ) : ''
                                                                                }
                                                                            </Wrapper>
                                                                        </div>
                                                                    )
                                                                }
                                                            }
                                                            renderPagination={({ pages, totalPages, currentPage, setPage, fragmentName }) => {
                                                                totalPages = Number.isNaN(totalPages) ? 0 : parseInt(totalPages);
                                                                currentPage = parseInt(currentPage);

                                                                const selectPage = Number.isFinite(pages) && (
                                                                    <Paginator>
                                                                        <a className={`paginator-prev ${currentPage === 0 ? 'disabled' : ''}`} disabled={currentPage === 0} onClick={() => setPage(getPage(totalPages, currentPage, -1))}>
                                                                            <ChevronLeft />
                                                                        </a>

                                                                        {
                                                                            Array.from(Array(totalPages).keys()).map((item, index) => {
                                                                                return (<a key={`page_${index}`} className={`paginator-page ${currentPage === item ? 'active' : ''}`} onClick={() => setPage(parseInt(item))}>{item + 1}</a>);
                                                                            })
                                                                        }

                                                                        <a className={`paginator-next ${currentPage === totalPages - 1 ? 'disabled' : ''}`} disabled={currentPage === totalPages - 1} onClick={() => setPage(getPage(totalPages, currentPage, 1))}>
                                                                            <ChevronRight />
                                                                        </a>
                                                                    </Paginator>
                                                                );

                                                                return selectPage;
                                                            }}
                                                            onData={(data, streamData, promotedData, rawData, resultStats) => this.setState({ stream: true, dataS: data })}
                                                            loader={
                                                                <div className={classes.load}>
                                                                    <ReactLoading type={"bars"} color={"gray"} height={'5%'} width={'5%'} />
                                                                </div>
                                                            }
                                                            renderNoResults={
                                                                () => {
                                                                    return (
                                                                        <div className={classes.noData}>
                                                                            <InfoOutlinedIcon />
                                                                            <Typography variant="subtitle1">
                                                                                {lang.fileManager.NoResults}
                                                                            </Typography>
                                                                        </div>
                                                                    )
                                                                }
                                                            }
                                                            sortOptions={
                                                                [
                                                                    {

                                                                        dataField : "datePublication"
                                                                        , sortBy : "desc"
                                                                        , label : "Desc"
                                                                    },
                                                                    {

                                                                        dataField : "datePublication"
                                                                        , sortBy : "asc"
                                                                        , label : "Asc"
                                                                    }
                                                                ]
                                                            }
                                                        />
                                                }

                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {/*Detail drawer*/}
                                <Drawer
                                    className={classes.drawer}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                    variant="persistent"
                                    anchor="right"
                                    open={this.state.openDrawer}
                                >
                                    <DrawerContent
                                        handleClick={() => this.handleCloseDrawer(false)}
                                        file={this.state.slctdFileDetail}
                                    />
                                </Drawer>
                            </ReactiveBase>

                            {/** Create Folder modal */}
                            <CustomDialog
                                open={openCF}
                                handleClose={() => this.handleCloseDialog("openCF")}
                                title={lang.fileManager.createFolderForm.title}
                                showActions={false}
                            >
                                <CreateFolderForm
                                    handleSubmit={this.createFolder}
                                    handleCancel={() => this.handleCloseDialog("openCF")}
                                />
                            </CustomDialog>

                            {/** Upload File modal */}
                            <CustomDialog
                                open={openUF}
                                handleClose={() => this.handleCloseDialog("openUF")}
                                title={lang.fileManager.uploadFilesForm.title}
                                showActions={false}
                            >
                                <UploadFileForm
                                    handleSubmit={this.uploadFiles}
                                    handleCancel={() => this.handleCloseDialog("openUF")}
                                    rics={this.state.rics}
                                />
                            </CustomDialog>

                            {/** Upload Recommendation modal */}
                            <CustomDialog
                                open={openUR}
                                handleClose={() => this.handleCloseDialog("openUR")}
                                title={lang.fileManager.uploadFilesForm.titleRecommendation}
                                showActions={false}
                            >
                                <UploadRecommendationForm
                                    handleSubmit={this.uploadFiles}
                                    handleCancel={() => this.handleCloseDialog("openUR")}
                                    rics={this.state.rics}
                                />
                            </CustomDialog>

                            {/** Update File modal */}
                            <CustomDialog
                                open={openEF}
                                handleClose={() => this.handleCloseDialog("openEF")}
                                title={lang.fileManager.upateFilesForm[this.state.titleEdit]}
                                showActions={false}
                            >
                                <UpdateFileForm
                                    handleSubmit={this.editFile}
                                    handleCancel={() => this.handleCloseDialog("openEF")}
                                    file={this.state.slctdFileEF}
                                    rics={this.state.rics}
                                />
                            </CustomDialog>

                            {/** Update Recommendation modal */}
                            <CustomDialog
                                open={openER}
                                handleClose={() => this.handleCloseDialog("openER")}
                                title={lang.fileManager.upateFilesForm.titleRecommendation}
                                showActions={false}
                            >
                                <UpdateRecommendationForm
                                    handleSubmit={this.editFile}
                                    handleCancel={() => this.handleCloseDialog("openER")}
                                    file={this.state.slctdFileEF}
                                    rics={this.state.rics}
                                />
                            </CustomDialog>

                            {/*Delete File Modal*/}
                            <CustomDialog
                                open={openDF}
                                handleClose={() => this.handleCloseDialog("openDF")}
                                title={lang.fileManager.deleteFilesForm.title}
                                handleCancel={() => this.handleCloseDialog("openDF")}
                                handleAccept={this.deleteFile}
                                cancelLabel={lang.fileManager.deleteFilesForm.cancel}
                                acceptLabel={lang.fileManager.deleteFilesForm.accept}
                            >
                                <Typography variant="h5" component="h5" >
                                    {lang.fileManager.deleteFilesForm.message}
                                </Typography>
                            </CustomDialog>

                            {/*Delete Multiple File Modal*/}
                            <CustomDialog
                                open={openMDF}
                                handleClose={() => this.handleCloseDialog("openMDF")}
                                title={lang.fileManager.deleteFilesForm.titleMultiple}
                                handleCancel={() => this.handleCloseDialog("openMDF")}
                                handleAccept={this.deleteMultipleFiles}
                                cancelLabel={lang.fileManager.deleteFilesForm.cancel}
                                acceptLabel={lang.fileManager.deleteFilesForm.accept}
                            >
                                <Typography variant="h7" component="h7" className={classes.mb1}>
                                    {lang.fileManager.deleteFilesForm.messageMultiple}
                                </Typography>
                            </CustomDialog>

                            <Alert
                                open={this.state.openAlert}
                                variant={this.state.typeAlert}
                                message={this.state.messageAlert}
                                title={this.state.titleAlert}
                                onClose={() => this.handleCloseAlert()}
                            />
                        </div>
                }
            </LanguageConsumer>
        )
    }
}

FilesManager.contextType = LanguageCxt;

export default withStyles(styles)(FilesManager)