import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { FormControl, Typography, Box, FormHelperText} from '@material-ui/core';
import { ButtonBase } from '../../../uielements/button/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DialogActions from '@material-ui/core/DialogActions';

//Autocomplete
import Autosuggest from 'react-autosuggest';
import RicSuggestionCard from '../../Cards/RicSuggestionCard/RicSuggestionCard';

//Styles 
import { withStyles } from '@material-ui/core/styles';
import { InputLabel , Snackbars, IconButton, Upload, DateDIV} from '../Forms'
import { StyledFormTextField, StyledFormTextArea } from '../../../uielements/textfield/StyledFormTextField'
import styles from '../forms-jss';

//Icons
import { faTimesCircle, faSave } from '@fortawesome/free-regular-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import iconoFile from '../../../../assets/img/icons/file.svg';
import Icon from '@material-ui/core/Icon';


//Language
import { LanguageConsumer, LanguageCxt } from '../../../language/LanguageContext';

//Services 
import { getBookmark, getRootFolder } from '../../../services/file-s3'; 
import { validateRic, searchRicSymbol } from '../../../services/index';
import { getTotalFileSize, getIconClass } from '../../../utils/helpers';

//Autocomplete
const getSuggestions = (array, value) => {
    if(!array || !value){
        return [];
    }
    
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : array.filter(ric =>
        ric.mnemonic.toLowerCase().slice(0, inputLength) === inputValue || ric.cfname.toLowerCase().slice(0, inputLength) === inputValue  || ric.mainIndexCommonNam.toLowerCase().slice(0, inputLength) === inputValue
    );
};

const getSuggestionValue = suggestion => {
    return suggestion.assetgroupid === 2 ? suggestion.mnemonic : suggestion.assetgroupid === 6 ? suggestion.mainIndexCommonNam : suggestion.cfname;
};

const renderSuggestion = suggestion => (
    <RicSuggestionCard ric={suggestion}/>
);

//End Autocomplete

const maxSize = 10288; // 12288 = 12 MB 

//Validate form
const validateForm = async(form) => {
    let result = await form.walk(form.childs);
    console.log(result);
    return result;
}

class UploadFileForm extends React.Component {

    constructor(props){
        super(props); 

        this.state = {
            //FORM
            title: '',
            date: null,
            ric: '',
            description: '',
            files: [],
            filesRaw: [],
            openDatePicker: false,
            dateError: false,
            errFiles: "",
            //FILES
            filesToUpload: [], 
            //RICS autocomplete
            rics: [], 
            value: '',
            rawValue: '',
            suggestions: [],
            ricError: false,
            //Folder Navigation
            currentFolder: `/${getRootFolder()}/`
        }

        //this.fileInput = React.createRef();
        this.form = React.createRef();
    }

    //----------------------------- Lifecycle
    componentDidMount(){
        ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
            if (/^\s+$/i.test(value)) {
                return false;
            }
            return true;
          });

        this.setState({rics: this.props.rics});
    }
    //----------------------------- End Lifecycle

    //----------------------------- Inputs
    handleChange = (event) => {
            if(!/^[a-z0-9 ÁÉÍÚÓáéíóú.,-_!#$%&/()=?¡¿]+$/gi.test(event.target.value.toLowerCase()) && event.target.value !== ''){
            return;
        }
        this.setState({ [event.target.name]: event.target.value });
    }

    onChange = (event, { newValue }) => {
        this.setState({ value: newValue});
    };

    handleChangeDate = (date) => {
        this.setState({date: date, openDatePicker: false, dateError: false});
    }

    handleClickDate = (open) => {
        this.setState({ openDatePicker: open })
    }

    handleFile = (event) => {
        let file = event.target.files[0]; //New file
        if(!file){
            return;
        }

        let files = [...this.state.files]; //Copy files
        
        let extension = file.name.split("."); 
        extension = extension[extension.length - 1];

        let _newDate = new Date(this.state.date).setHours(0,0,0,0);
        
        let completeFile = {
            idFile: files.length + 1,
            name: file.name,
            title: this.state.title, 
            datePublication: _newDate,
            typeFile: getBookmark(extension),
            folder: this.state.currentFolder,
            description: this.state.description ? this.state.description : '',
            ric: this.state.value,
            content: '',
            dateUpload: new Date().setHours(0,0,0,0), 
            file: file, 
            size: file.size / 1000,
            instrument: searchRicSymbol(this.state.value, this.state.rics),
            isRecommendation: 0
        }

        files.push(completeFile);
        this.setState({ files: files });
        this.clearForm();
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({ suggestions: getSuggestions(this.state.rics, value) });
    };
    
    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };
    //----------------------------- End Inputs

    //----------------------------- Submit
    handleFormSubmit = () => {
        let err = false, errRic = false, errTitle = false;

        if(this.form.childs[0].state.isValid){
            //Validate title
            this.form.childs[0].validate(this.state.title, true);
    
            //Wait for validation to refresh
            setTimeout(() => {
                errTitle = !this.form.childs[0].state.isValid;
            }, 5);
        }else{
            errTitle = !this.form.childs[0].state.isValid;
        }

            
        if(!this.state.date || this.state.date === ""){
            err = true;
        }

        if(this.state.value !== ''){
            errRic = validateRic(this.state.value, this.state.rics) === undefined ? true : false;
        }

        if(this.state.title === ""){
            errTitle = true;
        }

        this.setState({ dateError: err, ricError: errRic });

        if(!err && !errRic && !errTitle){
            document.getElementById("filesPicker").click();
        }
    }

    uploadFiles = () => {

        //Return if there is no submit
        if(!this.props.handleSubmit){
            return false;
        }

        if(this.state.files.length <= 0){
            this.setState({errFiles: "noFiles"});
            return;
        }

        if(getTotalFileSize(this.state.files, maxSize)){
            this.setState({errFiles: "maxLimit"});
            return;
        }

        this.props.handleSubmit(this.state.files);
    }

    deleteFiles = (file) => {
        let auxFiles = [...this.state.files];
        let auxIndex = auxFiles.findIndex(f => f.idFile === file.idFile);

        if(auxIndex > -1){
            auxFiles.splice(auxIndex, 1);
        }

        this.setState({files: auxFiles});
    }
    //----------------------------- Submit

    //----------------------------- Helpers

    clearForm = () => {
        this.setState({
            title: '',
            date: null,
            ric: '',
            description: '',
            value: '',
            rawValue: '',
            dateError: false, 
            ricError: false
        }, () => { })
    }

    emptySubmit = () => {

    }
    //----------------------------- Helpers

    render(){
        const { classes, handleCancel } = this.props; 
        const { value, suggestions, files } = this.state;

        const inputProps = {
            value,
            onChange: this.onChange,
            maxLength: "15"
        };
        
        return(
            <LanguageConsumer>
                {
                    ({lang}) => 
                    <React.Fragment>
                        <div style={{ flexGrow: 1 }}> 
                            <ValidatorForm onSubmit={() => this.emptySubmit()} ref={form => (this.form = form)}>
                                <Grid container spacing={3}>
                                    <Grid item sm={4} xs={12}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink htmlFor="title">
                                                {lang.fileManager.uploadFilesForm.titleName}*
                                            </InputLabel>
                                            <StyledFormTextField 
                                                id="title"
                                                name="title"
                                                value={this.state.title}
                                                onChange={this.handleChange}
                                                validators={['required', 'isSpaceEmpty', 'maxStringLength: 50']}
                                                errorMessages={[lang.fileManager.uploadFilesForm.titleRequired, lang.fileManager.uploadFilesForm.titleRequired, lang.fileManager.uploadFilesForm.maxLengthTitle]}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={4} xs={6}>
                                        <FormControl className={classes.suggestionWrap} error={this.state.ricError}>
                                            <InputLabel shrink htmlFor="symbol">
                                            {lang.fileManager.uploadFilesForm.issuer}
                                            </InputLabel>
                                            {/* <Input id="symbol"/> */}
                                            <Autosuggest
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                getSuggestionValue={getSuggestionValue}
                                                renderSuggestion={renderSuggestion}
                                                inputProps={inputProps}
                                                theme={{
                                                    input: classes.autocompleteInput,
                                                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                                    suggestionsList: classes.suggestionsList,
                                                    suggestion: classes.suggestion,
                                                }}
                                            />
                                            {
                                                this.state.ricError &&
                                                <FormHelperText>{lang.fileManager.uploadFilesForm.invalidRic}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={4} xs={6}>
                                        <FormControl error={this.state.dateError}>
                                            <InputLabel shrink htmlFor="date">
                                                {lang.fileManager.uploadFilesForm.date}*
                                            </InputLabel>
                                            <DateDIV>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        allowKeyboardControl={false}
                                                        disableToolbar
                                                        variant="inline"
                                                        format="DD/MM/YYYY"
                                                        id="date-picker-inline"
                                                        value={this.state.date}
                                                        onChange={this.handleChangeDate}
                                                        disableFuture={true}
                                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                        placeholder=""
                                                        open={this.state.openDatePicker}
                                                        onOpen={() => this.handleClickDate(true)}
                                                        onClose={() => this.handleClickDate(false)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </DateDIV>
                                            {
                                                this.state.dateError && <FormHelperText>{lang.fileManager.uploadFilesForm.dateRequired}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                            {lang.fileManager.uploadFilesForm.description}
                                            </InputLabel>
                                            <StyledFormTextArea 
                                                id="description"
                                                name="description"
                                                value={this.state.description}
                                                onChange={this.handleChange}
                                                validators={['isSpaceEmpty', 'maxStringLength: 250']}
                                                errorMessages={[lang.fileManager.uploadFilesForm.emptyField, lang.fileManager.uploadFilesForm.maxLengthDescription]}
                                                InputLabelProps={{ shrink: true }}
                                                multiline
                                                rows="2"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <Upload>
                                            <img  src={iconoFile} alt="file"/>
                                            <Typography className='text' component="p" >
                                                {lang.fileManager.uploadFilesForm.selectFile}
                                            </Typography>
                                            
                                            <ButtonBase className='secondary' onClick={() => this.handleFormSubmit()}>
                                                {lang.fileManager.uploadFilesForm.selectFileButton}
                                            </ButtonBase>

                                            {/** File input */}
                                            <input
                                                type="file"
                                                name="filesRaw"
                                                value={this.state.filesRaw}
                                                onChange={this.handleFile}
                                                id="filesPicker"
                                                hidden
                                            />
                                        </Upload>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                            <Grid container spacing={3}>
                                <Grid item xs={12}> 
                                    {
                                        files.length > 0 &&
                                        <Typography variant="h6" component="h6" >
                                            {lang.fileManager.uploadFilesForm.files}
                                        </Typography>
                                    }
                                    {
                                        files.map(file => {
                                            return (
                                                <Snackbars key={`file_${file.idFile}`}>
                                                    <Box className='img'>
                                                        <Icon className={getIconClass(file.typeFile)}></Icon>
                                                    </Box>
                                                    <Box className='data'>
                                                        <Typography className='name' component="p" >
                                                            {file.title}
                                                        </Typography>
                                                        <Typography className='freight' component="p">
                                                            {file.size} KB
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <IconButton onClick={() => this.deleteFiles(file)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />        
                                                        </IconButton>
                                                    </Box>
                                                </Snackbars>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        this.state.errFiles !== '' &&
                                        <Typography variant="caption" display="block" gutterBottom color="error">
                                            { this.state.errFiles === 'noFiles' ? lang.fileManager.uploadFilesForm.minFiles : lang.fileManager.uploadFilesForm.fileLimit}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        <DialogActions>
                            
                            {/**Action buttons */} 
                            <ButtonBase className="outline" onClick={() => handleCancel()} type="button">
                                <FontAwesomeIcon icon={faTimesCircle} />  
                                {lang.fileManager.uploadFilesForm.cancel}
                            </ButtonBase> 
                            <ButtonBase className='primary ml-1' type="button" onClick={() => this.uploadFiles()}>
                                <FontAwesomeIcon icon={faSave} /> 
                                {lang.fileManager.uploadFilesForm.accept}
                            </ButtonBase>   
                        </DialogActions>
                    </React.Fragment>
                }
            </LanguageConsumer>
        )
    }
}

UploadFileForm.contextType = LanguageCxt;

UploadFileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func,
    rics: PropTypes.array
}

UploadFileForm.defaultProps = {
    handleCancel: () => {},
    rics: []
}

export default withStyles(styles)(UploadFileForm);
                            