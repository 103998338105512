import styled from 'styled-components';

const AppBar = styled.div `
    background-color: ${(props) => props.theme.tabs.bgColor};
    display: flex;
    justify-content: space-between;
    >div{
        min-height: 1px;
    }
    .tabs {
        .tab-item {
            background: ${(props) => props.theme.tabs.tab};
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            color: ${(props) => props.theme.tabs.textColor};
            font-size: 0.8125em;
            font-weight: normal;
            line-height: 1.53857143;
            margin: 0 .125em 0 0;
            min-height: 1px;
            opacity: 1;
            padding: 6px 10px;
            text-transform: none;
            >span {
                display: block;
                align-items: flex-start;
                max-width: 175px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:hover{
                background: ${(props) => props.theme.tabs.hover};
                color: ${(props) => props.theme.tabs.textHover};
            }

            &.Mui-selected{
                background: ${(props) => props.theme.tabs.select};
                color: ${(props) => props.theme.tabs.textSelect}
            }
        }
    }

    >button.primary{
        border-radius: 3px 3px 0 0 !important;
        border: 0 !important;
        flex: 0 0 175px;
        margin-left: 1rem;
        @media screen and (max-width: 768px){
            display: none;
        }
    }

    .MuiTabs-scrollButtonsDesktop{
        display: inline-flex !important;
    }
`;
export { AppBar };