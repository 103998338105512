const styles = theme => (
    {
        root: {
            minHeight: '100%',
            marginTop: 0,
            // overflow: 'hidden auto',
            width: '100%',
            zIndex: 1,
        }
    }
)

export default styles;