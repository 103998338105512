import styled from 'styled-components';
import { TextValidator } from 'react-material-ui-form-validator';

const StyledFormTextField = styled(TextValidator)`
    >div {
        background: transparent !important;
        >input {
            background: ${(props) => props.theme.input.bgColor} !important;
            border: 1px solid ${(props) => props.theme.input.borderColor} !important;
            border-radius: 3px;
            box-sizing: border-box;
            font-size: 13px;
            height: 33px;
            line-height: 1.42857143;
            padding: 6px 12px !important;
            transition: border-color .15s ease-in-out;
            &:focus{
                border-color: ${(props) => props.theme.input.borderFocus} !important;
                outline: none;
            }
        }
        &::after {
            content: initial !important;
        }
    }
`;

const StyledFormTextArea = styled(TextValidator)`
    >div {
        background: transparent !important;
        >textarea {
            background: ${(props) => props.theme.input.bgColor} !important;
            border: 1px solid  ${(props) => props.theme.input.borderColor} !important;
            border-radius: 3px;
            box-sizing: border-box;
            font-size: 13px;
            height: 33px;
            line-height: 1.42857143;
            min-height: 66px;
            overflow-y: auto;
            padding: 6px 12px !important;
            resize: vertical;
            transition: border-color .15s ease-in-out;
            &:focus{
                border-color: ${(props) => props.theme.input.borderFocus} !important;
                outline: none;
            }
        }
        &::after {
            content: initial !important;
        }
    }
`;

export { StyledFormTextField, StyledFormTextArea };