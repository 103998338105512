import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { StyledTextField } from '../../../uielements/textfield/StyledTextField';
import {CirclePicker}  from 'react-color';

class FormRSI extends React.Component{
    render(){

        const {
            classes,
            periodRSI,
            handleChange,
            handleChangeComplete,
            upperBandRSI,
            middleRSI,
            lowerRSI,
            colorRSI,
            colorInsideRSI,
            colorMiddleRSI,
            colorOverBoughtRSI,
            colorOverSoldRSI,
            colorOutsideRSI,
            lang
        } = this.props;

        return <React.Fragment>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.period}</InputLabel>
                <StyledTextField
                    autoFocus
                    id="periodRSI"
                    type="number"
                    fullWidth
                    value={periodRSI}
                    onChange={(e) => handleChange(e, 'periodRSI')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.upperBand}</InputLabel>
                <StyledTextField
                    autoFocus
                    id="upperBandRSI"
                    type="number"
                    fullWidth
                    value={upperBandRSI}
                    onChange={(e) => handleChange(e, 'upperBandRSI')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.middle}</InputLabel>
                <StyledTextField
                    autoFocus
                    id="middleRSI"
                    type="number"
                    fullWidth
                    value={middleRSI}
                    onChange={(e) => handleChange(e, 'middleRSI')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.lowerBand}</InputLabel>
                <StyledTextField
                    autoFocus
                    id="lowerRSI"
                    type="number"
                    fullWidth
                    value={lowerRSI}
                    onChange={(e) => handleChange(e, 'lowerRSI')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.rsiColor}</InputLabel>
                <CirclePicker
                    className={classes.colorCustom}
                    circleSize={20}
                    circleSpacing={10}
                    color={colorRSI}
                    onChangeComplete={(color) => handleChangeComplete(`colorRSI`, color)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.overBoughtColor}</InputLabel>
                <CirclePicker
                    className={classes.colorCustom}
                    circleSize={20}
                    circleSpacing={10}
                    color={colorOverBoughtRSI}
                    onChangeComplete={(color) => handleChangeComplete(`colorOverBoughtRSI`, color)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.middleColor}</InputLabel>
                <CirclePicker
                    className={classes.colorCustom}
                    circleSize={20}
                    circleSpacing={10}
                    color={colorMiddleRSI}
                    onChangeComplete={(color) => handleChangeComplete(`colorMiddleRSI`, color)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.overSoldColor}</InputLabel>
                <CirclePicker
                    className={classes.colorCustom}
                    circleSize={20}
                    circleSpacing={10}
                    color={colorOverSoldRSI}
                    onChangeComplete={(color) => handleChangeComplete(`colorOverSoldRSI`, color)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.outsideColor}</InputLabel>
                <CirclePicker
                    className={classes.colorCustom}
                    circleSize={20}
                    circleSpacing={10}
                    color={colorOutsideRSI}
                    onChangeComplete={(color) => handleChangeComplete(`colorOutsideRSI`, color)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel htmlFor="component-helper">{lang.insideColor}</InputLabel>
                <CirclePicker
                    className={classes.colorCustom}
                    circleSize={20}
                    circleSpacing={10}
                    color={colorInsideRSI}
                    onChangeComplete={(color) => handleChangeComplete(`colorInsideRSI`, color)}
                />
            </Grid>
        </Grid>
    </React.Fragment>
    }
}

export default FormRSI;