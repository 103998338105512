import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import { FormControl, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DialogActions from '@material-ui/core/DialogActions';
import { ButtonBase } from '../../../uielements/button/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';

//Autocomplete
import Autosuggest from 'react-autosuggest';
import RicSuggestionCard from '../../Cards/RicSuggestionCard/RicSuggestionCard';

//Styles 
import { withStyles } from '@material-ui/core/styles';
import styles from '../forms-jss';
import { InputLabel, DateDIV } from '../Forms'
import { StyledFormTextField } from '../../../uielements/textfield/StyledFormTextField'

//Language
import { LanguageConsumer, LanguageCxt } from '../../../language/LanguageContext';

//Services 
import { validateRic, searchRicSymbol } from '../../../services/index';

//Autocomplete
const getSuggestions = (array, value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : array.filter(ric =>
        ric.name.toLowerCase().slice(0, inputLength) === inputValue || ric.shortName.toLowerCase().slice(0, inputLength) === inputValue
    );
};

const getSuggestionValue = suggestion => {
    return suggestion.assetgroupid === 2 ? suggestion.mnemonic : suggestion.assetgroupid === 6 ? suggestion.mainIndexCommonNam : suggestion.cfname;
};

const renderSuggestion = suggestion => (
    <RicSuggestionCard ric={suggestion}/>
);

class UpdateRecommendationForm extends React.Component {
    
    constructor(props){
        super(props); 

        this.state = {
            //Form
            nameFile: '',
            source: '',
            recommendation: '',
            analyst: '',
            date: null,
            //Date
            openDatePicker: false,
            dateError: false,
            //RICS autocomplete
            rics: [], 
            value: '',
            suggestions: [],
            ricError: false,
            ricErrorMsg: ''
        }

        this.form = React.createRef();
    }

    //---------------------------- Lifecycle
    componentDidMount(){
        ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
            if (/^\s+$/i.test(value)) {
                return false;
            }
            return true;
          });

        this.setState({rics: this.props.rics});
        this.initializeForm(this.props.file);
    }
    //---------------------------- End Lifecycle

    //---------------------------- Submit
    handleFormSubmit = () => {
        if(!this.props.handleSubmit || !this.props.file){
            return; 
        }

        this.form.walk(this.form.childs).then(
            isValid => {
                let err = false, errRic = false, errRicMsg = '';
                
                //Validate date
                if(!this.state.date || this.state.date === ""){
                    err = true;
                }

                if(!this.state.value || this.state.value === ""){
                    errRic = true; 
                    errRicMsg = 'instrumentRequired'; 
                }

                if(this.state.value !== ''){
                    errRic = validateRic(this.state.value, this.state.rics) === undefined ? true : false;
                    errRicMsg = 'invalidRic';
                }

                this.setState({ dateError: err, ricError: errRic, ricErrorMsg: errRicMsg });

                if(isValid && !err && !errRic){
                    let auxFile = Object.assign({}, this.props.file);
                    auxFile.title = this.state.nameFile;
                    auxFile.instrument = this.state.value !== '' ? searchRicSymbol(this.state.value, this.state.rics) : ''; 
                    auxFile.ric = this.state.value; 
                    auxFile.datePublication = new Date(this.state.date).setHours(0,0,0,0); 
                    auxFile.isRecommendation = 1;
                    auxFile.recommendation = this.state.recommendation;
                    auxFile.analyst = this.state.analyst;
                    auxFile.source = this.state.source;

                    this.props.handleSubmit(auxFile);
                }
                
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    }
    //---------------------------- End Submit

    //---------------------------- Input
    handleChange = (event) => {
        if(!/^[a-z0-9 ]+$/gi.test(event.target.value.toLowerCase()) && event.target.value !== ''){
            return;
        }
        this.setState({ [event.target.name]: event.target.value });
    }

    //Datepicker
    handleChangeDate = (date) => {
        this.setState({date: date, openDatePicker: false, dateError: false});
    }

    handleClickDate = (open) => {
        this.setState({ openDatePicker: open })
    }

    //Autosuggest
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({ suggestions: getSuggestions(this.state.rics, value) });
    };
    
    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onChange = (event, { newValue }) => {
        this.setState({ value: newValue, ricError: false, ricErrorMsg: '' });
    };
    //---------------------------- End Input

    //---------------------------- Helpers
    initializeForm = (file) => {
        if(!file){
            return;
        }

        this.setState({
            nameFile: file.title, 
            recommendation: file.recommendation ? file.recommendation.description : '',
            analyst: file.recommendation ? file.recommendation.analyst : '',
            source: file.recommendation ? file.recommendation.source : '',
            value: file.instrument ? file.instrument : '', 
            date: file.datePublication ? new Date(file.datePublication) : null,
        });
    }

    emptySubmit = () => {

    }
    //---------------------------- End Helpers

    render(){
        const { classes, file, handleCancel } = this.props; 
        const { nameFile, analyst, recommendation, source, suggestions, value } = this.state;
        
        const inputProps = {
            value,
            onChange: this.onChange,
            maxLength: "15"
        };

        return (
            <LanguageConsumer>
                {
                    ({lang}) => 
                    <React.Fragment>
                        <ValidatorForm onSubmit={() => this.emptySubmit()} ref={form => (this.form = form)}>
                            <div style={{ flexGrow: 1, marginBottom: 15 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={12}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink >
                                                {lang.fileManager.upateFilesForm.Name}*
                                            </InputLabel>
                                            <StyledFormTextField 
                                                name="nameFile"
                                                id="nameFile"
                                                value={nameFile}
                                                onChange={this.handleChange}
                                                validators={['required', 'isSpaceEmpty', 'maxStringLength: 50']}
                                                errorMessages={[lang.fileManager.upateFilesForm.nameFileRequired, lang.fileManager.upateFilesForm.nameFileRequired, lang.fileManager.upateFilesForm.maxLengthNameFile]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink>
                                                {lang.fileManager.upateFilesForm.Source}*
                                            </InputLabel>
                                            <StyledFormTextField
                                                name="source"
                                                id="source" 
                                                value={source}
                                                onChange={this.handleChange}
                                                validators={['required', 'isSpaceEmpty']}
                                                errorMessages={[lang.fileManager.upateFilesForm.sourceRequired, lang.fileManager.upateFilesForm.sourceRequired]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.suggestionWrap} error={this.state.ricError}>
                                            <InputLabel shrink>
                                                {lang.fileManager.upateFilesForm.issuer}*
                                            </InputLabel>
                                            <Autosuggest
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                getSuggestionValue={getSuggestionValue}
                                                renderSuggestion={renderSuggestion}
                                                inputProps={inputProps}
                                                theme={{
                                                    input: classes.autocompleteInput,
                                                    suggestionsContainerOpen: classes.suggestionsContainerOpenUpdate,
                                                    suggestionsList: classes.suggestionsList,
                                                    suggestion: classes.suggestion,
                                                }}
                                            />
                                            {
                                                this.state.ricError &&
                                                <FormHelperText>{lang.fileManager.upateFilesForm[this.state.ricErrorMsg]}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink>
                                                {lang.fileManager.upateFilesForm.Rating}*
                                            </InputLabel>
                                            <StyledFormTextField 
                                                name="recommendation"
                                                id="recommendation" 
                                                value={recommendation}
                                                onChange={this.handleChange}
                                                validators={['required', 'isSpaceEmpty']}
                                                errorMessages={[lang.fileManager.upateFilesForm.recommendationRequired, lang.fileManager.upateFilesForm.recommendationRequired]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl className={classes.w100}>
                                            <InputLabel shrink>
                                                {lang.fileManager.upateFilesForm.Analyst}*
                                            </InputLabel>
                                            <StyledFormTextField 
                                                name="analyst"
                                                id="analyst" 
                                                value={analyst}
                                                onChange={this.handleChange}
                                                validators={['required', 'isSpaceEmpty']}
                                                errorMessages={[lang.fileManager.upateFilesForm.analystRequired, lang.fileManager.upateFilesForm.analystRequired]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl className={classes.w100} error={this.state.dateError}>
                                            <InputLabel shrink>
                                                {lang.fileManager.upateFilesForm.date}*
                                            </InputLabel>
                                            <DateDIV>
                                                <MuiPickersUtilsProvider utils={MomentUtils}> 
                                                    <DatePicker 
                                                        allowKeyboardControl={false}
                                                        disableToolbar
                                                        variant="inline"
                                                        format="DD/MM/YYYY"
                                                        id="dp-editFile"
                                                        value={this.state.date}
                                                        onChange={this.handleChangeDate}
                                                        disableFuture={true}
                                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                        placeholder=""
                                                        open={this.state.openDatePicker}
                                                        onOpen={() => this.handleClickDate(true)}
                                                        onClose={() => this.handleClickDate(false)} 
                                                    /> 
                                                </MuiPickersUtilsProvider> 
                                            </DateDIV>
                                            {
                                                this.state.dateError && <FormHelperText>{lang.fileManager.uploadFilesForm.dateRequired}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>

                            <DialogActions>
                                {/**Action buttons */} 
                                <ButtonBase className="outline" onClick={() => handleCancel()} type="button">
                                    <FontAwesomeIcon icon={faTimesCircle} />  
                                    {lang.fileManager.upateFilesForm.cancel}
                                </ButtonBase> 
                                <ButtonBase className='primary ml-1' type="button" onClick={() => this.handleFormSubmit()}>
                                    <FontAwesomeIcon icon={faCheckCircle}/> 
                                    {lang.fileManager.upateFilesForm.accept}
                                </ButtonBase>   
                            </DialogActions>
                        </ValidatorForm>
                    </React.Fragment>
                }
            </LanguageConsumer>
        )
    }
}

UpdateRecommendationForm.contextType = LanguageCxt;

UpdateRecommendationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired, 
    file: PropTypes.object,
    handleCancel: PropTypes.func
}

UpdateRecommendationForm.defaultProps = {
    handleCancel: () => {}
}

export default withStyles(styles)(UpdateRecommendationForm);