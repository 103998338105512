import React from 'react';
import { Grid, InputLabel, FormControlLabel, Checkbox } from '@material-ui/core';
import { StyledTextField } from '../../../uielements/textfield/StyledTextField';
import { CirclePicker } from 'react-color'
class FormStochastic extends React.Component {
    render() {

        const { classes, periodStochastic, kWindowSizeStochastic, dWindowSizeStochastic, handleChange, activeSlowStochastic, activeFastStochastic, activeFullStochastic, lang , strokeKLine, strokeDLine ,
        handleChangeComplete } = this.props;

        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.period}</InputLabel>
                    <StyledTextField
                        autoFocus
                        id="period"
                        type="number"
                        fullWidth
                        value={periodStochastic}
                        onChange={(e) => handleChange(e, 'periodStochastic')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.kWindowSize}</InputLabel>
                    <StyledTextField
                        autoFocus
                        id="kWindowSize"
                        type="number"
                        fullWidth
                        value={kWindowSizeStochastic}
                        onChange={(e) => handleChange(e, 'kWindowSizeStochastic')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.strokeKLine}</InputLabel>
                    <CirclePicker
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        color={strokeKLine}
                        onChangeComplete={(color) => handleChangeComplete(`strokeKLine`, color)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.dWindowSize}</InputLabel>
                    <StyledTextField
                        autoFocus
                        id="dWindowSize"
                        type="number"
                        fullWidth
                        value={dWindowSizeStochastic}
                        onChange={(e) => handleChange(e, 'dWindowSizeStochastic')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.strokeDLine}</InputLabel>
                    <CirclePicker
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        color={strokeDLine}
                        onChangeComplete={(color) => handleChangeComplete(`strokeDLine`, color)}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControlLabel
                        control={<Checkbox 
                                    checked={Boolean(activeSlowStochastic)} 
                                    onChange={(e) => handleChange(e, `activeSlowStochastic`)} 
                                    value={Boolean(activeSlowStochastic)}
                                />}
                        label={lang.slowActive}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControlLabel
                        control={<Checkbox 
                                    checked={Boolean(activeFastStochastic)} 
                                    onChange={(e) => handleChange(e, `activeFastStochastic`)} 
                                    value={Boolean(activeFastStochastic)} 
                                />}
                        label={lang.fastActive}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControlLabel
                        control={<Checkbox 
                                    checked={Boolean(activeFullStochastic)} 
                                    onChange={(e) => handleChange(e, `activeFullStochastic`)} 
                                    value={Boolean(activeFullStochastic)} 
                                />}
                        label={lang.fullActive}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default FormStochastic;