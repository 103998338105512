import App from "../pages/main";
import TechnicalAnalyst from "../pages/technical-analyst";
import FilesManager from '../pages/files-manager';
import withTracker from "./withTracker";


const routes = [
    {
        component : withTracker(App),
        routes : [
            {
                component : withTracker(TechnicalAnalyst),
                path : '/technical-analyst/:lang/:chartType/:theme/:token/:refreshToken',
                exact : true
            }, 
            {
                component : withTracker(FilesManager),
                path: '/file-manager/:lang/:theme/:token/:refreshToken', 
                exact: true
            }
        ]
    }
]

export default routes; 