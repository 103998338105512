import { fade } from '@material-ui/core/styles';

const styles = theme => (
    {
        grow: {
            flexGrow: 1,
            position: 'relative',
            zIndex: 10,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing(7),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 200,
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up(769)]: {
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up(769)]: {
                display: 'none',
            },
        },
        formControl: {
            alignItems: 'center',
            border:'1px solid',
            borderColor: theme.palette.primary.borderAutocomplete,
            borderRadius: '3px',
            display: 'flex',
            flexFlow: 'row nowrap',
            marginRight: theme.spacing(1),
            minWidth: 120,
            padding: '0.25rem',

            '&:hover': {
                borderColor: theme.palette.primary.borderFocus,
            }
        },
        inputIcon: {
            alignItems: 'center',
            borderLeft:'1px solid',
            borderColor: theme.palette.primary.borderBorder,
            display: 'flex',
            fontSize: '0.85em',
            padding: '0.357rem 0.45rem',
        },
        iconSmall: {
            fontSize: '1em !important',
        },
        iconBtn: {
            fontSize: '1.25em !important', 
        },
        small:{
            fontSize: '1.15em !important',
        },
        textBtn: {
            padding: '0.25em 0 0.25em 5px',
            borderWidth: '0 0 0 1px',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.borderBorder,
            fontWeight: 300,
            fontSize: '0.75rem',
            lineHeight: 1,

            [theme.breakpoints.down(1025)]: {
                display: 'none',
            },
        },
        dflex: {
            alignItems: 'center',
            display: 'flex',
        },
        dflexColumn: {
            display: 'flex',
            flexFlow: 'column'
        },
        m0: {
            margin: 0,
        },
        p0: {
            padding: 0,
        },
        margin8: {
            margin: 8,
        },
        w100: {
            width: '100% !important',
        },
        mTop: {
            marginTop: '-10px',
        },
        alignRight: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        active: {
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                    color: theme.palette.common.white,
                },
            },
        },
        autocompleteInput: {
            backgroundColor: theme.palette.primary.bgAutocomplete,
            border:0,
            boxSizing: 'border-box',
            color: theme.palette.primary.colorAutocomplete,
            fontSize: '0.75rem',
            fontFamily: 'inherit',
            overflow: 'hidden',
            padding: '3px 8px',
            transition: 'border-color .15s ease-in-out',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
            
        },
        suggestionsContainerOpen: {
            background: theme.palette.primary.bgSuggestions,
            boxShadow: '0 2px 5px -2px rgba(0,0,0,.25)',
            borderRadius: '4px',
            left: 0,
            top: 36,
            maxHeight: '240px',
            overflow: 'hidden',
            overflowY: 'auto',
            padding: theme.spacing(1),
            position: 'absolute',
            right: 0,
            width: '230px',
            zIndex: '999999',
        },
        suggestion: {
            color: theme.palette.primary.colorAutocomplete,
            display: 'block',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.08)',
            }
        },
        suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
        },
        colorIcon: {
            color: theme.palette.primary.colorIcon,
        },
        sizeText: {
            cursor: 'pointer',
            fontSize: '0.8rem',
        },
        menu: {
            '& >div:nth-child(3)':{

                '& >ul': {
                    width: '13em !important'
                }
            },
        },
        item: {
            alignItems: 'flex-start',
            flexFlow: 'column',
            padding: 0,

            '& >span': {
                marginBottom: '10px',
            }
        }
    }
)

export default styles;