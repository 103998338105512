import React from 'react';
//Components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReactLoading from 'react-loading';
import { IconButton } from '@material-ui/core';
//Modal Fullscreen
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
//Color
import Typography from '@material-ui/core/Typography';

//Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './FormDialog-jss';
function DialogLoading(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title">
                <DialogContent dividers>
                    <div className={props.boxLoading}>
                        <ReactLoading
                            type={"bars"}
                            color={"#5c5c5c"}/>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withStyles(styles)(DialogLoading);