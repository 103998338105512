import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import IconButton from '@material-ui/core/IconButton';
import { ButtonBase } from '../../uielements/button/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//Styles
import { withStyles, } from '@material-ui/core/styles';
import styles from './dialog-jss';

//Icons
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
//import CloseIcon from '@material-ui/icons/Close';

const CustomDialog = withStyles(styles)(props => {
    const { open, handleClose, children, title, handleCancel, handleAccept, showCancel, cancelLabel, acceptLabel, showActions, classes } = props;

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="custom-dialog-title" maxWidth={"sm"} fullWidth={true}>
            <DialogTitle onClose={handleClose} >
                <span className={classes.dialogHeadign}>
                    {title}
                </span>
                {/* <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton> */}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {
                showActions && 
                <DialogActions>
                    {
                        showCancel && 
                        <ButtonBase
                        className="outline"
                        onClick={() => handleCancel()}>
                            <FontAwesomeIcon icon={faTimesCircle} />   
                            {cancelLabel}
                        </ButtonBase>
                    }
                    <ButtonBase className='primary ml-1' onClick={() => handleAccept()}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        {acceptLabel}
                    </ButtonBase>
                </DialogActions>
            }
        </Dialog>
    )
});

CustomDialog.propTypes = {
    open: PropTypes.bool.isRequired, 
    handleClose: PropTypes.func.isRequired, 
    title: PropTypes.string, 
    handleCancel: PropTypes.func, 
    handleAccept: PropTypes.func, 
    showCancel: PropTypes.bool, 
    cancelLabel: PropTypes.string, 
    acceptLabel: PropTypes.string,
    showActions: PropTypes.bool
}

CustomDialog.defaultProps = {
    handleCancel: () => {}, 
    handleAccept: () => {},
    showCancel: true, 
    cancelLabel: "Cancelar", 
    acceptLabel: "Aceptar",
    showActions: true
}

export default CustomDialog;
