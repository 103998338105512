import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormControl } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { ButtonBase } from '../../../uielements/button/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import DialogActions from '@material-ui/core/DialogActions';

//Styles 
import { withStyles } from '@material-ui/core/styles';
import { InputLabel } from '../Forms'
import { StyledFormTextField } from '../../../uielements/textfield/StyledFormTextField'
import styles from '../forms-jss';

//Language
import { LanguageConsumer, LanguageCxt } from '../../../language/LanguageContext';

class CreateFolderForm extends React.Component {

    state = {
        //FORM
        folderTitle: ''
    }

    //----------------------------- Lifecycle
    componentDidMount(){
        ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
            if (/^\s+$/i.test(value)) {
                return false;
            }
            return true;
          });
    }
    //----------------------------- End Lifecycle

    //----------------------------- Inputs
    handleChange = (event) => {
        if(!/^[a-z0-9 ]+$/gi.test(event.target.value.toLowerCase()) && event.target.value !== ''){
            return;
        }
        
        this.setState({ [event.target.name]: event.target.value });
    }
    //----------------------------- End Inputs

    //----------------------------- Submit
    handleFormSubmit = () => {
        let folder = {
            name: this.state.folderTitle,
            title: this.state.folderTitle,
            isRecommendation: 0
        }

        if(this.props.handleSubmit){
            this.props.handleSubmit(folder);
        }
    }
    //----------------------------- End Submit

    render(){
        const {classes, handleCancel} = this.props; 

        return(
            <LanguageConsumer>
                {
                    ({lang}) => 
                    <ValidatorForm onSubmit={() => this.handleFormSubmit()}>
                        <div style={{ flexGrow: 1 }}> 
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <FormControl className={classes.w100}>
                                    <InputLabel shrink htmlFor="folderTitle">
                                        {lang.fileManager.createFolderForm.folderName}*
                                    </InputLabel>
                                    <StyledFormTextField 
                                        id="folderTitle"
                                        name="folderTitle"
                                        value={this.state.folderTitle}
                                        onChange={this.handleChange}
                                        validators={['required', 'isSpaceEmpty', 'maxStringLength:50']}
                                        errorMessages={[lang.fileManager.createFolderForm.isRequired, lang.fileManager.createFolderForm.isRequired, lang.fileManager.createFolderForm.maxLength]}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>    
                        </div>
                        <DialogActions>
                            {/**Action buttons */} 
                            <ButtonBase className="outline" onClick={() => handleCancel()} type="button">
                                <FontAwesomeIcon icon={faTimesCircle} />  
                                {lang.fileManager.createFolderForm.cancel}
                            </ButtonBase> 
                            <ButtonBase className='primary ml-1' type="submit">
                                <FontAwesomeIcon icon={faCheckCircle} /> 
                                {lang.fileManager.createFolderForm.accept}
                            </ButtonBase>   
                        </DialogActions>
                    </ValidatorForm>
                }
            </LanguageConsumer>
        )
    }
}

CreateFolderForm.contextType = LanguageCxt;

CreateFolderForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired
}

CreateFolderForm.defaultProps = {
    handleCancel: () => {}
}

export default withStyles(styles)(CreateFolderForm);