export const ligth = {
    Red: '#ff0101',
    Redlighten20: '#ff6767',
    bgColor:'#fff',
    borderColor: "rgba(0,0,0,0.07)",
    textColor: '#666',
    ligthColor: '#aeaeae',
    textDarkColor: '#4b4b4b',
    borderCheckbox: "#ff0101",
    checkboxChecked: "#d80200",
    card: {
        bgColor:"#fff",
        titleColor:"#020202",
        subColor:"#aeaeae",
        bgColorActive: "#fff6f6",
    },
    paginator: {
        bgColor: "#fff",
        borderColor: "rgba(0,0,0,0.07)",
        textColor: '#4a4a4a',
    },
    input: {
        bgColor: '#fff',
        borderColor: 'rgba(0,0,0,0.07)',
        borderFocus: "#183c96",
    },
    whiteColor: '#fff',
    //AppBar
    appBg: '#fff',
    //Button-Primary
    btnPrimary: '#356cff',
    btnHoverPrimary: '#356cff',
    //Button-Secondary
    bgTrans: 'transparent',
    btnSecondary: '#fff',
    btnHoverSecondary: '#f3f3f3',
    textBtnSecondary: '#666',
    textHoverSecondary:'rgba(102, 102, 102, .50)',
    //Button-Outline
    btnOutline: '#fff',
    borderOutline: 'rgba(0,0,0,.07)',
    textBtnOutline: '#4a4a4a',
    btnHoverOutline: '#ededed',
    borderHoverOutline: 'rgba(0,0,0,.07)',
    colorHoverOutline: '#7a878e',
    //StyledFormTextField
    bgTextField: '#fff',
    borderFocus: '#183c96',
    borderTextField: 'rgba(0,0,0,.07)',
    snackbars: {
        bgColor: '#f0f0f0',
        svg: '#b5b5b5',
        svgHover: '#8f8f8f',
        hover: '#dbdbdb',
    },
    tabs: {
        bgColor: `#f8f9fa`,
        tab: '#f6f7f9',
        hover: "#ededf0",
        textColor:'#4a4a4a',
        textHover: '#212121;',
        select: '#fff',
        textSelect: '#8c8c8c'
    }
}

export const dark = {
    Red: '#ff0101',
    Redlighten20: '#ff6767', 
    bgColor:'#0e131a',
    borderColor: "rgba(255,255,255,0.1)",
    textColor: '#fff',
    ligthColor: '#5e5e5e',
    textDarkColor: '#9c9c9c',
    borderCheckbox: "#ff6767",
    checkboxChecked: "#ff4e4e",
    card: {
        bgColor:"#2b2b2b",
        titleColor:"#eaeaea",
        subColor:"#5e5e5e",
        bgColorActive: "#1e1e1e",
    },
    paginator: {
        bgColor: "#4a4a4a",
        borderColor: "#4f4f4f",
        textColor: '#303030',
    },
    input: {
        bgColor: '#0e131a',
        borderColor: 'rgba(255,255,255,.03)',
        borderFocus: "rgba(255,255,255,.03)",
    },
    whiteColor: '#fff',
    //AppBar
    appBg: '#191f28',
    //Button-Primary
    btnPrimary: '#183c96',
    btnHoverPrimary: '#183c96',
    //Button-Secondary
    bgTrans: 'transparent',
    btnSecondary: '#191f28',
    btnHoverSecondary: '#191f28',
    textBtnSecondary: '#fff',
    textHoverSecondary:'rgba(255, 255, 255, .50)',
    //Button-Outline
    btnOutline: 'transparent',
    borderOutline: '#0e131a',
    textBtnOutline: '#d4d4d4',
    btnHoverOutline: 'transparent',
    borderHoverOutline: '#0e131a',
    colorHoverOutline: '#ababab',
    //StyledFormTextField
    borderFocus: '#183c96',
    bgTextField: '#0e131a',
    borderTextField: 'rgba(255,255,255,.03)',
    snackbars: {
        bgColor: '#313131',
        svg: '#696969',
        svgHover: '#828282',
        hover: '#4f4f4f',
    },
    tabs: {
        bgColor: `#0e131a`,
        tab: '#484848',
        hover: "#3c3c3c",
        textColor:'#fff',
        textHover: '#fff',
        select: '#191f28',
        textSelect: '#fff'
    }
}