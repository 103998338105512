import React from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { LanguageProvider, themes } from './app/language/LanguageContext'; 
import l from './app/language/lang'; 
import route from './app/routes/index';
import './App.css';

//Services
import { API_POST_UNAUTHORIZED } from './app/services/provider';
import { urlSettings, params, paramsSession, auxUrl } from './app/services/env';

//Dummy for development
//import rics from './app/utils/ricsData';

//History
let createHistory = require('history').createBrowserHistory;
const history = createHistory();

class App extends React.Component {

  constructor(props) {
    super(props);

    this.changeLang = (l) => {
      this.setState(state => ({
        lang:
          state.lang === l
            ? l
            : l,
      }));
    };

    //AHDA 26/09/2019
    this.changeTheme = (t) => {
      let auxTheme = themes[t] ? themes[t] : "ligth" 
      this.setState({
        theme: auxTheme
      }, () => {
        console.log(this.state.theme);
      });
    }

    this.state = {
      lang: l,
      changeLang: this.changeLang,
      changeTheme: this.changeTheme,
      theme: themes.ligth, //AHDA 26/09/2019
      hasSettings: false
    };
  }

  componentWillMount(){
    //Get params from parammeter store
    API_POST_UNAUTHORIZED(urlSettings, params).then(
      response => {
        //Se sobreescribe la url ya que el behaivor está en el dns principal y no en el de react
        //TODO: Ver si le pondrán behaivor 
        response.URL_SERVICES = auxUrl;
        response.DOWNLOAD_URL = `https://${response.AWS_BUCKETNAME}`;
        sessionStorage.setItem(paramsSession, JSON.stringify(response));
        this.setState({hasSettings: true}, () => {
          console.log("Loaded");
        });
      }
    ).catch(
      err => {
        console.log("Error: ", err); 
        //TODO: mange errors
      }
    )
  }

  componentWillUnmount(){
    sessionStorage.clear();
  }

  render() {
    return (
      <LanguageProvider value={this.state}>
          {
            this.state.hasSettings && 
            <Router history={history}>
              {
                renderRoutes(route)
              }
            </Router>
          }
      </LanguageProvider>
    )
  }
}

export default App;
