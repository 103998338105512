import React, { Component } from 'react';
import { object } from 'prop-types';
//import { Analytics } from 'aws-amplify';
//const googleAnaliticsId ='';
 

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
   
   
  };

  const HOC = class extends Component {
    static propTypes = {
      location: object.isRequired,
    };

    componentDidMount() {
      /*const page = this.props.location.pathname;
      trackPage(page);*/

    }

    componentWillReceiveProps(nextProps) {
      /*const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }*/
      
    //   if (googleAnaliticsId) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage) {
          trackPage(nextPage);
        }
    //   }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  if (typeof WrappedComponent.fetchData !== 'undefined') {
    HOC.fetchData = WrappedComponent.fetchData;
  }

  return HOC;
}
