import React from 'react';
//Components
import Grid from '@material-ui/core/Grid';
import { StyledTextField } from '../../../uielements/textfield/StyledTextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { ButtonBase } from '../../../uielements/button/ButtonBase';
import { IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

//Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './indexStyle-jss';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import CloseIcon from '@material-ui/icons/Close';

class FormText extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			text: props.text,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}
	componentWillReceiveProps(nextProps) {	
		this.setState({
			text: nextProps.text === `Lorem ipsum...` ? `` : nextProps.text,
		});
	}
	handleChange(e) {
		this.setState({
			text: e.target.value
		});
	}
	handleSave() {
		this.props.onSave(this.state.text, this.props.chartId);
	}
	render() {
		const {
			showModal,
			onClose,
			classes,
			lang
		} = this.props;
		const { text } = this.state;


		return (
			<Dialog
				fullScreen={false}
				open={showModal}
				onClose={onClose}
				aria-labelledby="customized-dialog-title">
				<DialogTitle id="customized-dialog-title" onClose={onClose}>
					<div className={classes.titleModal}>
						<Typography variant="h6">{lang.technicalAnalyst.titleForm}</Typography>
						<IconButton aria-label="close" onClick={() => { onClose() }}>
							<CloseIcon />
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent dividers>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						spacing={3}>
						<Grid item xs={12}>
							<InputLabel htmlFor="component-helper">{lang.technicalAnalyst.multiline}</InputLabel>
							<StyledTextField
								autoFocus
								id="standard-multiline-flexible"
								multiline
								rowsMax="4"
								value={text}
								onChange={this.handleChange}
								fullWidth
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<ButtonBase
						onClick={() => { onClose() }}
						className="outline">
						<FontAwesomeIcon icon={faTimesCircle} />
						{lang.technicalAnalyst.cancel}
                    </ButtonBase>
					<ButtonBase
						onClick={() => { this.handleSave() }}
						className="primary ml-1">
						<FontAwesomeIcon icon={faCheckCircle} />
						{lang.technicalAnalyst.accept}
                    </ButtonBase>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(styles)(FormText);