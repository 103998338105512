export const FormatDate = (date) => {
    let newDate = new Date(date); 

    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear(); 

    day = day <= 9 ? `0${day}` : day; 
    month = month <= 9 ? `0${month}` : month; 

    return `${day}/${month}/${year}`;
}

export const getTotalFileSize = (files, maxSize) => {
    let size = 0;
    files.map(f => {
        size += f.size;
        return f;
    }); 

    return size > maxSize ? true : false;
}

export const getIconClass = (type) => {
    let icon = "";
    switch(type){
        case "PDF":
            icon = "icon-001-pdf";
            break; 
        case "ZIP":
            icon = "icon-003-zip";
            break; 
        case "IMAGE":
            icon = "icon-004-img";
            break; 
        case "OFFICE":
            icon = "icon-002-doc";
            break; 
        default: 
            icon = "icon-002-doc";
            break; 
    }

    return icon;
}