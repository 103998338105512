import React from 'react';
import { Grid, Box, FormControl, MenuItem } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { StyledFormTextField } from '../../../uielements/textfield/StyledFormTextField'
import { ButtonBase } from '../../../uielements/button/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectBase } from '../../../uielements/select/SelectBase';
import ReactLoading from 'react-loading';
//Styles 
import { makeStyles } from '@material-ui/core/styles';
import styles from '../forms-jss';
import { InputLabel } from '../Forms'

//Icons
import { faTimesCircle, faSave } from '@fortawesome/free-regular-svg-icons';


//Language
import { LanguageConsumer } from '../../../language/LanguageContext';
import Autosuggest from 'react-autosuggest';

const useStyles = makeStyles(styles);

function AddForm(props) {
    const classes = useStyles();

    ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
        if (/^\s+$/i.test(value)) {
            return false;
        }
        return true;
    });

    ValidatorForm.addValidationRule('special', (value) => {
        if (/[!123456790#“º$%&*¡¿!;\\\\'"|?]/.test(value)) {
            return false;
        }
        return true;
    });

    // ValidatorForm.addValidationRule('ric', (value) => {
    //     return props.validateRic();
    // });

    return (
        <LanguageConsumer>
            {
                ({ lang }) =>
                    <ValidatorForm onSubmit={() => {
                        if (props.validateRic()) {
                            props.save()
                        }
                    }}>
                        <React.Fragment>
                            <div style={{ flexGrow: 1, padding: '1rem' }}>
                                {props.isLoading()? 
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <div style={
                                            {
                                                width : '100% !important' 
                                                , height: '100%'
                                                , display: 'flex'
                                                , justifyContent: 'center'
                                            }
                                        }>
                                            <ReactLoading
                                                type={"bars"}
                                                delay={10}
                                                color={"#5c5c5c"}
                                                height={props.isEdit?'30%':'6%'}
                                                width={props.isEdit?'30%':'6%'} />
                                        </div>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.w100} style={{width : '100% !important'}}>
                                            <InputLabel>
                                                {lang.technicalAnalyst.formAnalysis.title}(*)
                                        </InputLabel>
                                            <StyledFormTextField
                                                value={props.nameTechnical}
                                                onChange={(e) => props.onChange(e, `nameTechnical`)}
                                                id="nameTechnical"
                                                name="nameTechnical"
                                                validators={
                                                    [
                                                        `required`,
                                                        `isSpaceEmpty`,
                                                        'maxStringLength:50',
                                                        `minStringLength:5`,
                                                        `special`
                                                        // `ric`
                                                    ]
                                                }
                                                errorMessages={
                                                    [
                                                        props.lang.empty,
                                                        props.lang.empty,
                                                        props.lang.max,
                                                        props.lang.min,
                                                        props.lang.specialCharacter
                                                    ]
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                placeholder={lang.technicalAnalyst.formAnalysis.title}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.select}>
                                            <InputLabel>
                                                {lang.technicalAnalyst.formAnalysis.symbol}(*)
                                        </InputLabel>
                                            <Autosuggest
                                                suggestions={props.suggestions}
                                                onSuggestionsFetchRequested={props.onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={props.onSuggestionsClearRequested}
                                                getSuggestionValue={props.getSuggestionValue}
                                                renderSuggestion={props.renderSuggestion}
                                                inputProps={props.inputProps}
                                                theme={{
                                                    input: classes.autocompleteInput,
                                                    suggestionsContainerOpen: classes.suggestionsContainerOpenInModal,
                                                    suggestionsList: classes.suggestionsList,
                                                    suggestion: classes.suggestionInModal,
                                                }}
                                            />
                                            {
                                                props.errorSymbol ?
                                                    <InputLabel className={classes.errorStyle}>
                                                        {lang.technicalAnalyst.Messages.errorSymbol}
                                                    </InputLabel>
                                                    :''
                                                    // props.symbolTechnical === '' ?
                                                    //     <InputLabel className={classes.errorStyle}>
                                                    //         {lang.technicalAnalyst.Messages.emptyField}
                                                    //     </InputLabel> :''
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.w100} style={{width : '100% !important'}}>
                                            <InputLabel>
                                                {lang.technicalAnalyst.formAnalysis.Description}(*)
                                        </InputLabel>
                                            <StyledFormTextField
                                                value={props.descriptionTechnical}
                                                onChange={(e) => props.onChange(e, `descriptionTechnical`)}
                                                id="descriptionTechnical"
                                                name="descriptionTechnical"
                                                validators={
                                                    [
                                                        `required`,
                                                        `isSpaceEmpty`,
                                                        'maxStringLength:50',
                                                        `minStringLength:5`,
                                                        `special`
                                                    ]
                                                }
                                                errorMessages={
                                                    [
                                                        props.lang.emptyD,
                                                        props.lang.emptyD,
                                                        props.lang.max,
                                                        props.lang.min,
                                                        props.lang.specialCharacter
                                                    ]
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                placeholder={lang.technicalAnalyst.formAnalysis.Description}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {
                                        !props.isEdit ?
                                            <Grid item xs={12}>
                                                <Box width="100%" display="flex" justifyContent="flex-end">
                                                    <ButtonBase className="outline" type="button" onClick={() => { props.cancel(); }}>
                                                        <FontAwesomeIcon icon={faTimesCircle} />
                                                        {lang.technicalAnalyst.formAnalysis.cancel}
                                                    </ButtonBase>
                                                    <ButtonBase className='primary ml-1' type="submit" disabled={!props.isValidNewTA()}>
                                                        <FontAwesomeIcon icon={faSave} />
                                                        {lang.technicalAnalyst.formAnalysis.Save}
                                                    </ButtonBase>
                                                </Box>
                                            </Grid>
                                            : ``
                                    }
                                </Grid>
                                }
                            </div>
                        </React.Fragment>
                    </ValidatorForm>
            }
        </LanguageConsumer>
    )
}

export default AddForm