import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { StyledTextField } from '../../../uielements/textfield/StyledTextField';
import { CirclePicker } from 'react-color';
class FormMACD extends React.Component {
    render() {

        const { classes, handleChange, handleChangeComplete, fastMACD, slowMACD, signalMACD, colorStrokeMACD, lang } = this.props;

        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="component-helper">{lang.fast}</InputLabel>
                        <StyledTextField
                            autoFocus
                            id="fast"
                            type="number"
                            fullWidth
                            value={fastMACD}
                            onChange={(e) => handleChange(e, 'fastMACD')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="component-helper">{lang.slow}</InputLabel>
                        <StyledTextField
                            autoFocus
                            id="slow"
                            type="number"
                            fullWidth
                            value={slowMACD}
                            onChange={(e) => handleChange(e, 'slowMACD')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="component-helper">{lang.signal}</InputLabel>
                        <StyledTextField
                            autoFocus
                            id="signal"
                            type="number"
                            fullWidth
                            value={signalMACD}
                            onChange={(e) => handleChange(e, 'signalMACD')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="component-helper">{lang.stroke}</InputLabel>
                        <CirclePicker
                            className={classes.colorCustom}
                            circleSize={20}
                            circleSpacing={10}
                            color={colorStrokeMACD}
                            onChangeComplete={(color) => handleChangeComplete(`colorStrokeMACD`, color)}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default FormMACD;