import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ButtonBase } from '../button/ButtonBase';
import styled from 'styled-components';

const StyledMenu = styled(({ className, ...props }) => (
  <Menu {...props} classes={{ paper: className }} />
))`
    background-color: ${(props) => props.theme.bgColor} !important;

  li {
    border-radius: 3px;
    color: ${(props) => props.theme.textColor};
    margin: 0 .4125rem;
    padding: 7px 10px;
  }
`;


export default function DropDown(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickItem = (handler) => {
        handleClose();
        if(handler){
            handler();
        }
    }

    const opt = props.options ? props.options : [];

    return (
        <div>
            <ButtonBase className={opt.length > 0 ? 'primary' : 'noDisplay'} aria-controls={props.id} aria-haspopup="true" onClick={handleClick} disabled={props.disabled}>
                {props.icon}
                <span>{props.name}</span>
            </ButtonBase>

            {
                opt.length > 0 &&  
                <StyledMenu
                    id={props.id}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    {
                        opt.map(
                            (item, index) => {
                                return <MenuItem onClick={() => handleClickItem(item.handler)} key={item.key ? item.key : `item_${index}`}>{item.label}</MenuItem>
                            }
                        )
                    }
                </StyledMenu>
            }
            
        </div>
    );
}