//import { NONAME } from "dns";

const styles = theme => (
    {
        root: {
            background: theme.palette.primary.main,
            flexGrow: 1,
            boxSizing: 'border-box',
            height: '100%',
        },
        heading: {
            color: theme.palette.primary.textColor,
            padding: '0.5rem 0',
            borderWidth: '0 0 1px',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.borderBorder,
            marginBottom: theme.spacing(1),
        },
        btn: {
            color: theme.Redlighten20,
            fontSize: '0.7rem',
            fontWeight: 400,
            padding: 0,
            textTransform: 'inherit',
            '&:hover': {
                background: 'none',
                color: theme.Red,
            }
        },
        dFlex: {
            display: 'flex',
        },
        input: {
            margin: '0 !important',
            minWidth: 250,
        },
        mb1:{
          marginBottom: theme.spacing(3),  
        },
        mr1: {
            marginRight: theme.spacing(1),
        },
        contentWidth: {
            flexGrow: 1,
            height: '100%',
            transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
            }),
            marginRight: -250,
            paddingLeft: 280,
            position: 'relative',
            
            '@media screen and (max-width: 959px)': {
                paddingLeft: 0,
            }
        },
        contentWidthShift: {
            height: '100%',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            '@media screen and (min-width: 768px)': {
                marginRight: 0,
            }
        },
        drawer: {
            width: 250,
            flexShrink: 0,  
        },
        drawerPaper: {
            backgroundColor: theme.palette.primary.main,
            width: 250,
            borderColor: theme.palette.primary.borderBorder,
        },
        flexContainer: {
            display: 'flex',
            height: '100%',
        },
        wrappList: {
            display: 'flex',    
            flexFlow: 'column',
            height:' 100%'
        },
        list: {
            height:' 100%',
            overflowY: 'auto',
            padding: 6,
        },
        hiddenList: {
            display: 'none'
        },
        GridContent: {
            height:' 100%',
            padding: theme.spacing(1, 2),
        },
        load: {
            alignItems: 'center',
            display: 'flex',
            height:' 100%',
            justifyContent: 'center'
        },
        noData: {
            alignItems: 'center',
            height:' 100%',
            justifyContent: 'center',
            textAlign: 'center'
        },
        asideLeft: {
            background: theme.palette.primary.main,
            bottom: 0,
            color: theme.palette.primary.textColor,
            left: 0,
            fontFamily: 'Nunito, sans-serif',
            height: 'auto',
            minHeight: '100vh',
            overflow: 'hidden',
            overflowY: 'auto',
            padding: theme.spacing(1),
            position: 'absolute',
            top: 0,
            width: '280px',
            zIndex: '1199',

            '@media screen and (max-width: 959px)': {
                left: '-100%',
                transition: theme.transitions.create('left', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            '@media screen and (max-width: 568px)': { 
                width: '100%',
            }
        },
        positionLeft:{
            left: 0,
        },
    }
) 

export default styles;