import styled from 'styled-components';

const Wrapper = styled.div `
    display: flex;
    flex-flow: row wrap;
    @media (max-width: 767px) {
        justify-content: space-between;
    }
`;
export { Wrapper };

const MultiSelect = styled.div `
    input {
        background-color: ${(props) => props.theme.input.bgColor};
        border: 1px solid ${(props) => props.theme.input.borderColor};
        border-radius: 3px;
        box-sizing: border-box;
        color: ${(props) => props.theme.textColor};
        font-family: 'Nunito', sans-serif !important;
        font-size: 13px;
        height: 33px;
        line-height: 1.42857143;
        padding: 6px 12px;
        transition: border-color .15s ease-in-out;
        transition-duration: .5s;
        &:focus{
            background-color: ${(props) => props.theme.input.bgColor};
            border-color: ${(props) => props.theme.input.borderFocus};
            outline: none;
        }

        &::placeholder {
            color: #c8c8c8 !important;
        }        
    }

    ul{
        li {
            input{
                &:focus{
                    box-shadow: none;
                    outline: none;
                }
            }
            label {
                color: ${(props) => props.theme.textColor};
                font-size: 0.825rem;
                &:hover {
                    color: ${(props) => props.theme.textColor};
                    &::before{
                        border-color: ${(props) => props.theme.borderCheckbox};
                    }
                }
                &::before{
                    background: transparent;
                    border-color: ${(props) => props.theme.borderCheckbox};
                    border-radius: 0;
                    box-shadow: none !important;
                    height: 12px;
                    width: 13px;
                }
            }

            &.active {
                label {
                    &::before{
                        border-color: ${(props) => props.theme.borderCheckbox};
                        background: ${(props) => props.theme.checkboxChecked};
                    }
                    &::after{
                        content: initial;
                    }
                }
            }
        }
    }

    .search-input {
        input{
            padding-left: 32px;
            min-width: 220px;
            @media screen and (max-width: 567px) {
                min-width: 100%;
            }
        }
        >div >div {
            >div{
                top: 50%;
                transform: translateY(-50%);
                svg {
                    fill: #c8c8c8 !important;
                }
            }    
        }
    }
    
    .search-hidden {
        display: none
    }
`;
export { MultiSelect };

const WrappDay = styled.div `
    >div {
        padding-bottom: 10px;
        >div{
            >div {
                &:nth-child(2n+1) {
                    border: 1px solid ${(props) => props.theme.borderColor} !important;
                    border-radius: 3px;
                }
            }
        }
    }
    input {
        background: transparent;
        border-radius: 3px;
        font-family: 'Nunito', sans-serif !important;
        font-size: 13px !important;
        height: 33px !important;
        line-height: 1.42857143 !important;
        padding: 6px 12px !important;
    }
`;
export { WrappDay };

const Paginator = styled.div `
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    [class*=paginator]{
        align-items: center;
        background-color: ${(props) => props.theme.paginator.bgColor};
        border: 1px solid ${(props) => props.theme.paginator.borderColor};
        border-radius: 3px;
        color: ${(props) => props.theme.paginator.textColor};
        cursor: pointer;
        display: flex;
        font-size: 0.9rem;
        height: 30px;
        justify-content: center;
        min-width: 30px;
        &:not(:last-child) {
            margin-right: 0.25rem;
        }
        &.active {
            border-color: #ff9a9a;
            background: #ff6767;
            color: #fff;
        }
        &.disabled {
            cursor: default;
            opacity: 0.4;
        }
        svg{
            fill: ${(props) => props.theme.paginator.textColor};
            font-size: 24px
        }
    }
`;
export { Paginator };

const BarTop = styled.div`
    align-items: flex-end;  
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    color: ${(props) => props.theme.textColor};
    display: flex;
    padding-bottom: 0.5em;
    margin-bottom: 1rem; 
    >div{
        flex: 1;

        .selectFilter {
            a{
                background: ${(props) => props.theme.snackbars.bgColor};
                color: ${(props) => props.theme.textDarkColor};
                span:last-child {
                    border-color: ${(props) => props.theme.borderColor}
                }
            }
        }
    }
    button{
        border-left: 1px solid ${(props) => props.theme.borderColor};
        border-radius: 0;
        box-sizing: border-box;
        color: ${(props) => props.theme.textColor};
        height: 33px;
        padding: 6px 12px;
        &:hover{
            background-color: transparent;
        }
    }
    
`;
export { BarTop };

const WrappDate = styled.div`
    color: ${(props) => props.theme.ligthColor};
    .customDate {
        >div {
            >div:nth-child(2n + 1) {
                background: ${(props) => props.theme.input.bgColor} !important;
                border: 1px solid ${(props) => props.theme.input.borderColor};
                border-radius: 3px;
            }
        }
    }

    .container {
        box-sizing: border-box;
        flex: 1;
        input {
            background: ${(props) => props.theme.input.bgColor};
            border: 0;
            box-sizing: border-box;
            color: ${(props) => props.theme.textColor};
            font-size: 0.825rem;
            height: 33px;
            padding: 6px 12px;
            width: 100%;
        }
    }

    .overlay{
        background: ${(props) => props.theme.bgColor};
        border-radius: 3px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
        font-size: 0.75rem;
        position: absolute;
        .DayPicker-Caption>div {
            color: ${(props) => props.theme.textColor};
            font-size: 0.95rem !important;
        }
        
        .DayPicker-Weekday {
            color: #5c5c5c;
        }

        .DayPicker-Day {
            color: ${(props) => props.theme.textColor} !important;
        }

        .DayPicker-Day--today {
            color: ${(props) => props.theme.Red} !important;
        }

        .DayPicker-Day--disabled {
            color:${(props) => props.theme.ligthColor} !important; 
        }

        .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
            background-color: #ff6767 !important;
            color: #fff !important;
        }

        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            background-color: #ff6767 !important;
            border-radius:50%;
            color: #fff !important;
        }

        .DayPicker-Day.DayPicker-Day--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            background-color: blue !important;
            color: #fff !important;
        }
    }
`;
export {WrappDate};
