import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { StyledTextField } from '../../../uielements/textfield/StyledTextField';
import { CirclePicker } from 'react-color';
class FormEMASMA extends React.Component {

    render() {
        const { classes, handleChange, handleChangeComplete, period, strokeColorSMA, lang} = this.props;
        
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <InputLabel htmlFor="component-helper">{lang.period}</InputLabel>
                        <StyledTextField
                            placeholder="Ej. 10"
                            id="period"
                            type="number"
                            fullWidth
                            value={period}
                            onChange={(e) => handleChange(e, 'periodIndicator')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="component-helper">{lang.stroke}</InputLabel>
                        <CirclePicker
                            className={classes.colorCustom}
                            circleSize={20}
                            circleSpacing={10}
                            color={strokeColorSMA}
                            onChangeComplete={(color) => handleChangeComplete(`colorStokeSMA`, color)}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default FormEMASMA