import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

const IconButtonBase = styled(IconButton)`
    font-size: 0.85rem !important;
    height: 33px;
    padding: 0 !important;
    width: 33px;
    &.primary {
        background: ${(props) => props.theme.btnPrimary} !important;
        color: ${(props) => props.theme.whiteColor} !important;
        &:hover {
            background: ${(props) => props.theme.btnHoverPrimary} !important;
        }
    }
    &.secondary {
        background: ${(props) => props.theme.btnSecondary} !important;
        color: ${(props) => props.theme.textBtnSecondary} !important;
        &:hover {
            background: ${(props) => props.theme.btnHoverSecondary} !important;

            svg {
                color: ${(props) => props.theme.textHoverSecondary} !important;
            }
        }
    }
    &.mRight1 {
        margin-right: 0.5rem;
    }
`;

export { IconButtonBase };