import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import { ButtonBase } from '../../uielements/button/ButtonBase';

//Styles
import styles from './tabs-jss';
import { AppBar } from "./custom_tab"

//Icons
import { AddCircleOutline } from '@material-ui/icons';

//Language
import { LanguageConsumer } from '../../language/LanguageContext';

const useStyles = makeStyles(styles);

const StyledTabs = styled(({ className, ...props }) => (
    <Tabs  {...props} classes={{ flexContainer: 'tabs' }} />
))`
`;

const StyledTab = styled(({ className, ...props }) => (
    <Tab  disableRipple {...props} classes={{ root: `tab-item ${props.active ? `Mui-selected` : ``}` }} />
))`
`;

function AntTabs(props) {
    const classes = useStyles();
    return (
        <LanguageConsumer>
            {
                ({ lang }) =>
                    <React.Fragment>
                        <AppBar>
                            <StyledTabs
                                variant="scrollable"
                                scrollButtons="auto">
                                {
                                    props.selected ? 
                                    props.allTechnical.map((item, index) => (<StyledTab active={props.selected.idTechnicalAnalysis === item.idTechnicalAnalysis ? true : false} onClick={() => props.select(item)} label={item.name} key={`tab_${index}_${item.name}`}/>))
                                    : ``
                                }
                            </StyledTabs>
                            <ButtonBase disabled={props.disableAdd} className='primary' onClick={() => props.add()}>
                                <AddCircleOutline />
                                {lang.technicalAnalyst.add}
                            </ButtonBase>
                        </AppBar>
                    </React.Fragment>
            }
        </LanguageConsumer>
    )
}


export default AntTabs
