import React from 'react';
//Components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ButtonBase } from '../../uielements/button/ButtonBase';
import { IconButton } from '@material-ui/core';
//Modal Fullscreen
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
//Color
import Typography from '@material-ui/core/Typography';

//Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './FormDialog-jss';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import CloseIcon from '@material-ui/icons/Close';
import AddForm from '../Forms/addAnalysis/new';

function EditTechnical(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title">
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    <div className={props.classes.titleModal}>
                        <Typography variant="h6">{props.titleForm}</Typography>
                        <IconButton className={props.classes.closeButton} aria-label="close" onClick={() => props.handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                <AddForm
                    errorSymbol={props.errorSymbol}
                    lang={props.lang}
                    isEdit={props.isEdit}
                    nameTechnical={props.nameTechnical}
                    descriptionTechnical={props.descriptionTechnical}
                    symbolTechnical={props.symbolTechnical}
                    onChange={props.onChange}
                    inputProps={{
                        value : props.value,
                        onChange : props._changeSymbolTechnical
                    }}
                    suggestions={props.suggestions}
                    onSuggestionsFetchRequested={props.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={props.onSuggestionsClearRequested}
                    getSuggestionValue={props.getSuggestionValue}
                    renderSuggestion={props.renderSuggestion}
                    save={props.save}
                    cancel={props.cancel}
                    validateRic={props.validateRic}
                    isValidNewTA={props.isValidNewTA}
                    isLoading={props.isLoading}
                />
                </DialogContent>
                <DialogActions style={{
                    minWidth : '350px'
                }}>
                {
                    !props.isLoading() ? (
                            <>
                            <ButtonBase
                                className="outline"
                                onClick={() => props.handleClose()}>
                                <FontAwesomeIcon icon={faTimesCircle} className={props.classes.iconBtn} />
                                {props.langButton.cancel}
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => {props.save()}}
                                className="primary ml-1"
                                disabled={!props.isValidNewTA()}>
                                <FontAwesomeIcon icon={faCheckCircle} className={props.classes.iconBtn} />
                                {props.langButton.Save}
                            </ButtonBase>
                            </>
                    ) : ''
                }
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withStyles(styles)(EditTechnical);