const styles = theme => (
    {
        boxLoading: {
            alignItems: 'center',
            background: theme.palette.primary.bgColorSecond,
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            position: 'fixed',
            width: '100%',
            zIndex :1
        },
        boxLoadingModal: {
            alignItems: 'center',
            // background: theme.palette.primary.bgColorSecond,
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            // position: 'fixed',
            width: '100%',
            zIndex :99
        },
        noData: {
            textAlign: 'center'
        },
        contentChart: {
            background: theme.palette.primary.bgColorSecond,
            margin: 'auto',
        },
        contentForm: {
            background: theme.palette.primary.bgColorSecond,
        },
        color: {
            color: theme.palette.primary.colorIcon,
        }
    }
)

export default styles;