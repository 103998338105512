import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { CirclePicker, AlphaPicker  } from 'react-color'
class FormVolume extends React.Component {
    render() {
        const { classes, handleChangeComplete, colorUpVolume, colorDownVolume, OpacityVolume, lang } = this.props;
        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
            >
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.upColor}</InputLabel>
                    <CirclePicker
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        color={colorUpVolume}
                        onChangeComplete={(color) => handleChangeComplete(`colorUpVolume`, color)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.downColor}</InputLabel>
                    <CirclePicker
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        color={colorDownVolume}
                        onChangeComplete={(color) => handleChangeComplete(`colorDownVolume`, color)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.opacity}</InputLabel>
                    <AlphaPicker
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        color={OpacityVolume}
                        onChangeComplete={(color) => handleChangeComplete(`OpacityVolume`, color)}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default FormVolume;