import React from 'react';
import { Grid, InputLabel, MenuItem, FormControl } from '@material-ui/core';
import { StyledTextField } from '../../../uielements/textfield/StyledTextField';
import { CirclePicker } from 'react-color';
import { SelectBase } from '../../../uielements/select/SelectBase';

class FormBBands extends React.Component {
    render() {
        const { classes, windowSizeBBand, multiplierBBand, handleChange, maTypeBBand, lang} = this.props;
        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
            >
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.windowSize}</InputLabel>
                    <StyledTextField
                        placeholder="Ej. 10"
                        focused={false}
                        id="period"
                        type="number"
                        fullWidth
                        value={windowSizeBBand}
                        onChange={(e) => handleChange(e, 'windowSizeBBand')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.multiplier}</InputLabel>
                    <StyledTextField
                        placeholder="Ej. 10"
                        id="period"
                        type="number"
                        fullWidth
                        value={multiplierBBand}
                        onChange={(e) => handleChange(e, 'multiplierBBand')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='component-helper'>{lang.maType}</InputLabel>
                    <FormControl className={classes.formControl}>
                        <SelectBase
                            disableUnderline={true}
                            value={maTypeBBand}
                            onChange={(e) => handleChange(e, 'maTypeBBand')}
                            name="maTypeBBand"
                            id="maTypeBBand"
                            displayEmpty
                            className={classes.selectEmpty}
                        >
                            <MenuItem value="" disabled>{lang.option}</MenuItem>
                            <MenuItem value={'sma'}>sma</MenuItem>
                            <MenuItem value={'ema'}>ema</MenuItem>
                        </SelectBase>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}

export default FormBBands;