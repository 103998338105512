import { auxUrl, paramsSession } from './env';

//POST
export const API_POST = (method, url, data, token, refresh) => {
    token = token ? token : getToken();
    refresh = refresh ? refresh : getRefreshToken()
    return new Promise(
        function (resolve, reject) {
            var myHeaders = new Headers();
            myHeaders.set('Authorization', token)
            myHeaders.set('Content-type', 'application/json')
            var myInit = {
                method: method,
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)
            };

            var myRequest = new Request(`${getURLPATH()}${url}`, myInit);

            fetch(myRequest)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.message && responseJson.message === `The incoming token has expired` || responseJson.message === `Unauthorized`) {
                        refreshToken(token, refresh, method, url, data).then(
                            r => {
                                resolve(r)
                            }
                        ).catch(e => reject(e))
                    } else {
                        resolve(responseJson);
                    }
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                });


        }
    )
}

export const API_POST_BEANSTALK = (method, url, data, token, refresh) => {
    token = token ? token : getToken();
    refresh = refresh ? refresh : getRefreshToken()
    return new Promise(
        function (resolve, reject) {
            var myHeaders = new Headers();
            myHeaders.set('Authorization', token)
            // myHeaders.set('Content-type', 'application/json')
            // myHeaders.set('Content-type', 'multipart/form-data')
            var myInit = {
                method: method,
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
                body: data
            };

            //var myRequest = new Request(`${url}`, myInit);
            var myRequest = new Request(`${getURLPATH()}${url}`, myInit);            
            fetch(myRequest)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.message && responseJson.message === `The incoming token has expired` || responseJson.message === `Unauthorized`) {
                        refreshToken(token, refresh, method, url, data).then(
                            r => {
                                resolve(r)
                            }
                        ).catch(e => reject(e))
                    } else {
                        resolve(responseJson);
                    }
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                });


        }
    )
}

export const refreshToken = (token, refresh, method, url, data) => {
    return new Promise(
        (resolve, reject) => {
            let currentUser = getUser();
            let r = {
                "username": currentUser.email,
                "refreshToken": refresh
            }
            API_POST(`POST`, "auth/refreshtoken", r, token, refresh).then(
                res => {
                    let user = getUser();
                    let auxUser = {
                        accessToken: res.data.authenticationResult.idToken,
                        refreshToken: res.data.authenticationResult.accessToken,
                        userData: {
                            email: user.email,
                            userRics: user.userRics
                        }
                    }
                    const params = JSON.parse(sessionStorage.getItem(paramsSession));
                    if (params) {
                        sessionStorage.setItem(params.SESSION_NAME, JSON.stringify(auxUser));
                        API_POST(method, url, data).then(r => resolve(r)).catch(e => reject(e))
                    }
                }
            ).catch(
                error => {
                    reject(error)
                }
            )
        }
    )
}

export const API_POST_UNAUTHORIZED = (url, data, externalURL) => {
    //TODO: add timeout and retry
    return new Promise(
        function (resolve, reject) {
            var myHeaders = new Headers();
            myHeaders.set('Content-type', 'application/json');
            var myInit = {
                method: 'POST',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)
            };

            var myRequest = externalURL ? new Request(url, myInit) : new Request(`${getURLPATH()}${url}`, myInit);

            fetch(myRequest)
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                }).catch((error) => {
                    reject(error);
                });
        }
    )
}

//GET
export const API_GET = (url, params, token) => {
    token = token ? token : getToken();

    return new Promise(
        function (resolve, reject) {
            let finalUrl = `${getURLPATH()}${url}`;

            if (params) {
                finalUrl = new URL(`${getURLPATH()}${url}`);
                Object.keys(params).forEach(key => finalUrl.searchParams.append(key, params[key]))
            }

            var myHeaders = new Headers();
            myHeaders.set('Authorization', getToken());

            var myInit = {
                method: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default'
            };

            var myRequest = new Request(finalUrl, myInit);

            fetch(myRequest)
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                }).catch((error) => {
                    reject(error);
                });
        }
    )
}

export const getURLPATH = () => {
    let session = JSON.parse(sessionStorage.getItem(paramsSession));
    return session ? session.URL_SERVICES : auxUrl;
}

const getToken = () => {
    const params = JSON.parse(sessionStorage.getItem(paramsSession));
    if (params) {
        let user = JSON.parse(sessionStorage.getItem(params.SESSION_NAME));
        return user ? user.accessToken : "";
    } else {
        return "";
    }
}

const getRefreshToken = () => {
    const params = JSON.parse(sessionStorage.getItem(paramsSession));
    if (params) {
        let user = JSON.parse(sessionStorage.getItem(params.SESSION_NAME));
        return user ? user.refreshToken : "";
    } else {
        return "";
    }
}

export const getUser = () => {
    const params = JSON.parse(sessionStorage.getItem(paramsSession));
    if (params) {
        let user = JSON.parse(sessionStorage.getItem(params.SESSION_NAME));
        return user ? user.userData : undefined;
    } else {
        return undefined;
    }
}

export const getCompleteUser = () => {
    const params = JSON.parse(sessionStorage.getItem(paramsSession));
    if (params) {
        let user = JSON.parse(sessionStorage.getItem(params.SESSION_NAME));
        return user ? user : undefined;
    } else {
        return undefined;
    }
}

export const getRics = () => {
    const params = JSON.parse(sessionStorage.getItem(paramsSession));
    if (params) {
        let user = JSON.parse(sessionStorage.getItem(`${params.SESSION_NAME}`));
        return user ? user.userData.userRics : [];
    } else {
        return [];
    }
}

export const searchRic = (ric, rics) => {
    if (!ric || !rics) {
        return '';
    }

    let auxRic = rics.find(r => r.symbol === ric || r.ric === ric);
    if (auxRic !== undefined) {
        switch (auxRic.assetgroupid) {
            case 2:
                return auxRic.mnemonic;
            case 6:
                return auxRic.mainIndexCommonNam;
            default:
                return auxRic.cfname;
        }
    } else {
        return ric;
    }
}

export const validateRic = (ric, rics) => {
    let auxRic = rics.find(r => r.symbol === ric || r.ric === ric || r.mnemonic === ric || r.mainIndexCommonNam === ric || r.cfname === ric);
    return auxRic ? auxRic.name : undefined;
}

export const searchRicSymbol = (ric, rics) => {
    if (!ric) {
        return '';
    }

    if (!rics || rics.length <= 0) {
        return ric;
    }

    let auxRic = rics.find(r => r.name === ric || r.cfname === ric || r.mainIndexCommonNam === ric || r.mnemonic === ric);
    return auxRic ? auxRic.ric : ric;
}