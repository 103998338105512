const styles = theme => ({
    titleModal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    colorCustom: {
        marginTop: '0.5rem',
        width: '100% !important',
    },
    formControl: {
        margin: '8px 8px 8px 0',
        minWidth: 120,
    },
    iconBtn: {
        marginRight: '0.3rem',
    },
});

export default styles;