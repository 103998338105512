import React from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, CardActions, Typography }from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import DropDown from '../../../uielements/dropdown/dropdown';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFolder } from '@fortawesome/free-regular-svg-icons';

//Styles 
import { withStyles } from '@material-ui/core/styles';
import styles from '../cards-jss';
import { StyledCard } from "../card"

//Language
import { LanguageConsumer, LanguageCxt } from '../../../language/LanguageContext';

//Helpers
import {FormatDate} from '../../../utils/helpers'; 

class FileManager extends React.Component {
    
    state={
        selected: false
    }

    //------------------------------ Lifecycle
    //------------------------------ End Lifecycle

    //------------------------------ Helpers
    handleChange = (event) => {
        
        if(this.props.handleCheck){
            this.setState({selected: event.target.checked});
            this.props.handleCheck(this.props.file)(event.target.checked);
        }
    }
    //------------------------------ End Helpers 

    render(){
        const {file, handleDownload, handleOpenDetail, handleEdit, handleDelete, handleEditRecommendation} = this.props; 

        // const fileOptions = [
        //     { label: 'Descargar', handler: handleDownload, key: `download_${file.idFile}` },
        //     { label: 'Información', handler: handleOpenDetail, key: `info_${file.idFile}` },
        //     { label: 'Editar', handler: handleEdit, key: `edit_${file.idFile}` },
        //     { label: 'Eliminar', handler: handleDelete, key: `delete_${file.idFile}` }
        // ]; 

        // const folderOptions = [
        //     { label: 'Editar', handler: handleEdit, key: `edit_${file.idFile}` },
        //     { label: 'Eliminar', handler: handleDelete, key: `delete_${file.idFile}` }
        // ]; 

        const getIcon = (type) => {
            let icon = "";
            switch(type){
                case "PDF":
                    icon = "icon-001-pdf";
                    break; 
                case "ZIP":
                    icon = "icon-003-zip";
                    break; 
                case "IMAGE":
                    icon = "icon-004-img";
                    break; 
                case "OFFICE":
                    icon = "icon-002-doc";
                    break; 
                case "FOLDER":
                    icon = "icon-folder";
                    break;
                default: 
                    icon = "icon-002-doc";
                    break; 
            }

            return(<Icon className={icon}></Icon>)
        }

        const getDate = (date) => {
            if(!date || date === ''){
                return '';
            }

            return FormatDate(date);
        }

        const getOptionsFolder = (lang) => {
            let options = [];
            if(this.props.canEditFile){
                options.push({ label: lang.fileManager.cardOptions.edit, handler: handleEdit, key: `edit_${file.idFile}` });
            }
            if(this.props.canDeleteFile){
                options.push({ label: lang.fileManager.cardOptions.delete, handler: handleDelete, key: `delete_${file.idFile}` })
            }

            return options;
        } 

        const getOptionsFile = (lang, file) => {
            let options = [];
            //Para mantener el orden original
            if(this.props.canDownloadFile){
                options.push({ label: lang.fileManager.cardOptions.download, handler: handleDownload, key: `download_${file.idFile}` });
            }
            options.push({ label: lang.fileManager.cardOptions.detail, handler: handleOpenDetail, key: `info_${file.idFile}` });
            if(this.props.canEditFile){
                options.push(
                    { label: lang.fileManager.cardOptions.edit, handler: file.isRecommendation === 1 ? handleEditRecommendation : handleEdit, key: `edit_${file.idFile}` },
                    //{ label: lang.fileManager.cardOptions.editRecommendation, handler: handleEditRecommendation, key: `editRecommendation_${file.idFile}` }
                );
            }
            if(this.props.canDeleteFile){
                options.push({ label: lang.fileManager.cardOptions.delete, handler: handleDelete, key: `delete_${file.idFile}` });
            }

            return options;
        } 

        return(
            <LanguageConsumer>
                {
                    ({lang}) => 
                    <React.Fragment>
                        <StyledCard className={this.state.selected ? 'active' : ''}>
                            <CardActions className='actions'>
                                
                                {
                                    file.typeFile !== 'FOLDER' &&
                                    <input 
                                    type="checkbox" 
                                    value={this.state.selected} 
                                    onChange={this.handleChange}
                                    name={`check_${file.idFile}`}/>
                                }
                                
                                
                                <DropDown 
                                    id="options" 
                                    options={
                                        file.typeFile === 'FOLDER' ?
                                        getOptionsFolder(lang) :
                                        getOptionsFile(lang, file)
                                    } 
                                    icon={<MoreHorizIcon />}
                                    disabled={false}
                                />
                            </CardActions>
                            <CardContent className="content">
                                <Box className='media'>
                                    { getIcon(file.typeFile) }
                                </Box>
                                <Typography variant="h6" component="h6" onClick={() => handleOpenDetail(file)}>
                                    { file.title }
                                </Typography>
                                <Typography component="p">
                                    { getDate(file.datePublication) }
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </React.Fragment>
                }
            </LanguageConsumer>
        )
    }
}

FileManager.contextType = LanguageCxt; 

FileManager.propTypes = {
    classes : PropTypes.object.isRequired, 
    file: PropTypes.object, 
    handleDownload: PropTypes.func,
    handleOpenDetail: PropTypes.func,
    handleEdit: PropTypes.func,
    handleEditRecommendation: PropTypes.func,
    handleDelete: PropTypes.func,
    handleCheck: PropTypes.func,
    canDeleteFile: PropTypes.bool,
    canDeleteFolder: PropTypes.bool,
    canEditFile: PropTypes.bool,
    canEditFolder: PropTypes.bool,
    canDownloadFile: PropTypes.bool,
}

FileManager.defaultProps = {
    canDeleteFile: false,
    canDeleteFolder: false,
    canEditFile: false,
    canEditFolder: false,
    canDownloadFile: false,
}

export default withStyles(styles)(FileManager);