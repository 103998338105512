var fetch = require('fetch-retry');

//POST
export const API_POST_UNAUTHORIZED = (url, data) => {
    //TODO: add timeout and retry
    return new Promise(
        function (resolve, reject) {
            var myHeaders = new Headers();
            myHeaders.set('Content-type', 'application/json');
            var myInit = {
                method: 'POST',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)
            };

            var myRequest = new Request(url, myInit);

            fetch(myRequest, {
                retries: 3
              })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                }).catch((error) => {
                    reject(error);
                });
        }
    )
}