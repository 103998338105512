import React from 'react';
import { Typography } from '@material-ui/core';

class FormDelete extends React.Component{
    render(){
        const {lang} =this.props;
        return <React.Fragment>
            <Typography>{lang}</Typography>
        </React.Fragment>
    }
}

export default FormDelete;