import React from 'react';
import { Grid, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import { SelectBase } from '../../../uielements/select/SelectBase';
import {CirclePicker, AlphaPicker} from 'react-color';
import { StyledTextField } from '../../../uielements/textfield/StyledTextField';

class FormFibonacci extends React.Component {

    render() {

        const { 
            classes,
            handleChange, 
            handleChangeComplete,
            typeLineFB,
            colorStrokeFB,
            alphaStokeFB,
            strokeWidthFB,
            lang,
            colorFontFill
        } = this.props;

        return <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.type}</InputLabel>
                    <FormControl className={classes.formControl}>
                        <SelectBase
                            disableUnderline={true}
                            value={typeLineFB}
                            onChange={(e) => handleChange(e, 'typeLineFB')}
                            name="typeLineFB"
                            id="typeLineFB"
                            displayEmpty
                            className={classes.selectEmpty}
                        >
                            <MenuItem value="" disabled>{lang.option}</MenuItem>
                            <MenuItem value={'Extend'}>Extend</MenuItem>
                            <MenuItem value={'Ray'}>Ray</MenuItem>
                            <MenuItem value={'Bound'}>Bound</MenuItem>
                        </SelectBase>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.stroke}</InputLabel>
                    <CirclePicker
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        color={colorStrokeFB}
                        onChangeComplete={(color) => handleChangeComplete(`colorStrokeFB`, color)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.fontFill}</InputLabel>
                    <CirclePicker
                        className={classes.colorCustom}
                        circleSize={20}
                        circleSpacing={10}
                        color={colorFontFill}
                        onChangeComplete={(color) => handleChangeComplete(`colorFontFill`, color)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.opacity}</InputLabel>
                    <AlphaPicker
                        color={alphaStokeFB}
                        className={classes.colorCustom}
                        onChangeComplete={(color) => handleChangeComplete(`alphaStokeFB`, color)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-helper">{lang.width}</InputLabel>
                    <StyledTextField
                        autoFocus
                        id="strokeWidthFB"
                        type="number"
                        fullWidth
                        value={strokeWidthFB}
                        onChange={(e) => handleChange(e, 'strokeWidthFB')}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

export default FormFibonacci;