import {API_POST,API_POST_BEANSTALK, getUser, searchRic, API_POST_UNAUTHORIZED} from './index'; 
import {download, fileManagerAPI} from './env';
import pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

//Services
//----------------------- Folder
//Create new folder - one folder
export const createFolder = (file, parentRoute) => {

    let user = getUser(); 

    return new Promise((resolve, reject) => {
    
        API_POST('POST', "s3/createfolder", { "parentRoute" : parentRoute, "name" : file.name })
        .then(
            response => {
                if(response.status === "OK"){
                    API_POST('POST', "files/add", {
                        "key": parentRoute + "/" + file.name + "/",
                        "user": user ? user.email : "",
                        "name": file.name,
                        "ext": "",
                        "registrationDate": getDateUI(),
                        "typeFile": "FOLDER",
                        "bookmark": "",
                        "fileSize": 0,
                        "isRecommendation": 0
                      }).then(
                          response => {
                             
                                if(response.status === "OK"){
                                    let elasaticFile = {
                                        idFile: response.data,
                                        name: file.name,
                                        title: file.name,
                                        typeFile: 'FOLDER',
                                        folder: parentRoute, 
                                        datePublication: new Date().setHours(0,0,0,0),
                                        isRecommendation: 0
                                    }
    
                                    upsertToElastic(elasaticFile)
                                        .then()
                                        .catch(err => {
                                            console.log("ErrElastic: ", err);
                                        })
                                        .finally(() => {
                                            setTimeout(() => resolve(response), 2000)
                                        });
                                }else{
                                    reject(response);
                                }
                          }
                      ).catch(
                          err => {
                              reject(err);
                          }
                      )
                }else{
                    reject(response.data);
                }
            }
        )
        .catch(
            err => {
                console.log("Err creating folder: ", err);
                reject("-2");
            }
        );
    });
}

export const deleteFolder = (folder, file) => { 
    return new Promise((resolve, reject) => {
        listFolderFiles(folder.substring(0, folder.length - 1)).then(
            res => {
                let listedObject = res;

                var deleteServer = []; //Database
                let deleteElastic = []; //Elasticsearch

                deleteServer.push(folder);

                listedObject.forEach(({ key, id }) => {
                    deleteServer.push(key);
                    deleteElastic.push(id);
                });

                //Delete from Elastic search first
                deleteToElastic(file).then(
                    response => {
                        API_POST('POST', "s3/deletefolder", deleteServer).then(
                            resDelete => {
                                if(resDelete.status === "OK"){
                                    //Si a este punto la carpeta ya no existe y falla, aún así resolver
                                    //Ya que a ese punto los archivos ya no existen
                                    deleteMultipleToElastic(deleteElastic).then(
                                        r => {
                                            API_POST('POST', "files/delete", deleteServer).then(
                                                resFinal => {
                                                    resolve("1");
                                                }
                                            ).catch(
                                                err => resolve("1")
                                            )
                                        }
                                    );
                                }else{
                                    //TODO: Volver a meter la carpeta y dar reject
                                    reject("1");
                                }
                            }
                        ).catch(
                            //TODO: Volver a meter la carpeta y dar reject
                            err => reject("1")
                        )
                    }
                ).catch(
                    err => {
                        console.log("Err: ", err);
                        reject("-2");
                    }
                );
            }
        ).catch(
            err => reject("-1")
        );
    });
}

export const copyFileFolder = (fileFolderData) => {

    const {content, paramsCopyKey, newKey, rics, oldLocation, shouldBeCreated} = fileFolderData;
    let user = getUser(); 

    return new Promise((resolve, reject) => {
        if(content.key === oldLocation){
            resolve("1");
        }
        

        Promise.resolve('START')
        .then(_=> {
            if (shouldBeCreated) {
                const newFolder = { 
                    "parentRoute" : paramsCopyKey.replace(`/${content.name}/`, ''), 
                    "name" : content.name
                }
        
                return API_POST('POST', "s3/createfolder", newFolder)
            }

            const copyReq = {
                "location" : `${content.key}`,
                "newlocation" : `${paramsCopyKey}`
            }
    
            return API_POST('POST', 's3/copyFile', copyReq)
            
        })
        .then(resParent => {
            if(resParent.status.toUpperCase() === "ERROR") {
                reject(resParent);
                return false;
            }
            var date = new Date();
            if (content.datePublication) {
                date = new Date(content.datePublication);
            }
            if (content.displayDate) {
                date = new Date(content.displayDate);

            }

            const dateString = parseDate(date);
    
            const fileAddBody = {
                "key": paramsCopyKey,
                "user": user? user.email : '',
                "name": content.name,
                "ext": content.ext,
                "registrationDate": getDateUI(),
                "typeFile": content.fileType,
                "bookmark": content.bookmark,
                "fileSize" : !content.size ? 0 : content.size,
                "description" : content.descripcion,
                "instrument" : !content.emisora ? "" : content.emisora, 
                "title": content.fileType === 'FOLDER' ? content.name : content.title, 
                "displayDate": dateString,
                "source": content.source ? content.source : '',
                "recommendation": content.recommendation ? content.recommendation : '',
                "analyst": content.analyst ? content.analyst :'',
                "isRecommendation": content.isRecommendation ? content.isRecommendation : 0
            }

            API_POST('POST', 'files/add', fileAddBody).then( resAdd => {
                    if(resAdd.status.toUpperCase() === "ERROR"){
                        reject(resAdd);
                        return false;
                    }
    
                    if(!resAdd.data){
                        reject(resAdd);
                        return false;
                    }
    
                    let elasticKey = newKey.charAt(newKey.length - 1) === "/" 
                        ? newKey.substring(0, newKey.length - 1) 
                        : newKey;
                    
                    if (content.fileType === 'FOLDER') {
                        elasticKey = paramsCopyKey.replace(`/${content.name}/`, '')
                    } else {
                        elasticKey = paramsCopyKey.replace(`/${content.name}`,'');
                    }

                    const fileElastic = {
                        idFile: resAdd.data,
                        name: content.name,
                        title: content.fileType === 'FOLDER' ? content.name : content.title, 
                        datePublication: date,
                        typeFile: content.fileType === 'FOLDER' ? content.fileType : content.bookmark,
                        folder: elasticKey,
                        description: content.descripcion,
                        ric: searchRic(content.emisora, rics),
                        isRecommendation: content.isRecommendation ? content.isRecommendation : 0
                    }
    
                    //For recommendations
                    if(fileElastic.isRecommendation === 1){
                        fileElastic.recommendation = {
                            description: content.recommendation,
                            analyst: content.analyst,
                            source: content.source
                        }
                    }

                    upsertToElastic(fileElastic).then().catch(
                        err => {
                            console.log("Err: ", err);
                            reject("-1");
                        }
                    ).finally(
                        () => {
                            resolve("1");
                        }
                    )
                }).catch(
                    err => {
                        reject("-1");
                    }
                )
        })
    });
}

export const getFolders = () => {
    let user = getUser();
    return API_POST("files/getFolders", { "user": user ? user.email : "" });
}

export const getFoldersTree = () => {
    let user = getUser(); 
    return API_POST('POST', "files/gettreefolder", {"user": user ? user.email : ''});
}

export const changeNameFolder = (data) => {
    const {location, newlocation, file, rics} = data;
    //var name = newlocation;
    let user = getUser();

    return new Promise((resolve, reject) => {
        
        const newFolder = { 
            "parentRoute" : file.folder, 
            "name" : file.newName
        }

        API_POST('POST', "s3/createfolder", newFolder)
            .then((folderRes) => {
                
                let request = {
                    "key": newlocation,
                    "user": user ? user.email : '',
                    "name": file.newName,
                    "registrationDate": getDateUI(),
                    "typeFile": "FOLDER",
                    "bookmark": '',
                    "ext": '',
                    "fileSize" : 0,
                    "isRecommendation": 0
                }

                API_POST('POST', 'files/add', request).then(
                    responseAdd => { 
                        if(responseAdd.status !== "OK"){
                            reject("-1");
                            return false;
                        }
        
                        let elasaticFile = {
                            idFile: responseAdd.data,
                            name: file.newName,
                            title: file.newName,
                            typeFile: 'FOLDER',
                            folder: file.folder, 
                            datePublication: new Date().setHours(0,0,0,0),
                            isRecommendation: 0
                        }
        
                        upsertToElastic(elasaticFile).then(
                            responsseUTE => {
                                if(responsseUTE.status){
                                    reject(responsseUTE);
                                    return false;
                                }
        
                                listFolderFiles(location.substring(0, location.length - 1)).then(
                                    responseLFF => {
                                        var content = responseLFF;
        
                                        if(content.length > 0){
                                            content = content.filter(r => r.key !== location);
                                            copyMultipleFilesAsync(content, location, newlocation, rics).then(
                                                rMF => {
            
                                                    // rMF.errors.length === content.length
                                                    if(rMF.errors.length > 0){
                                                        reject("-2");
                                                    }
            
                                                    deleteFolder(location, file).then(
                                                        (responseDF) => {
                                                            resolve("1");
                                                        }
                                                    ).catch(
                                                        errDF => {
                                                            reject("-2");
                                                        }
                                                    );
                                                }
                                            );
                                        }else{
                                            deleteFolder(location, file).then(
                                                (responseDF) => {
                                                    resolve("1");
                                                }
                                            ).catch(
                                                errDF => {
                                                    reject("-2");
                                                }
                                            );
                                        }
                                    }
                                );
                            }
                        ).catch(
                            err => {
                                reject("-1");
                            }
                        );
                    }
                ).catch(
                    err => {
                        reject("-1");
                    }
                )
            })
    });
}

const copyMultipleFiles = (files, location, newlocation, rics) => {
    let promises = [];
    let errors = [];
    let success = []; 
    let folderParents = [];

    let response = {
        success: [],
        errors: []
    }
    files.map(f => {

        var paramsCopyKey = f.key.replace(location, newlocation);

        const data = {
            content: f, 
            paramsCopyKey, 
            newKey: newlocation, 
            rics, 
            oldLocation: location,
            shouldBeCopy: true,
        }


        promises.push(copyFileFolder(data).then(
            r => {

                success.push(f);
                return r;
            }
        ).catch(
            err => {
                errors.push(err);
                return err;
            }
        ));

        return f;
    });

    return Promise.all(promises).then(r => {
        response.success = success;
        response.errors = errors;
        return response;
    });
}

const groupFilesFolder = (entities) => {

    let files = [];
    let folders = [];

    const shortener = (value) => value.charAt(value.length - 1) === "/" 
            ? value.substring(0, value.length - 1)  
            : value;  
    
    const sorterer = (type) => (ab, bc) => {
        let a = "",b = "";
        if (type === 'folder') {
            b = shortener(ab.key).split("/").length;
            a = shortener(bc.key).split("/").length;
        }

        if (a > b) {
            return -1;
        }
        if (b > a) {
            return 1;
        }
        return 0;
    }

    folders = entities
        .filter(e => e.fileType === 'FOLDER')
        .sort(sorterer('folder'));

    files = entities
    .filter(e => e.fileType !== 'FOLDER')
    .sort(sorterer('files'));

    return {
        folders,
        files, 
    };


}

const copyMultipleFilesAsync = async (entities, location, newlocation, rics) => {
    let promises = [];
    let errors = [];
    let success = []; 
    let response = { success: [], errors: [] }


    const groups = groupFilesFolder(entities);

    // Itirates in this order so the folders can be created first 
    // And the files get to copy correctly
    // 
    for (const key in groups) {
        for (const f of groups[key]) {
            var paramsCopyKey = f.key.replace(location, newlocation);

            const fileData = {
                content: f, 
                paramsCopyKey, 
                newKey: newlocation, 
                rics, 
                oldLocation: location,
                shouldBeCreated: f.fileType === 'FOLDER',
            }

            try {
                const f = await copyFileFolder(fileData);
                            
                success.push(f);
            } catch(error) {
                errors.push(error);
            }
        }
    }

    response.success = success;
    response.errors = errors;

    return Promise.resolve(response);

}
//----------------------- End Folder

//----------------------- File
//Get File description
export const getDescriptionFile = (key) => {
    return new Promise((resolve, reject) => {

        API_POST('POST', "files/getdescription", { "key" : key })
        .then(
            res => {
                if(res.status === "OK"){
                    resolve(res.data);
                }else{
                    reject();
                }
            }
        )
        .catch(
            err => {
                reject();
            }
        );
    });
}

export const listFolderFiles = (path) => {
    return new Promise((resolve, reject) => {
        API_POST('POST', "s3/listfolderfiles", {"path" : path + "/", "delimiter" : ""})
        .then(
            res => {
                if(res.status === "OK"){
                    resolve(res.data.objectSummaries);
                }else{
                    reject({});
                }
            }
        )
        .catch(
            err => {
                reject({});
            }
        );
    });
}

export const uploadFile = (file, path, index) => {

    let user = getUser();
    return new Promise((resolve, reject) => {

        try{
            var extension = file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);
            var nameFile = file.name;
            
            // if(index > 0) {
            //     nameFile = file.name.substring(0, file.name.lastIndexOf(".")) + " (" + index + ")." + extension;
            // }
    
            let existsFile = checkExistingKey(nameFile, index, file, extension, path);
            existsFile.then(resExists => {

                nameFile = resExists;

                // fileToBase64(file.file)
                Promise.resolve(file.file)
                .then(
                    fileRequest => {
                        const formData  = new FormData();
                        
                        formData.append('path', path)
                        formData.append('nameFile', nameFile)
                        formData.append('body', fileRequest, URL.createObjectURL(fileRequest))

                        API_POST_BEANSTALK('POST', `/s3/uploadFile`, formData)
                        .then(
                            respUpload => {
                                if(respUpload.status === "OK"){
                                    if(file.description === undefined) {
                                        file.description = "";
                                    }

                                    var date = file.datePublication ? new Date(file.datePublication) : new Date();
                                    const dateString = parseDate(date);

                                    API_POST('POST', 'files/add', {
                                        "key": path + "/" + nameFile,
                                        "user": user ? user.email : "",
                                        "name": nameFile,
                                        "ext": extension,
                                        "registrationDate": getDateUI(),
                                        "typeFile": "FILE",
                                        "bookmark": file.typeFile,
                                        "fileSize" : Number.isInteger(file.size) ? Math.round(file.size) : 0,
                                        "description" : file.description,
                                        "instrument" : !file.instrument ? "" : file.instrument, 
                                        "title": file.title, 
                                        "displayDate": dateString,
                                        "source": file.source ? file.source : '',
                                        "recommendation": file.recommendation ? file.recommendation : '',
                                        "analyst": file.analyst ? file.analyst :'',
                                        "isRecommendation": file.isRecommendation ? file.isRecommendation : 0
                                      }).then(
                                          resAdd => {
                                                if(resAdd.status.toUpperCase() === "ERROR"){
                                                   return reject("-1");
                                                }

                                                //Files to elastic
                                                let fileElastic = {
                                                    idFile: resAdd.data,
                                                    name: nameFile,
                                                    title: file.title, 
                                                    datePublication: file.datePublication,
                                                    typeFile: file.typeFile,
                                                    folder: path,
                                                    description: file.description,
                                                    ric: file.ric,
                                                    isRecommendation: file.isRecommendation ? file.isRecommendation : 0
                                                }

                                                //For recommendations
                                                if(fileElastic.isRecommendation === 1){
                                                    fileElastic.recommendation = {
                                                        description: file.recommendation,
                                                        analyst: file.analyst,
                                                        source: file.source
                                                    }
                                                }

                                                if(extension == "pdf"){
                                                    parsePDF(`${path}/${nameFile}`, fileElastic).then(
                                                        rPDF => {
                                                            fileElastic = rPDF;
                                                        }
                                                    ).catch(
                                                        errPDF => {
                                                            fileElastic = errPDF;
                                                        }
                                                    ).finally(
                                                        () => {
                                                            upsertToElastic(fileElastic).then(rePDF => {
                                                                return resolve(rePDF);
                                                            }).catch( err => {
                                                                console.log("Err: ", err);
                                                                return reject(err)
                                                            });
                                                        }
                                                    );
                                                }
                                                else if(extension === "docx" || extension === "doc"){
                                                    const host = `${window.location.protocol}//${window.location.hostname}`;
                                                    //const host = "https://linkToHost";
                                                    path = path.charAt(0) === "/" ? path : `/${path}`;
                                                    API_POST_UNAUTHORIZED(`${fileManagerAPI}/files/parsedocument`, {url: `${host}${path}/${encodeURIComponent(nameFile)}`}, true).then(
                                                        resDOC => {
                                                            if(resDOC.status.toUpperCase() === "OK"){
                                                                fileElastic.content = resDOC.data.value ? resDOC.data.value : '';
                                                            }
                                                        } 
                                                    ).catch(err => console.log("Err: ", err)).finally(
                                                        () => {
                                                            upsertToElastic(fileElastic).then(reDoc => {
                                                                return resolve(reDoc);
                                                            }).catch( err => { 
                                                                console.log("Err: ", err);
                                                                return reject(err)
                                                            });
                                                        }
                                                    )
                                                }
                                                else{
                                                    upsertToElastic(fileElastic).then(reElastic => {
                                                        return resolve(reElastic);
                                                    }).catch( err => { 
                                                        console.log("Err: ", err);
                                                        return reject(err)
                                                    });
                                                }
                                          }
                                      ).catch(
                                          err => {reject("-1")}
                                      )
                                }else{
                                    reject("-1");
                                }
                            }
                        ).catch(
                            err => {reject("-1")}
                        )
                    }
                ).catch(
                    err => {reject("-1")}
                )
                //resolve(existsFile);
            });
        }catch(err){
            console.log(err);
            reject(err);
        }
        
        //Verifica si el Key a usar no esta usado, de ser as? guarda con otro nombre
        // API_POST('POST', 'files/existskey', { "key" : path + "/" + nameFile })
        // .then(
        //     res => {
        //         if(!res.data){
                    
        //         }else{
        //             index = index + 1;
        //             uploadFile(file, path, index);
        //         }
        //     }
        // ).catch(
        //     err => { reject("-1") }
        // );
    });
}

const checkExistingKey = (nameFile, index, file, extension, path) => {
    if(index > 0) {
        nameFile = file.name.substring(0, file.name.lastIndexOf(".")) + " (" + index + ")." + extension;
    }

    return API_POST('POST', 'files/existskey', { "key" : path + "/" + nameFile }).then(existsKey => {
        if(!existsKey.data){
            return nameFile;
        }else{
            return checkExistingKey(nameFile, index + 1, file, extension, path);
        }
    }).catch(errExists => {
        return -1;
    });
}

export const uploadMultipleFiles = (files, path) => {
    let success = []; 
    let errors = []; 

    let response = {
        message: "",
        success: [], 
        errors: []
    }

    let promises = [];
    files.map((file) => {
        promises.push(uploadFile(file, path, 0).then(
            res => {
                success.push(file);
                return "1";
            }
        ).catch(err => {
            errors.push(file);
            return "-1";
        }));

        return file;
    })

    return Promise.all(promises).then(
        result => {
            response.success = success; 
            response.errors = errors; 
            return response;
        }
    )
}

export const getFile = (key) => {

    return new Promise((resolve, reject) => {

        API_POST("POST", "s3/getFile", { "key" : key })
        .then(
            res => {
                if(res.status === "OK"){
                    resolve(res.data);
                }else{
                    reject(res.data);
                }
            }
        )
        .catch(
            err => {
                reject(err);
            }
        );
    });
}

export const deleteFile = (key, file) => {

    return new Promise((resolve, reject) => {

        //Delete from Elasticsearch
        deleteToElastic(file).then(
            result => {
                //Delete from S3
                API_POST('POST', "s3/deletefile", { "key" : key })
                .then().catch(errS3 => console.log(errS3)).finally(() => {
                    //Delete from RDS
                    API_POST('POST', "files/delete", [key])
                    .then().catch(errRDS => console.log(errRDS)).finally(() => {
                        resolve("1");
                    });
                }); 
            }
        ).catch(
            err => {
                reject("-1");
            }
        ); 
    });
}

export const deleteMultipleFiles = (files) => {
    let success = []; 
    let errors = []; 

    let response = {
        message: "",
        success: [], 
        errors: []
    }

    let promises = [];

    files.map((file, index) => {
        let key = `${file.folder}/${file.index}`;
        promises.push(deleteFile(key, file).then(
            r => {
                success.push(file);
                return "Success";
            }
        ).catch(
            e => {
                errors.push(file);
                return "Error";
            }
        ));

        return file;
    })

    return Promise.all(promises).then(
        result => {
            response.success = success; 
            response.errors = errors; 
            return response;
        }
    )
}

export const updateFile = (key, file) => {
    return new Promise((resolve, reject) => {

        var date = file.datePublication ? new Date(file.datePublication) : new Date();
        const dateString = parseDate(date);

        API_POST('POST', "/files/update", {
            "key" : key,
            "newKey" : key,
            "description" : file.description, 
            "title": file.title, 
            "instrument": file.instrument ? file.instrument : '', 
            "displayDate": dateString, 
            "name" : file.typeFile !== 'FOLDER' ? file.name : "",
            "source": file.source ? file.source : '',
            "recommendation": file.recommendation ? file.recommendation : '',
            "analyst": file.analyst ? file.analyst :'',
            "isRecommendation": file.isRecommendation ? file.isRecommendation : 0
          }
        ).then(
            res => {
                if(res.status === "OK"){
                    
                    //Send to elastic
                    const fileToElastic = {
                        idFile: file.idFile,
                        title: file.title, 
                        datePublication: file.datePublication,
                        typeFile: file.typeFile,
                        folder: file.folder,
                        description: file.description,
                        ric: file.ric,
                        isRecommendation: file.isRecommendation ? file.isRecommendation : 0
                    }

                    //For recommendations
                    if(fileToElastic.isRecommendation === 1){
                        fileToElastic.recommendation = {
                            description: file.recommendation,
                            analyst: file.analyst,
                            source: file.source
                        }
                    }

                    upsertToElastic(fileToElastic).then().catch().finally(
                        () => {
                            resolve(res.data);
                        }
                    )
                }else{
                    reject("1");
                }
            }
        ).catch(
            err => {
                reject("1");
            }
        );
    });
}

const deleteIncompleteFile = (file) => {
    return new Promise((resolve, reject) => {
        file.folder = file.folder.charAt(file.folder.length - 1) === "/" ? file.folder : `${file.folder}/`;
        //Delete from S3
        API_POST('POST', "s3/deletefile", { "key" : `${file.folder}${file.name}` })
        .then().catch().finally(() => {
            //Delete from RDS
            API_POST('POST', "files/delete", [`${file.folder}${file.name}`])
            .then().catch(err => console.log(err)).finally(() => {
                resolve({error: 400, reason: "Error in elastic"});
            });
        });
    });
}
//----------------------- End File

//----------------------- Helpers
const getDateUI = () => {
    var date = new Date();
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
}

export const getBookmark = (extension) => {
    var bookmark = "GENERAL";

    switch(extension.toLowerCase()) {
      case "zip":
      bookmark = "ZIP";
      break;
      case "html":
      bookmark = "WEB";
      break;
      case "mp4":
      case "avi":
      case "flv":
      bookmark = "VIDEO";
      break;
      case "mp3":
      bookmark = "AUDIO";
      break;
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
      case "ppt":
      case "pptx":
      case "txt":
      bookmark = "OFFICE";
      break;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      bookmark = "IMAGE";
      break;
      case "pdf":
      bookmark = "PDF";
      break;
      case "":
      bookmark = "OFFICE";
      break;
      default:
        bookmark = "OFFICE";
        break;

    }

    return bookmark;
}

const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
    //   var myReader = new FileReader();

    //   myReader.onloadend = (e) => {
    //     resolve(myReader.result.toString().split("base64,")[1]);
    //   }

    //   myReader.readAsDataURL(file);
        resolve(file);

    });
}

export const getRootFolder = () => {
    let params = JSON.parse(sessionStorage.getItem("params")); 
    return params ? params.AWS_FILEMANAGER_FOLDER : '';
}

const parseDate = (date) => {
    var month = (date.getMonth() + 1) <= 9 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1); 
    var day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    var dateString = `${day}/${month}/${date.getFullYear()}`; 
    return dateString;
}

export const parsePDF = (file, item) => {
    const host = `${window.location.protocol}//${window.location.hostname}`;
    //const host = 'https://linkToS3Host';
    file = file.charAt(0) === "/" ? file : `/${file}`;
    let pdf = pdfjsLib.getDocument(`${host}${file}`);
    let finalContent = "";
    
    //content
    return new Promise((resolve, reject) => {
        if(!file || !item){
            reject(null);
        }

        pdf.promise.then(
            (doc) => {
                let pages = doc.numPages;
                let pagesPromises = [];
                for(let x = 1; x <= pages; x++){
                    pagesPromises.push(doc.getPage(x).then((page) => {
                            let contentPromise = page.getTextContent(); 
                            return contentPromise.then((content) => {
                                let pageContent = "";
                                let contents = content.items.map(c => {
                                    return c.str;
                                });

                                pageContent = contents.join('');
        
                                finalContent = finalContent.concat(`${pageContent} `);
                                return pageContent;
                            });
                        })
                    );
                }

                Promise.all(pagesPromises).then((response) => {
                    item.content = finalContent;
                    resolve(item);
                })
            }
        ).catch(err => {
            console.log("Error at: ", err);
            reject(item);
        });
    });
}
//----------------------- End Helpers

//----------------------- ElasticSearch
export const upsertToElastic = (file) => {
    if(!file){
        return false; 
    }

    let doc = {
        id: file.idFile,
        doc: file
    }
    
    return new Promise((resolve, reject) => {
        API_POST('POST', 'elastic/file/upsert', doc).then(
            res => {
                if(res.error){
                    deleteIncompleteFile(file).then(r => {
                        return reject(res);
                    });
                }else{
                    return resolve(res);
                }
            }
        ).catch(
            err => {
                console.log('Err upsert: ', err);
                return reject(err);
            }
        )
    })
} 

export const deleteToElastic = (file) => {
    if(!file){
        return false; 
    }

    let doc = {
        id: file.idFile
    }
    
    return new Promise((resolve, reject) => {
        API_POST('POST', 'elastic/file/delete', doc).then(
            res => {
                resolve(res);
            }
        ).catch(
            err => {
                reject(err);
            }
        )
    })
} 

const deleteMultipleToElastic = (files) => {
    let success = []; 
    let errors = []; 

    let response = {
        message: "",
        success: [], 
        errors: []
    }

    let promises = [];
    files.map((file) => {
        let cFile = { idFile: file }
        promises.push(deleteToElastic(cFile).then(
            r => {
                console.log("Delete multiple success: ", cFile);
                success.push(cFile);
                return "Success";
            }
        ).catch(
            err => {
                console.log("Err delete multiple file: ", cFile);
                errors.push(cFile);
                return "Error"; 
            }
        ));

        return file;
    })

    return Promise.all(promises).then(
        result => {
            response.success = success; 
            response.errors = errors; 
            return response;
        }
    )
}
//----------------------- End ElasticSearch